import React, { useEffect, useState } from "react";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import { Form, Button } from "react-bootstrap";

import EventEmpty from "./EventEmpty";
import "./Events.css";

const EventPolicy = ({}) => {
  const [authedAxios, tokenSet] = useAuthenticatedAxios(true);

  return (
    <section className="travel-policy-info">
      <h2>Travel policy</h2>
      <p>
        We’ll book travel based on your company travel policy. For destinations
        with a negotiated hotel rate we'll prioritise this.
      </p>
      <div className="policy-details">
        <p>
          Your travel policy is set by your service administrator. Please
          contact them with any questions.
        </p>
        <div className="policy-verbatim">
          <p>
            <b>Flights</b>
            <ul>
              <li>
                All flights below 6 hours in length should be in an Economy
                cabin unless a higher standard cabin is cheaper.
              </li>
              <li>
                Flights under 6 hours can be in one continuous flight (no
                connections) with no requirement to break the flight where
                cheaper.
              </li>
              <li>
                Flights above 6 hours can be taken in a Premium Economy cabin
                for all employees with the discretion of the Chief People
                Officer or Chief Executive Officer to allow for flights to be
                taken in Business Class.
              </li>
              <li>
                Flights above 6 hours in length taken in a Premium cabin should
                be broken with one connection where this is not overly
                burdensome (layover greater than 3 hours) and where the cost is
                more than 50% cheaper.
              </li>
            </ul>
            <b>Trains</b>
            <ul>
              <li>
                Trains should always be taken in a standard-class carriage where
                this is the cheapest available carriage and there is no
                requirement to break the trip where cheaper.
              </li>
            </ul>
            <b>Hire Cars</b>
            <ul>
              <li>
                Hire cars can be rented where cheaper then a train or other form
                of transport but must be in the lowest available, valid class
                for the driver.
              </li>
            </ul>
            <b>Personal Car Use</b>
            <ul>
              <li>
                Mileage can be reimbursed according to the company's standard
                expense policy.
              </li>
            </ul>
          </p>
        </div>
      </div>
      <br />
      {/*       <div className="override-policy">
        <h4>Override travel policy</h4>
        <div className="override-policy-form">
          <div className="override-select">
            <input type="checkbox" id="override-policy" />
            <label htmlFor="override-policy">
              Override travel policy for this booking
            </label>
          </div>
          <div className="amend-policy">
            <label className="disabled-label" htmlFor="change-policy">
              What changes do you want to make?
            </label>
            <textarea disabled id="change-policy"></textarea>
            <Button disabled variant="primary">
              Save changes
            </Button>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default EventPolicy;
