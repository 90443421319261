import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { utcToZonedTime } from "date-fns-tz";

import { ReactComponent as ChevronRightBlue } from "../../img/chevron-right-blue.svg";

import { useAuth0 } from "@auth0/auth0-react";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

import { Loader } from "@aws-amplify/ui-react";
import EventStatus, {
  Event as ImpalaEvent,
  EventDate,
} from "../../components/EventStatus";
import ManageDates from "../../components/ManageDates";
import EventAttendees from "../../components/EventAttendees";
import EventStats from "../../components/EventStats";
import Error from "../../components/Error";
import EventImportantDates from "../../components/EventImportantDates";
import WarningModal from "../../components/WarningModal";
import EventNavigation from "../../components/EventNavigation";
import PricingListModal from "../../components/PricingListModal";
import BillingPortal from "../../components/BillingPortal";
import useCompanyDetails, { CompanyDetails } from "../../lib/useCompanyDetails";
import ManageHotels from "../../components/ManageHotels";

const Event = () => {
  let userAuth = useAuth0();
  const [axios, tokenSet] = useAuthenticatedAxios(true);

  const { eventId } = useParams();
  const [loadingEventData, setLoadingEventData] = useState(true);
  const [event, setEvent] = useState<ImpalaEvent>();
  const [refetchEvent, setRefetchEvent] = useState(false);
  const [subPage, setSubPage] = useState("");
  const [eventPage, setEventPage] = useState("Event Details");
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [warningModalText, setWarningModalText] = useState("");
  const [warningModalLabel, setWarningModalLabel] = useState("");
  const [PricingListModalOpen, setPricingListModalOpen] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>();
  const [isSelfServe, setIsSelfServe] = useState(false);

  useCompanyDetails(setCompanyDetails, false);

  useEffect(() => {
    if (!userAuth.isAuthenticated && !userAuth.isLoading) {
      userAuth.loginWithRedirect({
        appState: { path: window.location.pathname },
        authorizationParams: {
          redirect_uri: window.location.origin + "/callback",
        },
      });
    } else if (tokenSet) {
      const fetchEventDetails = async () => {
        try {
          setLoadingEventData(true);
          const response = await axios.get(`/api/users/events/${eventId}`);
          setEvent({
            realEvent: response.data.event ? true : false,
            details: response.data.prospective_event,
            dates: response.data.event_dates
              .sort((a: EventDate, b: EventDate) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
              )
              .map((d: EventDate) => {
                return {
                  ...d,
                  date: utcToZonedTime(
                    new Date(d.date.toString() + "T00:00:00Z"),
                    "Etc/UTC"
                  ),
                };
              }),
            attendees: response.data.event_attendees,
            owner: {
              name: response.data.event?.owner_name,
              email: response.data.event?.owner_email,
            },
            calendlyInvite: response.data.calendly_invite,
            offers: [],
            groups: response.data.event_groups,
            hotels: response.data.event_hotels,
            marketing: response.data.event_marketing,
            subscription: response.data.subscription,
            customer: response.data.customer,
          });
          setLoadingEventData(false);
        } catch (error) {
          console.error("Error fetching event details:", error);
          setLoadingEventData(false);
        }
      };

      fetchEventDetails();
    }
  }, [axios, eventId, tokenSet, userAuth, refetchEvent]);

  useEffect(() => {
    const fetchData = async () => {
      if (tokenSet) {
        try {
          const response = await axios.get("/api/users/active-services");
          setIsSelfServe(
            response?.data?.services.events_service &&
              !response?.data?.services.hotel_offers_service
          );
        } catch {
          console.error("Error retrieving active services");
        }
      }
    };

    fetchData();
  }, [axios, tokenSet]);

  useEffect(() => {
    if (tokenSet) {
      const fetchCompanyDetails = async () => {
        try {
          const response = await axios.get(`/api/users/company-details`);
          setCompanyDetails(response.data);
        } catch (error) {
          console.error("Error fetching company details:", error);
        }
      };

      fetchCompanyDetails();
    }
  }, [axios, tokenSet]);

  const openWarningModal = (label: string, text: string) => {
    setWarningModalText(text);
    setWarningModalLabel(label);
    setWarningModalOpen(true);
  };

  const renderPage = (page: string) => {
    switch (page) {
      case "Event Details":
        return (
          <EventStatus
            event={event!}
            setPage={setEventPage}
            setSubPage={setSubPage}
            userAuth={userAuth}
            onUpdate={() => setRefetchEvent(!refetchEvent)}
            loadingEventData={loadingEventData}
          />
        );
      case "Manage Dates":
        return (
          <ManageDates
            event={event!}
            setPage={setEventPage}
            setSubPage={setSubPage}
            subPage={subPage}
            onUpdate={() => setRefetchEvent(!refetchEvent)}
          />
        );
      case "Hotels":
        return (
          <ManageHotels
            openWarningModal={openWarningModal}
            event={event!}
            setPage={setEventPage}
            setSubPage={setSubPage}
            subPage={subPage}
            onUpdate={() => setRefetchEvent(!refetchEvent)}
          />
        );
      case "Attendees":
        return (
          <EventAttendees
            openWarningModal={openWarningModal}
            event={event!}
            setSubPage={setSubPage}
            subPage={subPage}
            onUpdate={() => setRefetchEvent(!refetchEvent)}
          />
        );
      case "Stats & Costs":
        return <EventStats event={event} />;
      case "Planning Schedule":
        return <EventImportantDates event={event} />;
      case "Billing":
        return <BillingPortal />;
      default:
        return <Error />;
    }
  };

  return (
    <Container fluid>
      <WarningModal
        setIsOpen={setWarningModalOpen}
        modalIsOpen={warningModalOpen}
        text={warningModalText}
        label={warningModalLabel}
      ></WarningModal>
      {userAuth.user && (
        <PricingListModal
          setIsOpen={setPricingListModalOpen}
          modalIsOpen={PricingListModalOpen}
          user={userAuth.user}
        ></PricingListModal>
      )}
      <Row className="full-height-row eventTracker">
        <Col className="dark-column events-nav">
          <div className="event-nav-content">
            {userAuth.isAuthenticated && event && (
              <EventNavigation
                setEventPage={setEventPage}
                setSubPage={setSubPage}
                eventPage={eventPage}
                event={event}
              />
            )}
          </div>
        </Col>
        <Col className="light-column event-view-wrapper">
          {userAuth.isAuthenticated && (
            <div className="light-column-content">
              {isSelfServe && (
                <div className="account-status-tier">
                  {companyDetails &&
                    (companyDetails.subscribed ? (
                      <span className="pro-tier">Pro Account</span>
                    ) : (
                      <div>
                        <span>
                          Basic Account{" "}
                          <Button onClick={() => setPricingListModalOpen(true)}>
                            Upgrade
                          </Button>
                        </span>
                      </div>
                    ))}
                </div>
              )}
              {event?.details ? (
                <div>
                  {eventPage !== "Event Details" && (
                    <div className="event-breadcrumb">
                      <a
                        onClick={() => {
                          setEventPage("Event Details");
                          setSubPage("");
                        }}
                      >
                        {event.details.name || "Your team event"}
                      </a>{" "}
                      <ChevronRightBlue />{" "}
                      <a onClick={() => setSubPage("")}>{eventPage}</a>
                      {subPage && (
                        <>
                          {" "}
                          <ChevronRightBlue /> <span>{subPage}</span>
                        </>
                      )}
                    </div>
                  )}
                  <div>{renderPage(eventPage)}</div>
                </div>
              ) : loadingEventData || !tokenSet ? (
                <Loader variation="linear" />
              ) : (
                <div className="overall-summary">
                  <Error />
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Event;
