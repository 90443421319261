import React, { useState } from "react";

import { Heading, Loader, Text, View } from "@aws-amplify/ui-react";

import DirectSetup from "../../components/DirectSetup";
import NavanSetup from "../../components/NavanSetup";
import TravelPerkSetup from "../../components/TravelperkSetup";
import { ReactComponent as EmptyIllustration } from "../../images/palm-tree.svg";
import useCustomers, { Customer, TMC } from "../../lib/useCustomers";
import useCustomerDeals, { HotelDeal } from "../../lib/useHotelDeals";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

function Setup() {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [hotelDeals, setHotelDeals] = useState<HotelDeal[]>([]);
  const [loadingHotels, setLoadingHotels] = useState<boolean>(false);

  useCustomers(setCustomers, setSelectedCustomer, setLoadingHotels);
  useCustomerDeals(
    false,
    selectedCustomer,
    null,
    setHotelDeals,
    setLoadingHotels,
    true
  );

  const [axios] = useAuthenticatedAxios(false);

  async function requestSetup() {
    for (const hotelDeal of hotelDeals) {
      try {
        axios.put(`/api/users/hotelDeals/${hotelDeal.id}/status`, {
          setupRequested: true,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  function changeCustomer(event: any) {
    setSelectedCustomer(
      customers.find((c) => c.id.toString() === event.target.value) ||
        selectedCustomer
    );
  }

  return (
    <View className="PageContent">
      {customers && customers.length > 1 && (
        <select onChange={changeCustomer} value={selectedCustomer?.id}>
          {customers.map((customer) => (
            <option value={customer.id}>{customer.name}</option>
          ))}
        </select>
      )}
      {!loadingHotels ? (
        <View>
          {!selectedCustomer?.tmc && <DirectSetup hotelDeals={hotelDeals} />}
          {selectedCustomer?.tmc === TMC.TRAVELPERK &&
            hotelDeals.length > 0 && (
              <TravelPerkSetup
                hotelDeals={hotelDeals}
                customer={selectedCustomer}
                requestSetup={requestSetup}
              />
            )}
          {selectedCustomer?.tmc === TMC.NAVAN && hotelDeals.length > 0 && (
            <NavanSetup
              hotelDeals={hotelDeals}
              customer={selectedCustomer}
              requestSetup={requestSetup}
            />
          )}
          {hotelDeals.length === 0 && (
            <View className="DirectEmpty">
              <EmptyIllustration />
              <Heading level={5}>
                You've not got any offers needing setup.
              </Heading>
              <Text>Accept a CNR offer to begin the setup process.</Text>
            </View>
          )}
        </View>
      ) : (
        <Loader variation="linear"></Loader>
      )}
    </View>
  );
}

export default Setup;
