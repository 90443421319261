import "@aws-amplify/ui-react/styles.css";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Heading, TabItem, Tabs } from "@aws-amplify/ui-react";

import { ReactComponent as HotelIcon } from "../../images/icons/hotel.svg";
import { OFFERS_HEADING, OFFERS_TAB, SETUP_TAB } from "../../utils/Strings";
import Content from "./Content";
import Setup from "./Setup";
import OfferOverview from "./OfferOverview";

function Offers() {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const navigate = useNavigate();
  function goToOffers() {
    navigate("/offers");
    setTabIndex(0);
  }
  function goToSetup() {
    navigate("/setup");
    setTabIndex(1);
  }

  /*
  Fetch offers after render
  */
  useEffect(() => {
    const tabIndex = window.location.pathname.includes("setup") ? 1 : 0;
    setTabIndex(tabIndex);
  }, []);

  return (
    <div className="MainContent">
      <Heading className="PageTitle" level={1}>
        {OFFERS_HEADING}
        <HotelIcon style={{ fill: "#141414" }} />
      </Heading>
      <Tabs className="header" currentIndex={tabIndex}>
        <TabItem title={OFFERS_TAB} onClick={goToOffers}>
          <OfferOverview />
        </TabItem>
        <TabItem title={SETUP_TAB} onClick={goToSetup}>
          <Setup />
        </TabItem>
      </Tabs>
    </div>
  );
}

export default Offers;
