/** components/HotelPackages.js */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

const HotelPackages = ({ packages }) => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleViewHotels = (pkg) => {
    setSelectedPackage(pkg);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedPackage(null);
  };

  const formatCurrency = (amount) => {
    if (!amount) return '-';
    return amount >= 1000000 ? `$${(amount / 1000000).toFixed(1)}M` :
           amount >= 1000 ? `$${(amount / 1000).toFixed(1)}K` : 
           `$${amount.toFixed(2)}`;
  };


  return (
    <div className="packages-wrapper">
      <div className="packages-list">
        {packages.map((pkg, idx) => (
          <div key={idx} className="package">
            <h4>Bundle {idx+1}</h4>
            <p>{pkg.totalContracts} Hotel Contracts</p>
            <p>{formatCurrency(pkg.totalSavings)} Savings</p>
            <Button onClick={() => handleViewHotels(pkg)}>View Hotels</Button>
          </div>
        ))}
      </div>

      <Modal show={showModal} onHide={handleClose}  size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Hotel Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPackage && (
            <DataTable
              columns={[
                { name: 'Hotel Name', selector: row => row.name, sortable: true },
                { name: 'Total Spend', selector: row => formatCurrency(row.total_spend), sortable: true },
                { name: 'Potential Savings', selector: row => formatCurrency(row.potential_savings), sortable: true },
              ]}
              data={selectedPackage.hotels}
              pagination
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HotelPackages;