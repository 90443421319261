import React, { useState } from "react";
import { TabItem, Tabs } from "@aws-amplify/ui-react";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { Event } from "./EventStatus";
import EventDates from "./EventDates";
import EventDateRestrictions from "./EventDateRestrictions";

import { ReactComponent as DateEmpty } from "../img/dates-empty.svg";

import EventEmpty from "./EventEmpty";
import EventAgenda from "./EventAgenda";
import { Button } from "react-bootstrap";

import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";
import { addDays, format } from "date-fns";
import { enGB } from "date-fns/locale";
registerLocale("enGB", enGB);

const tabs = ["", "Agenda"];

const ManageDates = (props: {
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  subPage: string;
  event: Event;
  onUpdate: Function;
}) => {
  const [axios] = useAuthenticatedAxios(true);

  const [selectedDate, setSelectedDate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null | undefined>(null);
  const [endDate, setEndDate] = useState<Date | null | undefined>(null);

  const onChange = (dates: (Date | null | undefined)[]) => {
    let [start, end] = dates;
    if (start && end && end > addDays(start, 29)) end = addDays(start, 29);
    setStartDate(start);
    setEndDate(end);
    console.log(dates);
  };

  const handleSave = async () => {
    if (startDate && endDate) {
      try {
        setLoading(true);

        const requestDates = [];
        for (
          let d = new Date(startDate);
          d <= endDate;
          d.setDate(d.getDate() + 1)
        ) {
          requestDates.push({
            date: format(new Date(d), "yyyy-MM-dd"),
            type: "Required",
          });
        }

        await axios.post(
          `/api/users/events/1634${props.event.details.id}/dates`,
          { dates: requestDates }
        );
        props.onUpdate();
      } catch (error) {
        alert("Error adding dates, please try again later");
        console.error("Error adding dates:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {props.event.realEvent ? (
        <>
          {props.subPage === "Set Restrictions" ? (
            <EventDateRestrictions
              event={props.event}
              onUpdate={props.onUpdate}
              setPage={props.setPage}
              setSubPage={props.setSubPage}
              initialDate={selectedDate}
            />
          ) : (
            <>
              <div className="header-wrapper">
                <h2>Manage Dates</h2>
              </div>{" "}
              {props.event.dates.length === 0 ? (
                <div className="event-dates-not-added">
                  <section className="dates-not-added-content">
                    <DateEmpty />
                    <div>
                      <h2>Let's get it in the calendar</h2>
                      <p>
                        Get started with your event by adding some dates. Don't
                        worry if they aren't final yet - you can always adjust
                        them later.
                      </p>
                      <Button
                        onClick={handleSave}
                        disabled={loading || !startDate || !endDate}
                      >
                        Save Dates
                      </Button>
                    </div>
                  </section>
                  <DatePicker
                    wrapperClassName="event-date-picker"
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    locale="enGB"
                    selectsRange
                    inline
                    minDate={new Date()}
                    maxDate={
                      !startDate || endDate
                        ? null
                        : addDays(startDate || new Date(), 29)
                    }
                  />
                </div>
              ) : (
                <div className="events-tabs">
                  <Tabs
                    className="header"
                    currentIndex={tabs.indexOf(props.subPage)}
                    onChange={(i) => props.setSubPage(tabs[Number(i)])}
                  >
                    <TabItem title="Manage Dates">
                      <EventDates
                        event={props.event}
                        onUpdate={props.onUpdate}
                        setSelectedDate={setSelectedDate}
                        setSubPage={props.setSubPage}
                      />
                    </TabItem>
                    <TabItem title="Agenda">
                      <EventAgenda
                        event={props.event}
                        onUpdate={props.onUpdate}
                      />
                    </TabItem>
                  </Tabs>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="event-not-accepted-wrapper">
          <DateEmpty />
          <EventEmpty />
        </div>
      )}
    </>
  );
};

export default ManageDates;
