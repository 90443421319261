import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { TabItem, Tabs } from "@aws-amplify/ui-react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";

import "./Events.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "550px",
  },
};

type Option = { label: string; value: string };

const groupOptionsOne: Option[] = [
  { value: "allow", label: "are" },
  { value: "disallow", label: "are not" },
];

const groupOptionsTwo: Option[] = [
  { value: "or", label: "any" },
  { value: "and", label: "all" },
];

function AttendeeFilterModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialIndex: number;
  setShowNoReply: React.Dispatch<React.SetStateAction<boolean>>;
  setShowNotAttending: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPartialAttending: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAttending: React.Dispatch<React.SetStateAction<boolean>>;
  showNoReply: boolean;
  showNotAttending: boolean;
  setShowNotInvited: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInvited: React.Dispatch<React.SetStateAction<boolean>>;
  showInvited: boolean;
  showNotInvited: boolean;
  showPartialAttending: boolean;
  showAttending: boolean;
  nameFilter: string | undefined;
  setNameFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  eventGroups: Option[];
  filterGroups: Option[];
  setFilterGroups: React.Dispatch<React.SetStateAction<Option[]>>;
  filterGroupsType: string | undefined;
  setFilterGroupsType: React.Dispatch<React.SetStateAction<string | undefined>>;
  filterGroupsOperator: string | undefined;
  setFilterGroupsOperator: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) {
  const [index, setIndex] = useState<string | number>(0);

  function closeModal() {
    props.setIsOpen(false);
  }

  useEffect(() => {
    setIndex(props.initialIndex);
  }, [props.initialIndex, props.modalIsOpen]);

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Filter Attendee Dates"
    >
      <div className="event-management-modal">
        <div className="event-management-modal-header">
          <h4>Filter Attendees</h4>
          <Button
            className="btn-secondary btn-danger"
            onClick={() => {
              props.setNameFilter(undefined);
              props.setShowInvited(true);
              props.setShowNotInvited(true);
              props.setShowAttending(true);
              props.setShowPartialAttending(true);
              props.setShowNotAttending(true);
              props.setShowNoReply(true);
              props.setFilterGroups([]);
              props.setFilterGroupsOperator(undefined);
              props.setFilterGroupsType(undefined);
              closeModal();
            }}
          >
            Clear all filters
          </Button>
        </div>
        <Tabs
          className="header"
          currentIndex={index}
          onChange={(i) => setIndex(i)}
        >
          <TabItem title="Name">
            <div>
              <Form.Label>Name or email contains</Form.Label>
              <Form.Control
                type="text"
                name="filter"
                value={props.nameFilter || ""}
                onChange={(e) => {
                  props.setNameFilter(e.target.value);
                }}
              />
            </div>
          </TabItem>
          <TabItem title="Invite status">
            <div>
              <div className="checkbox-row">
                <Form.Check
                  id="showInvited"
                  name="showInvited"
                  checked={props.showInvited}
                  onChange={(e) => props.setShowInvited(e.target.checked)}
                />
                <Form.Label for="showInvited">
                  Show people who received an invite
                </Form.Label>
              </div>
              <div className="checkbox-row">
                <Form.Check
                  id="showNotInvited"
                  name="showNotInvited"
                  checked={props.showNotInvited}
                  onChange={(e) => props.setShowNotInvited(e.target.checked)}
                />
                <Form.Label for="showNotInvited">
                  Show people who haven't received an invite
                </Form.Label>
              </div>
            </div>
          </TabItem>
          <TabItem title="Dates">
            <div>
              <div className="checkbox-row">
                <Form.Check
                  id="showNoReply"
                  name="showNoReply"
                  checked={props.showNoReply}
                  onChange={(e) => props.setShowNoReply(e.target.checked)}
                />
                <Form.Label for="showNoReply">
                  Show people who haven't responded
                </Form.Label>
              </div>
              <div className="checkbox-row">
                <Form.Check
                  id="showNotAttending"
                  name="showNotAttending"
                  checked={props.showNotAttending}
                  onChange={(e) => props.setShowNotAttending(e.target.checked)}
                />
                <Form.Label for="showNotAttending">
                  Show people who aren't attending
                </Form.Label>
              </div>
              <div className="checkbox-row">
                <Form.Check
                  id="showPartialAttending"
                  name="showPartialAttending"
                  checked={props.showPartialAttending}
                  onChange={(e) =>
                    props.setShowPartialAttending(e.target.checked)
                  }
                />
                <Form.Label for="showPartialAttending">
                  Show people who will attend some event dates
                </Form.Label>
              </div>
              <div className="checkbox-row">
                <Form.Check
                  id="showAttending"
                  name="showAttending"
                  checked={props.showAttending}
                  onChange={(e) => props.setShowAttending(e.target.checked)}
                />
                <Form.Label for="showAttending">
                  Show people who will attend all event dates
                </Form.Label>
              </div>
            </div>
          </TabItem>
          <TabItem title="Groups">
            <div className="filter-groups">
              <label>Only show attendees who </label>
              <Select
                options={groupOptionsOne}
                className="attendee-filter-group-control"
                value={groupOptionsOne.find(
                  (o) => o.value === props.filterGroupsType
                )}
                onChange={(newValue) =>
                  props.setFilterGroupsType(newValue?.value)
                }
              />{" "}
              <label>in </label>
              <Select
                options={groupOptionsTwo}
                className="attendee-filter-group-control"
                value={groupOptionsTwo.find(
                  (o) => o.value === props.filterGroupsOperator
                )}
                onChange={(newValue) =>
                  props.setFilterGroupsOperator(newValue?.value)
                }
              />{" "}
              <label>of these groups</label>
              <Select
                isMulti
                isClearable
                defaultValue={[]}
                value={props.filterGroups}
                className="attendee-filter-group-control"
                onChange={(newValue) => props.setFilterGroups([...newValue])}
                options={props.eventGroups || []}
              />
            </div>
          </TabItem>
        </Tabs>

        <footer className="modalButtonFooter">
          <Button
            className="btn-secondary"
            onClick={() => {
              if (index === 0 || index === "0") props.setNameFilter(undefined);
              if (index === 1 || index === "1") {
                props.setShowInvited(true);
                props.setShowNotInvited(true);
              }
              if (index === 2 || index === "2") {
                props.setShowAttending(true);
                props.setShowPartialAttending(true);
                props.setShowNotAttending(true);
                props.setShowNoReply(true);
              }
              if (index === 3 || index === "3") {
                props.setFilterGroups([]);
                props.setFilterGroupsOperator(undefined);
                props.setFilterGroupsType(undefined);
              }
            }}
          >
            Clear this filter
          </Button>
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            Apply selected filters
          </Button>
        </footer>
      </div>
    </Modal>
  );
}

export default AttendeeFilterModal;
