import React, { useState } from "react";
import Modal from "react-modal";
import { Event, EventHotel } from "./EventStatus";
import { ReactComponent as UserRemoveIcon } from "../img/user-remove.svg";
import { Button, Form } from "react-bootstrap";
import axios from "axios";

import "./Events.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "640px",
  },
};

function HotelDeleteModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: Event;
  hotel: EventHotel | undefined;
  onUpdate: Function;
}) {
  const [confirmText, setConfirmText] = useState("");
  Modal.setAppElement("#root");

  function closeModal() {
    setConfirmText("");
    props.setIsOpen(false);
  }

  const handleDelete = async () => {
    try {
      await axios.delete(
        `/api/users/events/1634${props.event.details.id}/event-hotel/${props.hotel?.id}`
      );
      props.onUpdate();
      closeModal();
    } catch (error) {
      console.error("Error deleting hotel:", error);
      alert("We could not delete the hotel, please try again");
    }
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Delete Hotel"
    >
      <div className="event-management-modal">
        <h4>{props.hotel?.name || "Delete Hotel"}</h4>
        <div className="event-attendee-delete-modal-title">
          <h4>
            <UserRemoveIcon />
            Delete this hotel
          </h4>
          <p>
            Any attendees that have been assigned to this hotel will be
            unassigned. If you already did send a rooming list to the hotel,
            their page will no longer be accessible.
          </p>
        </div>
      </div>
      <Form.Label for="confirmText">Type 'DELETE' into the field</Form.Label>
      <Form.Control
        type="text"
        id="confirmText"
        value={confirmText}
        onChange={(e) => setConfirmText(e.target.value)}
      />
      <footer className="modalButtonFooter">
        <Button className="btn-secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          className="btn-secondary danger-button"
          onClick={handleDelete}
          disabled={confirmText !== "DELETE"}
        >
          Delete Hotel
        </Button>
      </footer>
    </Modal>
  );
}

export default HotelDeleteModal;
