import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ImpalaHeader from '../../components/ImpalaHeader/ImpalaHeader';
import useAuthenticatedAxios from '../../lib/useAuthenticatedAxios';
import HotelModal from './HotelCityModal'; // Import the HotelModal component
import CNRModal from './CNRModal'; // Import the CNRModal component
import RemoveCNRModal from './RemoveCNRModal'; // Import the RemoveCNRModal component

const ReportingCityAudit = ({ userAuth, activeServices }) => {
  const { cityName } = useParams(); // Extract the city name from the URL parameters
  const [discountedBooking, setDiscountedBooking] = useState(null);
  const [maxImmediateSavings, setMaxImmediateSavings] = useState(null);
  const [existingContracts, setExistingContracts] = useState(null);
  const [contractsGap, setContractsGap] = useState(null);
  const [maxFutureSavings, setMaxFutureSavings] = useState(null);
  const [hotelsData, setHotelsData] = useState([]);
  const [loadingStates, setLoadingStates] = useState({
    discountedBooking: true,
    maxImmediateSavings: true,
    contractsGap: true,
    maxFutureSavings: true,
    hotels: true,
  });

  const [errorStates, setErrorStates] = useState({
    discountedBooking: false,
    maxImmediateSavings: false,
    contractsGap: false,
    maxFutureSavings: false,
    hotels: false,
  });

  const [authAxios, tokenSet] = useAuthenticatedAxios(true);
  const [modalShow, setModalShow] = useState(false); // Manage modal visibility
  const [selectedHotel, setSelectedHotel] = useState(null); // Manage selected hotel name
  const [cnrModalShow, setCnrModalShow] = useState(false);
  const [removeCnrModalShow, setRemoveCnrModalShow] = useState(false);
  const [hasCNR, setHasCNR] = useState(false);

  useEffect(() => {
    if (!tokenSet) return;
    const fetchData = async (url, successCallback, errorCallback) => {
      try {
        const response = await authAxios.get(url);
        successCallback(response.data);
      } catch (err) {
        console.error(`Error fetching data from ${url}:`, err);
        errorCallback(true);
      }
    };

    const fetchDiscountedBooking = async () => {
      try {
        const response = await authAxios.post('/api/audit/city-discount-analysis', { cities: [cityName] });
        const discountedBookingData = response.data.discounted_booking_percentages.find(
          (entry) => entry.city_name === cityName
        );
        setDiscountedBooking(discountedBookingData ? discountedBookingData.percentage : null);
      } catch (err) {
        console.error(`Error fetching discounted booking data for ${cityName}:`, err);
        setErrorStates((state) => ({ ...state, discountedBooking: true }));
      } finally {
        setLoadingStates((state) => ({ ...state, discountedBooking: false }));
      }
    };

    const fetchMaxImmediateSavings = async () => {
      fetchData(
        `/api/audit/savings-available-new-contracts/${cityName}`,
        (data) => {
          setMaxImmediateSavings(data.max_savings);
          setContractsGap(data.total_new_contracts); // Update contracts gap here
        },
        () => setErrorStates((state) => ({ ...state, maxImmediateSavings: true, contractsGap: true }))
      ).finally(() => {
        setLoadingStates((state) => ({ ...state, maxImmediateSavings: false, contractsGap: false }));
      });
    };

    const fetchMaxFutureSavings = async () => {
      fetchData(
        `/api/audit/contract-savings/${cityName}`,
        (data) => setMaxFutureSavings(data.potential_contract_savings),
        () => setErrorStates((state) => ({ ...state, maxFutureSavings: true }))
      ).finally(() => {
        setLoadingStates((state) => ({ ...state, maxFutureSavings: false }));
      });
    };

    const fetchContractsGap = async () => {
      fetchData(
        `/api/audit/city-details/${cityName}`,
        (data) => setExistingContracts(data.details.existing_contracts),
        () => setErrorStates((state) => ({ ...state, contractsGap: true }))
      ).finally(() => setLoadingStates((state) => ({ ...state, contractsGap: false })));
    };

    const fetchHotelsData = async () => {
      fetchData(
        `/api/audit/top-hotels/${cityName}`,
        (data) => setHotelsData(data.hotels),
        () => setErrorStates((state) => ({ ...state, hotels: true }))
      ).finally(() => {
        setLoadingStates((state) => ({ ...state, hotels: false }));
      });
    };

    fetchDiscountedBooking();
    fetchMaxImmediateSavings();
    fetchMaxFutureSavings();
    fetchContractsGap();
    fetchHotelsData();
  }, [cityName, authAxios, tokenSet]);

  const handleRowClicked = (row) => {
    setSelectedHotel(row.hotel_name);
    setModalShow(true);
  };

  const handleCNRClick = (row) => {
    setSelectedHotel(row.hotel_name);
    setHasCNR(row.has_cnr);
    if (row.has_cnr) {
      setRemoveCnrModalShow(true);
    } else {
      setCnrModalShow(true);
    }
  };

  const refreshData = (hotelName) => {
    // Add functions to refresh data as necessary
    setHotelsData((prevHotelsData) => {
      return prevHotelsData.map(hotel => {
        if (hotel.hotel_name === hotelName) {
          return {
            ...hotel,
            pending_cache_rerun: true,
          };
        }
        return hotel;
      });
    });
  };

  const hotelsColumns = [
    {
      name: 'Hotel Name',
      selector: (row) => row.hotel_name,
      sortable: true,
    },
    {
      name: 'Total Nights',
      selector: (row) => row.total_nights,
      sortable: true,
    },
    {
      name: 'Has CNR',
      selector: (row) => row.pending_cache_rerun ? (<span>Pending</span>) : (
        <span
          onClick={() => handleCNRClick(row)}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {row.has_cnr ? 'Yes' : 'No'}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Max Future Savings',
      selector: (row) =>
        row.max_future_savings !== null
          ? row.max_future_savings >= 1000000
            ? `$${(row.max_future_savings / 1000000).toFixed(1)}M`
            : row.max_future_savings >= 1000
            ? `$${(row.max_future_savings / 1000).toFixed(1)}K`
            : `$${row.max_future_savings.toFixed(2)}`
          : '-',
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const valA = rowA && rowA.max_future_savings !== null ? rowA.max_future_savings : -Infinity;
        const valB = rowB && rowB.max_future_savings !== null ? rowB.max_future_savings : -Infinity;
        return valA - valB;
      },
    },
    {
      name: 'Max Immediate Savings',
      selector: (row) =>
        row.max_immediate_savings !== null
          ? row.max_immediate_savings >= 1000000
            ? `$${(row.max_immediate_savings / 1000000).toFixed(1)}M`
            : row.max_immediate_savings >= 1000
            ? `$${(row.max_immediate_savings / 1000).toFixed(1)}K`
            : `$${row.max_immediate_savings.toFixed(2)}`
          : '-',
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const valA = rowA && rowA.max_immediate_savings !== null ? rowA.max_immediate_savings : -Infinity;
        const valB = rowB && rowB.max_immediate_savings !== null ? rowB.max_immediate_savings : -Infinity;
        return valA - valB;
      },
    },
  ];

  return (
    <div>
      <ImpalaHeader userAuth={userAuth} activeServices={activeServices} />
      <div className="reporting-wrapper">
        <div className="reporting-page">
          <div className="breadcrumbs">
            <Link to="/srv/dashboard">
              <span>My Dashboard</span>
            </Link>
            <Link to="/srv/reporting">
              <span>Reporting</span>
            </Link>
            <Link to={`/srv/reporting/city-audit/${cityName}`}>
              <span>{`${cityName}`}</span>
            </Link>
          </div>
          <h1>{`${cityName} Hotel Audit`}</h1>
          <div className="top-bubbles reporting-hotel-audit">
            <div className={`bubble audit ${loadingStates.discountedBooking ? 'loading-bubble-innards' : ''}`}>
              <p className="title">Discounted Booking %</p>
              <p className="value">
                {loadingStates.discountedBooking ? (
                  <></>
                ) : errorStates.discountedBooking ? (
                  'Err'
                ) : discountedBooking !== null ? (
                  `${(discountedBooking * 100).toFixed(2)}%`
                ) : (
                  '-'
                )}
              </p>
            </div>
            <div className={`bubble audit ${loadingStates.contractsGap ? 'loading-bubble-innards' : ''}`}>
              <p className="title">Contracts / Recommended</p>
              <p className="value">
                {loadingStates.contractsGap ? (
                  <></>
                ) : errorStates.contractsGap ? (
                  'Err'
                ) : contractsGap !== null ? (
                  <>{`${existingContracts?.length} / ${existingContracts?.length + contractsGap}`}</>
                ) : (
                  '-'
                )}
              </p>
            </div>
            <div className={`bubble audit ${loadingStates.maxImmediateSavings ? 'loading-bubble-innards' : ''}`}>
              <p className="title">Max Immediate Savings</p>
              <p className="value">
                {loadingStates.maxImmediateSavings ? (
                  <></>
                ) : errorStates.maxImmediateSavings ? (
                  'Err'
                ) : maxImmediateSavings !== null ? (
                  maxImmediateSavings >= 1000000 ? (
                    `$${(maxImmediateSavings / 1000000).toFixed(1)}M`
                  ) : maxImmediateSavings >= 1000 ? (
                    `$${(maxImmediateSavings / 1000).toFixed(1)}K`
                  ) : (
                    `$${maxImmediateSavings.toFixed(2)}`
                  )
                ) : (
                  '-'
                )}
              </p>
            </div>
            <div className={`bubble audit ${loadingStates.maxFutureSavings ? 'loading-bubble-innards' : ''}`}>
              <p className="title">Max Future Savings</p>
              <p className="value">
                {loadingStates.maxFutureSavings ? (
                  <></>
                ) : errorStates.maxFutureSavings ? (
                  'Err'
                ) : maxFutureSavings !== null ? (
                  maxFutureSavings >= 1000000 ? (
                    `$${(maxFutureSavings / 1000000).toFixed(1)}M`
                  ) : maxFutureSavings >= 1000 ? (
                    `$${(maxFutureSavings / 1000).toFixed(1)}K`
                  ) : (
                    `$${maxFutureSavings.toFixed(2)}`
                  )
                ) : (
                  '-'
                )}
              </p>
            </div>
          </div>
          {loadingStates.hotels ? (
            <div className="spinner-container">
              <Spinner animation="border" />
            </div>
          ) : errorStates.hotels ? (
            <div>Error loading hotels data for {cityName}</div>
          ) : (
            <div className="audit-wrapper hotel-audit-wrapper">
              <h1>{`Top Hotels in ${cityName}`}</h1>
              <DataTable
                columns={hotelsColumns}
                data={hotelsData}
                pagination
                highlightOnHover
                onRowClicked={handleRowClicked} // Attach row click handler
              />
            </div>
          )}
          <HotelModal show={modalShow} onHide={() => setModalShow(false)} hotelName={selectedHotel} />
          <CNRModal
            show={cnrModalShow}
            onHide={() => setCnrModalShow(false)}
            hotelName={selectedHotel}
            hasCNR={hasCNR}
            city={cityName}
            refreshData={refreshData}
          />
          <RemoveCNRModal
            show={removeCnrModalShow}
            onHide={() => setRemoveCnrModalShow(false)}
            hotelName={selectedHotel}
            city={cityName}
            refreshData={refreshData}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportingCityAudit;