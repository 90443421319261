import React from "react";
import { Link } from 'react-router-dom';
import { ReactComponent as ImageHotel } from "../img/hotel.svg";

const DealLocationSelector = ({
    selectedDeal,
    setSelectedDeal,
    deals,
}) => {
    return (
        <div className="deal-location-selector">
            <div className="deal-selection-area">
                <p>Select a destination to filter</p>
                <div className="pill-box">
                    <div className={`deal-pill ${!selectedDeal && 'selected'}`} onClick={() => setSelectedDeal(null)}>
                        All
                    </div>
                    {deals.sort((a, b) => a.location_name < b.location_name ? -1 : 1 ).map(deal => (
                        <div
                            key={deal.location_name}
                            className={`deal-pill ${selectedDeal?.id === deal.id ? 'selected' : ''}`}
                            onClick={() => setSelectedDeal(deal)}
                        >
                            {deal.location_name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default DealLocationSelector;