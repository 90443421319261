import React, { useState, useEffect } from "react";
import { CompanyDetails } from "../lib/useCompanyDetails";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Form, ProgressBar } from "react-bootstrap";
import { Image } from "@aws-amplify/ui-react";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

const AccountDetails = (props: {
  companyDetails: CompanyDetails;
  onCompanyUpdate: Function;
}) => {
  const [axios] = useAuthenticatedAxios(false);
  const auth0 = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [userName, setUserName] = useState<string>(
    auth0?.user?.name && !auth0?.user?.name.includes("@") ? auth0.user.name : ""
  );
  const [companyName, setCompanyName] = useState<string>(
    props.companyDetails?.name || ""
  );
  const [logoUrl, setLogoUrl] = useState<string>(
    props.companyDetails?.logo_url || ""
  );

  useEffect(() => {
    setCompanyName(companyName || props.companyDetails?.name || "");
    setLogoUrl(props.companyDetails?.logo_url || "");
  }, [companyName, props.companyDetails]);

  const handleLogoUpload = async (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("logo", file);

    try {
      setIsLoading(true);
      setUploadProgress(0);
      await axios.post(`/api/users/logo`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 1)
          );
          setUploadProgress(progress);
        },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error Uploading or Parsing File");
    } finally {
      props.onCompanyUpdate();
      setUploadProgress(0);
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await axios.patch(`/api/users/details`, { name: userName });
      await axios.patch(`/api/users/customer-details`, { name: companyName });
    } catch (error) {
      console.error("Error saving details:", error);
      alert("Error Saving Details");
    } finally {
      props.onCompanyUpdate();
      setIsLoading(false);
    }
  };

  return (
    <div className="event-account-section">
      <h2>Your Details</h2>
      <div className="event-account-section-content">
        <Form.Label htmlFor="userEmail">
          Email
          <Form.Control
            type="text"
            disabled={true}
            id="userEmail"
            className="accountDetailsFormControl"
            value={auth0?.user?.email || ""}
          ></Form.Control>
        </Form.Label>
        <Form.Label htmlFor="name">
          Your Name
          <Form.Control
            disabled={isLoading}
            type="text"
            id="name"
            placeholder="Your full name"
            className="accountDetailsFormControl"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          ></Form.Control>
        </Form.Label>
      </div>

      <h2>Company Details</h2>
      <div className="event-account-section-content">
        <Form.Label htmlFor="companyName">
          Company Name
          <Form.Control
            disabled={isLoading}
            type="text"
            id="companyName"
            placeholder="Your Company's Name"
            className="accountDetailsFormControl"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          ></Form.Control>
        </Form.Label>
        <Form.Label>Company Logo</Form.Label>
        <div className="logo-holder">
          <Image
            alt={logoUrl ? `${companyName} Logo` : ""}
            maxHeight={60}
            maxWidth={240}
            src={logoUrl}
          />
        </div>
        <label
          htmlFor="file-upload"
          className="btn-secondary file-upload-label"
        >
          {logoUrl ? <div>Update logo</div> : <div>Add your logo</div>}
        </label>
        <input
          id="file-upload"
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleLogoUpload}
          style={{ display: "none" }}
        />
        <small>
          PNG, JPG, and GIF accepted. We recommend a transparent PNG.
        </small>
        {uploadProgress > 0 && (
          <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
        )}
      </div>
      <Button
        disabled={isLoading || !companyName || !userName || !logoUrl}
        onClick={handleSave}
      >
        Save
      </Button>

      <div></div>
    </div>
  );
};

export default AccountDetails;
