import React from "react";
import { format } from "date-fns";

const CalendarLink = ({ title, type, event }) => {
  const createGoogleCalendarLink = (event) => {
    const start = encodeURI(format(event.startDate, "yyyyMMdd"));
    let nextDay = new Date(event.endDate);
    nextDay.setDate(event.endDate.getDate() + 1);
    const end = encodeURI(format(nextDay, "yyyyMMdd"));
    const text = encodeURI(event.title);
    const details = encodeURI(event.description);
    const location = encodeURI(event.location);
    return `https://www.google.com/calendar/render?action=TEMPLATE&text=${text}&dates=${start}/${end}&details=${details}&location=${location}`;
  };

  const createOutlookCalendarLink = (event) => {
    const start = encodeURI(format(event.startDate, "yyyy-MM-dd"));
    let nextDay = new Date(event.endDate);
    nextDay.setDate(event.endDate.getDate() + 1);
    const end = encodeURI(format(nextDay, "yyyy-MM-dd"));
    const text = encodeURI(event.title);
    const details = encodeURI(event.description);
    const location = encodeURI(event.location);
    return `https://outlook.live.com/calendar/0/action/compose?allday=true&body=${details}&enddt=${end}T00%3A00%3A00%2B00%3A00&location=${location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${start}T00%3A00%3A00%2B00%3A00&subject=${text}`;
  };

  const createICalLink = (event) => {
    const start = encodeURI(format(event.startDate, "yyyyMMdd"));
    let nextDay = new Date(event.endDate);
    nextDay.setDate(event.endDate.getDate() + 1);
    const end = encodeURI(format(nextDay, "yyyyMMdd"));
    const description = event.description;
    const location = event.location;
    const data = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "BEGIN:VEVENT",
      `DTSTART:${start}`,
      `DTEND:${end}`,
      `SUMMARY:${description}`,
      `DESCRIPTION:${description}`,
      `LOCATION:${location}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\n");

    return `data:text/calendar;charset=utf8,${encodeURIComponent(data)}`;
  };

  if (type === "GOOGLE") {
    return (
      <a href={createGoogleCalendarLink(event)} target="_blank">
        {title}
      </a>
    );
  } else if (type === "OUTLOOK") {
    return (
      <a href={createOutlookCalendarLink(event)} target="_blank">
        {title}
      </a>
    );
  } else if (type === "APPLE") {
    return (
      <a href={createICalLink(event)} target="_blank">
        {title}
      </a>
    );
  } else {
    return <div>{title}</div>;
  }
};

export default CalendarLink;
