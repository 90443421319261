import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ReactComponent as BackIcon } from "../../img/arrow-left.svg";
import { ReactComponent as HeartInHands } from "../../img/heart-in-hands.svg";
import { ReactComponent as ThumbsUp } from "../../img/thumbs-up.svg";

import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

import { Loader } from "@aws-amplify/ui-react";
import Error from "../../components/Error";

import "../../components/Events.css";
import ImpalaEventsHeader from "../../components/ImpalaHeader/ImpalaEventsHeader";

const EventAccess = ({ userAuth, activeServices, user }) => {
  const [authAxios, tokenSet] = useAuthenticatedAxios(true);
  const { eventId } = useParams();
  const [events, setEvents] = useState(null);
  const [loadingEventData, setLoadingEventData] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingEventData(true);
      try {
        const eventsResponse = await authAxios.get(`/api/events?id=${eventId}`);
        setEvents(eventsResponse.data.events);
        setHasRequested(eventsResponse.data.events[0].requestedAccess);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingEventData(false);
      }
    };

    if (tokenSet) {
      fetchData();
    }
  }, [tokenSet, eventId, user, authAxios]);

  const handleSubmit = async () => {
    setIsUpdating(true);
    try {
      if (hasRequested) {
        await authAxios.delete(
          `/api/users/event-requests/${eventId}/organisers/requests`
        );
        setHasRequested(false);
      } else {
        await authAxios.post(
          `/api/users/event-requests/${eventId}/organisers/requests`
        );
        setHasRequested(true);
      }
    } catch (e) {
      alert("Could not submit your request, please try again");
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="event-list-wrapper event-edit-planners">
      <ImpalaEventsHeader userAuth={userAuth} activeServices={activeServices} />
      <div className="event-list-center">
        {events && events.length === 1 ? (
          <div className="event-list-inner">
            <div className="event-planners-header">
              <div className="back-to-previous">
                <Link to="/srv/event/list">
                  <BackIcon /> Back to all events
                </Link>
              </div>
              <h1>Request access</h1>
            </div>
            <div className="events-planners-title">
              <div>
                <div className="event-title-subtitle">Event name</div>
                <h3>{events[0].name || "Team Event"}</h3>
              </div>
              <div className="event-id">Event ID 1634{events[0].id}</div>
              <div className={`tag tag-${events[0].stage}`}>
                {events[0].stage}
              </div>
            </div>
            {hasRequested ? (
              <div className="event-planner-request-empty">
                <ThumbsUp />
                <h4>You've requested access</h4>
                <p>All event planners have been notified of your request.</p>
                <p>
                  If you no longer need access, you can withdraw your request
                  below.
                </p>
                <Button onClick={handleSubmit} disabled={isUpdating}>
                  Cancel Request
                </Button>
              </div>
            ) : (
              <div className="event-planner-request-empty">
                <HeartInHands />
                <h4>Request access for this event</h4>
                <p>
                  You can ask{" "}
                  {events[0].organisers[0].name ||
                    events[0].organisers[0].email}
                  , or the Travel Admin for your account, for access to this
                  event.
                </p>
                <p>They'll get an email notification about your request.</p>
                <Button onClick={handleSubmit} disabled={isUpdating}>
                  Request Access
                </Button>
              </div>
            )}
          </div>
        ) : loadingEventData || !tokenSet ? (
          <Loader variation="linear" />
        ) : (
          <div className="overall-summary">
            <Error />
          </div>
        )}
      </div>
    </div>
  );
};

export default EventAccess;
