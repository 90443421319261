import "@aws-amplify/ui-react/styles.css";

import React, { createContext, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@aws-amplify/ui-react";

import { ImpalaTheme } from "./theme/index";
import Dashboard from "./pages/Navigation/Dashboard";
import AuthenticatedServices from "./pages/Navigation/AuthenticatedServices";

import "./App.css";
import Callback from "./pages/Navigation/Callback";
import EventInvite from "./pages/Events/EventInvite";
import EventAttendeeForm from "./pages/Events/EventAttendeeForm";
import Event from "./pages/Events/Event";

import { PostHogProvider } from "posthog-js/react";
import ExternalBooking from "./pages/ExternalBooking/ExternalBooking";
import EventHotelPage from "./pages/Events/EventHotelPage";
import Feedback from "./components/Feedback";

export const FeedbackContext = createContext<{
  feedbackMessage: FeedbackMessage | undefined;
  setFeedbackMessage:
    | React.Dispatch<React.SetStateAction<FeedbackMessage>>
    | Function;
}>({ feedbackMessage: undefined, setFeedbackMessage: () => {} });

export type FeedbackMessage = {
  button?: string;
  message: string;
  type?: string;
  refresh: boolean;
};

const App = () => {
  const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage>({
    button: "",
    message: "",
    type: "",
    refresh: false,
  });

  return (
    <ThemeProvider theme={ImpalaTheme}>
      <PostHogProvider
        apiKey="phc_qCIhqP7TRgzFE6FxtJREjA31ruGvYsHqYsPGixTORd6"
        options={{ api_host: "https://app.posthog.com" }}
      >
        <FeedbackContext.Provider
          value={{ feedbackMessage, setFeedbackMessage }}
        >
          <BrowserRouter>
            <Routes>
              <Route
                path="/events/invite/:eventInviteId"
                element={<EventInvite />}
              />
              <Route
                path="/events/:eventId/attend"
                element={<EventAttendeeForm />}
              />
              <Route
                path="/external/:eventId/attend"
                element={<EventAttendeeForm />}
              />
              <Route
                path="/events/:eventId/hotel"
                element={<EventHotelPage />}
              />
              <Route path="/events/:eventId" element={<Event />} />

              <Route path="/external/:eventId" element={<ExternalBooking />} />

              <Route path="/callback" element={<Callback />} />

              <Route path="/srv/*" Component={AuthenticatedServices} />

              <Route path="*" Component={Dashboard} />
            </Routes>
          </BrowserRouter>
          <Feedback message={feedbackMessage}></Feedback>
        </FeedbackContext.Provider>
      </PostHogProvider>
    </ThemeProvider>
  );
};

export default App;
