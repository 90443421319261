import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { Button, Form } from "react-bootstrap";
import axios, { AxiosResponse } from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { Event, EventAttendeeDate } from "./EventStatus";
import CalendarLink from "./CalendarLink";

import { Loader } from "@aws-amplify/ui-react";
import { ReactComponent as CalendarIcon } from "../img/calendar.svg";
import { ReactComponent as AttendanceRequired } from "../img/attendees-dark.svg";
import { ReactComponent as AttendanceTravel } from "../img/walking-black.svg";
import { ReactComponent as RepliedSuccessfully } from "../img/replied-successfully.svg";
import { ReactComponent as IconUsers } from "../img/icon-users.svg";
import { ReactComponent as NoHotel } from "../img/warning-triangle-grey.svg";
import { Tooltip } from "react-tooltip";
import Error from "./Error";

import "./Events.css";
import "./attendeeForm.css";
import WarningModal from "./WarningModal";
import PersonalDaysModal from "./PersonalDaysModal";

type CalendarEvent = {
  startDate: Date | undefined;
  endDate: Date | undefined;
  title: string;
  description: string;
  location: string;
};

const EventAttendeePreferences = (props: {
  response: AxiosResponse<any, any>;
}) => {
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [warningModalText, setWarningModalText] = useState("");
  const [warningModalLabel, setWarningModalLabel] = useState("");
  const [personalDaysModalIsOpen, setPersonalDaysModalIsOpen] = useState(false);
  const [maxExtraNights, setMaxExtraNights] = useState(0);
  const [isAttendingEvent, setIsAttendingEvent] = useState(true);
  const [preferencesSubmitted, setPreferencesSubmitted] = useState(false);
  const [loadingEventData, setLoadingEventData] = useState(true);
  const [submittingData, setSubmittingData] = useState(false);
  const { eventId } = useParams();
  const [searchParams] = useSearchParams();
  const attendeeId = searchParams.get("attendee");
  const [roomPreferences, setRoomPreferences] = useState(false);
  const [sharingPreferences, setSharingPreferences] = useState(false);

  // const [mealPreferences, setMealPreferences] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    roomPreferences: "",
    sharingPreferences: "",
    //  mealPreferences: "",
  });
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [plusOne, setPlusOne] = useState(false);
  const [prefersTwin, setPrefersTwin] = useState<string | undefined>();
  const [attendeeDates, setAttendeeDates] = useState<EventAttendeeDate[]>([]);
  const [previousAttendeeDates, setPreviousAttendeeDates] = useState<
    EventAttendeeDate[]
  >([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isPastResponseDeadline, setIsPastResponseDeadline] = useState(false);
  const [isPastCancellationDeadline, setIsPastCancellationDeadline] =
    useState(false);

  const [eventForCalendars, setEventForCalendars] = useState<CalendarEvent>();

  const [event, setEvent] = useState<Event>();

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        setLoadingEventData(true);
        const response = props.response;

        setIsDisabled(
          ["Ongoing", "Completed", "Cancelled"].includes(
            response.data.prospective_event.status
          )
        );
        const now = new Date();
        const responseDeadline = new Date(
          response.data.prospective_event.attendee_deadline
        );
        setIsPastResponseDeadline(
          response.data.prospective_event.attendee_deadline &&
            responseDeadline.getTime() - now.getTime() < 0
        );
        const cancellationDeadline = new Date(
          response.data.prospective_event.cancellation_deadline
        );
        const pastCancDeadline =
          response.data.prospective_event.cancellation_deadline &&
          cancellationDeadline.getTime() - now.getTime() < 0;
        setIsPastCancellationDeadline(pastCancDeadline);

        setEvent({
          details: response.data.prospective_event,
          realEvent: true,
          attendees: response.data.event_attendees,
          dates: response.data.event_dates
            .sort((a: any, b: any) =>
              a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            )
            .map((d: any) => {
              return {
                ...d,
                date: utcToZonedTime(
                  new Date(d.date.toString() + "T00:00:00Z"),
                  "Etc/UTC"
                ),
              };
            }),
          owner: undefined,
          calendlyInvite: undefined,
          offers: response.data.offers,
          groups: [],
          hotels: [],
          marketing: [],
          subscription: undefined,
          customer: undefined,
        });
        setRoomPreferences(
          response.data.event_attendees[0].room_preferences ? true : false
        );
        setSharingPreferences(
          response.data.event_attendees[0].sharing_preferences ? true : false
        );
        setFormValues({
          name: response.data.event_attendees[0].name || "",
          roomPreferences:
            response.data.event_attendees[0].room_preferences || "",
          sharingPreferences:
            response.data.event_attendees[0].sharing_preferences || "",
          /*           mealPreferences:
            response.data.event_attendees[0].meal_preferences || "", */
        });
        const previousAttendeeDate =
          response.data.event_attendees[0].dates || [];
        let previousTravelDatesWithHotel = 0;
        for (const date of response.data.event_dates) {
          const correspondingAttendeeDate = previousAttendeeDate.find(
            (pad: EventAttendeeDate) => pad.date === date.id
          );
          if (!correspondingAttendeeDate) {
            previousAttendeeDate.push({
              date: date.id,
              hotel_required:
                date.type !== "Travel" &&
                date.hotel_required &&
                date.hotel_allowed &&
                !pastCancDeadline,
              attending: date.type !== "Travel",
            });
          } else {
            previousTravelDatesWithHotel +=
              date.type === "Travel" && correspondingAttendeeDate.hotel_required
                ? 1
                : 0;
          }
        }
        setMaxExtraNights(
          previousTravelDatesWithHotel >
            response.data.prospective_event.max_extra_nights &&
            response.data.prospective_event.max_extra_nights > 0
            ? previousTravelDatesWithHotel
            : response.data.prospective_event.max_extra_nights
        );
        setPreviousAttendeeDates(structuredClone(previousAttendeeDate));
        setAttendeeDates(structuredClone(previousAttendeeDate));
        setIsAttendingEvent(
          !response.data.event_attendees[0].has_replied ||
            previousAttendeeDate.filter(
              (date: EventAttendeeDate) => date.hotel_required || date.attending
            ).length > 0
        );

        setPlusOne(response.data.event_attendees[0].plus_one);
        setDetailsOpen(response.data.event_attendees[0].plus_one);

        setPrefersTwin(
          response.data.event_attendees[0].prefers_twin === null
            ? undefined
            : response.data.event_attendees[0].prefers_twin
            ? "Twin"
            : "Double"
        );

        setPreferencesSubmitted(false);
        setLoadingEventData(false);
      } catch (error) {
        console.error("Error fetching event details:", error);
        setLoadingEventData(false);
      }
    };

    setFormValues({
      name: "",
      roomPreferences: "",
      sharingPreferences: "",
    });

    fetchEventDetails();
  }, [props.response]);

  const handleInputChange = async (e: any, key: string) => {
    setFormValues({ ...formValues, [key]: e.target.value });
  };

  const toggleAttendConfirmation = async (e: any, dateId: number) => {
    const cd = [...attendeeDates];
    let changeDate = cd.find((ad) => ad.date === dateId);
    if (changeDate) changeDate.attending = e.target.checked;
    setAttendeeDates(cd);
  };

  const toggleHotelRequiredConfirmation = async (e: any, dateId: number) => {
    // This is to prevent checking more than the allowed number of travel dates
    if (
      e.target.checked && // Only when selecting, rather than de-selecting
      maxExtraNights > 0 && // Only where there is a restriction in place
      event?.dates.find((d) => d.id === dateId)?.type === "Travel" && // Only where the checkbox clicked is for a Travel Date
      attendeeDates
        .filter((ad) => ad.hotel_required)
        .filter(
          (ad) => event?.dates.find((d) => d.id === ad.date)?.type === "Travel"
        ).length >= maxExtraNights // Only where we are already at limit
    ) {
      setWarningModalText(
        `Your organiser has set a limit of ${maxExtraNights} hotel nights for non-event dates. Please first de-select another non-event date to chose this one.`
      );
      setWarningModalLabel(`Hotel night limit reached`);
      setWarningModalOpen(true);
    } else {
      const cd = [...attendeeDates];
      let changeDate = cd.find((ad) => ad.date === dateId);
      if (changeDate) changeDate.hotel_required = e.target.checked;
      setAttendeeDates(cd);
    }
  };

  const handleSubmit = async () => {
    try {
      if (
        attendeeDates.filter((ad) => ad.attending || ad.hotel_required)
          .length === 0 &&
        isAttendingEvent
      ) {
        setWarningModalText(
          `Please select attendance days or confirm you are not attending.`
        );
        setWarningModalLabel(`Unable to save preferences`);
        setWarningModalOpen(true);
        return;
      }

      if (!formValues.name?.trim() && isAttendingEvent) {
        setWarningModalText(`Please include your name.`);
        setWarningModalLabel(`Unable to save preferences`);
        setWarningModalOpen(true);
        return;
      }

      setSubmittingData(true);
      await axios.patch(
        `/api/event-external/events/${eventId}/attendees/${attendeeId}`,
        {
          name: formValues.name?.trim(),
          roomPreferences: roomPreferences ? formValues.roomPreferences : null,
          sharingPreferences: sharingPreferences
            ? formValues.sharingPreferences
            : null,

          attendeeDates: isAttendingEvent
            ? attendeeDates
            : attendeeDates.map((ad) => {
                return { ...ad, attending: false, hotel_required: false };
              }),
          plusOne: plusOne,
          prefersTwin:
            prefersTwin === "Double"
              ? false
              : prefersTwin === "Twin"
              ? true
              : undefined,
        }
      );
      setSubmittingData(false);

      setEventForCalendars({
        startDate: event?.dates.find((d) =>
          attendeeDates.find(
            (ad) => (ad.hotel_required || ad.attending) && ad.date === d.id
          )
        )?.date,
        endDate: event?.dates
          .slice()
          .reverse()
          .find((d) =>
            attendeeDates.find(
              (ad) => (ad.hotel_required || ad.attending) && ad.date === d.id
            )
          )?.date,
        title: event?.details.name || "Team Event",
        description:
          event?.details.name ||
          `Your colleague ${
            event?.details.creator_name || event?.details.creator_email
          }'s event`,
        location:
          event?.details.place_option === "find"
            ? event?.details.find_destination
            : event?.details.specific_place || "",
      });

      setPreferencesSubmitted(true);
    } catch (error) {
      console.error("Error submitting preferences:", error);
      setPreferencesSubmitted(false);
      setSubmittingData(false);
      setWarningModalText(`Could not update preferences, please try again.`);
      setWarningModalLabel(`Something went wrong`);
      setWarningModalOpen(true);
    }
  };

  return (
    <div className="attendee-form-questions">
      <WarningModal
        setIsOpen={setWarningModalOpen}
        modalIsOpen={warningModalOpen}
        text={warningModalText}
        label={warningModalLabel}
      ></WarningModal>
      <PersonalDaysModal
        modalIsOpen={personalDaysModalIsOpen}
        setIsOpen={setPersonalDaysModalIsOpen}
        event={event}
      ></PersonalDaysModal>

      <div className="attendee-form-questions-content">
        {event && event.details ? (
          preferencesSubmitted ? (
            <div className="preferencesSuccess">
              <RepliedSuccessfully />
              <h3>You've replied successfully</h3>
              <h5>We have saved your attendance and preferences.</h5>
              <p>
                If something changes, update your response using this form.
                {!isPastResponseDeadline && event.details.attendee_deadline
                  ? ` The response deadline is the ${format(
                      new Date(event.details.attendee_deadline),
                      "do MMMM yyyy"
                    )} at ${format(
                      new Date(event.details.attendee_deadline),
                      "HH:mm z."
                    )}`
                  : ""}
              </p>
              <Button
                variant="secondary"
                onClick={() => setPreferencesSubmitted(false)}
              >
                EDIT RESPONSE
              </Button>
            </div>
          ) : (
            <div>
              <div className="eventInviteTitle">
                <p>
                  You're responding for <b>{event.attendees[0].email}</b>
                </p>
                {!isDisabled &&
                  isPastResponseDeadline &&
                  !isPastCancellationDeadline && (
                    <div className="information warning">
                      <p>
                        The response deadline for this event was{" "}
                        {format(
                          new Date(event.details.attendee_deadline),
                          "eeee, do MMM hh:mmaaa z"
                        )}
                        . You can still add or change your dates and
                        preferences, but hotel allocation will be subject to
                        availability.
                      </p>
                    </div>
                  )}
                {!isDisabled && isPastCancellationDeadline && (
                  <div className="information warning">
                    <p>
                      Hotel bookings and room allocations have now been
                      finalised for this event. You can still adjust your
                      attendance and preferences, but rooms cannot be added any
                      longer.
                    </p>
                  </div>
                )}
                {isDisabled && (
                  <div className="information warning">
                    <p>Preferences for this event can no longer be edited.</p>
                  </div>
                )}
              </div>
              <div className="form-controls-wrapper">
                <Form.Group className="question-container-main">
                  <section className="formQuestionSection form-section form-question-indent">
                    <article className="formQuestionInputs">
                      <Form.Label className="attendance-title number-tag">
                        Which dates can you attend?
                      </Form.Label>
                      {isAttendingEvent && (
                        <>
                          <p>
                            Let us know which dates you'll come to the event,
                            and whether you need a hotel room booked. <br />
                            <b>
                              Hotel bookings are for the night of the date
                              shown, through to the following day.
                            </b>{" "}
                            {event.offers && event.offers.length > 0 && (
                              <a
                                href="#"
                                onClick={() => setPersonalDaysModalIsOpen(true)}
                              >
                                Want to stay extra nights before or after the
                                event?
                              </a>
                            )}
                          </p>

                          <table>
                            <thead>
                              <th />
                              <th />
                              <th>I'm attending</th>
                              <th>I need a hotel for this night</th>
                            </thead>
                            <tbody>
                              {event.dates
                                .filter(
                                  (date) =>
                                    date.type !== "Travel" ||
                                    (date.hotel_required &&
                                      date.hotel_allowed) ||
                                    previousAttendeeDates
                                      .filter((pad) => pad.hotel_required)
                                      .map((pad) => pad.date)
                                      .includes(date.id)
                                )
                                .map((date) => (
                                  <tr key={date.id}>
                                    <td className="attendance-date-data">
                                      <b>{format(date.date, "eeee")}</b>{" "}
                                      {format(date.date, "dd MMM yyyy")}
                                    </td>
                                    <td>
                                      {date.type === "Travel" ? (
                                        <AttendanceTravel />
                                      ) : (
                                        <AttendanceRequired />
                                      )}
                                      {date.type === "Travel"
                                        ? "Non-event Date"
                                        : "Event Date"}
                                    </td>
                                    <td className="table-form form-attending-checkbox">
                                      <div>
                                        {date.type !== "Travel" && (
                                          <>
                                            <span className="show-on-mobile checkbox-label">
                                              Attending?
                                            </span>
                                            <Form.Check
                                              type="checkbox"
                                              disabled={
                                                submittingData || isDisabled
                                              }
                                              id={date.id.toString()}
                                              name="attendingCheck"
                                              checked={
                                                attendeeDates.find(
                                                  (ad) => ad.date === date.id
                                                )?.attending
                                              }
                                              onChange={(e) => {
                                                toggleAttendConfirmation(
                                                  e,
                                                  date.id
                                                );
                                              }}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </td>

                                    {(date.hotel_required &&
                                      date.hotel_allowed) ||
                                    previousAttendeeDates.find(
                                      (pad) => pad.date === date.id
                                    )?.hotel_required ? (
                                      <td className="table-form hotel-required form-hotel-checkbox">
                                        <div>
                                          <span className="show-on-mobile checkbox-label">
                                            Need a Hotel?
                                          </span>
                                          <Form.Check
                                            type="checkbox"
                                            id={date.id.toString()}
                                            disabled={
                                              isPastCancellationDeadline ||
                                              submittingData ||
                                              isDisabled
                                            }
                                            name="hotelRequiredCheck"
                                            checked={
                                              attendeeDates.find(
                                                (ad) => ad.date === date.id
                                              )?.hotel_required
                                            }
                                            onChange={(e) => {
                                              toggleHotelRequiredConfirmation(
                                                e,
                                                date.id
                                              );
                                            }}
                                          />
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="hotel-not-required">
                                        <Tooltip
                                          className="eventTooltip"
                                          id="hotel_not_required"
                                        />
                                        <div
                                          className="noHotel"
                                          data-tooltip-id="hotel_not_required"
                                          data-tooltip-place="top"
                                          data-tooltip-html="<h3>No hotel booking allowed<h3><p>Your event organiser has set this date to not allow hotel bookings. This may be because they’re arranging accommodation themselves, because it’s not required, or because this date has been marked as restricted. </p>"
                                        >
                                          <NoHotel />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {event.dates.filter((d) => !d.hotel_required).length >
                            0 && (
                            <div className="attendanceMetaInfo attendanceNoHotel">
                              <NoHotel /> Your organiser has selected some dates
                              as no hotel booking allowed
                            </div>
                          )}
                          <div className="attendanceMetaInfo attendanceTotal">
                            <b>
                              {
                                attendeeDates.filter(
                                  (ad) =>
                                    ad.attending &&
                                    event.dates
                                      .filter((d) => d.type === "Required")
                                      .map((d) => d.id)
                                      .includes(ad.date)
                                ).length
                              }
                              /
                              {
                                event.dates.filter((d) => d.type === "Required")
                                  .length
                              }
                            </b>{" "}
                            event days selected
                          </div>
                        </>
                      )}
                    </article>
                  </section>

                  <section className="formQuestionSection form-section form-question-indent">
                    <div className="form-attendance-negative">
                      <Form.Check
                        type="checkbox"
                        disabled={submittingData || isDisabled}
                        id="notAttendingEvent"
                        name="notAttendingEvent"
                        checked={!isAttendingEvent}
                        onChange={() => setIsAttendingEvent(!isAttendingEvent)}
                      />
                      <Form.Label for="notAttendingEvent">
                        I won't be able to attend the event
                      </Form.Label>
                    </div>
                  </section>

                  {isAttendingEvent &&
                    attendeeDates.filter((ad) => ad.hotel_required).length >
                      0 && (
                      <>
                        <section className="formQuestionSection">
                          <article className="formQuestionInputs form-section form-input-name form-question-indent">
                            <Form.Label className="attendance-title number-tag">
                              Your name
                              <p>
                                {`We'll use this to book your hotel room so please make sure it matches your passport or ID.`}
                              </p>
                            </Form.Label>
                            <div className="macro-option">
                              <Form.Control
                                type="text"
                                value={formValues.name}
                                disabled={submittingData || isDisabled}
                                onChange={(e) => handleInputChange(e, "name")}
                              />
                            </div>
                            {!event.attendees[0].must_share_room &&
                              event.details.plus_ones_covered !== null && (
                                <details
                                  className="room-share-details"
                                  open={detailsOpen}
                                >
                                  <summary
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (!detailsOpen) {
                                        setPlusOne(true);
                                        setDetailsOpen(true);
                                      }
                                    }}
                                  >
                                    <IconUsers />
                                    <div>
                                      <h5>Bringing someone else?</h5>
                                      <p>
                                        By default, your room will be booked for
                                        single occupancy
                                        {!event.details.plus_ones_covered
                                          ? " and an additional charge may apply for an extra person."
                                          : ", but your organiser is allowing an extra person and will cover the additional cost."}
                                      </p>
                                      <span className="room-share-cta">
                                        I want to bring someone else
                                      </span>
                                    </div>
                                  </summary>
                                  <div className="plus-one-form">
                                    <Form.Check
                                      className="plusOneCheckBox"
                                      id="plusOne"
                                      name="plusOne"
                                      checked={plusOne}
                                      onChange={(e) => {
                                        setPlusOne(e.target.checked);
                                        if (!e.target.checked)
                                          setPrefersTwin(undefined);
                                      }}
                                      disabled={submittingData || isDisabled}
                                    />{" "}
                                    <Form.Label for="plusOne">
                                      I'm planning to bring another person with
                                      me
                                      {!event.details.plus_ones_covered
                                        ? " and agree to cover any price difference myself."
                                        : "."}
                                    </Form.Label>
                                  </div>
                                  <div
                                    className="plus-one-room-prefs"
                                    hidden={!plusOne}
                                  >
                                    <label htmlFor="roomPrefs">
                                      Room setup preference:
                                    </label>
                                    <select
                                      disabled={isDisabled}
                                      id="roomPrefs"
                                      value={prefersTwin}
                                      onChange={(e) =>
                                        setPrefersTwin(e.target.value)
                                      }
                                    >
                                      <option selected={!prefersTwin}>
                                        No preference
                                      </option>
                                      <option>Double</option>
                                      <option>Twin</option>
                                    </select>
                                    <p>
                                      Room types will depend on hotel
                                      availability and cannot be guaranteed.
                                    </p>
                                  </div>
                                </details>
                              )}
                          </article>
                        </section>

                        <section className="formQuestionSection attendance-preferences">
                          <article className="formQuestionInputs">
                            <Form.Label className="attendance-title number-tag">
                              Do you have any room preferences?
                              <p>
                                {`Need an accessible room? Do you have medical requirements? Let us know.`}
                              </p>
                            </Form.Label>
                            <div className="form-question-indent">
                              <Form.Label className="checkboxGroup">
                                <Form.Check
                                  type="radio"
                                  className="roomCheckbox impala-styled-radio"
                                  id="noRoomPreferences"
                                  name="roomPreferencesOption"
                                  disabled={submittingData || isDisabled}
                                  checked={!roomPreferences}
                                  onChange={(e) => setRoomPreferences(false)}
                                />{" "}
                                No, I don't have any room preferences
                              </Form.Label>

                              <Form.Label className="checkboxGroup">
                                <Form.Check
                                  type="radio"
                                  className="roomCheckbox impala-styled-radio"
                                  id="yesRoomPreferences"
                                  name="roomPreferencesOption"
                                  disabled={submittingData || isDisabled}
                                  checked={roomPreferences}
                                  onChange={(e) => setRoomPreferences(true)}
                                />
                                Yes, they are:
                              </Form.Label>
                              <div className="macro-option">
                                <Form.Control
                                  type="text"
                                  value={formValues.roomPreferences}
                                  disabled={
                                    !roomPreferences ||
                                    submittingData ||
                                    isDisabled
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, "roomPreferences")
                                  }
                                />
                              </div>
                            </div>
                          </article>
                        </section>
                        {event.attendees[0].must_share_room && (
                          <section className="formQuestionSection attendance-preferences">
                            <article className="formQuestionInputs">
                              <Form.Label className="attendance-title number-tag">
                                Do you have any room sharing preferences?
                                <p>
                                  {`Your event organiser is asking attendees to share rooms in the hotel. Please let us know if you have any preferences, such as sharing with a particular person, a member of a particular team, or gender.`}
                                </p>
                              </Form.Label>
                              <div className="form-question-indent">
                                <Form.Label className="checkboxGroup">
                                  <Form.Check
                                    type="radio"
                                    className="roomCheckbox impala-styled-radio"
                                    id="noSharingPreferences"
                                    name="sharingPreferencesOption"
                                    disabled={submittingData || isDisabled}
                                    checked={!sharingPreferences}
                                    onChange={(e) =>
                                      setSharingPreferences(false)
                                    }
                                  />{" "}
                                  No, I don't have any room sharing preferences
                                </Form.Label>

                                <Form.Label className="checkboxGroup">
                                  <Form.Check
                                    type="radio"
                                    className="roomCheckbox impala-styled-radio"
                                    id="yesSharingPreferences"
                                    name="sharingPreferencesOption"
                                    disabled={submittingData || isDisabled}
                                    checked={sharingPreferences}
                                    onChange={(e) =>
                                      setSharingPreferences(true)
                                    }
                                  />
                                  Yes, they are:
                                </Form.Label>
                                <div className="macro-option">
                                  <Form.Control
                                    type="text"
                                    value={formValues.sharingPreferences}
                                    disabled={
                                      !sharingPreferences ||
                                      submittingData ||
                                      isDisabled
                                    }
                                    onChange={(e) =>
                                      handleInputChange(e, "sharingPreferences")
                                    }
                                  />
                                </div>
                              </div>
                            </article>
                          </section>
                        )}
                        {/* <RoomIcon />
                      <Form.Label>Do you have any meal preferences?</Form.Label>
                      <p>
                        {`Please let us know about any allergies, dietary requirements, or preferences.`}
                      </p>
                      <Form.Check
                        type="radio"
                        id="noMealPreferences"
                        name="mealPreferencesOption"
                        checked={!mealPreferences}
                        onChange={(e) => setMealPreferences(false)}
                      />{" "}
                      <Form.Label>
                        No, I don't have any meal preferences
                      </Form.Label>
                      <Form.Check
                        type="radio"
                        id="yesMealPreferences"
                        name="mealPreferencesOption"
                        checked={mealPreferences}
                        onChange={(e) => setMealPreferences(true)}
                      />
                      <Form.Label>Yes, they are:</Form.Label>
                      <div className="macro-option">
                        <Form.Control
                          type="text"
                          value={formValues.mealPreferences}
                          disabled={!mealPreferences}
                          onChange={(e) =>
                            handleInputChange(e, "mealPreferences")
                          }
                        />
                      </div> */}
                        <div className="disclaimer-wrapper">
                          <p className="formDisclaimer">
                            We'll do our best to take your needs into account,
                            but we'll prioritize people with medical
                            requirements first.
                          </p>
                        </div>
                      </>
                    )}
                  <div className="form-save-preferences-wrapper">
                    <Button
                      className="formConfirm"
                      onClick={handleSubmit}
                      disabled={submittingData || isDisabled}
                    >
                      Save dates & preferences
                    </Button>
                  </div>
                </Form.Group>
              </div>
            </div>
          )
        ) : loadingEventData ? (
          <Loader variation="linear" />
        ) : (
          <div className="overall-summary">
            <Error />
          </div>
        )}
        {preferencesSubmitted &&
          isAttendingEvent &&
          event &&
          eventForCalendars &&
          eventForCalendars.startDate && (
            <div className="calendar-download-area-wrapper">
              <div className="calendar-download-area">
                <div className="header">
                  <CalendarIcon /> Add this event to your calendar
                </div>
                <div className="links">
                  <CalendarLink
                    title="Google Calendar"
                    event={eventForCalendars}
                    type="GOOGLE"
                  />
                  <CalendarLink
                    title="Outlook"
                    event={eventForCalendars}
                    type="OUTLOOK"
                  />
                  <CalendarLink
                    title="Apple Calendar"
                    event={eventForCalendars}
                    type="APPLE"
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default EventAttendeePreferences;
