import React from "react";
import Modal from "react-modal";
import { Button } from "react-bootstrap";

import "./Events.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "640px",
  },
};

function ConfirmModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  content: string;
  onConfirm: Function;
  isDisabled: boolean;
}) {
  // Accessibility enhancement: https://reactcommunity.org/react-modal/accessibility/
  Modal.setAppElement("#root");

  function closeModal() {
    props.setIsOpen(false);
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Delete Attendee"
    >
      <div className="event-management-modal">
        <h4>Are you sure?</h4>
        <div className="event-attendee-delete-modal-title">
          <p>{props.content}</p>
        </div>
      </div>

      <footer className="modalButtonFooter">
        <Button className="btn-secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={props.isDisabled}
          className="btn-primary"
          onClick={() => {
            props.onConfirm();
            closeModal();
          }}
        >
          Confirm{" "}
        </Button>
      </footer>
    </Modal>
  );
}

export default ConfirmModal;
