import React, { useEffect, useState } from "react";

import { ReactComponent as BackIcon } from "../img/arrow-left.svg";

import { Event } from "./EventStatus";

import { Button } from "react-bootstrap";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";

import CreatableSelect from "react-select/creatable";
import { MultiValue } from "react-select";
import Tiptap from "./TipTap";
import { Editor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import Placeholder from "@tiptap/extension-placeholder";
import EmailPreview from "./EmailPreview";

type Option = { label: string; value: string };

const extensions = [
  StarterKit,
  Underline,
  Link.configure({
    protocols: ["mailto"],
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Placeholder.configure({
    placeholder: "Customize your email invite text",
  }),
];

const InviteEventAttendees = (props: {
  openWarningModal: Function;
  event: Event;
  onUpdate: Function;
  selectedAttendees: string[];
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [axios] = useAuthenticatedAxios(true);
  const [options, setOptions] = useState<Option[]>([]);
  const [recipients, setRecipients] = useState<Option[]>([]);
  const [invitingAttendees, setInvitingAttendees] = useState<boolean>(false);
  const [emailsToInvite, setEmailsToInvite] = useState<string[]>(
    props.event.attendees
      .filter((a) => !a.is_silent)
      .filter((a) => props.selectedAttendees.includes(a.id))
      .map((a) => a.email)
  );
  const [editor, setEditor] = useState<Editor>();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  useEffect(() => {
    setEditor(
      new Editor({
        extensions: extensions,
        content:
          props.event.marketing?.find((m) => m.type === "attendee_invite")
            ?.content || props.event.details.invite_text,
        editable: !["Ongoing", "Completed", "Cancelled"].includes(
          props.event.details.status
        ),
      })
    );
  }, [
    props.event.details.invite_text,
    props.event.details.status,
    props.event.marketing,
  ]);

  useEffect(() => {
    setOptions(
      props.event.attendees
        .filter((a) => !a.is_silent)
        .sort((a, b) => (a?.email < b?.email ? -1 : 1))
        .map((a) => {
          return {
            label: a.email,
            value: a.id,
          };
        })
    );
    setRecipients(
      props.event.attendees
        .filter((a) => !a.is_silent)
        .filter((a) => emailsToInvite.includes(a.email))
        .sort((a, b) => (a?.email < b?.email ? -1 : 1))
        .map((a) => {
          return {
            label: a.email,
            value: a.id,
          };
        })
    );
  }, [emailsToInvite, props.event.attendees, props.selectedAttendees]);

  const handleNewValue = (newValue: MultiValue<Option> | Option[]) => {
    const newOptions: Option[] = structuredClone(newValue);
    setRecipients(newOptions);
    setEmailsToInvite(newOptions.map((o) => o.label));
  };

  const openPreview = () => {
    setPreviewOpen(true);
  };

  const handleCreate = async (inputValue: string) => {
    setInvitingAttendees(true);
    const emails: string[] = [inputValue];

    const allEmailsValid = emails
      .map((email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))
      .every(Boolean);

    if (emails.length > 0 && !allEmailsValid) {
      props.openWarningModal(
        "Invalid email address",
        `It looks like this email (${emails[0]}) doesn't have the right format. Please double check.`
      );
    } else {
      try {
        await axios.post(
          `/api/users/events/1634${props.event.details.id}/attendees`,
          {
            emails: emails,
            groups: [],
            suppressInvite: true,
          }
        );
        setEmailsToInvite([...emailsToInvite, inputValue]);
        props.onUpdate();
        setInvitingAttendees(false);
      } catch (error) {
        console.error("Error adding event attendees:", error);
      }
    }
    setInvitingAttendees(false);
  };

  const handleSend = async () => {
    setInvitingAttendees(true);
    try {
      const response = await axios.post(
        `/api/users/events/1634${props.event.details.id}/send-attendee-invites`,
        {
          attendees: recipients.map((r) => r.value),
          json: editor?.getJSON(),
          html: editor?.getHTML(),
        }
      );
      props.onUpdate();
      if (response.data.emailsSent === 0) throw new Error("No emails sent");
      props.openWarningModal(
        "Invites sent",
        `You sent invite emails to ${response.data.emailsSent} attendee${
          response.data.emailsSent > 1 ? "s" : ""
        }`
      );
      props.setSubPage("");
    } catch (error) {
      console.error("Error adding event attendees:", error);
      props.openWarningModal(
        "Invites could not be sent",
        `We could not send attendee invites. Please try again.`
      );
    }
    setInvitingAttendees(false);
  };

  return (
    <div>
      <EmailPreview
        setIsOpen={setPreviewOpen}
        modalIsOpen={previewOpen}
        custom_text={editor?.getHTML() || ""}
        subject={`You're invited to ${props.event.details.name}`}
        emailTitle={`You're invited to ${props.event.details.name}`}
        button={"Add your information"}
        event={props.event}
      ></EmailPreview>
      <div className="manage-attendees-wrapper">
        <header className="pageHeader">
          <Button
            className="backHeaderLink"
            onClick={() => props.setSubPage("")}
          >
            <BackIcon />
            <span className="visually-hidden">Back</span>
          </Button>
          <h2>Create new invite</h2>
          <div className="invite-actions">
            <Button className="btn-secondary" onClick={openPreview}>
              Preview
            </Button>
            <Button onClick={handleSend} disabled={recipients.length < 1}>
              Send Invite Now
            </Button>
          </div>
        </header>
        <div className="attendee-invite-addressbar">
          <label>To: </label>
          <CreatableSelect
            isMulti
            isLoading={invitingAttendees}
            isClearable
            isDisabled={["Ongoing", "Completed", "Cancelled"].includes(
              props.event.details.status
            )}
            value={recipients}
            onCreateOption={(newValue) => handleCreate(newValue)}
            options={options}
            onChange={(newValue) => handleNewValue(newValue)}
            id="recipients"
            name="recipients"
            noOptionsMessage={({ inputValue }) => {
              return (
                <>
                  No more attendees to invite. Type an email address to create
                  one.
                </>
              );
            }}
            placeholder="No attendees selected"
            formatCreateLabel={(inputValue: string) => {
              return (
                <>
                  Create a new attendee for
                  <br />
                  <b>{inputValue}</b>
                </>
              );
            }}
          />
        </div>
        <hr />
        The attendees above will receive an invite email. This includes a link
        to their response form, where they can mark their attendance, hotel
        requirements, and preferences. You can customize the invitation email
        below.
        <section className="agenda-container">
          <div className="agenda-date-rows">
            <div className="agenda-date">
              <div className="agenda-editor">
                <Tiptap editor={editor}></Tiptap>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default InviteEventAttendees;
