import React from "react";

import { Event } from "./EventStatus";
import { Button } from "react-bootstrap";

import "./Events.css";
import "./attendeeForm.css";

import { ReactComponent as PreferencesIllustration } from "../images/attendee-form/preferences.svg";
import { ReactComponent as AgendaIllustration } from "../images/attendee-form/agenda.svg";
import { Editor } from "@tiptap/react";
import EventAttendeeHotel from "./EventAttendeeHotel";

const EventAttendeeAbout = (props: {
  event: Event;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  editors: Map<number, Editor>;
}) => {
  return (
    <>
      <div className="event-about">
        <div className="event-about-content">
          {props.event.details.id !== 68 ? (
            <div
              className="event-about-user-content"
              dangerouslySetInnerHTML={{
                __html: props.event.details.invite_text,
              }}
            ></div>
          ) : (
            <>
                <p>
                  Welcome to Summit Week 2024!
                  <br/><br/>
                  Great news you're all set for Summit Week 2024! Your accommodation details are included below!
                  <br/><br/>
                  So you feel fully prepared for your trip to Berlin, please find all the information on your arrival and stay on the <a href="https://sites.google.com/getyourguide.com/intranet/workplace/summit-week-2024/international-travel" target="_blank">International Travel page</a>.
                  <br/><br/>
                For information on the office, from maps to access card information, please see the <a href="https://sites.google.com/getyourguide.com/intranet/workplace/summit-week-2024/navigating-ampere" target="_blank">Navigating Ampere page</a>.
                <br/><br/>
                  A quick read through both of these pages will set you up well before, during and after your trip! Safe travels!
                </p>
            </>
          )}
          {props.event.details.rooming_list_approved &&
            props.event.attendees[0].dates.filter(d => d.assigned_hotel).length > 0 &&
            (props.event.attendees[0].room_confirmation_sent ||
              props.event.attendees[0].is_silent) && (
              <EventAttendeeHotel event={props.event}></EventAttendeeHotel>
            )}
          <section className="event-actions">
            <div className="event-action-item">
              <PreferencesIllustration />
              <div className="event-action-item-content">
                <h4>
                  {props.event.attendees[0].has_replied ? "Edit" : "Add"} Your
                  Preferences
                </h4>
                <p>
                  Let us know what dates you can come, and whether you’ve got
                  any requirements for your room.
                </p>
                <Button
                  variant="secondary"
                  onClick={() => props.setTabIndex(1)}
                >
                  {props.event.attendees[0].has_replied ? "Edit" : "Add"}{" "}
                  preferences
                </Button>
              </div>
            </div>
            {Array.from(props.editors.values()).filter(
              (editor) => editor && editor.state.doc.textContent.length
            ).length > 1 && (
              <div className="event-action-item">
                <AgendaIllustration />
                <div className="event-action-item-content">
                  <h4>View the Agenda</h4>
                  <p>
                    Learn more about what your organiser has planned for each
                    day.
                  </p>
                  <Button
                    variant="secondary"
                    onClick={() => props.setTabIndex(2)}
                  >
                    View agenda
                  </Button>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default EventAttendeeAbout;
