import React from "react";
import { ReactComponent as ComputerOnFire } from "../img/computer-on-fire.svg";

const Error = () => {
  return (
    <div>
      <ComputerOnFire />
      <h2>Something has gone wrong</h2>
      <p>
        We've had a problem with the events app and can't do what you've asked.
      </p>
      <div>
        <p>
          Keep seeing this error?{" "}
          <a href="mailto:support@impala.travel">Let us know</a>
        </p>
      </div>
    </div>
  );
};

export default Error;
