import React from "react";

import { ReactComponent as CheckOutIcon } from "../img/check-out-dark.svg";
import { ReactComponent as HelpCircleIcon } from "../img/help-circle.svg";
import { ReactComponent as AttendeesIcon } from "../img/attendees-dark.svg";
import { ReactComponent as HotelsIcon } from "../img/hotel.svg";
import { ReactComponent as DatesIcon } from "../img/calendar-dark.svg";
import { ReactComponent as HomeIcon } from "../img/home.svg";
import { ReactComponent as StatsIcon } from "../img/data-bars.svg";
import { ReactComponent as ScheduleIcon } from "../img/tasks.svg";

import { useAuth0 } from "@auth0/auth0-react";

import { ReactComponent as BackIcon } from "../img/arrow-left.svg";
import { Link } from "react-router-dom";
import { Event } from "./EventStatus";

const EventNavigation = (props: {
  setEventPage: React.Dispatch<React.SetStateAction<string>>;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
  eventPage: string;
  event: Event;
}) => {
  const userAuth = useAuth0();

  const navigationButtons = [
    { label: "Event Details", icon: <HomeIcon /> },
    { label: "Manage Dates", icon: <DatesIcon /> },
    { label: "Attendees", icon: <AttendeesIcon /> },
    { label: "Hotels", icon: <HotelsIcon /> },
  ];

  if (props.event.details.is_procured) {
    navigationButtons.push({ label: "Stats & Costs", icon: <StatsIcon /> });
    navigationButtons.push({
      label: "Planning Schedule",
      icon: <ScheduleIcon />,
    });
  }

  return (
    <div className="navigation-column">
      <div className="back-logo">
        <Link className="navigation-back" to="/srv/event/list">
          <BackIcon />
          <p>
            <span>Back to </span>All Events
          </p>
        </Link>
        <div>
          <img className="mainlogo" src="/logo.png" alt="Impala Logo" />
        </div>
      </div>
      <nav>
        <ul>
          {navigationButtons.map((button) => (
            <li>
              <a
                className={`navigation-top${
                  props.eventPage === button.label ? " selected" : ""
                }`}
                onClick={() => {
                  props.setEventPage(button.label);
                  props.setSubPage("");
                }}
              >
                {button.icon} {button.label}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <div className="extra-links">
        <Link className="navigation-bottom" to="https://impala.travel/help/help-support/" target="_blank">
          <HelpCircleIcon /> Help & Support
        </Link>
        <a
          className="navigation-bottom"
          onClick={() =>
            userAuth.logout({
              logoutParams: {
                returnTo:
                  window.location.origin + `/callback?eventServiceLogout=true`,
              },
            })
          }
        >
          <CheckOutIcon /> Logout
        </a>
      </div>
    </div>
  );
};

export default EventNavigation;
