import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import BenefitsList from "../../components/BenefitsList";
import HotelProgramMeta from "../../components/HotelProgramMeta";
import FAQSection from "../../components/FAQSection";

const AboutOurProgram = () => {
  const [customerData, setCustomerData] = useState(null);
  const navigate = useNavigate();
  const { companyName } = useParams();

  return (
    <div className="hotel-program-display">
      <HotelProgramMeta
        setData={setCustomerData}
        setIsLoading={() => {}}
        setError={() => {}}
        data={customerData}
        leftText="Back to Program"
        leftAction={() => navigate(`/hotel-program/${companyName}`)}
        hideRight
      />
      <div className="about-program-wrapper">
        <div className="top-section">
          <div className="left-side">
            <h1>About our travel program</h1>
            <div>
              <p>We’ve selected ideal hotels for your work trips.</p>
              <p>
                Conveniently located close to our offices, these hotels have
                everything you need for your work trip; comfy rooms, fast
                internet, and gyms included.
              </p>
              <p>
                Please only book these hotels when you’re travelling to an
                office location, for other trips you can continue to use Navan
                as usual.
              </p>
            </div>
            <button
              className="view-hotels-button"
              onClick={() => navigate(`/hotel-program/${companyName}`)}
            >
              View hotels
            </button>
          </div>
          <div className="right-side"></div>
        </div>
        <div className="mid-section">
          <img src="/gift-image.png" />
          <h2>We've made travelling even more rewarding</h2>
          <p>
            Choosing to book direct doesn’t just save money, it also unlocks
            great benefits for you.{" "}
          </p>
          <BenefitsList hideHeadline mode="listing" />
        </div>
      </div>
      <FAQSection />
    </div>
  );
};

export default AboutOurProgram;
