import "./EditEventNameModal/EditEventNameModal.css";

import React, { useState } from "react";

import { Button, Form } from "react-bootstrap";
import { ReactComponent as BackIcon } from "../img/arrow-left.svg";
import { ReactComponent as RandomIcon } from "../img/random.svg";
import { useNavigate } from "react-router-dom";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

const wordList1 = [
  "Group",
  "Team",
  "Squad",
  "Company",
  "Fellowship",
  "Unified",
  "Corporate",
  "Innovative",
  "Cross-functional",
];

const wordList2 = [
  "Synergy",
  "Collaboration",
  "Empowerment",
  "Collective",
  "Strategic",
  "Performance",
  "Growth",
  "Dynamic",
  "Alignment",
];

const wordList3 = [
  "Session",
  "Exercise",
  "Gathering",
  "Initiative",
  "Journey",
  "Retreat",
  "Seminar",
  "Conclave",
  "Endeavour",
];

const CreateNewEvent = (props: {
  setIsNewEvent: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [axios] = useAuthenticatedAxios(false);
  const navigate = useNavigate();

  const [eventName, setEventName] = useState<string>("");
  const [eventLocation, setEventLocation] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);
  const [procurementRequested, setProcurementRequested] = useState(false);

  function randomizeName() {
    const word1 = wordList1[Math.floor(Math.random() * wordList1.length)];
    const word2 = wordList2[Math.floor(Math.random() * wordList2.length)];
    const word3 = wordList3[Math.floor(Math.random() * wordList3.length)];
    setEventName(`${word1} ${word2} ${word3}`);
  }

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const response = await axios.post(`/api/events`, {
        name: eventName,
        closeToLocation: eventLocation,
        procurementRequested: procurementRequested,
        placeOption: "specific",
      });
      navigate(`/events/1634${response.data.prospective_event_id}`);
    } catch (error) {
      alert("Error creating event");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="event-my-account-outer">
      <div className="event-my-account">
        <div className="event-my-account-inner">
            <header className="event-section-header">
            <a className="back-link" onClick={() => props.setIsNewEvent(false)}>
                <BackIcon />
                <span>Back to all events</span>
              </a>
              <h1>Create New Event</h1>
          </header>
          <div className="create-event-details">
            <div>
              <Form.Label for="eventName">Event Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={60}
                id="eventName"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
              <div className="name-controls">
                <button className="randomise-button" onClick={randomizeName}>
                  <RandomIcon />
                  Random name
                </button>
                <small>{eventName.length || "0"}/60 characters</small>
              </div>
              <Form.Label for="eventPlace">Event Location</Form.Label>
              <Form.Control
                type="text"
                maxLength={100}
                id="eventPlace"
                value={eventLocation}
                onChange={(e) => setEventLocation(e.target.value)}
              />
              <small>{eventLocation.length || "0"}/100 characters</small>
              <fieldset>
                <legend>How would you like to arrange accommodation?</legend>
                <section className="create-accom-options">  
                  <Form.Label for="notProcured" className="create-radio-group">
                    <Form.Check
                    type="radio"
                    className="impala-styled-radio"
                    id="notProcured"
                    checked={!procurementRequested}
                    onChange={(e) => setProcurementRequested(!e.target.checked)}
                  />
                    <h6>I'm organising hotel bookings myself</h6>
                    <p>Select this if you’re arranging your own accommodation. You’ll be able to add hotels for your attendees and use our rooming list tool to manage them. </p>
                    <p>If you’re unsure, choose this option - you can still ask Impala to manage hotels up to 4 weeks before your event starts.</p>
                </Form.Label>
                <Form.Label for="procured" className="create-radio-group">
                  <Form.Check
                    type="radio"
                    id="procured"
                    className="impala-styled-radio"
                    checked={procurementRequested}
                    onChange={(e) => setProcurementRequested(e.target.checked)}
                  />
                   <h6>Help me find the best hotels for my event</h6>
                   <p>Get Impala to find you great hotels at great prices. We'll manage all hotel communication, as well as creating and managing rooming lists.</p> 
                   <p>This requires more than 10 people at your event, and we need at least 4 weeks notice to begin sourcing hotels.</p>
                 </Form.Label>
                </section>
              </fieldset>
            </div>
            <div>
              <Button
                onClick={handleSubmit}
                disabled={submitting || !eventName || !eventLocation}
              >
                Create Event
              </Button>
            </div>
          </div>
        </div>
    </div>
    </div>
  );
};

export default CreateNewEvent;
