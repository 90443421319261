// HotelProgramMeta.js
import React, { useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as HelpIcon } from "../img/help-circle.svg";
import { ReactComponent as CalendarIcon } from '../img/calendar-small.svg';
import { ReactComponent as CheckOutIcon } from '../img/check-out.svg';
import { Image } from 'react-bootstrap';


import { ReactComponent as ImageArrowLeft } from "../img/arrow-left.svg";

import { useAuth0 } from "@auth0/auth0-react";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

const HotelProgramMeta = ({
  setData, setIsLoading, setError, data, leftText, leftAction, hideRight, showEventLink
}) => {
  const navigate = useNavigate();

  const [authenticatedAxios, tokenSet] = useAuthenticatedAxios(true);
  const { isAuthenticated, user, logout } = useAuth0();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authenticatedAxios.get(`/api/hotel-program/customer-deals`);
        setData(response.data);

        // Change the page title
        document.title = `Frequent Traveller Program`;
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [tokenSet]);

  if (!data) return null;

  return (
    <div className={`top-bar ${!hideRight && 'full-mode'}`}>
        {hideRight && (
          <div
            className="back-area"
            onClick={() => leftAction ? leftAction() : false}
          >
            {leftText && (
              <div className="back-button">
                <ImageArrowLeft />
                {leftText}
              </div>
            )}
          </div>
        )}
        {hideRight && (
          <div className="image-wrapper">
            <Image className="mainlogo" src="/logo.png" alt="Impala Logo" />
            <img src={data?.logo} alt={data?.name} />
          </div>
        )}
        <div className="right-wrapper">
          {!hideRight && (
            <>
              {showEventLink && (
                <div className="about-the-program" onClick={(e) => {
                  navigate(`/srv/event/create`);
                }}>
                  <CalendarIcon />
                  <span className="hide-on-mobile">Create & manage events</span>
                  <span className="show-on-mobile">Events</span>
              </div>  
              )}
              <div className="about-the-program" onClick={() => window.location = 'mailto:support@impala.travel'}>
                  <HelpIcon />
                  <span className="hide-on-mobile">Help & support</span>
                  <span className="show-on-mobile">Help</span>
              </div>
              <div className="logout-link" onClick={() => logout({
                  logoutParams: {
                      returnTo: window.location.origin+`/callback?directBookServiceLogout=true`,
                  },
              })}><CheckOutIcon /><span>Logout</span></div>
            </>
          )}
        </div>
    </div>
  );
};

export default HotelProgramMeta;
