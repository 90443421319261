import {
    GoogleApiWrapper,
    Map,
    Marker,
  } from "google-maps-react";
    
  const MapWithMarkers = ({
    google,
    hotelColor,
    dealColor,
    hotelLat,
    hotelLng,
    dealLat,
    dealLng,
  }) => (
    <Map
      google={google}
      zoom={14}
      containerStyle={{
        width: "100%",
        height: `320px`,
        position: "relative",
      }}
      initialCenter={{ lat: parseFloat(hotelLat), lng: parseFloat(hotelLng) }}
      mapTypeControl={false}
      zoomControl={false}
      streetViewControl
      rotateControl={false}
    >
      <Marker
        key="hotel"
        position={{ lat: parseFloat(hotelLat), lng: parseFloat(hotelLng) }}
        icon={{
          url: '/icons/hotel.svg',
          scaledSize: new window.google.maps.Size(32, 32)
        }}
      />
  
      <Marker
        position={{ lat: parseFloat(dealLat), lng: parseFloat(dealLng) }}
        icon={{
          url: '/icons/office.svg',
          scaledSize: new window.google.maps.Size(32, 32)
        }}
      />
    </Map>
  );

  export default GoogleApiWrapper({
    apiKey: "AIzaSyD7tGntA-5CYR_b6Te3PrQgKWsp7VkPwRw",
    libraries: ["visualization", "places", "geometry"],
  })(MapWithMarkers);
  

  