import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import useAuthenticatedAxios from '../../lib/useAuthenticatedAxios';
import { ReactComponent as PlaneIcon } from '../../img/airplane.svg'; // Assuming you have this icon
import { Spinner, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';

const FlightsAudit = () => {
  const [airlines, setAirlines] = useState([]);
  const [routeData, setRouteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [routeLoading, setRouteLoading] = useState(false);
  const [routeError, setRouteError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedAirline, setSelectedAirline] = useState('');
  const [totalImmediateSavings, setTotalImmediateSavings] = useState(0);
  const [totalFutureSavings, setTotalFutureSavings] = useState(0);
  const [auditedFlightSpend, setAuditedFlightSpend] = useState(0)
  const [totalSavingsAvailable, setTotalSavingsAvailable] = useState(0);
  const [authAxios, tokenSet] = useAuthenticatedAxios(true);

  const [auditedAirlines, setAuditedAirlines] = useState(null);

  useEffect(() => {
    if (!tokenSet) return;

    const fetchAuditedAirlines = async () => {
      try {
        const response = await authAxios.get('/api/audit/audited-airlines');
        const airlines = response.data.airlines;

        setAuditedAirlines(airlines);
      } catch (e) {
        console.error(e);
      }
    }

    fetchAuditedAirlines();
  }, [authAxios, tokenSet]);


  useEffect(() => {
    if (!tokenSet || !auditedAirlines) return;
    const fetchAirlinesData = async () => {
      setLoading(true);
      try {
        const response = await authAxios.get('/api/audit/airline-discounts-evaluation');
        const airlinesData = response.data.per_group;

        // Transform the data to array format for DataTable
        const airlines = Object.entries(airlinesData)
          .map(([key, value]) => ({
            airline: key,
            ...value,
          }))
          .sort((a, b) => {
            return b.totalSpend - a.totalSpend;
          })

        setAirlines(airlines);
        const { audited, unaudited } = airlines
          .reduce((prev, curr) => {
            let hasBeenAudited = false;
            if (auditedAirlines === 'all') {
              hasBeenAudited = true;
            } else if (auditedAirlines.includes(curr.airline)) {
              hasBeenAudited = true;
            }

            if (hasBeenAudited) {
              prev.audited.push(curr);
            } else {
              prev.unaudited.push(curr);
            }

            return prev;
          }, { audited: [], unaudited: []});

        // Calculate totals
        const totalImmediateSavings = audited.reduce(
          (acc, airline) => acc + (airline.missedSavingsImmediate || 0), 0
        );
        const totalFutureSavings = audited.reduce(
          (acc, airline) => acc + (airline.missedSavingsFuture || 0), 0
        );
        const totalSavingsAvailable = totalImmediateSavings + totalFutureSavings;

        const auditedFlightSpend = audited.reduce((prev, curr) => prev + curr.totalSpend, 0);
        const unauditedFlightSpend = unaudited.reduce((prev, curr) => prev + curr.totalSpend, 0);

        setTotalImmediateSavings(totalImmediateSavings);
        setTotalFutureSavings(totalFutureSavings);
        setTotalSavingsAvailable(totalSavingsAvailable);
        setAuditedFlightSpend(auditedFlightSpend / (auditedFlightSpend + unauditedFlightSpend));

      } catch (error) {
        console.error('Error fetching airline discounts evaluation data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAirlinesData();
  }, [authAxios, tokenSet, auditedAirlines]);

  const handleRowClicked = async (row) => {
    setSelectedAirline(row.airline);
    setRouteLoading(true);
    setRouteError(false);
    setModalShow(true);

    try {
      const response = await authAxios.get(`/api/audit/airline-discounts-evaluation`);
      const routeData = response.data.per_route_per_group[row.airline];

      // Transform the data to array format for DataTable
      const routeArray = Object.entries(routeData).map(([key, value]) => ({
        route: key,
        ...value,
      }));

      setRouteData(routeArray);
    } catch (error) {
      console.error(`Error fetching data for ${row.airline}:`, error);
      setRouteError(true);
    } finally {
      setRouteLoading(false);
    }
  };

  const formatCurrency = (amount) => {
    if (!amount) return '-';
    return amount >= 1000000 ? `$${(amount / 1000000).toFixed(1)}M` :
           amount >= 1000 ? `$${(amount / 1000).toFixed(1)}K` : 
           `$${amount.toFixed(2)}`;
  };

  const columns = [
    {
      name: 'Airline',
      selector: row => row.airline,
      sortable: true,
    },
    {
      name: 'Total Spend',
      cell: row => (
        <div className="display-cell">
          {formatCurrency(row.totalSpend)}
        </div>
      ),
      sortable: true,
      sortFunction: (a, b) => a.totalSpend - b.totalSpend,
    },
    {
      name: 'Average Discount',
      cell: row => `${(row.discountPercent * 100).toFixed(2)}%`,
      sortable: true,
      sortFunction: (a, b) => a.discountPercent - b.discountPercent,
    },
    {
      name: 'Max Immediate Savings',
      cell: row => (
        <div className="display-cell">
          {formatCurrency(row.missedSavingsImmediate)}
        </div>
      ),
      sortable: true,
      sortFunction: (a, b) => a.missedSavingsImmediate - b.missedSavingsImmediate,
    },
    {
      name: 'Max Future Savings',
      cell: row => (
        <div className="display-cell">
          {formatCurrency(row.missedSavingsFuture)}
        </div>
      ),
      sortable: true,
      sortFunction: (a, b) => a.missedSavingsFuture - b.missedSavingsFuture,
    },
    {
      name: '',
      cell: row => (
        <div className="view-button-wrapper" onClick={() => handleRowClicked(row)}>
          <div className="view-button">
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      ),
    }
  ];

  const routeColumns = [
    {
      name: 'Route',
      selector: row => row.route,
      sortable: true,
    },
    {
      name: 'Total Spend',
      cell: row => (
        <div className="display-cell">
          {formatCurrency(row.totalSpend)}
        </div>
      ),
      sortable: true,
      sortFunction: (a, b) => a.totalSpend - b.totalSpend,
    },
    {
      name: 'Average Discount',
      cell: row => `${(row.discountPercent * 100).toFixed(2)}%`,
      sortable: true,
      sortFunction: (a, b) => a.discountPercent - b.discountPercent,
    },
    {
      name: 'Missed Savings',
      cell: row => (
        <div className="display-cell">
          {formatCurrency(row.missedOnRoute)}
        </div>
      ),
      sortable: true,
      sortFunction: (a, b) => a.missedOnRoute - b.missedOnRoute,
    },
  ];

  return (
    <div className="audit-wrapper hotel-audit-wrapper">
      <h1><PlaneIcon /> Flights</h1>
      <div className="top-bubbles">
        <div className="bubble audit">
          <p className="title">Total Savings Available</p>
          <p className="value">
            <CountUp
              start={0}
              end={totalSavingsAvailable}
              duration={2}
              separator=","
              decimals={2}
              prefix="$"
            />
          </p>
        </div>
        <div className="bubble audit">
          <p className="title">Total Immediate</p>
          <p className="value">
            <CountUp
              start={0}
              end={totalImmediateSavings}
              duration={2}
              separator=","
              decimals={2}
              prefix="$"
            />
          </p>
        </div>
        <div className="bubble audit">
          <p className="title">Total Future</p>
          <p className="value">
            <CountUp
              start={0}
              end={totalFutureSavings}
              duration={2}
              separator=","
              decimals={2}
              prefix="$"
            />
          </p>
        </div>
        <div className="bubble audit">
          <p className="title">Audited %</p>
          <p className="value">
            <CountUp
              start={0}
              end={auditedFlightSpend * 100}
              duration={2}
              separator=","
              decimals={2}
              suffix="%"
            />
          </p>
        </div>
      </div>
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={airlines}
          highlightOnHover
          pagination={airlines.length > 10}        
          conditionalRowStyles={[
            {
              when: (row) => {
                return auditedAirlines !== 'all' && auditedAirlines.indexOf(row.airline) === -1;
              },
              style: {
                opacity: 0.2,
              }
            }
          ]}
        />
      )}
      {/* Modal for Route Data */}
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedAirline} Route Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {routeLoading ? (
            <div className="spinner-container">
              <Spinner animation="border" />
            </div>
          ) : routeError ? (
            <div className="error-container">
              Err
            </div>
          ) : (
            <DataTable
              columns={routeColumns}
              data={routeData}
              highlightOnHover
              pagination={routeData.length > 10}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FlightsAudit;