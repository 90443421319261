import "./ImpalaLogo.css";

import React from "react";

import { ReactComponent as ImpalaLogoPrimaryDark } from "./full-logo-colour-dark.svg";
import { ReactComponent as ImpalaLogoPrimaryLight } from "./full-logo-colour-light.svg";
import { ReactComponent as ImpalaLogoMonoDark } from "./full-logo-mono-dark.svg";
import { ReactComponent as ImpalaLogoMonoLight } from "./full-logo-mono-light.svg";
import { ReactComponent as ImpalaMarqueMonoDark } from "./icon-dark.svg";

type LogoProps = React.SVGProps<SVGSVGElement> & {
  className?: "string";
  variant?: "primaryDark" | "primaryLight" | "monoDark" | "monoLight";
};

const LogoWordmark = ({ variant = "monoDark" }: LogoProps): JSX.Element => {
  if (variant === "primaryDark") {
    return <ImpalaLogoPrimaryDark className={`ImpalaLogo ${variant}`} />;
  }
  if (variant === "primaryLight") {
    return <ImpalaLogoPrimaryLight className={`ImpalaLogo ${variant}`} />;
  }

  if (variant === "monoLight") {
    return <ImpalaLogoMonoLight className={`ImpalaLogo ${variant}`} />;
  }

  return <ImpalaLogoMonoDark className={`ImpalaLogo ${variant}`} />;
};

const Logo = (): JSX.Element => {
  return <ImpalaMarqueMonoDark className="ImpalaLogo marque" />;
};

export { LogoWordmark, Logo };
