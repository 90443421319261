import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ImpalaHeader from "../../components/ImpalaHeader/ImpalaHeader";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";
import { Bar } from 'react-chartjs-2';
import { format, startOfISOWeek, addWeeks } from 'date-fns';
import DataTable from 'react-data-table-component';
import { Modal, Button } from 'react-bootstrap';

import { ReactComponent as CompareIllustrationImage } from '../../img/compare-illustration.svg';

import 'react-tooltip/dist/react-tooltip.css';
import 'chart.js/auto';
import './reporting.css';

const AddedIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 4.375C10.3452 4.375 10.625 4.65482 10.625 5V9.375H15C15.3452 9.375 15.625 9.65482 15.625 10C15.625 10.3452 15.3452 10.625 15 10.625H10.625V15C10.625 15.3452 10.3452 15.625 10 15.625C9.65482 15.625 9.375 15.3452 9.375 15V10.625H5C4.65482 10.625 4.375 10.3452 4.375 10C4.375 9.65482 4.65482 9.375 5 9.375H9.375V5C9.375 4.65482 9.65482 4.375 10 4.375Z" fill="#141414"/>
  </svg>
`;

const DeductedIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M15 10.625C15.3452 10.625 15.625 10.3452 15.625 10C15.625 9.65482 15.3452 9.375 15 9.375H5C4.65482 9.375 4.375 9.65482 4.375 10C4.375 10.3452 4.65482 10.625 5 10.625H15Z" fill="#141414"/>
</svg>
`;

const ReportingHotelDeal = ({ userAuth, activeServices }) => {
  const { hotelDealId } = useParams();
  const [authAxios, tokenSet] = useAuthenticatedAxios(true);
  const [hotelDealData, setHotelDealData] = useState(null);
  const [cumulativeSpendData, setCumulativeSpendData] = useState(null);
  const [contractPerformance, setContractPerformance] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTotalShow, setModalTotalShow] = useState(0);

  const handleShowModal = (content, total) => {
    setModalContent(content);
    setModalTotalShow(total);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Fetch hotel deal data
  useEffect(() => {
    if (tokenSet) {
      setLoading(true);
      const fetchHotelDealData = authAxios.get(`/api/reporting/hotel-deal/${hotelDealId}`);
      const fetchCumulativeSpendData = authAxios.get(`/api/reporting/week-spend/${hotelDealId}`);
      const fetchContractPerformance = authAxios.get(`/api/reporting/contract-performance/${hotelDealId}`);
      const fetchCustomerData = authAxios.get(`/api/reporting/customer-data/${hotelDealId}`);
  
      Promise.all([
        fetchHotelDealData,
        fetchCumulativeSpendData,
        fetchContractPerformance,
        fetchCustomerData
      ])
      .then(([hotelDealResponse, cumulativeSpendResponse, contractPerformanceResponse, customerDataResponse]) => {
        setHotelDealData(hotelDealResponse.data);
        setCumulativeSpendData(cumulativeSpendResponse.data.totalSpend);
        setContractPerformance(contractPerformanceResponse.data);
        setCustomerData(customerDataResponse.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [authAxios, hotelDealId, tokenSet]);
  

  const formatCurrency = (amount, dp = 0) => {
    if (!amount) return '$0.00';
    return `$${amount.toFixed(dp).replace(/\d(?=(\d{3})+\b)/g, '$&,')}`;
  }

  const transformToCumulative = (data) => {
    let cumulativeTotal = 0;
    return data.map(item => {
      cumulativeTotal += item.total_spend;
      return { ...item, cumulative_spend: cumulativeTotal };
    });
  }

  const calculateTotalHaveSpent = (data) => {
    return data.reduce((total, item) => total + item.total_spend, 0);
  }

  const transformedCumulativeData = cumulativeSpendData ? transformToCumulative(cumulativeSpendData) : [];
  const totalSpendWithImpala = cumulativeSpendData ? calculateTotalHaveSpent(cumulativeSpendData) : 0;

  const chartData = {
    labels: transformedCumulativeData.map(item => format(addWeeks(startOfISOWeek(new Date(item.week_start)), 0), 'MMM d, yyyy')),
    datasets: [
      {
        label: 'Cumulative Spend',
        data: transformedCumulativeData.map(item => item.cumulative_spend),
        backgroundColor: 'rgba(40, 187, 199, 1)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
          },
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: 'rgba(20, 20, 20, 0.2)',
          borderWidth: 2,
        },
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
          },
          callback: function (value) {
            if (value >= 1000000) {
              return `$${(value / 1000000).toFixed(1)}M`;
            } else if (value >= 1000) {
              return `$${(value / 1000).toFixed(0)}K`;
            } else {
              return `$${value}`;
            }
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      }
    },
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'EEE, dd MMM yyyy');
  }

  const calculateSavings = (benchmark, totalSpend) => {
    if (!benchmark || !totalSpend) return '0%';
    const savedPercentage = ((benchmark - totalSpend) / benchmark) * 100;
    return `${savedPercentage.toFixed(2)}%`;
  }

  const columns = [
    {
      name: 'Checked In',
      selector: row => formatDate(row.start_date),
      sortable: true,
    },
    {
      name: 'Checked Out',
      selector: row => formatDate(row.end_date),
      sortable: true,
    },
    {
      name: 'Booked On',
      selector: row => formatDate(row.booking_date),
      sortable: true,
    },
    {
      name: 'Would Have Spent',
      selector: row => {
        if (row.rates?.length) {
          const total = row.rates ? row.rates.reduce((prev, curr) => prev + parseFloat(curr.rate), 0) : 0;
          return (
            <div className="clicker" onClick={() => handleShowModal(
              `<p>Cheapest price across these dates when booked on ${formatDate(row.booking_date)}:
                <div class="price-explanation">
                <p>Price Found: ${formatCurrency(total, 2)}</p></p>
              ${(!!row.modifiers_to_adr === true && (row.modifiers_to_adr.length > 0) === true) ? row.modifiers_to_adr.map((modifier, index) => (
                `<p key=${index} ${modifier.amount < 0 ? `class="positive"` : `class="negative"`}>${modifier.amount < 0 ? DeductedIcon : AddedIcon} ${modifier.amount * 100}% ${modifier.reason}</p>`
              )).join('') : ''}
                </div>
              `,
              row.benchmark
            )}>
              {formatCurrency(row.benchmark)}
            </div>
          );
        } else {
          return 'N/A';
        }
      },
      sortable: false,
    },
    {
      name: 'Spent',
      selector: row => formatCurrency(row.total_cost),
      sortable: true,
    },
    {
      name: 'Saving',
      selector: row => calculateSavings(row.benchmark, row.total_cost),
      sortable: true,
    },
  ];

  return (
    <div>
      <ImpalaHeader userAuth={userAuth} activeServices={activeServices} />
      <div className="reporting-wrapper">
        <div className="reporting-page">
          <div className="breadcrumbs">
            <Link to="/srv/dashboard"><span>My Dashboard</span></Link>
            <Link to="/srv/reporting"><span>Reporting</span></Link>
            <Link to={`/srv/reporting/hotel-deal/${hotelDealId}`}>{hotelDealData ? hotelDealData.hotel_name : 'Hotel Reporting'}</Link>
          </div>
          <h1>{hotelDealData ? hotelDealData.hotel_name : 'Hotel Reporting'}</h1>
          {!!hotelDealData?.location_name && (<small>{hotelDealData?.location_name}</small>)}
          {!loading && (
            <div className="rate-details-area">
              <h3>Rate Details</h3>
              <div className="hotel-contracts-area">
                {hotelDealData?.offers.map((offer, index) => (
                  <div className="hotel-contract" key={index}>
                    <div className="info-section">
                      <label>Room Type</label>
                      <div>{offer.room_type}</div>
                    </div>
                    <div className="info-section">
                      <label>Cancellation</label>
                      <div>{offer.flexibility}</div>
                    </div>
                    <div className="info-section">
                      <label>Meal Plan</label>
                      <div>{offer.meal_plan}</div>
                    </div>
                    <div className="info-section">
                      <label>Rate</label>
                      <div>
                        {offer.offer_type === 'Static'
                          ? `${offer.static_currency} ${offer.static_rate}`
                          : `${offer.dynamic_percentage}%`}
                      </div>
                    </div>
                    {offer.season_start_date && (
                      <div className="info-section">
                        <label>Start Date</label>
                        <div>{formatDate(offer.season_start_date)}</div>
                      </div>
                    )}
                    {offer.season_end_date && (
                      <div className="info-section">
                        <label>End Date</label>
                        <div>{formatDate(offer.season_end_date)}</div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {loading ? (
            <div className="loading-reporting-data">
              <img className="loading-image" src="/loading-data.png" alt="Loading Reporting Data" />
              <h4>Loading data...</h4>
            </div>
          ) : (
            <>
              <div className="top-bubbles reporting-hotel-deal">
                {hotelDealData && contractPerformance && (
                  <>
                    <div className="bubble">
                      <p>Would Have Spent</p>
                      <p className="value">{formatCurrency(contractPerformance.benchmark)}</p>
                    </div>
                    <div className="bubble with-impala">
                      <p>Spend with Impala</p>
                      <p className="value">{formatCurrency(totalSpendWithImpala)}</p>
                    </div>
                    <div className="bubble audit">
                      <p>Total Savings</p>
                      <p className="value">{formatCurrency(contractPerformance.saving)}</p>
                    </div>
                  </>
                )}
              </div>
              {cumulativeSpendData && (
                <div className="reporting-area">
                  <h3>Contract cumulative spend</h3>
                  <div className="display-chart">
                    <Bar data={chartData} options={chartOptions} />
                  </div>
                </div>
              )}
              {customerData.length > 0 && (
                <div className="customer-data-table">
                  <h3>All Contract Stays</h3>
                  <DataTable
                    columns={columns}
                    data={customerData}
                    pagination
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>What you would have spent</Modal.Title>
        </Modal.Header>
        <Modal.Body className="reporting-modal">
          <div className="banner">
            <small>We've calculated you would've spent</small>
            <h3>{formatCurrency(modalTotalShow)} total</h3>
          </div>
          <div className="modal-main-body">
            <CompareIllustrationImage />
            <div className="question-area">
              <h6>How do we work this out?</h6>
              <p>Every quarter we sample hundreds of prices to see how different the price your TMC charges would have been vs. public pricing.</p>
            </div>
            <div className="question-area">
              <h6>We calculated this rate from</h6>
              <div dangerouslySetInnerHTML={{ __html: modalContent }} />
            </div>
            <div className="question-area">
              <h6>Audit trail</h6>
              <a href="https://docs.google.com/spreadsheets/d/1y1cjxAcR1-gjUuNCCHIqZctTiX6tESaxqudgxHsIeGY/edit?usp=sharing" target="_blank" rel="noopener noreferrer">View the data we took when comparing price data</a>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReportingHotelDeal;
