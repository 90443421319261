import React, { useEffect, useState } from "react";

import { Event, EventHotel } from "./EventStatus";

import "./Events.css";
import "./attendeeForm.css";
import { format } from "date-fns";

import { ReactComponent as InformationIcon } from "../img/information-circle.svg";
import { ReactComponent as CheckOutIcon } from "../images/icons/check-out.svg";

type Stay = {
  hotel: EventHotel | undefined;
  checkIn: Date;
  checkOut: Date;
  sharingPartner: string | undefined;
  roomType: string | undefined;
  reference: string | undefined;
};

const EventAttendeeHotel = (props: { event: Event }) => {
  const [stays, setStays] = useState<Stay[]>([]);

  useEffect(() => {
    const stayDates = props.event.dates.filter(
      (d) =>
        props.event.attendees[0].dates.find((ad) => ad.date === d.id)
          ?.hotel_required
    );

    let previousDate = new Date(0);
    let lastHotel: EventHotel | undefined;
    let stays = 0;

    const newHotels = [];
    const newCheckIns: Date[] = [];
    const newCheckOuts: Date[] = [];
    const newSharingPartners: (string | undefined)[] = [];
    const newReferences: (string | undefined)[] = [];
    const newRoomTypes: (string | undefined)[] = [];
    let sharingPartner;
    let reference;

    for (const stayDate of stayDates) {
      const currentHotel = props.event.attendees[0].dates.find(
        (d) => d.date === stayDate.id
      )?.assigned_hotel;

      const roomType = props.event.attendees[0].dates.find(
        (d) => d.date === stayDate.id
      )?.room_setup;

      if (
        stayDate.date.getDate() > previousDate.getDate() + 1 ||
        !lastHotel ||
        lastHotel.id !== currentHotel
      ) {
        // Set Hotel
        newHotels.push(props.event.hotels.find((h) => h.id === currentHotel));
        lastHotel = props.event.hotels.find((h) => h.id === currentHotel);

        // Set Check-in
        newCheckIns.push(stayDate.date);

        // Set RoomType
        newRoomTypes.push(roomType);

        if (stays > 0) {
          // Set Check-Out
          let checkOutDate = new Date(previousDate);
          checkOutDate.setDate(previousDate.getDate() + 1);
          newCheckOuts.push(checkOutDate);

          // Set Sharing Partner
          newSharingPartners.push(sharingPartner);
          sharingPartner = undefined;

          // Set Reference
          newReferences.push(reference);
          reference = undefined;
        }
        stays += 1;
      }

      reference =
        reference ||
        props.event.attendees[0].dates.find((d) => d.date === stayDate.id)
          ?.reference;

      sharingPartner =
        sharingPartner ||
        props.event.attendees[0].dates.find((d) => d.date === stayDate.id)
          ?.sharing_room_with;

      previousDate = stayDate.date;
    }

    let checkOutDate = new Date(previousDate);
    checkOutDate.setDate(previousDate.getDate() + 1);
    newCheckOuts.push(checkOutDate);
    newSharingPartners.push(sharingPartner);
    newReferences.push(reference);

    const newStays: Stay[] = [];
    newHotels.forEach((h, i) => {
      newStays.push({
        hotel: h,
        checkIn: newCheckIns[i],
        checkOut: newCheckOuts[i],
        sharingPartner: newSharingPartners[i],
        roomType: newRoomTypes[i],
        reference: newReferences[i],
      });
    });

    setStays(newStays);
  }, [props.event]);

  return (
    <section className="stay-details">
      <>
        <h4>Stay Details</h4>

        <section className="hotel-information">
          {stays
            .filter((stay) => stay.hotel && stay.roomType)
            .map((stay, index) => (
              <>
                <div className="hotel-information-content">
                  <div className="hotel-information-header">
                    <p>You're staying at</p>
                    <h2>{stay.hotel?.name}</h2>
                    <p>
                      {stay.hotel?.address}
                      <br />
                      <a href={stay.hotel?.maps_url}>Show on a map</a>
                    </p>
                    <img src={stay.hotel?.image_url} role="presentation" />
                  </div>
                  <div className="hotel-information-meta">
                    <div className="hotel-information-meta-block">
                      <h5>Check-in & Check-out</h5>
                      <p>
                        Your check-in date is
                        <br />
                        <b>{format(stay.checkIn, "eeee, do MMMM yyyy")}</b>
                        <small> from {stay.hotel?.check_in_time}</small>
                      </p>{" "}
                      <p>
                        Your check-out date is
                        <br />
                        <b>{format(stay.checkOut, "eeee, do MMMM yyyy")}</b>
                        <small> by {stay.hotel?.check_out_time}</small>
                      </p>
                    </div>
                    <div className="hotel-information-meta-block">
                      {stay.reference && (
                        <>
                          <h5>Booking Confirmation</h5>
                          <p>{stay.reference}</p>
                        </>
                      )}
                      <h5>Requests and Notes</h5>
                      <p>Your room type is {stay.roomType}</p>
                      <p>{props.event.attendees[0].room_preferences}</p>
                      {stay.sharingPartner && (
                        <p>You are sharing a room with {stay.sharingPartner}</p>
                      )}
                      {props.event.attendees[0].plus_one && (
                        <p>
                          You are bringing one extra guest.
                          {props.event.details.plus_ones_covered
                            ? ""
                            : " You will be asked to pay the additional cost for this at the hotel."}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {index < stays.length - 1 && stays.length > 1 && (
                  <div className="additional-stay-information">
                    <CheckOutIcon />
                    <span>
                      <strong>There is a gap between your hotel stays. </strong>
                      You'll need to check out on the date shown above. Please
                      note that your hotel or room allocation may be different.
                    </span>
                  </div>
                )}
              </>
            ))}

          <div className="hotel-information-contact">
            <InformationIcon />
            Need help with your stay?{" "}
            <a href="mailto:support@impala.travel">Contact us</a>
          </div>
        </section>
      </>
    </section>
  );
};

export default EventAttendeeHotel;
