import React, { useState, useEffect } from "react";
import { ReactComponent as SpinnerIcon } from "../../img/spinner.svg";
import { useParams, useLocation } from "react-router-dom";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

import HotelProgramMeta from "../../components/HotelProgramMeta";
import { usePostHog } from "posthog-js/react";

const BookOut = () => {
  const [customerData, setCustomerData] = useState(null);
  const [authenticatedAxios, tokenSet] = useAuthenticatedAxios(true);
  const location = useLocation();

  const { hotelDealId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const checkInDateStr = queryParams.get("checkIn");
      const checkOutDateStr = queryParams.get("checkOut");

      const response = await authenticatedAxios.post(
        `/api/users/generate-direct-booking-link/${hotelDealId}`,
        {
          CHECK_IN: checkInDateStr,
          CHECK_OUT: checkOutDateStr,
        }
      );

      if (response.data.url) {
        window.location = response.data.url;
      } else {
        console.log("An Error Occured. Please try again later.");
      }
    };

    fetchData();
  }, [authenticatedAxios, hotelDealId, tokenSet]);

  const posthog = usePostHog();
  posthog.capture("Service Visit", {
    service: "Frequent Traveller",
  });

  return (
    <div className="hotel-program-display">
      <HotelProgramMeta
        setData={setCustomerData}
        setIsLoading={() => {}}
        setError={() => {}}
        data={customerData}
      />
      <div className="book-out-wrapper">
        <div className="center-area">
          <h4>Redirecting you to the hotels website</h4>
          <p>They'll take it from here.</p>
          <SpinnerIcon />
        </div>
      </div>
    </div>
  );
};

export default BookOut;
