import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import useAuthenticatedAxios from '../../lib/useAuthenticatedAxios';

const CNRModal = ({ show, onHide, hotelName, hasCNR, refreshData, city }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [lowPrice, setLowPrice] = useState('');
  const [highPrice, setHighPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [authAxios] = useAuthenticatedAxios();

  const handleRecordCNR = async () => {
    setLoading(true);
    try {
      await authAxios.post('/api/audit/record-cnr', {
        hotel_name: hotelName,
        city,
        start_date: startDate,
        end_date: endDate,
        bottom_of_range: lowPrice,
        top_of_range: highPrice,
      });
      refreshData(hotelName);
      onHide();
    } catch (err) {
      console.error('Error recording CNR:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Set Existing CNR?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Would you like to record an existing CNR for this hotel? Leave all fields blank to cover all stays with CNR.</p>
        <Form className="cnr-modal-form">
          <Form.Group controlId="startDate">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="endDate">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="lowPrice">
            <Form.Label>Lowest Price</Form.Label>
            <Form.Control
              type="number"
              value={lowPrice}
              onChange={(e) => setLowPrice(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="highPrice">
            <Form.Label>Highest Price</Form.Label>
            <Form.Control
              type="number"
              value={highPrice}
              onChange={(e) => setHighPrice(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
        <Button
          variant="primary"
          onClick={handleRecordCNR}
          disabled={loading}
        >
          {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Record CNR'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CNRModal;