import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { Button } from "react-bootstrap";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

import { ReactComponent as ArrowLeftIcon } from "../../img/arrow-left.svg";
import { ReactComponent as Spinner } from "../../img/spinner.svg";

import { useAuth0 } from "@auth0/auth0-react";

const Invites = () => {
  const location = useLocation();
  const serviceQuery = new URLSearchParams(location.search).get("role");

  const [authenticatedAxios, tokenSet] = useAuthenticatedAxios(true);
  const [data, setData] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [name, setName] = useState("");
  const [selectedServices, setSelectedServices] = useState(
    serviceQuery ? [decodeURIComponent(serviceQuery)] : []
  );
  const [companyUsers, setCompanyUsers] = useState([]);

  const [isInvitingUser, setIsInvitingUser] = useState(false);
  const [isRevokingUser, setIsRevokingUser] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const userAuth = useAuth0();

  const showAddServiceDialog = () =>
    alert("To add a service, simply invite the user again");

  const inviteUser = async () => {
    if (!emailAddress || !selectedServices.length) {
      return alert("Please ensure all fields are filled in");
    }

    try {
      setIsInvitingUser(true);
      await authenticatedAxios.post("/api/users/invite-users", {
        name: name,
        userEmail: emailAddress,
        roles: selectedServices,
      });
      setSelectedServices([]);
      setEmailAddress("");
      setName("");
    } catch (e) {
      console.error("Error inviting user", e);
      alert("Error inviting user, please try again later");
    } finally {
      setIsInvitingUser(false);
    }
  };

  const revokeUserRoles = async (userId) => {
    const c = window.confirm(
      "Are you sure you wish to remove access to all services for this user?"
    );
    if (!c) return;

    try {
      setIsRevokingUser(true);
      await authenticatedAxios.delete("/api/users/remove-user-roles", {
        data: { userId },
      });
    } catch (e) {
      console.error("Error revoking user roles");
    } finally {
      setIsRevokingUser(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authenticatedAxios.get(
          "/api/users/active-services"
        );
        setData(response?.data?.services);
      } catch {
        console.error("Error retrieving active services");
      }
    };
    fetchData();
  }, [tokenSet]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingUsers(true);
        const response = await authenticatedAxios.get(
          "/api/users/company-users"
        );
        setCompanyUsers(response?.data?.users);
      } catch {
        console.error("Error retrieving users");
      } finally {
        setIsLoadingUsers(false);
      }
    };
    fetchData();
  }, [tokenSet, isInvitingUser, isRevokingUser]);

  const rolesDict = {
    "Company Admin": "Company admin",
    "Event Editor": "Events",
    "Direct Booking User": "Book direct",
    "External Booking Editor": "External bookings",
  };

  const services = [
    {
      name: "Admin",
      role: "Company Admin",
      enabled: true,
    },
    {
      name: "Events",
      role: "Event Editor",
      enabled: data?.events_service,
    },
    {
      name: "Frequent traveller",
      role: "Direct Booking User",
      enabled: data?.direct_booking_service,
    },
    {
      name: "Onboarding & Interviews",
      role: "External Booking Editor",
      enabled: data?.complex_bookings_service,
    },
  ];

  const handleServiceCheckChange = (serviceName, isChecked) => {
    if (isChecked) {
      setSelectedServices((prevServices) => [...prevServices, serviceName]);
    } else {
      setSelectedServices((prevServices) =>
        prevServices.filter((service) => service !== serviceName)
      );
    }
  };

  return (
    <div className="invites-wrapper">
      <div className="invites-inner-wrapper">
        <div className="back-to-previous">
          <Link to="/">
            <ArrowLeftIcon /> Back to your services
          </Link>
        </div>
        <h1>Manage Users</h1>
        <div className="add-invites-area">
          <section className="invite-user-form">
            <div className="form-line">
              <h2>Invite New User</h2>
              <div className="set">
                <label>Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label>Email Address</label>
                <input
                  type="text"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </div>

              <div className="set services-set">
                <label>Services</label>
                <div className="services-wrapper">
                  {services.map((service, index) =>
                    service.enabled ? (
                      <div key={index}>
                        <input
                          type="checkbox"
                          checked={selectedServices.includes(service.role)}
                          id={index}
                          onChange={(e) =>
                            handleServiceCheckChange(
                              service.role,
                              e.target.checked
                            )
                          }
                        />
                        <label for={index}>{service.name}</label>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
              <div className="button-holder">
                {!isInvitingUser && (
                  <Button
                    variant="primary"
                    disabled={!name || !emailAddress}
                    onClick={() => inviteUser()}
                  >
                    Send Invite
                  </Button>
                )}
                {isInvitingUser && (
                  <Button variant="primary" className="with-spinner">
                    <Spinner />
                  </Button>
                )}
              </div>
            </div>
            <div className="existing-users">
              <h2>Existing Users</h2>
              <table>
                <thead>
                  <tr>
                    <th>Email address</th>
                    <th>Has accepted invite?</th>
                    <th>Services available</th>
                    <th></th>
                    <th className="mobile-only">User</th>
                  </tr>
                </thead>
                <tbody>
                  {(isLoadingUsers || isRevokingUser) && (
                    <tr>
                      <td className="loading-table" colSpan="4">
                        <Spinner />
                      </td>
                    </tr>
                  )}
                  {!isLoadingUsers &&
                    !isRevokingUser &&
                    companyUsers
                      .filter((user) => !user.email.endsWith("impala.travel"))
                      .map((user, index) => (
                        <tr key={index}>
                          <td>{user.email}</td>
                          <td>{user.has_ever_logged_in ? "Yes" : "No"}</td>
                          <td>
                            {user.roles
                              .map((x) => rolesDict[x] || x)
                              .join(", ")}
                          </td>
                          <td className="button-cell">
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={showAddServiceDialog}
                            >
                              Add Service
                            </Button>
                            {user.email !== userAuth?.user?.email &&
                              user.roles.length > 0 && (
                                <Button
                                  variant="secondary"
                                  onClick={() => revokeUserRoles(user.id)}
                                  className="secondary-danger"
                                  size="sm"
                                >
                                  Revoke Access
                                </Button>
                              )}
                          </td>
                          <td className="mobile-only">
                            <span className="email">{user.email}</span>
                            <div className="mobile-services">
                              {user.roles
                                .map((x) => rolesDict[x] || x)
                                .join(", ")}
                            </div>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Invites;
