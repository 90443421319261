import React, { useState } from "react";
import Modal from "react-modal";
import { Event, EventAttendee } from "./EventStatus";
import { ReactComponent as UserRemoveIcon } from "../img/user-remove.svg";
import { Button, Form } from "react-bootstrap";
import axios from "axios";

import "./Events.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "640px",
  },
};

function AttendeeDeleteModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: Event;
  eventAttendee: EventAttendee | undefined;
  onUpdate: Function;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [confirmText, setConfirmText] = useState("");
  // Accessibility enhancement: https://reactcommunity.org/react-modal/accessibility/
  Modal.setAppElement("#root");

  function closeModal() {
    setConfirmText("");
    props.setIsOpen(false);
  }

  const handleDelete = async () => {
    try {
      await axios.delete(
        `/api/users/events/1634${props.event.details.id}/attendees/${props.eventAttendee?.id}`
      );
      props.onUpdate();
      props.setSubPage("");
      closeModal();
    } catch (error) {
      console.error("Error deleting attendee:", error);
      alert("We could not delete the attendee, please try again");
    }
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Delete Attendee"
    >
      <div className="event-management-modal">
        <h4>{props.eventAttendee?.name || props.eventAttendee?.email}</h4>
        <div className="event-attendee-delete-modal-title">
          <h4>
            <UserRemoveIcon />
            Delete this attendee
          </h4>
          <p>
            You can't undo this action. This attendee will need to be re-invited
            and enter their preferences again.
          </p>
        </div>
      </div>
      <Form.Label for="confirmText">Type 'DELETE' into the field</Form.Label>
      <Form.Control
        type="text"
        id="confirmText"
        value={confirmText}
        onChange={(e) => setConfirmText(e.target.value)}
      />
      <footer className="modalButtonFooter">
        <Button className="btn-secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          className="btn-secondary danger-button"
          onClick={handleDelete}
          disabled={confirmText !== "DELETE"}
        >
          Delete Attendee
        </Button>
      </footer>
    </Modal>
  );
}

export default AttendeeDeleteModal;
