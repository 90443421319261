import React, { useState, useEffect } from "react";

import DatePicker from "react-datepicker";

import { Event } from "./EventStatus";

import { Form } from "react-bootstrap";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";

const EventAttendeeSettings = (props: { event: Event; onUpdate: Function }) => {
  const [axios] = useAuthenticatedAxios(true);

  const [submitting, setSubmitting] = useState(false);
  const [plusOnesAllowed, setPlusOnesAllowed] = useState(true);
  const [plusOnesCovered, setPlusOnesCovered] = useState(false);
  const [softDeadline, setSoftDeadline] = useState<Date | undefined>();
  const [hardDeadline, setHardDeadline] = useState<Date | undefined>();
  const [reminderFrequency, setReminderFrequency] = useState<
    number | undefined
  >();

  useEffect(() => {
    setSoftDeadline(
      props.event.details.attendee_deadline
        ? new Date(props.event.details.attendee_deadline)
        : undefined
    );
    setHardDeadline(
      props.event.details.cancellation_deadline
        ? new Date(props.event.details.cancellation_deadline)
        : undefined
    );
    setPlusOnesAllowed(
      props.event.details.plus_ones_covered === true ||
        props.event.details.plus_ones_covered === false
    );
    setPlusOnesCovered(Boolean(props.event.details.plus_ones_covered));
    setReminderFrequency(props.event.details.attendee_reminder_frequency);
  }, [props.event.details]);

  const handleSoftDeadlineChange = async (date: Date | undefined) => {
    let sd = date;
    let hd = hardDeadline;
    if (date && hardDeadline && date > hardDeadline) hd = date;
    if (!date) hd = date;

    if (sd) sd.setHours(23, 59, 0);
    if (hd) hd.setHours(23, 59, 0);

    try {
      setSubmitting(true);
      await axios.patch(
        `/api/users/events/1634${props.event.details.id}/deadlines`,
        { attendee_deadline: date, cancellation_deadline: hd }
      );
      props.onUpdate();
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  };

  const handleHardDeadlineChange = async (date: Date | undefined) => {
    let sd = softDeadline;
    let hd = date;

    if (sd) sd.setHours(23, 59, 0);
    if (hd) hd.setHours(23, 59, 0);

    try {
      setSubmitting(true);
      await axios.patch(
        `/api/users/events/1634${props.event.details.id}/deadlines`,
        { attendee_deadline: softDeadline, cancellation_deadline: date }
      );
      props.onUpdate();
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  };

  const handleReminderFrequencyChange = async (
    frequency: number | undefined
  ) => {
    try {
      setSubmitting(true);
      await axios.patch(
        `/api/users/events/1634${props.event.details.id}/reminders`,
        { reminder_frequency: frequency }
      );
      props.onUpdate();
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  };

  const handlePlusOneAllowedChange = async (allowed: boolean) => {
    try {
      setSubmitting(true);
      await axios.patch(
        `/api/users/events/1634${props.event.details.id}/plus-ones`,
        { allowed: allowed, covered: plusOnesCovered }
      );
      props.onUpdate();
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  };

  const handlePlusOneCoveredChange = async (covered: boolean) => {
    try {
      setSubmitting(true);
      await axios.patch(
        `/api/users/events/1634${props.event.details.id}/plus-ones`,
        { covered: covered, allowed: plusOnesAllowed }
      );
      props.onUpdate();
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <section className="manage-attendee-options">
        <div className="manage-attendee-row">
          <div className="manage-attendee-row-description">
            <h4>Attendee reminder frequency</h4>
            <p>
              Set the frequency of automatic reminders for attendees to set
              their preferences. These will be sent until the attendee adds
              preferences, or until the soft deadline below has passed.
            </p>
          </div>
          <Form.Select
            disabled={
              submitting ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
            id="toggle-check"
            className="select-frequency"
            value={reminderFrequency || ""}
            onChange={(e) =>
              handleReminderFrequencyChange(
                e.target.value ? Number(e.target.value) : undefined
              )
            }
          >
            <option value="">No reminders</option>
            <option value="1">Every day</option>
            <option value="2">Every two days</option>
            <option value="4">Every four days</option>
            <option value="7">Every week</option>
            <option value="14">Every two weeks</option>
            <option value="28">Every four weeks</option>
          </Form.Select>
        </div>
        <div className="manage-attendee-row">
          <div className="manage-attendee-row-description">
            <h4>Soft response deadline</h4>

            <p>
              Display a deadline for attendees to add their preferences. A final
              reminder will be sent 7 days before this deadline to anyone who
              hasn't responded.{" "}
            </p>
            <p>
              We'll also remind attendees who have added preferences to check
              those are still valid.
            </p>
            <p>
              This is only a soft deadline, and attendees will still be able to
              respond after the date.
            </p>
          </div>
          <DatePicker
            disabled={
              submitting ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
            wrapperClassName="event-date-picker"
            isClearable
            placeholderText="No deadline set"
            selected={softDeadline}
            onChange={(date: Date) => handleSoftDeadlineChange(date)}
            locale="enGB"
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            maxDate={
              props.event.dates.length > 0
                ? new Date(props.event.dates[0].date)
                : undefined
            }
          />
        </div>
        <div className="manage-attendee-row" hidden={!softDeadline}>
          <div className="manage-attendee-row-description">
            <h4>Hard response deadline</h4>
            <p>
              Attendees will not be able to send any changes or add preferences
              after this date. We recommend setting this in line with
              cancellation deadlines from your event hotels.
            </p>
          </div>
          <DatePicker
            disabled={
              submitting ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
            wrapperClassName="event-date-picker"
            isClearable
            placeholderText="No deadline set"
            selected={hardDeadline}
            onChange={(date: Date) => handleHardDeadlineChange(date)}
            locale="enGB"
            dateFormat="dd/MM/yyyy"
            minDate={softDeadline}
            maxDate={
              props.event.dates.length > 0
                ? new Date(props.event.dates[0].date)
                : undefined
            }
          />
        </div>
        <div className="manage-attendee-row">
          <div className="manage-attendee-row-description">
            <h4>Attendees may bring an extra guest to the hotel</h4>
            <p>
              Allow attendees to optionally request a +1 for their hotel stays.
              If selected, they will be able to indicate this on their response
              form. This doesn't apply for attendees who are set to share a
              room.
            </p>
          </div>
          <Form.Check
            disabled={
              submitting ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
            id="toggle-check"
            type="checkbox"
            className="toggleCheckbox"
            checked={plusOnesAllowed}
            value="1"
            onChange={(e) => handlePlusOneAllowedChange(e.target.checked)}
          ></Form.Check>
        </div>
        <div className="manage-attendee-row" hidden={!plusOnesAllowed}>
          <div className="manage-attendee-row-description">
            <h4>Attendees pay for extra guests at the hotel</h4>
            <p>
              If selected your attendees will be required to cover the cost of
              bringing a +1. Deselect this option if you are paying these costs
              for your attendees.
            </p>
          </div>
          <Form.Check
            disabled={
              submitting ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
            id="toggle-check"
            type="checkbox"
            className="toggleCheckbox"
            checked={!plusOnesCovered}
            onChange={(e) => handlePlusOneCoveredChange(!e.target.checked)}
            value="1"
          ></Form.Check>
        </div>
      </section>
    </div>
  );
};

export default EventAttendeeSettings;
