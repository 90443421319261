import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../img/arrow-left.svg";
import { ReactComponent as EmptyRequests } from "../../img/services-events.svg";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

import { Loader } from "@aws-amplify/ui-react";
import Error from "../../components/Error";

import "../../components/Events.css";
import EventPlannerConfirmationModal from "../../components/EventPlannerConfirmationModal";
import ImpalaEventsHeader from "../../components/ImpalaHeader/ImpalaEventsHeader";
import useCompanyDetails from "../../lib/useCompanyDetails";

const EventPlanners = ({ userAuth, activeServices, user }) => {
  const [authAxios, tokenSet] = useAuthenticatedAxios(true);
  const { eventId } = useParams();
  const [events, setEvents] = useState(null);
  const [loadingEventData, setLoadingEventData] = useState(true);
  const [plannerOptions, setPlannerOptions] = useState(true);
  const [planners, setPlanners] = useState(true);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pendingValue, setPendingValue] = useState();
  const [refetchEvent, setRefetchEvent] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    console.log(user);
    const fetchData = async () => {
      setLoadingEventData(true);
      try {
        const roles = await authAxios.get("/api/users/roles");
        setIsAdmin(roles.data.includes("Company Admin"));
        const eventsResponse = await authAxios.get(`/api/events?id=${eventId}`);
        setEvents(eventsResponse.data.events);
        setPlanners(
          eventsResponse.data.events[0].organisers.map((u) => {
            return { label: u.name || u.email, value: u.id };
          })
        );
        const plannersResponse = await authAxios.get(
          `/api/users/event-planners`
        );
        setPlannerOptions(
          plannersResponse.data.users.map((u) => {
            return { label: u.name || u.email, value: u.id };
          })
        );
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingEventData(false);
      }
    };

    if (tokenSet) {
      fetchData();
    }
  }, [tokenSet, eventId, refetchEvent, user, authAxios]);

  const handleConfirm = async () => {
    await handleChange(pendingValue, true);
  };

  const handleChange = async (newValue, hasConfirmed) => {
    // Can't delete the last remaining planner
    if (newValue.length < 1) {
      setDisplayWarning(true);
      return;
    }

    // Warn if non-admin user is about to delete themselves
    if (
      !hasConfirmed &&
      !isAdmin &&
      !newValue.find((nv) => nv.value === user.sub)
    ) {
      setPendingValue(newValue);
      setModalIsOpen(true);
      return;
    }

    try {
      setIsUpdating(true);
      setDisplayWarning(false);
      await authAxios.put(`/api/users/events/${eventId}/organisers`, {
        organisers: newValue.map((v) => v.value),
      });
      setPlanners(newValue);
      if (hasConfirmed) navigate("/srv/event/list");
    } catch (e) {
      alert("Could not save changes to event planners");
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCancelEvent = async () => {
    setIsUpdating(true);
    try {
      await authAxios.put(`/api/users/events/${eventId}/status`, {
        status: "Cancelled",
      });
      setRefetchEvent(!refetchEvent);
    } catch (e) {
      alert("Could not cancel event. Please try again.");
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleReActivateEvent = async () => {
    setIsUpdating(true);
    try {
      await authAxios.put(`/api/users/events/${eventId}/status`, {
        status: "Planning",
      });
      setRefetchEvent(!refetchEvent);
    } catch (e) {
      alert("Could not re-activate event. Please try again.");
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDenyRequest = async (user_id) => {
    setIsUpdating(true);
    try {
      await authAxios.delete(
        `/api/users/events/${eventId}/organisers/requests/${user_id}`
      );
      setRefetchEvent(!refetchEvent);
    } catch (e) {
      alert("Could not reject request. Please try again.");
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleApproveRequest = async (user_id) => {
    setIsUpdating(true);
    try {
      await authAxios.put(`/api/users/events/${eventId}/organisers/requests`, {
        organisers: [user_id],
      });
      setRefetchEvent(!refetchEvent);
    } catch (e) {
      alert("Could not approve request. Please try again.");
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="event-list-wrapper event-edit-planners">
      <EventPlannerConfirmationModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        handleConfirm={handleConfirm}
      ></EventPlannerConfirmationModal>
      <ImpalaEventsHeader userAuth={userAuth} activeServices={activeServices} />
      <div className="event-list-center edit-planners">
        {events && events.length === 1 ? (
          <div className="event-list-inner">
            <div className="event-planners-header">
              <div className="back-to-previous">
                <Link to="/srv/event/list">
                  <BackIcon />
                  Back to all events
                </Link>
              </div>
              <h1>Manage Event</h1>
            </div>
            <div className="events-planners-title">
              <div>
                <div className="event-title-subtitle">Event name</div>
                <h3>{events[0].name || "Team Event"}</h3>
              </div>
              <div className="event-id">Event ID 1634{events[0].id}</div>
              <div className={`tag tag-${events[0].stage}`}>
                {events[0].stage}
              </div>
            </div>
            {events[0].stage === "Planning" && (
              <section>
                <h4>Cancel Event</h4>
                <p>
                  No changes can be made to cancelled events. Attendees won't be
                  able to submit any responses on their forms. You can
                  re-activate cancelled events later.
                </p>
                <Button
                  onClick={handleCancelEvent}
                  variant="secondary"
                  className="danger-button"
                >
                  Cancel Event
                </Button>
              </section>
            )}
            {events[0].stage === "Cancelled" && (
              <section>
                <h4>Re-activate Event</h4>

                <p>
                  This event was cancelled. Re-activate it to make changes and
                  allow attendees to submit responses on their forms.
                </p>
                <Button onClick={handleReActivateEvent}>Activate Event</Button>
              </section>
            )}
            {events[0].stage === "Planning" && (
              <section>
                <h4>Edit Event Planners</h4>
                <p>
                  Add or remove event planners. Each event planner can manage
                  dates, attendees, and invitations. Your company admin(s) can
                  access all events.
                </p>
                <Select
                  isMulti
                  isClearable={false}
                  options={plannerOptions}
                  value={planners}
                  onChange={(newValue) => handleChange(newValue, false)}
                  isLoading={isUpdating}
                  isDisabled={isUpdating}
                ></Select>
                {displayWarning && (
                  <div className="information danger">
                    <p>
                      {`An event always needs at least one assigned planner. Please add a new planner before removing the current one.`}
                    </p>
                  </div>
                )}
              </section>
            )}
            {events[0].stage === "Planning" && (
              <section>
                <h4>Requests</h4>
                {!events[0].accessRequests ||
                events[0].accessRequests.length === 0 ? (
                  <div className="event-planner-request-empty">
                    <EmptyRequests />
                    <h4>Nobody has requested access to this event</h4>
                    <p>
                      You can approve or reject requests to help edit this
                      event. Other event planners will have the same access as
                      you.
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      The people below have sent a request to help plan this
                      event.
                    </p>
                    <table className="event-access-manage">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email address</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {events[0].accessRequests.map((request) => (
                          <tr key={request.id}>
                            <td>{request.name}</td>
                            <td>{request.email}</td>
                            <td>
                              <Button
                                variant="secondary"
                                onClick={() => handleDenyRequest(request.id)}
                              >
                                Deny Request
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => handleApproveRequest(request.id)}
                              >
                                Approve Request
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </section>
            )}
          </div>
        ) : loadingEventData || !tokenSet ? (
          <Loader variation="linear" />
        ) : (
          <div className="overall-summary">
            <Error />
          </div>
        )}
      </div>
    </div>
  );
};

export default EventPlanners;
