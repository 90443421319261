import "./ContractsEmpty.css";

import React from "react";

import { ReactComponent as KeysIllustration } from "../../images/keys.svg";

function ContractsEmpty() {
  return (
    <div className="ContractsEmptyMain">
      <KeysIllustration />
      <h2>You don't have any offers yet</h2>
      <p>When you've received offers from hotels they'll appear here.</p>
    </div>
  );
}

export default ContractsEmpty;
