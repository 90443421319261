import "./ImpalaHeader.css";

import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";

import { Button } from "@aws-amplify/ui-react";

import { LogoWordmark } from "../../components/ImpalaLogo/ImpalaLogo";
import { ReactComponent as IconHelp } from "../../images/icons/help-circle.svg";
import { ReactComponent as IconSpeedometer } from "../../images/icons/speedometer.svg";
import { ReactComponent as IconBriefcase } from "../../img/briefcase.svg";
import { ReactComponent as IconCheckOut } from "../../img/check-out.svg";
import { ReactComponent as EventHeaderIcon } from "../../img/event-header.svg";
import { ReactComponent as OnboardingHeaderIcon } from "../../img/tasks.svg";

import NavItem from "../NavItem/NavItem";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";

import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

type NavigationItem = {
  title: string;
  path: string;
  variant: string;
  icon: JSX.Element;
};

type SpanItem = {
  type: "span";
  content: string;
};

type NavigationItemType = NavigationItem | SpanItem;

function isNavigationItem(item: NavigationItemType): item is NavigationItem {
  return (item as NavigationItem).path !== undefined;
}

const ImpalaHeader = (props: {
  userAuth: Auth0ContextInterface<User>;
  activeServices: any;
}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEventEditor, setIsEventEditor] = useState(false);
  const [isExternalBookingEditor, setIsExternalBookingEditor] = useState(false);

  const [authenticatedAxios, tokenSet] = useAuthenticatedAxios(true);

  useEffect(() => {
    const fetchData = async () => {
      if (tokenSet) {
        try {
          const roles = await authenticatedAxios.get("/api/users/roles");
          setIsAdmin(roles.data.includes("Company Admin"));
          setIsEventEditor(
            roles.data.includes("Company Admin") ||
              roles.data.includes("Event Editor")
          );
          setIsExternalBookingEditor(
            roles.data.includes("Company Admin") ||
              roles.data.includes("External Booking Editor")
          );
        } catch (e) {
          console.log(e);
          setIsAdmin(false);
          setIsEventEditor(false);
          setIsExternalBookingEditor(false);
        }
      }
    };

    fetchData();
  }, [authenticatedAxios, tokenSet]);

  const [activeNavItem, setActiveNavItem] = useState(0);

  const handleNavItemChange = (index: number) => {
    setActiveNavItem(index);
  };

  const navItems: NavigationItemType[] = isAdmin
    ? [
        {
          title: "Your Services",
          path: "/services",
          variant: "icon",
          icon: <IconBriefcase />,
        },
      ]
    : [];

  if (isEventEditor && props.activeServices?.events_service) {
    if (isAdmin) {
      navItems.push({
        title: "Team Events",
        path: "/srv/event/list",
        variant: "icon",
        icon: <EventHeaderIcon />,
      });
    } else {
      navItems.push({
        type: "span",
        content: "Team Events",
      });
    }
  }

  if (
    isExternalBookingEditor &&
    props.activeServices?.complex_bookings_service
  ) {
    navItems.push({
      title: "Onboarding & Interviews",
      path: "/srv/external/list",
      variant: "icon",
      icon: <OnboardingHeaderIcon />,
    });
  }

  if (props.activeServices?.auditing) {
    navItems.push({
      title: "Reporting",
      path: "/srv/reporting",
      variant: "icon",
      icon: <IconSpeedometer />,
    });
  }

  useEffect(() => {
    navItems.forEach((navItem, index) => {
      if (
        isNavigationItem(navItem) &&
        window.location.pathname.includes(navItem.path)
      ) {
        setActiveNavItem(index);
      }
    });
  }, [navItems]);

  return (
    <header className="ImpalaHeader">
      <Link to="/">
        <LogoWordmark variant="primaryDark" />
      </Link>

      <nav className="PrimaryNav">
        <ul>
          {navItems.map((item, index) =>
            isNavigationItem(item) ? (
              <NavLink className="HeaderNav" to={item.path} key={index}>
                <NavItem
                  key={index}
                  title={item.title}
                  path={item.path}
                  variant={item.variant}
                  icon={item.icon}
                  active={activeNavItem === index}
                  onClick={() => handleNavItemChange(index)}
                />
              </NavLink>
            ) : (
              <span key={index}>{item.content}</span>
            )
          )}
        </ul>
      </nav>

      <div className="HeaderSideNav">
        <a href="https://impala.travel/help/help-support/" target="_blank" className="navLink withMargin">
          <IconHelp />
          <span>Help & Support</span>
        </a>

        {props.userAuth.isAuthenticated && (
          <div>
            <a
              href="#"
              className="navLink"
              onClick={(e) => {
                e.preventDefault();
                props.userAuth.logout({
                  logoutParams: {
                    returnTo: window.location.origin + `/callback`,
                  },
                });
              }}
            >
              <IconCheckOut />
              <span>Log Out</span>
            </a>
          </div>
        )}
        {!props.userAuth.isAuthenticated && (
          <div>
            <Button onClick={() => props.userAuth.loginWithPopup()}>
              Sign In
            </Button>
          </div>
        )}
      </div>
    </header>
  );
};

export default ImpalaHeader;
