import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";

import { useAuth0 } from "@auth0/auth0-react";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

import HotelProgramMeta from "../../components/HotelProgramMeta";
import { ReactComponent as HotelIcon } from "../../img/hotel.svg";
import { ReactComponent as CardIcon } from "../../img/card.svg";
import { ReactComponent as GoogleIcon } from "../../img/google.svg";
import { ReactComponent as VisaIcon } from "../../img/visa.svg";
import { ReactComponent as SpinnerIcon } from "../../img/spinner.svg";
import BenefitsList from "../../components/BenefitsList";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { usePostHog } from "posthog-js/react";

import "../../components/HotelProgram.css";

const HotelProgramBook = ({
  hasDirectBookingServicePermission,
  hasEventServicePermission,
  userAuth,
  user,
}) => {
  const { hotelDealId, companyName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState(null);
  const [validCheck, setValidCheck] = useState(false);
  const [validCheckLoading, setValidCheckLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [virtualCard, setVirtualCard] = useState(null);

  const [virtualCardGenerating, setVirtualCardGenerating] = useState(false);

  const [customerData, setCustomerData] = useState(null);
  const longCardRef = useRef(null);

  const [copied, setCopied] = useState(null);

  const [authenticatedAxios, tokenSet] = useAuthenticatedAxios(true);
  const { loginWithRedirect, logout } = useAuth0();

  const posthog = usePostHog();
  posthog.capture("Service Visit", {
    service: "Frequent Traveller",
  });

  useEffect(() => {
    if (!tokenSet) return;
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/hotel-program/hotel-deal/${hotelDealId}`
        );
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [tokenSet, hotelDealId]);

  useEffect(() => {
    if (!tokenSet) return;
    const fetchData = async () => {
      try {
        setValidCheckLoading(true);
        await authenticatedAxios.get(`/api/users/is-valid-for-company`);
        setValidCheck(true);
      } catch (err) {
        setError(err);
        setValidCheck(false);
      } finally {
        setValidCheckLoading(false);
      }
    };

    fetchData();
  }, [tokenSet]);

  const queryParams = new URLSearchParams(location.search);
  const checkInDateStr = queryParams.get("checkIn");
  const checkOutDateStr = queryParams.get("checkOut");

  const generateCard = async () => {
    try {
      setVirtualCardGenerating(true);
      const response = await authenticatedAxios.get(
        `/api/users/create-card/${hotelDealId}?checkInDate=${encodeURIComponent(
          checkInDateStr
        )}&checkOutDate=${encodeURIComponent(checkOutDateStr)}`
      );
      setVirtualCard(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setVirtualCardGenerating(false);
    }
  };

  const heroImage = () => {
    if (!data) return null;
    return data.images.find((x) => x.is_hero_image);
  };

  const dateStrings = () => {
    if (!checkInDateStr || !checkOutDateStr) {
      return {
        checkIn: format(new Date(), "eee, dd MMM yyyy"),
        checkOut: format(new Date(), "eee, dd MMM yyyy"),
      };
    }

    // These dates will be in the format "dd/MM/yyyy". We need to convert them to Date objects.
    const [dayIn, monthIn, yearIn] = checkInDateStr.split("/");
    const [dayOut, monthOut, yearOut] = checkOutDateStr.split("/");

    const checkInDate = new Date(yearIn, monthIn - 1, dayIn); // Note: JavaScript months are 0-based
    const checkOutDate = new Date(yearOut, monthOut - 1, dayOut);

    // Now we can format these Date objects:
    const checkInDateFormatted = format(checkInDate, "eee, dd MMM yyyy");
    const checkOutDateFormatted = format(checkOutDate, "eee, dd MMM yyyy");

    return { checkIn: checkInDateFormatted, checkOut: checkOutDateFormatted };
  };

  return (
    <div className="hotel-program-display">
      <HotelProgramMeta
        setData={setCustomerData}
        setIsLoading={setIsLoading}
        showEventLink={hasEventServicePermission}
        setError={setError}
        leftText={"Back"}
        leftAction={() => navigate(`/srv/hotel-program-hotel/${hotelDealId}`)}
        data={customerData}
        hideRight={true}
      />
      {data && (
        <div className="booking-area">
          <div className="card-benefits-split">
            <div className="card-login-area">
              {virtualCardGenerating && (
                <div className="virtual-card-area loading">
                  <h4>Generating your virtual credit card</h4>
                  <p>You'll use this to book the hotel you've chosen.</p>
                  <SpinnerIcon />
                </div>
              )}
              {virtualCard && (
                <div className="virtual-card-area">
                  <div className="top-section">
                    <h4>Your virtual credit card</h4>
                    <p className="explainer">
                      Use this to pay for your hotel stay. We'll send you an
                      email with these details in case the hotel asks when you
                      check-in.
                    </p>
                    <div className="virtual-card">
                      <img
                        className="logo"
                        src="/logo.png"
                        alt="Company Logo"
                      />
                      <CopyToClipboard
                        text={virtualCard?.card_number}
                        onCopy={() => setCopied(true)}
                      >
                        <p className={`card-number ${copied && "copied"}`}>
                          {virtualCard.card_number?.replace(/(.{4})/g, "$1 ")}
                        </p>
                      </CopyToClipboard>
                      <p className="name-on-card">{virtualCard.name_on_card}</p>
                      <div className="expiry-row">
                        <p className="expiry">
                          <label>EXP:</label>
                          {virtualCard.expiry_month}/{virtualCard.expiry_year}
                        </p>
                        <p className="cvv">
                          <label>CVV:</label>
                          {virtualCard.cvv}
                        </p>
                      </div>
                      <div className="visa-icon">
                        <VisaIcon />
                      </div>
                    </div>
                  </div>
                  <div className="continue-to-book">
                    <h4>Finish your booking on the hotel's website</h4>
                    {data?.hotelDealDisplayDirect?.additional_direct_steps && (
                      <p>
                        {data.hotelDealDisplayDirect.additional_direct_steps}
                      </p>
                    )}
                    <button
                      onClick={() => {
                        window.open(
                          `/srv/book-out-hotel/${hotelDealId}?checkIn=${encodeURIComponent(
                            checkInDateStr
                          )}&checkOut=${encodeURIComponent(checkOutDateStr)}`,
                          "_blank"
                        );
                      }}
                      className="finish-on-website-button"
                    >{`Open the Hotel's Website`}</button>
                    <p>
                      <b>Note:</b> Good news, with direct bookings you don't
                      need to wait for a support agent. With any issues you can
                      simply contact the hotel direct.
                    </p>
                  </div>
                  <div className="property-sample">
                    <h4>
                      <HotelIcon /> Your stay
                    </h4>
                    <h5>{data.name}</h5>
                    <p>{data.location}</p>
                    <p className="date-string">
                      {dateStrings().checkIn} - {dateStrings().checkOut}
                    </p>
                  </div>
                </div>
              )}
              {!virtualCard && !virtualCardGenerating && (
                <>
                  <div className="property-sample">
                    <h4>
                      <HotelIcon /> Your stay
                    </h4>
                    <div
                      className="image-holder"
                      style={{ backgroundImage: `url(${heroImage()?.url})` }}
                    ></div>
                    <h5>{data.name}</h5>
                    <p>{data.location}</p>
                    <p>
                      {dateStrings().checkIn} - {dateStrings().checkOut}
                    </p>
                  </div>
                  <div className="virtual-card-create-area">
                    <h4>
                      <CardIcon /> Pay with a Virtual Credit Card
                    </h4>
                    <p>
                      Pay securely and easily with a Virtual Credit Card. We
                      generate a one-off card for your stay, with everything
                      automatically handled for the finance team.
                    </p>
                    {!hasDirectBookingServicePermission && (
                      <button
                        className="authzero-login"
                        onClick={() => {
                          loginWithRedirect({
                            appState: {
                              path: location.pathname + location.search,
                            },
                            openUrl: (url) =>
                              window.location.replace(
                                url.replace("redirect_uri", "unused") +
                                  `&access_type=offline&approval_prompt=force&redirect_uri=${encodeURIComponent(
                                    window.location.origin + "/callback"
                                  )}`
                              ),
                          });
                        }}
                      >
                        <GoogleIcon /> Login with Company Email
                      </button>
                    )}
                    {hasDirectBookingServicePermission &&
                      !validCheck &&
                      !validCheckLoading && (
                        <p className="warning">
                          The email address you have logged in with does not
                          allow you to book hotels with this company. Please
                          ensure that you've logged in with company email
                          address.
                          <br />
                          <br />
                          <span
                            href="#"
                            onClick={() =>
                              logout({
                                logoutParams: {
                                  returnTo:
                                    window.location.origin +
                                    `/callback?hotelProgramLogout=true&companyName=${companyName}`,
                                },
                              })
                            }
                          >
                            Logout and Try Again
                          </span>
                        </p>
                      )}
                    {hasDirectBookingServicePermission &&
                      validCheck &&
                      user.email_verified && (
                        <button
                          className="authzero-login"
                          onClick={generateCard}
                        >
                          <CardIcon /> Generate Card & Book
                        </button>
                      )}
                  </div>
                </>
              )}
            </div>
            <div className="benefits-area">
              <BenefitsList
                mode="listing"
                deals={data?.hotelDealDisplayDirect?.unique_benefits}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelProgramBook;
