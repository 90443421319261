import React, { useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { usePostHog } from "posthog-js/react";

const Callback = () => {
  const userAuth = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const shouldRedirect = useRef(true);

  // // Likely here because of a logout. If not then useAuthenticatedAxios will reset anyway.
  const posthog = usePostHog();
  posthog.reset();

  useEffect(() => {
    async function fetchState(userAuth){

      // Need this to facilitate the logout for Direct Program.
      // NOTE: It must come first.
      const queryParams = new URLSearchParams(location.search);

      const hotelProgramLogout = queryParams.get('hotelProgramLogout');
      const companyName = queryParams.get('companyName');
      const eventServiceLogout = queryParams.get('eventServiceLogout');
      const externalServiceLogout = queryParams.get('externalServiceLogout');

      const directBookServiceLogout = queryParams.get('directBookServiceLogout');

      const eventsLogout = queryParams.get('eventsLogout');
      const externalLogout = queryParams.get('externalLogout');
      const eventId = queryParams.get('eventId');


      let navTo = null;
      if (hotelProgramLogout && companyName) {
        navTo = `/hotel-program/${companyName}`;
      } else if (eventServiceLogout) {
        navTo = '/srv/event/list';
      } else if (externalServiceLogout) {
        navTo = '/srv/external/create';
      } else if (directBookServiceLogout) {
        navTo = '/';
      }

      if (eventsLogout && eventId) {
        navTo = `/events/${eventId}`;
      }

      if (externalLogout && eventId) {
        navTo = `/external/${eventId}`;
      }

      try {

        if (shouldRedirect.current) {
          shouldRedirect.current = false;

          const { appState } = await userAuth.handleRedirectCallback();

          if (navTo) {
            return navigate(navTo);
          } else if (appState && appState.path) {
            return navigate(appState.path)
          } else { 
            return navigate('/');
          }
        }

      } catch (e) {
        console.log(e);
        return navigate("/");
      }

    }
    fetchState(userAuth);
  }, [userAuth]);

  return <div></div>;
};

export default Callback;
