import "./EditEventNameModal.css";

import React from "react";
import Modal from "react-modal";

import { Button, Heading, ThemeProvider } from "@aws-amplify/ui-react";
import { ReactComponent as RandomIcon } from "../../img/random.svg";
import { Form } from "react-bootstrap";

import { ImpalaTheme } from "../../theme/index";
import { MODAL_LABEL } from "../../utils/Strings";
import { Event } from "../EventStatus";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0",
    width: "650px",
    borderRadius: "12px",
    maxHeight: "100%",
    transform: "translate(-50%, -50%)",
  },
};

const wordList1 = [
  "Group",
  "Team",
  "Squad",
  "Company",
  "Fellowship",
  "Unified",
  "Corporate",
  "Innovative",
  "Cross-functional",
];

const wordList2 = [
  "Synergy",
  "Collaboration",
  "Empowerment",
  "Collective",
  "Strategic",
  "Performance",
  "Growth",
  "Dynamic",
  "Alignment",
];

const wordList3 = [
  "Session",
  "Exercise",
  "Gathering",
  "Initiative",
  "Journey",
  "Retreat",
  "Seminar",
  "Conclave",
  "Endeavour",
];

function EditEventNameModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: Event;
  eventName: string;
  eventPlace: string;
  onUpdate: Function;
  setEventName: React.Dispatch<React.SetStateAction<string>>;
  setEventPlace: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [axios] = useAuthenticatedAxios(true);

  function randomizeName() {
    const word1 = wordList1[Math.floor(Math.random() * wordList1.length)];
    const word2 = wordList2[Math.floor(Math.random() * wordList2.length)];
    const word3 = wordList3[Math.floor(Math.random() * wordList3.length)];
    props.setEventName(`${word1} ${word2} ${word3}`);
  }

  function afterOpenModal() {}

  function closeModal() {
    props.setEventName(props.event.details.name);
    props.setEventPlace(props.event.details.specific_place);
    props.setIsOpen(false);
  }

  async function handleSaveName() {
    try {
      const nameBody = { name: props.eventName };
      const placeBody = { location: props.eventPlace };

      // First API call to update the event name
      await axios.patch(
        `/api/users/events/1634${props.event?.details.id}/name`,
        nameBody
      );

      // Second API call to update the event place
      await axios.patch(
        `/api/users/events/1634${props.event?.details.id}/location`,
        placeBody
      );

      props.onUpdate();
      props.setIsOpen(false);
    } catch (e) {
      console.log(e);
      alert("Could not save event changes");
    }
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={MODAL_LABEL}
    >
      <ThemeProvider theme={ImpalaTheme}>
        <Heading className="ModalHeader">Edit Event Details</Heading>

        <div className="ModalBody edit-event-details-modal">
          <div>
            <Form.Label for="eventName">Event Name</Form.Label>
            <Form.Control
              type="text"
              maxLength={60}
              id="eventName"
              value={props.eventName}
              onChange={(e) => props.setEventName(e.target.value)}
            />
            <div className="name-controls">
              <button className="randomise-button" onClick={randomizeName}>
                <RandomIcon />
                Random name
              </button>
              <small>{props.eventName?.length || "0"}/60 characters</small>
            </div>
            <Form.Label for="eventPlace">Event Location</Form.Label>
            <Form.Control
              type="text"
              maxLength={100}
              id="eventPlace"
              value={props.eventPlace}
              onChange={(e) => props.setEventPlace(e.target.value)}
            />
            <small>{props.eventPlace?.length || "0"}/100 characters</small>
          </div>
        </div>

        <div className="ModalButtonFooter">
          <Button onClick={closeModal}>Cancel</Button>
          <Button variation="primary" onClick={handleSaveName}>
            Save
          </Button>
        </div>
      </ThemeProvider>
    </Modal>
  );
}

export default EditEventNameModal;
