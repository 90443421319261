import React, { useEffect, useState } from "react";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { ReactComponent as Spinner } from "../img/spinner.svg";

const RedirectingComponent = ({ isSelfServe }) => {
  const navigate = useNavigate();
  const [authenticatedAxios, tokenSet] = useAuthenticatedAxios(true);
  const [loginAttempted, setLoginAttempted] = useState(false);

  let userAuth = useAuth0();

  console.log(isSelfServe);

  useEffect(() => {
    const fetchData = async () => {
      let isAdmin,
        isEventEditor,
        isDirectBooker,
        isExternalBookingEditor,
        isExternal;

      try {
        const roles = await authenticatedAxios.get("/api/users/roles");
        isAdmin = roles.data.includes("Company Admin");
        isEventEditor = isAdmin || roles.data.includes("Event Editor");
        isExternalBookingEditor =
          isAdmin || roles.data.includes("External Booking Editor");
        isDirectBooker = isAdmin || roles.data.includes("Direct Booking User");
        isExternal = roles.data.includes("External User");
      } catch (e) {
        console.log(e);
      }

      if (isAdmin && !isSelfServe) {
        setTimeout(() => navigate("/services"), 0);
      } else if (isExternalBookingEditor && !isEventEditor && !isDirectBooker) {
        setTimeout(() => navigate("/srv/external/create"), 0);
      } else if (isSelfServe || (isEventEditor && !isDirectBooker)) {
        setTimeout(() => navigate("/srv/event/list"), 0);
      } else if (isDirectBooker) {
        setTimeout(() => navigate("/srv/hotel-program"), 0);
      } else if (isExternal) {
        setTimeout(() => navigate("/external/attend"), 0);
      } else {
        setTimeout(() => navigate("/"), 0);
      }
    };

    if (tokenSet) {
      fetchData();
    }
  }, [authenticatedAxios, isSelfServe, navigate, tokenSet]);

  // loginAttempted is required and prevents an infinite loop
  // there is a render cycle where userAuth.isLoading is falsey and userAuth.isAuthenticated is falsey
  // this appears to be an issue with Auth0. That being the case this will always trigger
  // unless something is there to stop it from triggering until userAuth.isAuthenticated is correctly set.
  if (!userAuth.isAuthenticated && !userAuth.isLoading && !loginAttempted) {
    userAuth.loginWithRedirect({
      appState: { path: window.location.pathname },
      authorizationParams: {
        redirect_uri: window.location.origin + "/callback",
      },
    });
    return;
  }

  return (
    <div className="dashboard-authenticating-state">
      <div className="wrapper">
        <h1>Redirecting you to where you need to be</h1>
        <Spinner />
      </div>
    </div>
  );
};

export default RedirectingComponent;
