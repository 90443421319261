import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosStatic } from "axios";
import { usePostHog } from "posthog-js/react";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const useAuthenticatedAxios = (
  withLoadingRestriction: boolean
): [AxiosStatic, boolean] => {
  const auth0 = useAuth0();
  const [tokenSet, setTokenSet] = useState(false);
  const [isIdentified, setIsIdentified] = useState(false);

  const posthog = usePostHog();

  useEffect(() => {
    const main = async () => {
      try {
        if (auth0.isAuthenticated) {
          const accessToken = await auth0.getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            },
          });
        
          axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
          setTokenSet(true);
        }
      } catch (error) {
        console.error(`Error getting access token:`, error);
        auth0.logout({
          logoutParams: {
              returnTo: window.location.origin+`/callback`,
          },
        });
      }
    };

    main();

    if (auth0.user?.sub && !isIdentified) {
      posthog.identify(
        auth0.user?.sub,
        { email: auth0.user?.email },
      );
      setIsIdentified(true);
    }
  }, [auth0]);

  useEffect(() => {
    const identifyCompany = async () => {
      try {
        if (auth0.isAuthenticated && tokenSet) {
          const companyResult = await axios.get('/api/users/company');
          posthog.group('company', companyResult.data.id, {
            name: companyResult.data.name,
          });
        }
      } catch (e) {
        console.error(`Error identifying company:`, e);
      }
    }
    identifyCompany();

  }, [tokenSet]);

  if (!withLoadingRestriction) {
    return [axios, false];
  } else {
    return [axios, tokenSet];
  }
};

export default useAuthenticatedAxios;
