import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "../../components/Events.css";
import { TabItem, Tabs } from "@aws-amplify/ui-react";
import { Loader } from "@aws-amplify/ui-react";

import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

import { ReactComponent as EventListEmptyIcon } from "../../img/event-list-empty.svg";
import { usePostHog } from "posthog-js/react";
import EventsTable from "../../components/EventsTable";
import useCompanyDetails from "../../lib/useCompanyDetails";
import ImpalaEventsHeader from "../../components/ImpalaHeader/ImpalaEventsHeader";
import Account from "../../components/Account";
import CreateNewEvent from "../../components/CreateNewEvent";
import { useSearchParams } from "react-router-dom";
import PricingListModal from "../../components/PricingListModal";
import { Tooltip } from "react-tooltip";

const EventList = ({
  isAuthenticated,
  hasEventServicePermission,
  user,
  userAuth,
  activeServices,
}) => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  const [authAxios, tokenSet] = useAuthenticatedAxios(true);
  const [events, setEvents] = useState([]);
  const [eventListTabIndex, setEventListTabIndex] = useState(0);
  const [loadingEventData, setLoadingEventData] = useState(true);
  const [isAccount, setIsAccount] = useState(page === "account");
  const [isNewEvent, setIsNewEvent] = useState(page === "new_event");
  const [companyDetails, setCompanyDetails] = useState();
  const [refresh, setRefresh] = useState(false);
  const [PricingListModalOpen, setPricingListModalOpen] = useState(false);

  useCompanyDetails(setCompanyDetails, refresh);

  const onCompanyUpdate = () => {
    setRefresh(!refresh);
  };

  const posthog = usePostHog();
  posthog.capture("Service Visit", {
    service: "Events",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoadingEventData(true);
      try {
        const response = await authAxios.get("/api/events");
        setEvents(response.data.events);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingEventData(false);
      }
    };

    if (tokenSet) {
      fetchData();
    }
  }, [authAxios, tokenSet]);

  return (
    <div className="event-list-wrapper">
      <ImpalaEventsHeader
        companyDetails={companyDetails}
        userAuth={userAuth}
        activeServices={activeServices}
        setIsAccount={setIsAccount}
        setIsNewEvent={setIsNewEvent}
      />
      {userAuth.user && (
        <PricingListModal
          setIsOpen={setPricingListModalOpen}
          modalIsOpen={PricingListModalOpen}
          user={userAuth.user}
        ></PricingListModal>
      )}
      {isAccount ? (
        <Account
          companyDetails={companyDetails}
          setIsAccount={setIsAccount}
          onCompanyUpdate={onCompanyUpdate}
        />
      ) : isNewEvent ? (
        <CreateNewEvent setIsNewEvent={setIsNewEvent} />
      ) : (
        <div className="event-list-center">
          <div className="event-list-inner">
            <div className="event-list-header">
              <header className="service-page-title">
                <h2>All Events</h2>
              </header>
              {events.filter((e) => e.stage === "Planning").length > 1 &&
              !companyDetails?.subscribed ? (
                <>
                  <Tooltip
                    className="eventTooltip"
                    id="upgradeButton"
                  ></Tooltip>
                  <Button
                    data-tooltip-id="upgradeButton"
                    data-tooltip-place="top"
                    data-tooltip-html={`<span>${companyDetails.name} have reached the maximum number of live events. Upgrade to a Pro account for an unlimited number of events.</span>`}
                    variant="primary"
                    onClick={() => setPricingListModalOpen(true)}
                  >
                    Upgrade to Create More Events
                  </Button>
                </>
              ) : (
                <Button variant="primary" onClick={() => setIsNewEvent(true)}>
                  Create New Event
                </Button>
              )}
            </div>
            <div className="events-list-container">
              {loadingEventData || !tokenSet ? (
                <Loader variation="linear" />
              ) : (
                <>
                  {events.length === 0 && (
                    <div className="events-list-empty">
                      <EventListEmptyIcon />
                      <h4>You've not created any events yet</h4>
                      <p>Click the button below to get started</p>
                      <Button
                        onClick={() => setIsNewEvent(true)}
                        variant="secondary"
                      >
                        Create new event
                      </Button>
                    </div>
                  )}
                  {events.length > 0 && (
                    <div className="events-list-events">
                      <Tabs
                        className="header"
                        currentIndex={eventListTabIndex}
                        onChange={(i) => setEventListTabIndex(Number(i))}
                      >
                        <TabItem title="Your events">
                          {events.filter((e) => e.hasAccess === true).length ===
                          0 ? (
                            <div className="events-list-empty">
                              <EventListEmptyIcon />
                              <h4>You've not created any events yet</h4>
                              <p>Click the button below to get started</p>
                              <Button
                                onClick={() => setIsNewEvent(true)}
                                variant="secondary"
                              >
                                Create new event
                              </Button>
                            </div>
                          ) : (
                            <EventsTable
                              events={events.filter(
                                (e) => e.hasAccess === true
                              )}
                            />
                          )}
                        </TabItem>
                        <TabItem title="All your team's events">
                          <EventsTable events={events} />
                        </TabItem>
                      </Tabs>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventList;
