import React, { useEffect, useState } from "react";

import { ReactComponent as BackIcon } from "../img/arrow-left.svg";
import { ReactComponent as IdentityIcon } from "../img/identity-icon.svg";

import { Event, EventAttendee } from "./EventStatus";

import { Button, Form } from "react-bootstrap";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";
import AttendeeDatesModal from "./AttendeeDatesModal";

import "./Events.css";
import AttendeeGroupsModal from "./AttendeeGroupsModal";
import AttendeeDeleteModal from "./AttendeeDeleteModal";

type Option = { label: string; value: string };

const ManageAttendee = (props: {
  event: Event;
  onUpdate: Function;
  selectedAttendee: EventAttendee;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [axios] = useAuthenticatedAxios(true);
  const [showAttendeeDatesModal, setShowAttendeeDatesModal] = useState(false);
  const [showAttendeeGroupsModal, setShowAttendeeGroupsModal] = useState(false);
  const [showAttendeeDeleteModal, setShowAttendeeDeleteModal] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState<EventAttendee>();
  const [eventAttendees, setEventAttendees] = useState<EventAttendee[]>([
    ...props.event.attendees,
  ]);
  const [eventGroups, setEventGroups] = useState<Option[]>([]);

  useEffect(() => {
    const options: Option[] = props.event.details.attendee_categories?.map(
      (g) => {
        return {
          label: g,
          value: g,
        };
      }
    );
    setEventGroups(options);
  }, [props.event.details.attendee_categories]);

  useEffect(() => {
    setEventAttendees([...props.event.attendees]);
    if (props.selectedAttendee) {
      setSelectedAttendee(
        props.event.attendees.find((a) => a.id === props.selectedAttendee.id)
      );
    }
  }, [props.event.attendees, props.selectedAttendee]);

  const handleBookRoomToggle = async (
    e: any,
    attendeeId: string | undefined
  ) => {
    let body: any = {};

    let newAttendees = [...eventAttendees];
    let changedAttendee = newAttendees.find((ea) => ea.id === attendeeId);

    if (e.target.checked) {
      body.book = [attendeeId];
      if (changedAttendee) changedAttendee.book_room = true;
    } else {
      body.dont_book = [attendeeId];
      if (changedAttendee) changedAttendee.book_room = false;
    }
    setEventAttendees(newAttendees);
    await axios.patch(
      `/api/users/events/1634${props.event.details.id}/attendees/book`,
      body
    );
    props.onUpdate();
  };

  return (
    <div>
      <AttendeeGroupsModal
        modalIsOpen={showAttendeeGroupsModal}
        setIsOpen={setShowAttendeeGroupsModal}
        event={props.event}
        eventGroups={eventGroups}
        setEventGroups={setEventGroups}
        onUpdate={props.onUpdate}
        eventAttendee={selectedAttendee}
      ></AttendeeGroupsModal>
      <AttendeeDeleteModal
        modalIsOpen={showAttendeeDeleteModal}
        setIsOpen={setShowAttendeeDeleteModal}
        event={props.event}
        onUpdate={props.onUpdate}
        eventAttendee={selectedAttendee}
        setSubPage={props.setSubPage}
      ></AttendeeDeleteModal>
      <AttendeeDatesModal
        modalIsOpen={showAttendeeDatesModal}
        setIsOpen={setShowAttendeeDatesModal}
        event={props.event}
        onUpdate={props.onUpdate}
        eventAttendee={selectedAttendee}
      ></AttendeeDatesModal>
      <div className="manage-attendees-wrapper">
        <header className="pageHeader topLevel">
          <Button
            className="backHeaderLink"
            onClick={() => props.setSubPage("")}
          >
            <BackIcon />
            <span className="visually-hidden">Back</span>
          </Button>
          <h2>Manage Attendee</h2>
        </header>
        <div className="manage-attendee-header">
          <IdentityIcon aria-role="presentation" />
          <h5>Manage details for</h5>
          <h3>{selectedAttendee?.name || selectedAttendee?.email}</h3>
          <small>{selectedAttendee?.name ? selectedAttendee?.email : ""}</small>
        </div>
        <section className="manage-attendee-options">
          <div className="manage-attendee-row">
            <div className="manage-attendee-row-description">
              <h4>Book accommodation for this attendee</h4>
              <p>
                If you deselect this option we won't book any selected
                accommodation for {selectedAttendee?.name || "this attendee"}.
              </p>
            </div>
            <Form.Check
              id="toggle-check"
              type="checkbox"
              className="toggleCheckbox"
              checked={
                eventAttendees.find((ea) => ea.id === selectedAttendee?.id)
                  ?.book_room
              }
              value="1"
              onChange={(e) => handleBookRoomToggle(e, selectedAttendee?.id)}
            ></Form.Check>
          </div>
          <div className="manage-attendee-row">
            <div className="manage-attendee-row-description">
              <h4>Edit Groups</h4>
              <p>
                Add or remove groups for{" "}
                {selectedAttendee?.name || "this attendee"}.
              </p>
            </div>
            <Button
              className="btn-secondary"
              onClick={() => setShowAttendeeGroupsModal(true)}
            >
              Edit Groups
            </Button>
          </div>
          <div className="manage-attendee-row">
            <div className="manage-attendee-row-description">
              <h4>Edit dates</h4>
              <p>
                Change attendance and accommodation dates for{" "}
                {selectedAttendee?.name || "this attendee"}.
              </p>
            </div>
            <Button
              className="btn-secondary"
              onClick={() => setShowAttendeeDatesModal(true)}
            >
              Edit Dates
            </Button>
          </div>
          {selectedAttendee?.is_silent && (
            <div className="manage-attendee-row">
              <div className="manage-attendee-row-description">
                <h4>Open response form</h4>
                <p>
                  {selectedAttendee?.name || "This attendee"} is managed by the
                  event organiser(s) and will receive no communication from
                  Impala about the event. You can open their response form and
                  fill it in on their behalf.
                </p>
              </div>
              <Button
                className="btn-secondary"
                onClick={() =>
                  window.open(
                    `/events/1634${props.event.details.id}/attend?attendee=${selectedAttendee?.id}`
                  )
                }
              >
                Open response form
              </Button>
            </div>
          )}
          <div className="manage-attendee-row">
            <div className="manage-attendee-row-description">
              <h4>Delete this attendee</h4>
              <p>
                You can't undo this action. If you change your mind you'll need
                to re-invite this attendee and they'll have to re-enter their
                travel preferences.
              </p>
            </div>
            <Button
              className="btn-secondary danger-button"
              onClick={() => setShowAttendeeDeleteModal(true)}
            >
              Delete Attendee
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ManageAttendee;
