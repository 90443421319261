import React, { useState, useEffect, useCallback } from 'react';
import ImpalaHeader from "../../components/ImpalaHeader/ImpalaHeader";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";
import { useNavigate } from 'react-router-dom';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { ReactComponent as DownloadAuditIcon } from '../../img/download-audit.svg';
import { ReactComponent as InfoCircleIcon } from '../../img/info-circle.svg';
import { ReactComponent as AuditIcon } from '../../img/audit.svg';
import { ReactComponent as GraphIcon } from '../../img/graph.svg';

import { ReactComponent as RedHotelIcon } from '../../img/red-hotel.svg';
import { ReactComponent as BlueGraphIcon } from '../../img/blue-graph.svg';
import { ReactComponent as BlackAirplaneIcon } from '../../img/black-airplane.svg';

import HotelsAudit from './HotelsAudit';
import FlightsAudit from './FlightsAudit';
import AuditOptions from './AuditOptions';

import 'chart.js/auto';
import './reporting.css';
import NoPermission from '../Navigation/NoPermission';

const Reporting = ({
  isAuthenticated,
  user,
  userAuth,
  activeServices,
}) => {
  const [authAxios, tokenSet] = useAuthenticatedAxios(true);
  const [hasData, setHasData] = useState(false);
  const [activeTab, setActiveTab] = useState('hotels');
  const [loading, setLoading] = useState(false);
  const [preSpendingData, setPreSpendingData] = useState([]);
  const [postSpendingData, setPostSpendingData] = useState([]);
  const [spendingData, setSpendingData] = useState([]);
  const [calendarSavings, setCalendarSavings] = useState([]);
  const [viewMode, setViewMode] = useState('pre'); // 'pre' or 'post'
  const [auditSummary, setAuditSummary] = useState(null);
  const [contractPerformance, setContractPerformance] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const handleSelect = (key) => {
    if (key === 'options') {
      setModalShow(true);
    } else {
      setActiveTab(key);
    }
  };

  const navigate = useNavigate();

  const [dates, setDates] = useState({
    has_tracking_start: false,
    pre_start_date: null,
    pre_end_date: null,
    post_start_date: null,
    post_end_date: null,
  });

  // Fetch whether data is available
  useEffect(() => {
    if (tokenSet) {
      setLoading(true);
      authAxios.get('/api/reporting/have-data')
        .then(response => {
          if (response.data && response.data.hasData !== undefined) {
            setHasData(response.data.hasData);
          }
        })
        .catch(error => {
          console.error('Error fetching reporting data:', error);
          setHasData(false);
        });
    }
  }, [authAxios, tokenSet]);

  // Fetch pre and post spending data
  useEffect(() => {
    if (hasData && tokenSet) {
      setLoading(true);
      authAxios.get('/api/reporting/spend-by-month?set=audit')
        .then(response => {
          if (response.data) {
            setPreSpendingData(response.data.spendByMonth);
          }
        })
        .catch(error => {
          console.error('Error fetching pre spending data:', error);
        });

      authAxios.get('/api/reporting/spend-by-month?set=post')
        .then(response => {
          if (response.data) {
            setPostSpendingData(response.data.spendByMonth);
          }
        })
        .catch(error => {
          console.error('Error fetching post spending data:', error);
        })
        .finally(() => {
          setLoading(false);
        });

      authAxios.get('/api/reporting/calendar-savings')
        .then(response => {
          if (response.data) {
            setCalendarSavings(response.data.savingsByMonth);
          }
        })
        .catch(error => {
          console.error('Error fetching calendar savings data:', error);
        });
    }
  }, [authAxios, hasData, tokenSet]);

  // Fetch customer data dates
  useEffect(() => {
    if (tokenSet) {
      authAxios.get('/api/reporting/customer-data-dates')
        .then(response => {
          if (response.data) {
            setDates(response.data);
          }
        })
        .catch(error => {
          console.error('Error fetching customer data dates:', error);
        });
    }
  }, [authAxios, tokenSet]);

  // Fetch audit summary data if in 'pre' mode
  useEffect(() => {
    if (viewMode === 'pre' && tokenSet) {
      authAxios.get('/api/reporting/audit-summary')
        .then(response => {
          if (response.data) {
            setAuditSummary(response.data.auditSummary);
          }
        })
        .catch(error => {
          console.error('Error fetching audit summary:', error);
        });
    }
  }, [authAxios, tokenSet, viewMode]);

  // Fetch contract performance data
  useEffect(() => {
    if (tokenSet) {
      authAxios.get('/api/reporting/contract-performance')
        .then(response => {
          if (response.data) {
            setContractPerformance(response.data);
          }
        })
        .catch(error => {
          console.error('Error fetching contract performance data:', error);
        });
    }
  }, [authAxios, tokenSet]);

  useEffect(() => {
    setSpendingData(viewMode === 'pre' ? preSpendingData : postSpendingData);
  }, [viewMode, postSpendingData, preSpendingData]);

  // Calculate the sum of spending data
  const calculateTotalSpend = (data) => {
    return data ? data.reduce((acc, item) => acc + item.total_spend, 0) : 0;
  }

  const preTotalSpend = calculateTotalSpend(preSpendingData);
  const postTotalSpend = calculateTotalSpend(postSpendingData);

  const handleDownloadAudit = useCallback(() => {
    authAxios.get('/api/reporting/download-audit-summary', {
      responseType: 'blob',  // Important to set the response type to 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'audit-summary.pdf'); // Set the file name
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => {
      console.error('Error downloading audit summary:', error);
    });
  }, [authAxios]);

  // Format total spend with commas for thousands
  const formatCurrency = (amount) => {
    return `$` + amount.toFixed(0).replace(/\d(?=(\d{3})+\b)/g, '$&,');
  }

  // Format date to '16th October 23' format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "do MMMM yy");
  }

  const preDataSets = [
    {
      label: 'Spend',  
      data: spendingData ? spendingData.map(item => item.total_spend) : [],
      backgroundColor: 'rgba(255, 136, 119, 1)',
      borderRadius: 5,
      borderWidth: 1,
    },
  ];
  
  const postDataSets = [
    {
      label: 'Would Have Spent',
      backgroundColor: 'rgba(255, 136, 119, 1)',
      borderWidth: 1,
      borderRadius: 5,
      data: spendingData.map(item => {
        // Find the corresponding savings for the same month
        const savingEntry = calendarSavings.find(saving => saving.month === item.month);
        // Adjust the total_spend by the savings amount if found
        return savingEntry ? item.total_spend + savingEntry.saving : item.total_spend;
      }),
    },
    {
      label: 'Spend with Impala',
      data: spendingData.map(item => item.total_spend),
      backgroundColor: 'rgba(40, 187, 199, 1)',
      borderRadius: 5,
      borderWidth: 1,
    }
  ];

  const dataSets = viewMode === 'pre' ? preDataSets : postDataSets;

  // Chart configuration
  const chartData = {
    labels: spendingData ? spendingData.map(item => format(new Date(`20${item.month.split('/')[1]}-${item.month.split('/')[0]}-01`), 'MMM yyyy')) : [],
    datasets: dataSets, 
  };

  const chartOptions = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,  // Increase font size
            weight: 'bold',  // Increase font weight
          },
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: 'rgba(20, 20, 20, 0.2)',
          borderWidth: 2  // Increase the width of the lines to 2px
        },
        ticks: {
          font: {
            size: 14,  // Increase font size
            weight: 'bold',  // Increase font weight
          },
          callback: function(value) {
            if (value >= 1000000) {
              return `$${(value / 1000000).toFixed(1)}M`;
            } else if (value >= 1000) {
              return `$${(value / 1000).toFixed(0)}K`;
            } else {
              return `$${value}`;
            }
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false  // Hides the legend (key)
      }
    },
  };

  // Calculate "What Would Have Spent Total"
  const calculateTotalWouldHaveSpent = (spendingData, calendarSavings) => {
    return spendingData.reduce((acc, item) => {
      const savingEntry = calendarSavings.find(saving => saving.month === item.month);
      return acc + ((savingEntry ? item.total_spend + savingEntry.saving : item.total_spend));
    }, 0);
  }

  // Calculate "Total Saving"
  const calculateTotalSaving = (calendarSavings) => {
    return calendarSavings.reduce((acc, saving) => acc + saving.saving, 0);
  }

  const totalWouldHaveSpent = calculateTotalWouldHaveSpent(postSpendingData, calendarSavings);
  const totalSaving = calculateTotalSaving(calendarSavings);

  const viewHotelDealReporting = (hotelDealId) => navigate('/srv/reporting/hotel-deal/' + hotelDealId);

  if (!activeServices?.auditing) {
    return <NoPermission user={user} />
  }

  return (
    <div>
      <ImpalaHeader userAuth={userAuth} activeServices={activeServices} />
      <div className="reporting-wrapper">
        <div className="reporting-page">
          <div className="breadcrumbs">
            <Link to="/srv/dashboard"><span>My Dashboard</span></Link>
            <Link to="/srv/reporting"><span>Reporting</span></Link>
          </div>
          <h1>Reporting</h1>
          <div className="switch-wrapper">
            <div className="switch">
              <button onClick={() => setViewMode('pre')} className={viewMode === 'pre' ? 'active' : ''}><AuditIcon /> Audit</button>
              <button onClick={() => setViewMode('post')} className={viewMode === 'post' ? 'active' : ''}><GraphIcon /> Tracking</button>
            </div>
          </div>
          <div className="top-bubbles">
            {viewMode === 'pre' && (
              <>
                {!loading && viewMode == 'pre' && (
                  <div className="bubble">
                    <p>{'Spend in submitted historical data'}</p>
                    <p className="value">{formatCurrency(preTotalSpend)}</p>
                    <small>Booked {dates.pre_start_date ? `${formatDate(dates.pre_start_date)} to ${formatDate(dates.post_end_date)}` : ''}</small>
                  </div>
                )}
                {loading && (
                  <>
                    <div className="bubble loading">
                      <div className="loading-bubble-innards"></div>
                    </div>
                    <div className="bubble audit loading">
                      <div className="loading-bubble-innards"></div>
                    </div>
                  </>
                )}
              </>
            )}
            {viewMode === 'post' && dates.has_tracking_start && (
              <>
                <div className="bubble">
                  <p>Would Have Spent</p>
                  <p className="value">{formatCurrency(totalWouldHaveSpent)}</p>
                  <small>Booked {dates.post_start_date ? `${formatDate(dates.post_start_date)} to ${formatDate(dates.post_end_date)}` : ''}</small>
                </div>
                
                <div className="bubble with-impala">
                  <p>Spend with Impala</p>
                  <p className="value">{formatCurrency(postTotalSpend)}</p>
                  <small>Booked {dates.post_start_date ? `${formatDate(dates.post_start_date)} to ${formatDate(dates.post_end_date)}` : ''}</small>
                </div>
                
                <div className="bubble audit">
                  <p>Total Saving</p>
                  <p className="value">{formatCurrency(totalSaving)}</p>
                  <small>Saved {dates.post_start_date ? `${formatDate(dates.post_start_date)} to ${formatDate(dates.post_end_date)}` : ''}</small>
                </div>
              </>
            )}
          </div>
          {hasData ? (
            <div className="reporting-area">
              {!loading && viewMode === 'pre' && (
                <div className="display-chart">
                  <Bar data={chartData} options={chartOptions} />
                </div>
              )}
              {!loading && viewMode === 'post' && !!dates.has_tracking_start === true && (
                <div className="display-chart">
                  <Bar data={chartData} options={chartOptions} />
                </div>
              )}
              {!loading && viewMode === 'post' && dates?.has_tracking_start === false &&(
                <div className="no-data-wrapper">
                  <div className="no-data-area">
                    <img src="/reporting-empty.png" alt="Empty Reporting" />
                    <h3>We're waiting for your program to begin</h3>
                    <p>Data will only show here once we have confirmed that your new contracted pricing has been uploaded to your TMC(s).</p>
                    <p>If you think you should be seeing data here and are not, please contact your Account Manager.</p>
                  </div>
                </div>
              )}
              {loading && (
                <div className="loading-reporting-data">
                  <img className="loading-image" src="/loading-data.png" alt="Loading Reporting Data" />
                  <h4>Calculating spend data...</h4>
                </div>
              )}
            </div>
          ) : (
            <div className="no-data-wrapper">
              <div className="no-data-area">
                <img src="/reporting-empty.png" alt="Empty Reporting" />
                <h3>We haven't yet received your data!</h3>
                <p>Once we've uploaded the first batch of your data you'll be able to access reporting from right here. If you weren't expecting to see this screen then please contact your account manager.</p>
              </div>
            </div>
          )}
          {!!hasData === true && viewMode === 'post' && dates?.has_tracking_start !== false && contractPerformance && (
              <div className="all-contract-performance">
                <h4>All Contracts Performance</h4>
                <div className="hotel-contracts">
                  <h5>Hotel Contracts</h5>
                  <div className="hotel-contracts-area">
                    {contractPerformance.hotel && contractPerformance.hotel.map((hotel, index) => (
                      <div className="hotel-contract" key={index}>
                        <div className="info-section hotel-name">
                          <label>Hotel Name</label>
                          <div>{hotel.name}</div>
                        </div>
                        <div className="info-section">
                          <label>Location</label>
                          <div>{hotel.location}</div>
                        </div>
                        <div className="info-section">
                          <label>Total Saving</label>
                          <div>{formatCurrency(hotel.saving)}</div>
                        </div>
                        <div className="info-section">
                          <label>vs Benchmark</label>
                          <div className="difference-wrapper">
                            <div className={((hotel.saving / hotel.benchmark) * 100) >= 0 ? 'positive-difference' : 'negative-difference'}>
                              {((hotel.saving / hotel.benchmark) * 100).toFixed(2)}% <InfoCircleIcon />
                            </div>
                          </div>
                        </div>
                        <div className="info-section button">
                          <Button variant="secondary" onClick={() => viewHotelDealReporting(hotel.id)}>View</Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          
          {viewMode === 'pre' && (
            <div className="audit-area">
              <div className="audit-area-topper">
                <div className="icons-line">
                  <RedHotelIcon />
                  <BlueGraphIcon />
                  <BlackAirplaneIcon />
                </div>
                <h1>Your Travel Audit</h1>
                <p>
                  This report dives deep into your current travel expenditures and behaviours to 
                  offer practical, straightforward ways to cut costs while keeping your team happy 
                  and productive.
                </p>
              </div>
              <Tabs activeKey={activeTab} id="uncontrolled-tab-example" className="mb-3" onSelect={handleSelect}>
                <Tab eventKey="hotels" title="Hotels">
                  <HotelsAudit />
                </Tab>
                <Tab eventKey="flights" title="Flights">
                  <FlightsAudit />
                </Tab>
                <Tab eventKey="options" title="Settings" />
              </Tabs>
            </div>
          )}

          {hasData && viewMode === 'pre' && auditSummary && (
            <div className="audit-download">
              <div className="icon-area">
                <DownloadAuditIcon />
              </div>
              <div className="text-area">
                <h5>Download your Travel Audit</h5>
                <p>Get deep insights into your travel data, download your bespoke Travel Audit.</p>
              </div>
              <div className="button-area">
                <div className="button-area">
                  <Button onClick={handleDownloadAudit}>Download Your Travel Audit</Button>
                </div>
              </div>
            </div>
            )}  
        </div>
      </div>
      <AuditOptions
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          window.location.reload();
        }}
      />
    </div>
  );
}

export default Reporting;
