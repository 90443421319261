import React from "react";
import "./Events.css";

const EventEmpty = () => {
  return (
    <div>
      <h2>We need to review your request</h2>
      <p>
        You'll be able to manage event dates, set attendance requirements and
        more once we've reviewed your request and had the planning chat with
        you.
      </p>
    </div>
  );
};

export default EventEmpty;
