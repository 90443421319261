import React, { useEffect, useState } from "react";
import { utcToZonedTime } from "date-fns-tz";

import { Container, Row } from "react-bootstrap";
import axios, { AxiosResponse } from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { Event, EventDate } from "../../components/EventStatus";

import { Loader, TabItem, Tabs } from "@aws-amplify/ui-react";

import "../../components/Events.css";
import "../../components/attendeeForm.css";
import EventAttendeePageHeader from "../../components/EventAttendeePageHeader";
import EventAttendeePreferences from "../../components/EventAttendeePreferences";
import EventAttendeeAgenda from "../../components/EventAttendeeAgenda";
import Error from "../../components/Error";

import { Editor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import EventAttendeeAbout from "../../components/EventAttendeeAbout";

const extensions = [
  StarterKit,
  Underline,
  Link.configure({
    protocols: ["mailto"],
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Placeholder.configure({
    placeholder: "What's happening on this day...?",
  }),
];

const EventAttendeeForm = () => {
  const [response, setResponse] = useState<AxiosResponse<any, any>>();
  const [loadingEventData, setLoadingEventData] = useState(true);
  const { eventId } = useParams();
  const [searchParams] = useSearchParams();
  const attendeeId = searchParams.get("attendee");
  const page = searchParams.get("page");

  const [event, setEvent] = useState<Event>();
  const [editors, setEditors] = useState<Map<number, Editor>>(
    new Map<number, Editor>()
  );

  const [tabIndex, setTabIndex] = useState(1);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        setTabIndex(page === "about" ? 0 : page === "agenda" ? 2 : 1);

        setLoadingEventData(true);
        const response = await axios.get(
          `/api/event-external/events/${eventId}/attendees/${attendeeId}`
        );
        setResponse(response);

        const eventDates = response.data.event_dates
          .sort((a: any, b: any) =>
            a.date > b.date ? 1 : b.date > a.date ? -1 : 0
          )
          .map((d: any) => {
            return {
              ...d,
              date: utcToZonedTime(
                new Date(d.date.toString() + "T00:00:00Z"),
                "Etc/UTC"
              ),
            };
          });

        setEvent({
          details: response.data.prospective_event,
          realEvent: true,
          attendees: response.data.event_attendees,
          dates: eventDates,
          owner: undefined,
          calendlyInvite: undefined,
          offers: response.data.offers,
          groups: [],
          hotels: response.data.event_hotels,
          marketing: response.data.event_marketing,
          subscription: undefined,
          customer: undefined,
        });

        const agendaEditors = new Map<number, Editor>();

        eventDates.forEach((d: EventDate) => {
          const editor = new Editor({
            content: d.agenda || "",
            extensions: extensions,
          });
          editor.setEditable(false);
          agendaEditors.set(d.id, editor);
        });

        setEditors(agendaEditors);

        setLoadingEventData(false);
      } catch (error) {
        console.error("Error fetching event details:", error);
        setLoadingEventData(false);
      }
    };

    fetchEventDetails();
  }, [attendeeId, eventId, page]);

  document.title =
    event?.details?.name ||
    `Respond to ${
      event?.details?.creator_name || event?.details?.creator_name || "Invite"
    }`;

  return (
    <Container className="attendee-form" fluid>
      {loadingEventData ? (
        <Loader variation="linear"></Loader>
      ) : response && event ? (
        <Row className="full-height-row attendee-form-content">
          <div className="attendee-form-event-info limit-width">
            <div className="attendee-form-event-info-content">
              <EventAttendeePageHeader event={event} />
            </div>
          </div>
          <div className="attendee-form-event-info">
            <Tabs
              className="attendee-form-tabs"
              defaultIndex={tabIndex}
              currentIndex={tabIndex}
              onChange={(i) => setTabIndex(Number(i))}
            >
              <TabItem title="About">
                <EventAttendeeAbout
                  event={event}
                  setTabIndex={setTabIndex}
                  editors={editors}
                ></EventAttendeeAbout>
              </TabItem>
              <TabItem title="Add Your Preferences">
                <EventAttendeePreferences response={response} />
              </TabItem>
              {page === "agenda" ||
              Array.from(editors.values()).filter(
                (editor) => editor && editor.state.doc.textContent.length
              ).length > 1 ? (
                <TabItem title="View Agenda">
                  <EventAttendeeAgenda
                    editors={editors}
                    eventDates={event?.dates || []}
                  ></EventAttendeeAgenda>
                </TabItem>
              ) : (
                <></>
              )}
            </Tabs>
          </div>
        </Row>
      ) : (
        <div className="overall-summary">
          <Error />
        </div>
      )}
    </Container>
  );
};

export default EventAttendeeForm;
