import React, { useState, useEffect } from "react";
import { ReactComponent as Folder } from "../img/folder.svg";
import { ReactComponent as BackIcon } from "../img/arrow-left.svg";

import { Event } from "./EventStatus";
import { Button, Loader } from "@aws-amplify/ui-react";

import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";
import RoomingListChangeRequestModal from "./RoomingListChangeRequestModal";
import { format } from "date-fns";

type EventNote = {
  id: string;
  is_impala: boolean;
  content: string;
  author: string;
  created_at: string;
};

const EventAttendeeRLNotes = (props: {
  event: Event;
  setShowNotes: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [axios] = useAuthenticatedAxios(true);
  const [isLoading, setIsLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [notes, setNotes] = useState<EventNote[]>([]);
  const [showChangeRequestModal, setShowChangeRequestModal] = useState(false);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `/api/users/events/1634${props.event.details.id}/notes?type=RoomingList`
        );
        setNotes(response.data);
      } catch (error) {
        console.error("Error fetching notes", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotes();
  }, [axios, props.event.details.id, requestSent]);

  return (
    <>
      <RoomingListChangeRequestModal
        event={props.event}
        setIsOpen={setShowChangeRequestModal}
        setRequestSent={setRequestSent}
        modalIsOpen={showChangeRequestModal}
      ></RoomingListChangeRequestModal>
      <div>
        <header className="pageHeader attendee-rooming-header">
          <Button
            className="backHeaderLink"
            onClick={() => props.setShowNotes(false)}
          >
            <BackIcon />
            <span className="visually-hidden">Back</span>
          </Button>
          <h4>Requests & Updates</h4>
          <Button
            variation="primary"
            onClick={() => {
              setShowChangeRequestModal(true);
              setRequestSent(false);
            }}
          >
            Request a change
          </Button>
        </header>
        {isLoading ? (
          <Loader variation="linear" />
        ) : notes.length > 0 ? (
          <div className="rooming-list-notes-thread">
            {notes.map((note) => (
              <div
                key={note.id}
                className={
                  note.is_impala
                    ? "impalaNote attendee-note"
                    : "organiserNote attendee-note"
                }
              >
                <aside className="NoteMeta">
                  <div className="NoteAuthor">{note.author}</div>
                  <div className="NoteDate">
                    {format(new Date(note.created_at), "do MMM yyyy hh:mm")}
                  </div>
                </aside>
                <div className="NoteContent">{note.content}</div>
              </div>
            ))}
          </div>
        ) : (
          <div className="event-not-accepted-wrapper">
            <Folder />
            <h2>You've not got any notes yet</h2>
            <p>
              This is where you’ll find all notes and change requests from you
              and your Impala organiser.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default EventAttendeeRLNotes;
