import React, { useEffect, useState } from 'react';
import { Modal, Spinner, Button } from 'react-bootstrap';
import { Scatter } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns'; // Import the date adapter for chart.js
import useAuthenticatedAxios from '../../lib/useAuthenticatedAxios';

const HotelModal = ({ show, onHide, hotelName }) => {
  const [hotelData, setHotelData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [authAxios] = useAuthenticatedAxios(true);

  useEffect(() => {
    if (!show) return;
    const fetchHotelData = async () => {
      try {
        const response = await authAxios.get(`/api/audit/stays-at-hotel/${hotelName}`);
        setHotelData(response.data);
      } catch (err) {
        console.error(`Error fetching stays at hotel ${hotelName}:`, err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    setError(false);
    setHotelData(null);
    fetchHotelData();
  }, [show, hotelName, authAxios]);

  const getChartData = () => {
    const dataPoints = hotelData.hotelStays
    .map(stay => ({
      x: new Date(stay.booking_date),
      y: stay.cost_per_night
    }))
    .sort((a, b) => a.x - b.x);

    const earliestBookingDate = dataPoints[0].x;
    const latestBookingDate = dataPoints[dataPoints.length - 1].x;


    const datasets = [{
      label: 'Cost per Night',
      data: dataPoints,
      backgroundColor: 'rgba(75,192,192,1)'
    }];

    if (hotelData.adr !== null) {
      datasets.push({
        label: 'ADR',
        data: [
          { x: earliestBookingDate, y: hotelData.adr },
          { x: latestBookingDate, y: hotelData.adr }
        ],
        borderColor: 'rgba(255,0,0,1)',
        borderWidth: 2,
        fill: false,
        type: 'line'
      });
    }

    if (hotelData.cnrTarget !== null) {
      datasets.push({
        label: 'CNR Target',
        data: [
            { x: earliestBookingDate, y: hotelData.cnrTarget },
            { x: latestBookingDate, y: hotelData.cnrTarget }
        ],
        borderColor: 'rgba(0,255,0,1)',
        borderWidth: 2,
        fill: false,
        type: 'line'
      });
    }

    return {
      datasets
    };
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{hotelName} Stays</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="spinner-container">
            <Spinner animation="border" />
          </div>
        ) : error ? (
          <div className="error-container">
            Err
          </div>
        ) : (
          <Scatter
            data={getChartData()}
            options={{
              scales: {
                x: {
                  type: 'time',
                  time: {
                    unit: 'month',
                    tooltipFormat: 'MMM d, yyyy',
                  },
                  title: {
                    display: true,
                    text: 'Booking Date',
                  }
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Cost per Night',
                  }
                }
              }
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HotelModal;