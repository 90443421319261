import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import "../../components/Events.css";

import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";
import ImpalaHeader from "../../components/ImpalaHeader/ImpalaHeader";

import { ReactComponent as EventListEmptyIcon } from "../../img/event-list-empty.svg";
import { usePostHog } from "posthog-js/react";

const ExternalBookingList = ({
  isAuthenticated,
  hasExternalBookingServicePermission,
  user,
  userAuth,
  activeServices,
}) => {
  const [authAxios, tokenSet] = useAuthenticatedAxios(true);
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  const posthog = usePostHog();
  posthog.capture("Service Visit", {
    service: "ExternalBookings",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxios.get("/api/events?external=true");
        setEvents(response.data.events.filter((e) => e.hasAccess === true));
      } catch (e) {
        console.error(e);
      }
    };

    if (tokenSet) {
      fetchData();
    }
  }, [tokenSet]);

  return (
    <div className="event-list-wrapper">
      <ImpalaHeader userAuth={userAuth} activeServices={activeServices} />
      <div className="event-list-center">
        <div className="event-list-inner">
          <div className="event-list-header">
            <header className="service-page-title">
              <h5>Onboarding & Interviews</h5>
              <h2>Your bookings</h2>
            </header>
            <Button
              variant="primary"
              onClick={() => navigate("/srv/external/create")}
            >
              Create new booking
            </Button>
          </div>
          <div className="events-list-container">
            {events.length === 0 && (
              <div className="events-list-empty">
                <EventListEmptyIcon />
                <h4>You've not created any bookings yet</h4>
                <p>
                  Start planning your next onboarding or interview. Send invites
                  once you're ready.
                </p>
                <Button
                  onClick={() => navigate("/srv/external/create")}
                  variant="secondary btn-impala-brand"
                >
                  Create new booking
                </Button>
              </div>
            )}
            {events.length > 0 && (
              <div className="events-list-events">
                <table>
                  <thead>
                    <tr>
                      <th>Occasion</th>
                      <th>Guests</th>
                      <th>Location</th>
                      <th className="hide-on-mobile">Dates</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map((event) => (
                      <tr key={event.id}>
                        <td>{event.name || event.eventType}</td>
                        <td>
                          {event.attendees
                            .sort((a, b) =>
                              !a.name || a.name > b.name
                                ? 1
                                : !b.name || b.name > a.name
                                ? -1
                                : 0
                            )
                            .slice(0, 3)
                            .map((attendee) => (
                              <div>
                                {attendee.name ? attendee.name : attendee.email}
                              </div>
                            ))}
                          {event.attendees && event.attendees.length > 3 && (
                            <div>+ {event.attendees.length - 3} more</div>
                          )}
                          {(!event.attendees ||
                            event.attendees.length === 0) && (
                            <div class="no-attendees">None invited yet</div>
                          )}
                        </td>
                        <td>{event.location}</td>
                        <td className="hide-on-mobile">{event.dates}</td>
                        <td className="view-cell">
                          <Button
                            variant="secondary"
                            className="event-view-button"
                            onClick={() =>
                              navigate(`/external/1634${event.id}`)
                            }
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalBookingList;
