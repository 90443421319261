import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import useAuthenticatedAxios from '../../lib/useAuthenticatedAxios';

const RemoveCNRModal = ({ show, onHide, hotelName, city, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const [authAxios] = useAuthenticatedAxios();

  const handleRemoveCNR = async () => {
    setLoading(true);
    try {
      await authAxios.delete('/api/audit/remove-cnr', {
        data: { hotel_name: hotelName, city },
      });
      refreshData(hotelName);
      onHide();
    } catch (err) {
      console.error('Error removing CNR:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Remove Existing CNR?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to remove the existing CNR for {hotelName}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>Cancel</Button>
        <Button
          variant="secondary"
          onClick={handleRemoveCNR}
          disabled={loading}
        >
          {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Remove CNR'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveCNRModal;