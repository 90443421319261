import React, { useState } from "react";
import Modal from "react-modal";
import { Event, EventDate } from "./EventStatus";
import { ReactComponent as UserRemoveIcon } from "../img/user-remove.svg";
import { Button, Form } from "react-bootstrap";
import axios from "axios";

import "./Events.css";
import { format } from "date-fns";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "640px",
  },
};

function DateDeleteModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: Event;
  eventDate: EventDate | undefined;
  onUpdate: Function;
}) {
  const [confirmText, setConfirmText] = useState("");
  Modal.setAppElement("#root");

  function closeModal() {
    setConfirmText("");
    props.setIsOpen(false);
  }

  const handleDelete = async () => {
    try {
      await axios.delete(
        `/api/users/events/1634${props.event.details.id}/dates/${props.eventDate?.id}`
      );
      props.onUpdate();
      closeModal();
    } catch (error) {
      console.error("Error deleting date:", error);
      alert("We could not delete the date, please try again");
    }
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Delete Date"
    >
      <div className="event-management-modal">
        <h4>
          {format(props.eventDate?.date || new Date(), "eee, dd MMM yyyy")}
        </h4>
        <div className="event-attendee-delete-modal-title">
          <h4>
            <UserRemoveIcon />
            Delete this date
          </h4>
          <p>
            Some attendees have already responded for this date. Their responses
            will be deleted as well. You can't undo this action. If you decide
            to add this date later, attendee responses will need to be collected
            again.
          </p>
          <p>Note that attendees will not be notified of this action.</p>
        </div>
      </div>
      <Form.Label for="confirmText">Type 'DELETE' into the field</Form.Label>
      <Form.Control
        type="text"
        id="confirmText"
        value={confirmText}
        onChange={(e) => setConfirmText(e.target.value)}
      />
      <footer className="modalButtonFooter">
        <Button className="btn-secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          className="btn-secondary danger-button"
          onClick={handleDelete}
          disabled={confirmText !== "DELETE"}
        >
          Delete Date
        </Button>
      </footer>
    </Modal>
  );
}

export default DateDeleteModal;
