import { useEffect } from "react";

import useAuthenticatedAxios from "./useAuthenticatedAxios";

export type CompanyDetails = {
  name: string;
  logo_url: string;
  subscribed: boolean;
  expiry: Date;
};

const useCompanyDetails = (
  setCompanyDetails: React.Dispatch<
    React.SetStateAction<CompanyDetails | undefined>
  >,
  refresh: boolean
): void => {
  const [axios, tokenSet] = useAuthenticatedAxios(true);

  useEffect(() => {
    if (tokenSet) {
      const fetchCompanyDetails = async () => {
        try {
          const response = await axios.get(`/api/users/company-details`);
          setCompanyDetails(response.data);
        } catch (error) {
          console.error("Error fetching company details:", error);
        }
      };

      fetchCompanyDetails();
    }
  }, [axios, setCompanyDetails, tokenSet, refresh]);
};

export default useCompanyDetails;
