import React, { useState } from "react";
import { CompanyDetails } from "../lib/useCompanyDetails";
import { Loader } from "@aws-amplify/ui-react";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";
import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import PricingListModal from "./PricingListModal";

const Billing = (props: { companyDetails: CompanyDetails }) => {
  let userAuth = useAuth0();
  const [axios] = useAuthenticatedAxios(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pricingListModalOpen, setPricingListModalOpen] = useState(false);

  const handleOpenBillingPortal = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`/api/users/subscription`, {
        returnURL: window.location.href,
      });
      window.open(response.data.url);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <div>
      {userAuth.user && (
        <PricingListModal
          setIsOpen={setPricingListModalOpen}
          modalIsOpen={pricingListModalOpen}
          user={userAuth.user}
        ></PricingListModal>
      )}
      {isLoading ? (
        <Loader type="linear" />
      ) : (
        <div className="event-account-section">
          <h2>Your plan</h2>
          <div className="event-account-section-content">
            {props.companyDetails.subscribed ? (
              <>
                <div className="account-tier-details">
                  <h3>Impala Events Pro</h3>
                  <div>$20/month</div>
                  <p>You've got access to all the features of Impala Events.</p>
                  <a
                    href="https://impala.travel/impala-events/pricing/"
                    target="_blank"
                  >
                    Learn more about the features in Impala Events
                  </a>
                </div>
                <Button onClick={handleOpenBillingPortal}>
                  Manage my plan and view invoices
                </Button>
              </>
            ) : (
              <>
                <div className="account-tier-details">
                  <h3>Impala Events Basic</h3>
                  <div className="account-status-tier">
                    <span>Free</span>
                  </div>
                  <p>
                    You're on the Basic plan. You have a limit of 1 user
                    account, and a maximum of 2 live events, with 10 attendees
                    maximum.
                  </p>
                  <a
                    href="https://impala.travel/impala-events/pricing/"
                    target="_blank"
                  >
                    Learn more about the features in Impala Events
                  </a>
                </div>
                <Button onClick={() => setPricingListModalOpen(true)}>
                  Upgrade to Events Pro
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Billing;
