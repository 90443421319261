import React, { useEffect, useState } from "react";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import { ReactComponent as KeyDatesEmpty } from "../img/key-dates-empty.svg";

import EventEmpty from "./EventEmpty";
import "./Events.css";
import { Event } from "./EventStatus";

const EventImportantDates = (props: { event: Event | undefined }) => {
  const [importantDates, setImportantDates] = useState<any>([]);
  const [authedAxios, tokenSet] = useAuthenticatedAxios(true);

  useEffect(() => {
    if (!props.event) return;
    const fetchData = async () => {
      const response = await authedAxios.get(
        `/api/users/events/1634${props.event?.details.id}/important-dates`
      );
      setImportantDates(response.data);
    };

    fetchData();
  }, [tokenSet]);

  const ImportantDate = (props: { date: any }) => {
    if (props.date.type === "START_NEGOTIATING_DATE") {
      return (
        <tr>
          <td>{new Date(props.date.date).toUTCString().slice(4, 16)}</td>
          <td>We start negotiating with providers</td>
          <td>
            You should have a good overview of total attendees (within 10-20% of
            final numbers). It's okay if some attendees may not be 100% of which
            days they can attend.
          </td>
          <td>
            If after this date you choose not to move forward with the event, a
            £500 charge will be incurred.
          </td>
        </tr>
      );
    }

    if (props.date.type === "PROVIDER_SELECTION") {
      return (
        <tr>
          <td>{new Date(props.date.date).toUTCString().slice(4, 16)}</td>
          <td>You choose from the providers we've sent</td>
          <td>
            We always negotiate some cancellable rooms for late changes however
            you should have a very good idea of final numbers (within 5%).
          </td>
          <td>
            If you need to cancel more rooms then the minimum we've negotiated,
            you'll have to pay for the rooms.
          </td>
        </tr>
      );
    }

    if (props.date.type === "ROOMING_LIST_AND_PAY") {
      return (
        <tr>
          <td>{new Date(props.date.date).toUTCString().slice(4, 16)}</td>
          <td>We send the hotel a rooming list & you pay the invoice</td>
          <td>No changes can be made through the Impala Event portal</td>
          <td>No rooms can be cancelled.</td>
        </tr>
      );
    }

    if (props.date.type === "EVENT") {
      return (
        <tr>
          <td>{new Date(props.date.date).toUTCString().slice(4, 16)}</td>
          <td>Your event!</td>
          <td>-</td>
          <td>-</td>
        </tr>
      );
    }

    return (
      <tr>
        <td>{new Date(props.date.date).toUTCString().slice(4, 16)}</td>
        <td>{props.date.what_happens}</td>
        <td>{props.date.attendee_certainty}</td>
        <td>{props.date.cant_change_after}</td>
      </tr>
    );
  };

  return (
    <div className="important-dates-wrapper">
      <header className="header-wrapper">
        <h2>Planning schedule</h2>
      </header>
      {!props.event?.realEvent && (
        <div>
          <div className="event-not-accepted-wrapper">
            <KeyDatesEmpty />
            <EventEmpty />
          </div>
        </div>
      )}
      {props.event?.realEvent && (
        <>
          <p className="text-body-small">
            Keep these key dates in mind for your event - these are the dates
            that your Account Manager will need your actions on.
          </p>
          <table className=" attendee-list">
            <thead>
              <tr>
                <th>Date</th>
                <th>What happens?</th>
                <th>Attendee # certainty</th>
                <th>What can't be changed afterwards</th>
              </tr>
            </thead>
            <tbody>
              {importantDates
                .sort((a: any, b: any) => a.date.localeCompare(b.date))
                .map((date: any, index: any) => {
                  return <ImportantDate date={date} key={index} />;
                })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default EventImportantDates;
