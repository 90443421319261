import React from 'react';


const FAQSection = () => {
    return (
     <div className="faq-section">
        <div className="section">
            <h3>Why have we set this up?</h3>
            <div className="white-block">
                <div className="point">
                    <div className="count-circle">1</div>
                    <p>Better travel experience for everyone - we’ve got special rates at hotels that are specifically picked with our offices & our people in mind. </p>
                </div>
                <div className="point">
                    <div className="count-circle">2</div>
                    <p>Saves the company money - these special rates are discounted against what the rest of the public get, and mean that over the year, we’ll be saving considerable amounts on our total travel spend. Means more travel for everyone!</p>
                </div>
            </div>
        </div>
        <div className="section">
            <h3>FAQs</h3>
            <div className="white-block">
                <div className="question">
                    <h6>How do I book these hotels?</h6>
                    <p>Use the link above to View Hotels. You’ll see each of our office locations and the hotel we’ve selected. Choose your dates and you’ll be sent to the hotel website with the discount code applied, we’ll also generate a Virtual Credit Card (VCC) you can use when booking. After that just book as normal on the hotel site.</p>
                </div>
                <div className="question">
                    <h6>Do I have to book?</h6>
                    <p>Yes, you should book these special rates if you are traveling to one of our offices - this will secure consistent savings and value over time.</p>
                </div>
                <div className="question">
                    <h6>Where do we have hotels with special rates?</h6>
                    <p>At the moment, in the cities where we have offices (so Ghent, London, Denver, Singapore, Madrid). However we’re constantly monitoring destinations that might make sense to add to the list!</p>
                </div>
            </div>
        </div>
     </div>   
    )
}

export default FAQSection;