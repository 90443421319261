import React, { useEffect, useState } from "react";

import { ReactComponent as BackIcon } from "../img/arrow-left.svg";

import { Event } from "./EventStatus";

import { Button } from "react-bootstrap";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";

import Select from "react-select";
import { MultiValue } from "react-select";
import Tiptap from "./TipTap";
import { Editor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import Placeholder from "@tiptap/extension-placeholder";
import NotificationPreview from "./NotificationPreview";

type Option = { label: string; value: string };

const extensions = [
  StarterKit,
  Underline,
  Link.configure({
    protocols: ["mailto"],
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Placeholder.configure({
    placeholder: "Customize your email notification text",
  }),
];

const NotifyEventAttendees = (props: {
  openWarningModal: Function;
  event: Event;
  onUpdate: Function;
  selectedAttendees: string[];
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [axios] = useAuthenticatedAxios(true);
  const [options, setOptions] = useState<Option[]>([]);
  const [recipients, setRecipients] = useState<Option[]>([]);
  const [invitingAttendees, setInvitingAttendees] = useState<boolean>(false);
  const [idsToInvite, setIdsToInvite] = useState<string[]>(
    props.event.attendees
      .filter(
        (a) =>
          !a.is_silent &&
          a.book_room &&
          a.dates.find((d) => d.hotel_required && d.assigned_hotel)
      )
      .filter((a) => props.selectedAttendees.includes(a.id))
      .map((a) => a.id)
  );
  const [editor, setEditor] = useState<Editor>();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  useEffect(() => {
    setEditor(
      new Editor({
        extensions: extensions,
        content:
          props.event.marketing?.find(
            (m) => m.type === "attendee_booking_confirmation"
          )?.content ||
          `Great news, you're all set for ${
            props.event.details.name || "your event"
          }. Your accomodation details are included below.`,
        editable: !["Ongoing", "Completed", "Cancelled"].includes(
          props.event.details.status
        ),
      })
    );
  }, [
    props.event.details.name,
    props.event.details.status,
    props.event.marketing,
  ]);

  useEffect(() => {
    setOptions(
      props.event.attendees
        .filter(
          (a) =>
            !a.is_silent &&
            a.book_room &&
            a.dates.find((d) => d.hotel_required && d.assigned_hotel)
        )
        .sort((a, b) => (a?.email < b?.email ? -1 : 1))
        .map((a) => {
          return {
            label: a.name ? `${a.name} <${a.email}>` : a.email,
            value: a.id,
          };
        })
    );
    setRecipients(
      props.event.attendees
        .filter(
          (a) =>
            !a.is_silent &&
            a.book_room &&
            a.dates.find((d) => d.hotel_required && d.assigned_hotel)
        )
        .filter((a) => idsToInvite.includes(a.id))
        .sort((a, b) => (a?.email < b?.email ? -1 : 1))
        .map((a) => {
          return {
            label: a.name ? `${a.name} <${a.email}>` : a.email,
            value: a.id,
          };
        })
    );
  }, [idsToInvite, props.event.attendees, props.selectedAttendees]);

  const handleNewValue = (newValue: MultiValue<Option> | Option[]) => {
    const newOptions: Option[] = structuredClone(newValue);
    setRecipients(newOptions);
    setIdsToInvite(newOptions.map((o) => o.value));
  };

  const openPreview = () => {
    setPreviewOpen(true);
  };

  const handleSend = async () => {
    setInvitingAttendees(true);
    try {
      const response = await axios.post(
        `/api/users/events/1634${props.event.details.id}/send-attendee-confirmation-emails`,
        {
          attendeeIds: recipients.map((r) => r.value),
          json: editor?.getJSON(),
          html: editor?.getHTML(),
        }
      );
      props.onUpdate();
      if (response.data.emailsSent === 0) throw new Error("No emails sent");
      props.openWarningModal(
        "Notifications sent",
        `You sent notification emails to ${response.data.emailsSent} attendee${
          response.data.emailsSent > 1 ? "s" : ""
        }`
      );
      props.setSubPage("Rooming List");
    } catch (error) {
      console.error("Error adding event attendees:", error);
      props.openWarningModal(
        "Notifications could not be sent",
        `We could not send attendee notifications. Please try again.`
      );
    }
    setInvitingAttendees(false);
  };

  return (
    <div>
      <NotificationPreview
        setIsOpen={setPreviewOpen}
        modalIsOpen={previewOpen}
        custom_text={editor?.getHTML() || ""}
        event={props.event}
      ></NotificationPreview>
      <div className="manage-attendees-wrapper">
        <header className="pageHeader">
          <Button
            className="backHeaderLink"
            onClick={() => props.setSubPage("Rooming List")}
          >
            <BackIcon />
            <span className="visually-hidden">Back</span>
          </Button>
          <h2>Send Notification</h2>
          <div className="invite-actions">
            <Button className="btn-secondary" onClick={openPreview}>
              Preview
            </Button>
            <Button onClick={handleSend} disabled={recipients.length < 1}>
              Send Notification Now
            </Button>
          </div>
        </header>
        <div className="attendee-invite-addressbar">
          <label>To: </label>
          <Select
            isMulti
            isLoading={invitingAttendees}
            isClearable
            isDisabled={["Ongoing", "Completed", "Cancelled"].includes(
              props.event.details.status
            )}
            value={recipients}
            options={options}
            onChange={(newValue) => handleNewValue(newValue)}
            id="recipients"
            name="recipients"
            noOptionsMessage={({ inputValue }) => {
              return <>No more attendees to notify</>;
            }}
            placeholder="No attendees selected"
          />
        </div>
        <hr />
        The attendees above will receive a notification email with all the
        relevant details for their hotel stay. This includes a link to their
        attendee page, with an always up-to-date overview. You can customize the
        notification email below.
        <section className="agenda-container">
          <div className="agenda-date-rows">
            <div className="agenda-date">
              <div className="agenda-editor">
                <Tiptap editor={editor}></Tiptap>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NotifyEventAttendees;
