import React from "react";

import { Event } from "./EventStatus";

const EventAttendeePageHeader = (props: { event: Event | undefined }) => {
  return (
    <div className="event-page-header">
      {props.event && props.event.details && (
        <>
          <div className="event-brand-content">
            <img
                className="organiser-logo"
                src={props.event.details.logo_url}
                alt="Event logo"
              />
              <div className="powered-by-badge">
                <p>Powered by</p>
                <a target="_blank" href="http://www.impala.travel"><img className="impala-logo" src="/logo.png" alt="Impala logo" /></a>
              </div>
          </div>
          
          <div>
            <b>You're invited to</b>
            <h1 className="event-invite-title">
              {props.event.details.name || "a team event"}
            </h1>
            <h4>{props.event.details.specific_place}</h4>
          </div>
        </>
      )}
    </div>
  );
};

export default EventAttendeePageHeader;
