import React, { useState, useEffect } from "react";

import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";
import { Loader } from "@aws-amplify/ui-react";

const BillingPortal = () => {
  const [axios] = useAuthenticatedAxios(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchURL = async () => {
      try {
        setSubmitting(true);
        const response = await axios.post(`/api/users/subscription`, {
          returnURL: window.location.href,
        });
        if (response?.data?.url) window.open(response.data.url);
      } catch (err) {
      } finally {
        setSubmitting(false);
      }
    };
    fetchURL();
  }, [axios]);

  return <div>{submitting && <Loader variation="linear" />}</div>;
};

export default BillingPortal;
