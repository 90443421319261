import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import {
  Badge,
  Button,
  Flex,
  Heading,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  View,
} from "@aws-amplify/ui-react";

import BannerAlert from "../../components/BannerAlert/BannerAlert";
import ConfirmSignatureModal from "../../components/ConfirmSignatureModal/ConfirmSignatureModal";
import ContractsEmpty from "../../components/ContractsEmpty/ContractsEmpty";
import {
  format,
  OFFER_TYPE_TOOLTIP,
  OFFERS_OVERVIEW_HEADING,
  OFFERS_OVERVIEW_OFFER_COUNT,
  OFFERS_OVERVIEW_TABLE_COLUMN_LOCATION,
  OFFERS_OVERVIEW_TABLE_COLUMN_MIN_RN,
  OFFERS_OVERVIEW_TABLE_COLUMN_NAME,
  OFFERS_OVERVIEW_TABLE_COLUMN_RATE,
  OFFERS_OVERVIEW_TABLE_OFFER_TYPE,
  SIGN_BUTTON,
  SIGNED,
} from "../../utils/Strings";
import { formatCurrency } from "../../utils/utils";
import useCustomerDeals, { HotelDeal, Offer } from "../../lib/useHotelDeals";
import useCustomers, { Customer } from "../../lib/useCustomers";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";
import ContractUploadModal from "../../components/ConfirmSignatureModal/ContractUploadModal";

function OfferOverview() {
  const [axios] = useAuthenticatedAxios(false);

  const [signatureModalIsOpen, setSignatureModalIsOpen] =
    useState<boolean>(false);
  const [contractUploadModalIsOpen, setContractUploadModalIsOpen] =
    useState<boolean>(false);

  const [offlineContractURL, setOfflineContractURL] = useState<string>("");
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [customerDeals, setCustomerDeals] = useState<HotelDeal[]>([]);
  const [customerDeal, setCustomerDeal] = useState<HotelDeal>();
  const [loadingHotels, setLoadingHotels] = useState<boolean>(true);

  async function openModal(event: any) {
    event.stopPropagation();
    const selectedDeal = customerDeals.find(
      (cd) => cd.id.toString() === event.target.title
    );

    setCustomerDeal(selectedDeal);

    // For Offline contracts, we'll need to get the PDF from the backend
    if (selectedDeal?.is_offline_contract) {
      const contractUrl = await axios.get(
        `/api/users/hotelDeals/${selectedDeal.id}/offline-contract`
      );
      setOfflineContractURL(contractUrl.data.url);
      setContractUploadModalIsOpen(true);
    } else {
      setSignatureModalIsOpen(true);
    }
  }

  useCustomers(setCustomers, setSelectedCustomer, setLoadingHotels);
  useCustomerDeals(
    signatureModalIsOpen || contractUploadModalIsOpen,
    selectedCustomer,
    null,
    setCustomerDeals,
    setLoadingHotels,
    false
  );

  const navigate = useNavigate();
  function goToOffer(offerId: string) {
    navigate("/offers/" + offerId);
  }

  function changeCustomer(event: any) {
    setSelectedCustomer(
      customers.find((c) => c.id.toString() === event.target.value) ||
        selectedCustomer
    );
  }

  /* 
  Finds and formats the best rate from the list of pricing details
  */
  function getBestOffer(offers: (Offer | null)[]): string {
    if (!offers) return "";

    const fixedRates = offers
      .filter((offer) => offer?.offer_type === "Static")
      .map((offer) => Number(offer?.static_rate))
      .sort(function (a, b) {
        return (a ? a : 0) - (b ? b : 0);
      });
    if (fixedRates.length) {
      return (
        (fixedRates.at(0) === fixedRates.at(-1) ? "" : "From ") +
        formatCurrency(fixedRates[0], offers[0]?.static_currency)
      );
    }
    const percentageDiscounts = offers
      .filter((offer) => offer?.offer_type === "Dynamic")
      .map((offer) => Number(offer?.dynamic_percentage))
      .sort(function (a, b) {
        return (b ? b : 0) - (a ? a : 0);
      });
    if (percentageDiscounts.length) {
      return (
        (percentageDiscounts.at(0) === percentageDiscounts.at(-1)
          ? ""
          : "Up to ") +
        percentageDiscounts[0]?.toString() +
        "%"
      );
    }
    return "";
  }

  return (
    <View className="PageContent">
      <ConfirmSignatureModal
        modalIsOpen={signatureModalIsOpen}
        setIsOpen={setSignatureModalIsOpen}
        customerDealId={customerDeal?.id || 0}
        hotelName={customerDeal?.hotel.name || ""}
      ></ConfirmSignatureModal>
      <ContractUploadModal
        contractUrl={offlineContractURL}
        modalIsOpen={contractUploadModalIsOpen}
        setIsOpen={setContractUploadModalIsOpen}
        customerDealId={customerDeal?.id || 0}
        hotelName={customerDeal?.hotel.name || ""}
      ></ContractUploadModal>
      {customers && customers.length > 1 && (
        <select onChange={changeCustomer} value={selectedCustomer?.id}>
          {customers.map((customer) => (
            <option value={customer.id}>{customer.name}</option>
          ))}
        </select>
      )}
      {customerDeals.length ? (
        <Flex direction="column">
          <Heading level={4}>{OFFERS_OVERVIEW_HEADING}</Heading>
          {customerDeals.filter((cd) => !cd.signed_by_corporate).length > 0 && (
            <BannerAlert variant="BannerHelp">
              {format(OFFERS_OVERVIEW_OFFER_COUNT, [
                customerDeals
                  .filter((cd) => !cd.signed_by_corporate)
                  .length.toString(),
              ])}
            </BannerAlert>
          )}
          <Table
            className="offerTable ImpalaTable"
            caption=""
            highlightOnHover={true}
            variation="striped"
          >
            <TableHead>
              <Tooltip id="offer_type" />
              <TableRow>
                <TableCell as="th">
                  {OFFERS_OVERVIEW_TABLE_COLUMN_NAME}
                </TableCell>
                <TableCell as="th">
                  {OFFERS_OVERVIEW_TABLE_COLUMN_LOCATION}
                </TableCell>
                <TableCell as="th">
                  {OFFERS_OVERVIEW_TABLE_COLUMN_MIN_RN}
                </TableCell>
                <TableCell
                  as="th"
                  data-tooltip-id="offer_type"
                  data-tooltip-place="top"
                  data-tooltip-html={OFFER_TYPE_TOOLTIP}
                >
                  {OFFERS_OVERVIEW_TABLE_OFFER_TYPE}
                </TableCell>
                <TableCell as="th">
                  {OFFERS_OVERVIEW_TABLE_COLUMN_RATE}
                </TableCell>
                <TableCell as="th"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerDeals.map((customerDeal) => (
                <TableRow
                  key={customerDeal.id}
                  onClick={() => {
                    goToOffer(customerDeal.id.toString());
                  }}
                >
                  <TableCell>{customerDeal.hotel.name}</TableCell>
                  <TableCell>
                    {customerDeal.hotel.address
                      ? customerDeal.hotel.address
                          .split(",")
                          .slice(-3)
                          .filter((x) => x.toUpperCase() !== x)
                          .map((x) =>
                            x
                              .replaceAll(/\d/g, "")
                              .replaceAll(/[A-Z]{2,}/g, "")
                              .trim()
                          )
                          .slice(-2)
                          .join(", ")
                      : ""}
                  </TableCell>
                  <TableCell>
                    {customerDeal.minimum_room_nights || "N/A"}
                  </TableCell>
                  <TableCell>{customerDeal.type}</TableCell>
                  <TableCell>{getBestOffer(customerDeal.offers)}</TableCell>
                  <TableCell>
                    {!customerDeal.signed_by_corporate ? (
                      customerDeal.is_offline_contract ? (
                        <Button
                          title={customerDeal.id.toString()}
                          onClick={openModal}
                        >
                          {SIGN_BUTTON}
                        </Button>
                      ) : (
                        <a
                          href={customerDeal.contract_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            title={customerDeal.id.toString()}
                            onClick={openModal}
                          >
                            {SIGN_BUTTON}
                          </Button>
                        </a>
                      )
                    ) : (
                      <Badge size="large" variation="info">
                        {SIGNED}
                      </Badge>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Flex>
      ) : loadingHotels ? (
        <Loader variation="linear" />
      ) : (
        <ContractsEmpty />
      )}
    </View>
  );
}

export default OfferOverview;
