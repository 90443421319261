import React from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';


import { ReactComponent as PermissionKeys } from '../../img/permission-keys.svg';
const NoPermission = ({
    user,
    headline,
    logout,
}) => {
    const navigate = useNavigate();

    return (
        <div className="no-permission-wrapper">
            <div className="no-permission-content">
                <PermissionKeys />
                <div>
                    <h1>{headline}</h1>
                    <p>The account you're logged in with doesn't have permission to view this screen.</p>
                </div>
                <div className="who-logged-in-as">
                    {!user && (
                        <>
                            <p>{`You are not logged in`}</p>   
                            <Button
                                onClick={() => navigate('/')}
                                variant="primary"
                            >
                                Log In
                            </Button>    
                        </>
                    )}
                    {user && (
                        <>
                            <p>{`You are logged in as ${user?.email}`}</p>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    logout({
                                        logoutParams: {
                                            returnTo: window.location.origin + "/callback",
                                        },
                                    });
                                }}
                                variant="primary"
                            >
                                Log Out
                            </Button>    
                        </>
                    )}
            
                </div>
                <p>Think you shouldn't be seeing this? <a href="mailto:support@impala.travel">Let us know.</a></p>
            </div>
        </div>
    );
}

export default NoPermission;
