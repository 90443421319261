import React, { useState } from "react";
import Select from "react-select";

import { ReactComponent as AttendanceRequired } from "../img/attendees.svg";
import { ReactComponent as AddDate } from "../img/dates-add.svg";
import { ReactComponent as RemoveDate } from "../images/icons/dates-remove.svg";
import { ReactComponent as TravelDate } from "../img/walking-black.svg";
import { format, addDays, subDays } from "date-fns";

import { Button } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

import { Event, EventDate, EventDateType } from "./EventStatus";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";
import DateDeleteModal from "./DateDeleteModal";

const eventOptions = [
  {
    value: EventDateType.TRAVEL,
    label: (
      <div>
        <TravelDate className="required-icon" /> Non-event Date
      </div>
    ),
  },
  {
    value: EventDateType.REQUIRED,
    label: (
      <div>
        <AttendanceRequired className="required-icon" /> Event Date
      </div>
    ),
  },
];

const EventDates = (props: {
  event: Event;
  onUpdate: Function;
  setSelectedDate: React.Dispatch<React.SetStateAction<number>>;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [axios] = useAuthenticatedAxios(true);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dateToDelete, setDateToDelete] = useState<EventDate>();

  const handleDateTypeChange = async (id: number, type: string) => {
    try {
      setIsSubmitting(true);
      const body = { type: type };
      await axios.put(
        `/api/users/events/1634${props.event.details.id}/dates/${id}/type`,
        body
      );
      props.onUpdate();
    } catch (error) {
      alert("Error updating date type, please try again later");
      console.error("Error updating date type:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddDate = async (before: boolean) => {
    try {
      const referenceDate =
        props.event.dates[before ? 0 : props.event.dates.length - 1];
      const newDate = new Date(referenceDate.date);
      newDate.setDate(newDate.getDate() + (before ? -1 : 1));

      setIsSubmitting(true);
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/dates`,
        {
          dates: [{ date: format(newDate, "yyyy-MM-dd"), type: "Required" }],
        }
      );
      props.onUpdate();
    } catch (error) {
      alert("Error adding dates, please try again later");
      console.error("Error adding travel dates:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSetDateRestrictions = (id: number) => {
    props.setSelectedDate(id);
    props.setSubPage("Set Restrictions");
  };

  const handleDeleteDate = async (date: EventDate) => {
    const hasResponses = props.event.attendees.find((a) =>
      a.dates.find(
        (ad) => ad.date === date.id && (ad.hotel_required || ad.attending)
      )
    );
    if (hasResponses) {
      setDateToDelete(date);
      setDeleteModalOpen(true);
    } else {
      try {
        setIsSubmitting(true);
        await axios.delete(
          `/api/users/events/1634${props.event.details.id}/dates/${date.id}`
        );
        props.onUpdate();
      } catch (error) {
        alert("Error removing dates, please try again later");
        console.error("Error removing travel dates:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="manageDates">
      <DateDeleteModal
        modalIsOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        onUpdate={props.onUpdate}
        event={props.event}
        eventDate={dateToDelete}
      />
      <div>
        You can add or remove event and non-event dates, as well as restrict who
        can book hotel stays. Your attendees will see this information on the
        response form.
        <section className="eventDatesSettings">
          {props.event.dates.length >= 30 && (
            <Tooltip className="eventTooltip" id="enoughTravelDatesBefore" />
          )}
          <div
            data-tooltip-id={
              props.event.dates.length >= 30 ? "enoughTravelDatesBefore" : ""
            }
            data-tooltip-place="left"
            data-tooltip-html="<h3>Date Limit<h3><p>You can add a maximum of 30 days for your event. Please get in touch if this clashes with your requirements.</p>"
          >
            <Button
              disabled={
                isSubmitting ||
                props.event.dates.length >= 30 ||
                ["Ongoing", "Completed", "Cancelled"].includes(
                  props.event.details.status
                )
              }
              onClick={() => handleAddDate(true)}
              className="add-date-button"
            >
              <AddDate /> Add Date for{" "}
              {format(
                subDays(props.event.dates[0]?.date, 1),
                "eee, dd MMM yyyy"
              )}
            </Button>
          </div>
          <div className="eventDatesItems">
            {props.event.dates
              .sort((a, b) =>
                new Date(a.date.toString()) > new Date(b.date.toString())
                  ? 1
                  : -1
              )
              .map((date, dateIndex) => (
                <div className="eventDateRow" key={date.id}>
                  <div className="eventDateElement">
                    <h3>{format(date.date, "eee, dd MMM yyyy")}</h3>
                    <p className="booked-dates">
                      {date.type !== "Travel" && (
                        <>
                          {
                            props.event.attendees.filter(
                              (a) =>
                                a.dates.find((d) => d.date === date.id)
                                  ?.attending
                            ).length
                          }{" "}
                          confirmed &#65106;{" "}
                        </>
                      )}
                      {
                        props.event.attendees
                          .filter((a) => a.book_room)
                          .filter(
                            (a) =>
                              a.dates.find((d) => d.date === date.id)
                                ?.hotel_required
                          ).length
                      }{" "}
                      requested hotel
                    </p>
                    {(dateIndex === 0 ||
                      dateIndex === props.event.dates.length - 1) && (
                      <Button
                        className="remove-travel-date-button"
                        disabled={
                          ["Ongoing", "Completed", "Cancelled"].includes(
                            props.event.details.status
                          ) || isSubmitting
                        }
                        onClick={() => handleDeleteDate(date)}
                      >
                        <RemoveDate />
                        <span className="visually-hidden">
                          Remove this date
                        </span>
                      </Button>
                    )}
                  </div>
                  <div
                    className="eventDateElement"
                    data-tooltip-id="dateTypeToolTip"
                    data-tooltip-place="top"
                    data-tooltip-html="<h3>Setting date types<h3><p>By default any date you add will be marked as an 'Event Date'. You can add an agenda for these dates, and attendees can mark their attendance.</p>
                      <p>If you want to include social/travel/rest days, or allow attendees to request hotels outside of your core event days you can mark them as a 'Non-event Date'.</p>"
                  >
                    <Tooltip className="eventTooltip" id="dateTypeToolTip" />
                    <Select
                      id={date.id.toString()}
                      className="date-type-select"
                      classNamePrefix="date-type-select-element"
                      isDisabled={[
                        "Ongoing",
                        "Completed",
                        "Cancelled",
                      ].includes(props.event.details.status)}
                      options={eventOptions}
                      defaultValue={eventOptions.find(
                        (o) => o.value === date.type
                      )}
                      onChange={(e) =>
                        handleDateTypeChange(date.id, e?.value || "Required")
                      }
                    />
                  </div>
                  <div className="eventDateElement">
                    <div
                      className={
                        !date.hotel_required
                          ? "restriction-setting restrictions-set"
                          : date.max_people ||
                            date.max_people === 0 ||
                            date.allow_list?.length > 0
                          ? "restriction-setting restrictions-set"
                          : "restriction-setting no-hotel-restrictions"
                      }
                      onClick={() => handleSetDateRestrictions(dateIndex)}
                    >
                      {!date.hotel_required
                        ? "No hotel booking"
                        : date.max_people ||
                          date.max_people === 0 ||
                          date.allow_list?.length > 0
                        ? "Restrictions apply"
                        : "No Restrictions"}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {props.event.dates.length >= 30 && (
            <Tooltip className="eventTooltip" id="enoughTravelDatesAfter" />
          )}
          <div
            data-tooltip-id={
              props.event.dates.length >= 30 ? "enoughTravelDatesAfter" : ""
            }
            data-tooltip-place="left"
            data-tooltip-html="<h3>Date Limit<h3><p>You can add a maximum of 30 days for your event. Please get in touch if this clashes with your requirements.</p>"
          >
            <Button
              disabled={
                ["Ongoing", "Completed", "Cancelled"].includes(
                  props.event.details.status
                ) ||
                isSubmitting ||
                props.event.dates.length >= 30
              }
              onClick={() => handleAddDate(false)}
              className="add-date-button"
            >
              <AddDate /> Add Date for{" "}
              {format(
                addDays(props.event.dates.slice(-1)[0]?.date, 1),
                "eee, dd MMM yyyy"
              )}
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EventDates;
