import React from "react";
import { Tooltip } from "react-tooltip";

import {
  Expander,
  ExpanderItem,
  Heading,
  Link,
  Text,
  View,
} from "@aws-amplify/ui-react";

import { SETUP_HEADER } from "../utils/Strings";
import { formatCurrency, percentageOffPublic } from "../utils/utils";
import BannerAlert from "./BannerAlert/BannerAlert";
import SetupOfferTable from "./SetupOfferTable";
import { HotelDeal } from "../lib/useHotelDeals";
import { Customer } from "../lib/useCustomers";
import { useAuth0 } from "@auth0/auth0-react";

function TravelPerkSetup(props: {
  hotelDeals: HotelDeal[];
  customer: Customer;
  requestSetup: () => void;
}) {
  const { user } = useAuth0();

  function getCompanyEmail(): string {
    if (!props.customer.name) return "contracting@impala.travel";
    return (
      props.customer.name?.replaceAll(/[\W]+/g, "").toLowerCase() +
      "@impala.travel"
    );
  }

  function getToAddress(): string {
    return "sales@travelperk.com";
  }

  function getSubject(): string {
    return "Corporate rate setup for " + props.customer.name;
  }

  function getBody(): string {
    let body =
      "Dear TravelPerk team,%0D%0A%0D%0A" +
      "We have negotiated corporate rates with the following hotels:%0D%0A%0D%0A";

    for (const hotelDeal of props.hotelDeals) {
      if (!hotelDeal.setup_complete) {
        body += hotelDeal.hotel.name;
        if (hotelDeal.hotel.address)
          body +=
            ", " +
            hotelDeal.hotel.address
              .split(",")
              .slice(-3)
              .filter((x) => x.toUpperCase() !== x)
              .map((x) =>
                x
                  .replaceAll(/\d/g, "")
                  .replaceAll(/[A-Z]{2,}/g, "")
                  .trim()
              )
              .slice(-2)
              .join(", ");
        body += "%0D%0A";
      }
    }

    body +=
      "%0D%0AThese rates are set to be bookable via the GDS, " +
      "and we would want these rates to be available to our travelling employees. " +
      "Can you help us set these rates to be booked via our TravelPerk account?%0D%0A%0D%0A";

    body +=
      "The rate details, and hotel contact points are mentioned below:%0D%0A%0D%0A";

    for (const hotelDeal of props.hotelDeals) {
      if (!hotelDeal.setup_complete) {
        body +=
          hotelDeal.hotel.name +
          " - Contact: " +
          hotelDeal.hotel.contact_name +
          " (" +
          hotelDeal.hotel.contact_email +
          ")%0D%0A";
        for (const offer of hotelDeal.offers) {
          body += offer.room_type;
          body += "; " + offer.flexibility;
          body += "; " + offer.meal_plan;
          body +=
            "; " +
            new Date(offer.season_start_date).toDateString() +
            " - " +
            new Date(offer.season_end_date).toDateString();
          body += "; ";
          body +=
            offer.offer_type === "Static"
              ? formatCurrency(offer.static_rate, offer.static_currency)
              : percentageOffPublic(offer.dynamic_percentage);
          body += "%0D%0A";
        }
        body += "%0D%0A";
      }
    }

    body +=
      "Please reply to this email for any questions. ";

    body += "Thanks!%0D%0A%0D%0A";

    body += props.customer.name;

    return body;
  }

  function getMailToHref(): string {
    return (
      "mailto:" +
      getToAddress() +
      "?cc=" +
      getCompanyEmail() +
      "&subject=" +
      getSubject() +
      "&body=" +
      getBody().replaceAll("&", "%26")
    );
  }

  return (
    <View>
      <Heading className="SetupHeader" level={4}>
        {SETUP_HEADER}
      </Heading>
      {props.hotelDeals.filter((hotelDeal) => !hotelDeal.setup_complete)
        .length === 0 && (
        <BannerAlert variant="BannerHelp">
          You don't have any offers needing setup.
        </BannerAlert>
      )}
      <View className="SetupInstructions">
        <View>
          <View className="SetupPanel">
            <View className="SetupText">
              <Heading level={5}>Using TravelPerk</Heading>
              <Text>
                <br />
                In order to activate your rates you will need to message your TravelPerk account manager. Follow the steps below to setup your signed rates.
                <br />
              </Text>
              <Tooltip id="clipboard">Click to copy to Clipboard</Tooltip>
              <ol>
                <li>
                  Add&nbsp;
                  <Link
                    data-tooltip-id="clipboard"
                    onClick={() => {
                      navigator.clipboard.writeText(getCompanyEmail());
                    }}
                  >
                    {getCompanyEmail()}
                  </Link>
                  &nbsp;as an Admin user to your TravelPerk account. This allows us to monitor the rate upload.
                </li>
                <li>
                  Copy the email detail below and send to your TravelPerk Account Manager. <b>You should</b> also attach your signed contract. Let us know if you don't have it.
                </li>
              </ol>
            </View>

            {props.hotelDeals.filter((hotelDeal) => !hotelDeal.setup_complete)
              .length > 0 && (
              <View>
                {/* <Link
                  className="SetupAction"
                  href={getMailToHref()}
                  onClick={props.requestSetup}
                >
                  Send from my email app
                </Link> */}
                <Expander
                  className="SetupAction"
                  type="single"
                  isCollapsible={true}
                >
                  <ExpanderItem
                    onClick={props.requestSetup}
                    title="Email contents to send to TravelPerk"
                    value="email_contents"
                  >
                    <Text>To: {getToAddress()}</Text>
                    <Text>CC: {getCompanyEmail()}</Text>
                    <Text>Subject: {getSubject()}</Text>
                    <br />
                    {getBody()
                      .split("%0D%0A")
                      .map((paragraph) => (
                        <Text>
                          {paragraph}
                          <br />
                        </Text>
                      ))}
                  </ExpanderItem>
                </Expander>
              </View>
            )}
          </View>
        </View>
        <SetupOfferTable hotelDeals={props.hotelDeals} />
      </View>
    </View>
  );
}

export default TravelPerkSetup;
