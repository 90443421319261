import React from "react";
import Modal from "react-modal";
import { Event, EventAttendee } from "./EventStatus";
import { Button } from "react-bootstrap";

import "./Events.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "550px",
  },
};

function AttendeePreferencesModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: Event;
  eventAttendee: EventAttendee | undefined;
}) {
  function closeModal() {
    props.setIsOpen(false);
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="View attendee preferences"
    >
      <div className="event-management-modal">
        <h4>{props.eventAttendee?.name || "Attendee"} preferences</h4>
        {props.eventAttendee && (
          <div>
            <label>Do you have any room preferences?</label>
            <p>{props.eventAttendee.room_preferences || "No."}</p>
            <label>Bringing someone else for the hotel stay?</label>
            <p>{props.eventAttendee.plus_one ? "Yes." : "No."}</p>
            <label>Preferred room setup</label>
            <p>
              {props.eventAttendee.prefers_twin === false
                ? "Double Room"
                : props.eventAttendee.prefers_twin === true
                ? "Twin Room"
                : "No preference."}
            </p>
            {props.eventAttendee.must_share_room && (
              <>
                <label>Do you have any room sharing preferences?</label>
                <p>{props.eventAttendee.sharing_preferences || "No."}</p>
              </>
            )}
          </div>
        )}
        <Button onClick={closeModal}>Close</Button>
      </div>
    </Modal>
  );
}

export default AttendeePreferencesModal;
