import React, { useState } from "react";
import Modal from "react-modal";
import { Button, Form } from "react-bootstrap";
import { Event } from "./EventStatus";

import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "640px",
  },
};

function RoomingListChangeRequestModal(props: {
  modalIsOpen: boolean;
  event: Event;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRequestSent: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  Modal.setAppElement("#root");

  const [axios] = useAuthenticatedAxios(true);
  const [newNote, setNewNote] = useState("");

  function closeModal() {
    props.setIsOpen(false);
  }

  const handleSubmitNote = async () => {
    try {
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/notes?type=RoomingList`,
        {
          content: newNote,
        }
      );
      setNewNote("");
      props.setRequestSent(true);
      closeModal();
    } catch (e) {
      console.error(e);
      alert("Could not submit change request. Please try again later.");
    }
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Request a change"
    >
      <div className="event-management-modal">
        <h4>Request a change</h4>
        <div className="event-attendee-delete-modal-title">
          <p>
            Need to change something on your rooming list? Let us know what
            needs updated and we’ll send a revised list over.
          </p>
        </div>
        <Form.Label>Describe what you want to change</Form.Label>
        <Form.Control
          disabled={["Ongoing", "Completed", "Cancelled"].includes(
            props.event.details.status
          )}
          as="textarea"
          rows={4}
          id="newNote"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />
      </div>
      <footer className="modalButtonFooter">
        <Button className="btn-secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={["Ongoing", "Completed", "Cancelled"].includes(
            props.event.details.status
          )}
          className="btn-primary"
          onClick={handleSubmitNote}
        >
          Send Request
        </Button>
      </footer>
    </Modal>
  );
}

export default RoomingListChangeRequestModal;
