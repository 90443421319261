import React from "react";

import {
  Heading,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
  View,
} from "@aws-amplify/ui-react";

import { ReactComponent as IconLink } from "../images/icons/link.svg";
import { ReactComponent as IconMail } from "../images/icons/mail.svg";
import { ReactComponent as IconUnlink } from "../images/icons/unlink.svg";
import { HotelDeal } from "../lib/useHotelDeals";

function SetupOfferTable(props: { hotelDeals: HotelDeal[] }) {
  return (
    <View className="SetupOffersTable">
      {props.hotelDeals.filter((hotelDeal) => hotelDeal.setup_complete).length >
        0 && (
        <View>
          <Heading level={6}>Live Rates</Heading>
          <Table>
            <TableBody>
              {props.hotelDeals
                .filter((hotelDeal) => hotelDeal.setup_complete)
                .map((hotelDeal) => (
                  <TableRow key={hotelDeal.id}>
                    <TableCell>
                      <Text className="OfferLive">
                        <IconLink />
                        <span className="visually-hidden">Live</span>
                      </Text>
                    </TableCell>
                    <TableCell>{hotelDeal.hotel.name}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </View>
      )}
      {props.hotelDeals.filter((hotelDeal) => !hotelDeal.setup_complete)
        .length > 0 && (
        <View>
          <Heading level={6}>Rates signed but awaiting setup</Heading>
          <Table>
            <TableBody>
              {props.hotelDeals
                .filter((hotelDeal) => !hotelDeal.setup_complete)
                .map((hotelDeal) => (
                  <TableRow key={hotelDeal.id}>
                    <TableCell>
                      {hotelDeal.setup_requested ? (
                        <Text className="OfferRequested">
                          <IconMail />
                          <span className="visually-hidden">Requested</span>
                        </Text>
                      ) : (
                        <Text className="OfferSigned">
                          <IconUnlink />
                          <span className="visually-hidden">Signed</span>
                        </Text>
                      )}
                    </TableCell>
                    <TableCell>{hotelDeal.hotel.name}</TableCell>
                    <TableCell>
                      {hotelDeal.setup_requested ? "Rate Setup Requested" : ""}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </View>
      )}
      {props.hotelDeals.length === 0 && (
        <Text>You have no signed offers yet</Text>
      )}
    </View>
  );
}

export default SetupOfferTable;
