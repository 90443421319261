import React from "react";

import {
  Heading,
  Table,
  TableBody,
  TableCell,
  TableRow,
  View,
} from "@aws-amplify/ui-react";

import {
  DIRECT_SETUP_HEADER,
  SETUP_BOOKING_INSTRUCTIONS,
  SETUP_WEBSITE,
} from "../utils/Strings";
import { HotelDeal } from "../lib/useHotelDeals";

function DirectSetup(props: { hotelDeals: HotelDeal[] }) {
  return (
    <View>
      <Heading level={5}>{DIRECT_SETUP_HEADER}</Heading>
      <Table>
        <TableBody>
          {props.hotelDeals
            .filter((hd) => hd.signed_by_corporate)
            .map((hotelDeal) => (
              <TableRow key={hotelDeal.id}>
                <TableCell>{hotelDeal.hotel.name}</TableCell>
                <TableCell>
                  {SETUP_WEBSITE}
                  <br />
                  {hotelDeal.hotel.website_url}
                </TableCell>
                <TableCell>
                  {SETUP_BOOKING_INSTRUCTIONS}
                  <br />
                  {hotelDeal.booking_instructions}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </View>
  );
}

export default DirectSetup;
