import React, { useEffect, useState } from "react";
import {
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Button,
  Loader,
} from "@aws-amplify/ui-react";
import { Container, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import Error from "../../components/Error";
import { ReactComponent as RoomingList } from "../../img/rooming-list.svg";
import { LogoWordmark } from "../../components/ImpalaLogo/ImpalaLogo";
import { ReactComponent as Badge } from "../../img/badge.svg";
import BannerAlert from "../../components/BannerAlert/BannerAlert";

import "../../components/Events.css";
import "../../components/attendeeForm.css";
import { Tooltip } from "react-tooltip";

import { CSVLink } from "react-csv";

type CSVHeader = { label: string; key: string };

type Event = {
  event_name: string;
  customer_name: string;
  hotel_name: string;
  plus_ones_covered: boolean;
  is_procured: boolean;
};

type Attendee = {
  name: string;
  email: string;
  room_preferences: string[];
  sharing_name: string;
  plus_one: boolean;
  room_setup: string;
  checkIn: string;
  checkOut: string;
  booking_reference: string;
  ead_ids: string[];
};

type EventHotelData = {
  event: Event;
  attendees: Attendee[];
  organisers: string[];
};

const EventHotelPage = () => {
  const { eventId } = useParams();
  const [searchParams] = useSearchParams();
  const [onUpdate, setOnUpdate] = useState<boolean>(false);
  const [exportAttendees, setExportAttendees] = useState<any[]>([]);
  const [CSVHeaders, setCSVHeaders] = useState<CSVHeader[]>([]);
  const [loadingEventData, setLoadingEventData] = useState(true);
  const [bookingReference, setBookingReference] = useState("");
  const [listAddedToPMS, setListAddedToPMS] = useState(false);
  const [data, setData] = useState<EventHotelData>();
  const hotelPageId = searchParams.get("pageId");
  const src = searchParams.get("src");

  useEffect(() => {
    const prepareAttendeesForExport = () => {
      let headers: CSVHeader[] = [
        { label: "Name(s)", key: "name" },
        { label: "Room Type", key: "room_type" },
        { label: "Notes & Preferences", key: "notes" },
        { label: "Check-In", key: "check_in" },
        { label: "Check-Out", key: "check_out" },
      ];

      setCSVHeaders(headers);

      const ea = data?.attendees
        .sort((a, b) => (a.checkOut < b.checkOut ? -1 : 1))
        .sort((a, b) => (a.checkIn < b.checkIn ? -1 : 1))
        .map((attendee) => {
          const names = [];
          names.push(attendee.name || "Name TBD");
          if (attendee.sharing_name) names.push(attendee.sharing_name);
          if (attendee.plus_one) names.push("One Extra Guest");
          const attendeeValues: any = {
            name: names.join("\n"),
            room_type: attendee.room_setup,
            notes: attendee.room_preferences.join("\n"),
            room_preferences: attendee.room_preferences || "",
            check_in: attendee.checkIn,
            check_out: attendee.checkOut,
          };
          return attendeeValues;
        });
      setExportAttendees(ea || []);
    };

    prepareAttendeesForExport();
  }, [data?.attendees]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        setLoadingEventData(true);
        const response = await axios.get(
          `/api/event-external/events/${eventId}/hotels/${hotelPageId}`
        );
        setData(response.data);
        setBookingReference(response.data.event.booking_reference);
        if (src === "email")
          await axios.post(
            `/api/event-external/events/${eventId}/hotels/${hotelPageId}`,
            { viewed: true }
          );
      } catch (error) {
        console.error("Error fetching event details:", error);
      } finally {
        setLoadingEventData(false);
      }
    };

    fetchEventDetails();
  }, [hotelPageId, eventId, src, onUpdate]);

  const handleAck = async () => {
    try {
      setLoadingEventData(true);
      const room_booking_references = data?.attendees.map((a: Attendee) => {
        return { reference: a.booking_reference || "", ead_ids: a.ead_ids };
      });
      await axios.post(
        `/api/event-external/events/${eventId}/hotels/${hotelPageId}`,
        {
          confirmed: src !== "tracker",
          booking_reference: bookingReference,
          room_booking_references: room_booking_references,
        }
      );
      setListAddedToPMS(true);
    } catch (error) {
      console.error("Error submitting hotel confirmation:", error);
    } finally {
      setLoadingEventData(false);
      setOnUpdate(!onUpdate);
    }
  };

  const calculateNightsStay = (
    checkInDate: string,
    checkOutDate: string
  ): number => {
    const checkIn = new Date(checkInDate);
    const checkOut = new Date(checkOutDate);
    const differenceInTime = checkOut.getTime() - checkIn.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays;
  };

  const calculateTotalNights = (attendees: Attendee[]): number => {
    return attendees
      .filter((a) => a.name)
      .reduce((total: number, attendee: Attendee) => {
        return total + calculateNightsStay(attendee.checkIn, attendee.checkOut);
      }, 0);
  };

  const updateBookingReference = (
    checkIn: string,
    checkOut: string,
    email: string,
    value: string
  ) => {
    const newData = structuredClone(data);
    const attendee = newData?.attendees.find(
      (a: Attendee) =>
        a.email === email && a.checkIn === checkIn && a.checkOut === checkOut
    );
    if (attendee) attendee.booking_reference = value;
    setData(newData);
  };

  const calculateTotalGuests = (attendees: Attendee[]): number => {
    return attendees
      .filter((a) => a.name)
      .reduce((total: number, attendee: Attendee) => {
        // Every attendee is one guest
        total += 1;

        // If the attendee is sharing with someone, add one more guest
        if (attendee.sharing_name || attendee.plus_one) {
          total += 1;
        }

        return total;
      }, 0);
  };

  document.title = "Rooming List";

  return (
    <Container className="attendee-form" fluid>
      <Row className="full-height-row hotel-rooming-list">
        {loadingEventData ? (
          <Loader variation="linear" />
        ) : data ? (
          <Col className="hotel-rooming-list-content">
            <div className="hotel-rooming-list-info">
              <LogoWordmark variant="primaryDark" />
              <div className="hotel-rooming-list-info-content">
                <RoomingList />
                <h1>Rooming List</h1>
                <h4>{data.event.hotel_name}</h4>
                <p>
                  Here’s the list of {data.event.customer_name} employees who’re
                  staying at {data.event.hotel_name} for their event.
                </p>
                {data.attendees.filter((attendee) => attendee.plus_one).length >
                  0 && (
                  <p>
                    Attendees marked with <Badge /> are bringing one additional
                    guest.
                    {data.event.plus_ones_covered
                      ? ""
                      : " Where this incurs an additional occupancy fee, it should be settled by the guest on arrival."}
                  </p>
                )}
                <p>
                  Once you’ve added these guests to your PMS please add their
                  confirmation numbers/codes in the list and confirm using the
                  button below.
                </p>
                {data.event.is_procured ? (
                  <p>
                    If you've got any issues, get in touch with us at{" "}
                    <a
                      href={`mailto:support@impala.travel?subject=Query from ${data.event.hotel_name} for Event ${eventId}`}
                    >
                      support@impala.travel
                    </a>
                  </p>
                ) : (
                  <p>
                    If you've got any issues, get in touch with the{" "}
                    <a
                      href={`mailto:${data.organisers.join(
                        ","
                      )}?subject=Query from ${
                        data.event.hotel_name
                      } for Event ${data.event.event_name}`}
                    >
                      event organiser{data.organisers.length > 1 ? "s" : ""}
                    </a>
                    .
                  </p>
                )}
                <Form.Label>Group Booking ID</Form.Label>
                <Form.Control
                  value={bookingReference}
                  onChange={(e) => setBookingReference(e.target.value)}
                ></Form.Control>
                <Button disabled={!src} variation="primary" onClick={handleAck}>
                  I've added the rooming list to my PMS
                </Button>
              </div>
            </div>
            <div className="hotel-rooming-list-data">
              {listAddedToPMS && (
                <BannerAlert variant="BannerPositive">
                  Thanks for confirming the rooming list!
                </BannerAlert>
              )}
              <div className="hotel-rooming-list-summary">
                <div className="hotel-rooming-list-summary-row">
                  <span>Room nights</span>
                  <span>{calculateTotalNights(data.attendees)}</span>
                </div>
                <div className="hotel-rooming-list-summary-row">
                  <span>Total Guests</span>
                  <span>{calculateTotalGuests(data.attendees)}</span>
                </div>
                <div className="hotel-rooming-list-summary-row">
                  <div className="summaryBarItem export-btn">
                    <CSVLink
                      headers={CSVHeaders}
                      data={exportAttendees}
                      filename="RoomingList.csv"
                      target="_blank"
                      className="btn btn-secondary"
                    >
                      <span>Export Rooming List</span>
                    </CSVLink>
                  </div>
                </div>
              </div>
              <Table
                className="attendee-list"
                caption=""
                highlightOnHover={false}
              >
                <TableHead>
                  <TableRow>
                    <TableCell as="th">Name(s)</TableCell>
                    <TableCell as="th">Room Type</TableCell>
                    <TableCell as="th">Notes & Preferences</TableCell>
                    <TableCell width="112px" as="th">
                      Check-in
                    </TableCell>
                    <TableCell width="112px" as="th">
                      Check-out
                    </TableCell>
                    <TableCell width="155px" as="th">
                      Booking Confirmation
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Tooltip id="clipboard">Click to copy to Clipboard</Tooltip>
                  {data.attendees
                    .filter((a) => a.name)
                    .sort((a, b) => (a.checkOut < b.checkOut ? -1 : 1))
                    .sort((a, b) => (a.checkIn < b.checkIn ? -1 : 1))
                    .map((attendee) => (
                      <TableRow key={attendee.email}>
                        <TableCell>
                          <p
                            data-tooltip-id="clipboard"
                            onClick={() => {
                              navigator.clipboard.writeText(attendee.name);
                            }}
                          >
                            {attendee.name || "Name TBD"}{" "}
                            {attendee.plus_one && <Badge />}
                          </p>
                          {attendee.sharing_name && (
                            <p
                              data-tooltip-id="clipboard"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  attendee.sharing_name
                                );
                              }}
                            >
                              {attendee.sharing_name || "Name TBD"}
                            </p>
                          )}
                        </TableCell>
                        <TableCell>
                          <p
                            data-tooltip-id="clipboard"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                attendee.room_setup
                              );
                            }}
                          >
                            {attendee.room_setup}
                          </p>
                        </TableCell>
                        <TableCell
                          data-tooltip-id="clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              [
                                ...attendee.room_preferences,
                                attendee.plus_one
                                  ? "Bringing one additional guest"
                                  : "",
                              ]
                                .filter((p) => p && p.trim().length > 0)
                                .join("\n")
                            );
                          }}
                        >
                          {attendee.room_preferences.map(
                            (p) => p && <p>{p}</p>
                          )}
                          {attendee.plus_one && (
                            <p>Bringing one additional guest</p>
                          )}
                        </TableCell>
                        <TableCell
                          data-tooltip-id="clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(attendee.checkIn);
                          }}
                        >
                          <p>{attendee.checkIn}</p>
                        </TableCell>
                        <TableCell
                          data-tooltip-id="clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(attendee.checkOut);
                          }}
                        >
                          <p>{attendee.checkOut}</p>
                        </TableCell>
                        <TableCell>
                          <Form.Control
                            value={attendee.booking_reference}
                            onChange={(e) =>
                              updateBookingReference(
                                attendee.checkIn,
                                attendee.checkOut,
                                attendee.email,
                                e.target.value
                              )
                            }
                          ></Form.Control>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </Col>
        ) : (
          <Error />
        )}
      </Row>
    </Container>
  );
};

export default EventHotelPage;
