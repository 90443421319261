import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { TabItem, Tabs } from "@aws-amplify/ui-react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";

import "./Events.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "550px",
  },
};

type Option = { label: string; value: string };

function RoomingListFilterModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialIndex: number;
  setShowNotInvited: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInvited: React.Dispatch<React.SetStateAction<boolean>>;
  showInvited: boolean;
  showNotInvited: boolean;
  nameFilter: string | undefined;
  setNameFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  eventGroups: Option[];
  filterGroups: Option[];
  setFilterGroups: React.Dispatch<React.SetStateAction<Option[]>>;
  eventHotels: Option[];
  filterHotels: Option[];
  setFilterHotels: React.Dispatch<React.SetStateAction<Option[]>>;
}) {
  const [index, setIndex] = useState<string | number>(0);

  function closeModal() {
    props.setIsOpen(false);
  }

  useEffect(() => {
    setIndex(props.initialIndex);
  }, [props.initialIndex, props.modalIsOpen]);

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Filter Attendee Dates"
    >
      <div className="event-management-modal">
        <div className="event-management-modal-header">
          <h4>Filter Rooming List</h4>
          <Button
            className="btn-secondary btn-danger"
            onClick={() => {
              props.setNameFilter(undefined);
              props.setShowInvited(true);
              props.setShowNotInvited(true);
              props.setFilterGroups([]);
              props.setFilterHotels([]);
              closeModal();
            }}
          >
            Clear all filters
          </Button>
        </div>
        <Tabs
          className="header"
          currentIndex={index}
          onChange={(i) => setIndex(i)}
        >
          <TabItem title="Name">
            <div>
              <Form.Label>Name or email contains</Form.Label>
              <Form.Control
                type="text"
                name="filter"
                value={props.nameFilter || ""}
                onChange={(e) => {
                  props.setNameFilter(e.target.value);
                }}
              />
            </div>
          </TabItem>
          <TabItem title="Notification status">
            <div>
              <div className="checkbox-row">
                <Form.Check
                  id="showInvited"
                  name="showInvited"
                  checked={props.showInvited}
                  onChange={(e) => props.setShowInvited(e.target.checked)}
                />
                <Form.Label for="showInvited">
                  Show people who received their hotel confirmation email
                </Form.Label>
              </div>
              <div className="checkbox-row">
                <Form.Check
                  id="showNotInvited"
                  name="showNotInvited"
                  checked={props.showNotInvited}
                  onChange={(e) => props.setShowNotInvited(e.target.checked)}
                />
                <Form.Label for="showNotInvited">
                  Show people who haven't received their hotel confirmation
                  email
                </Form.Label>
              </div>
            </div>
          </TabItem>

          <TabItem title="Teams">
            <div className="filter-groups">
              <label>Only show attendees who are in one of these teams</label>
              <Select
                isMulti
                isClearable
                defaultValue={[]}
                value={props.filterGroups}
                className="attendee-filter-group-control"
                onChange={(newValue) => props.setFilterGroups([...newValue])}
                options={props.eventGroups || []}
              />
            </div>
          </TabItem>
          <TabItem title="Hotels">
            <div className="filter-groups">
              <label>Only show attendees assigned to one of these hotels</label>
              <Select
                isMulti
                isClearable
                defaultValue={[]}
                value={props.filterHotels}
                className="attendee-filter-group-control"
                onChange={(newValue) => props.setFilterHotels([...newValue])}
                options={props.eventHotels || []}
              />
            </div>
          </TabItem>
        </Tabs>

        <footer className="modalButtonFooter">
          <Button
            className="btn-secondary"
            onClick={() => {
              if (index === 0 || index === "0") props.setNameFilter(undefined);
              if (index === 1 || index === "1") {
                props.setShowInvited(true);
                props.setShowNotInvited(true);
              }
              if (index === 2 || index === "2") {
                props.setFilterGroups([]);
              }
              if (index === 3 || index === "3") {
                props.setFilterHotels([]);
              }
            }}
          >
            Clear this filter
          </Button>
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            Apply selected filters
          </Button>
        </footer>
      </div>
    </Modal>
  );
}

export default RoomingListFilterModal;
