import React from "react";

import { EditorContent, Editor } from "@tiptap/react";
import { Level } from "@tiptap/extension-heading";

import { ReactComponent as RedoIcon } from "../images/icons/editor/redo.svg";
import { ReactComponent as UndoIcon } from "../images/icons/editor/undo.svg";
import { ReactComponent as AlignCentre } from "../images/icons/editor/align-centre.svg";
import { ReactComponent as AlignLeft } from "../images/icons/editor/align-left.svg";
import { ReactComponent as AlignRight } from "../images/icons/editor/align-right.svg";
import { ReactComponent as BulletList } from "../images/icons/editor/bullet-list.svg";
import { ReactComponent as NumberList } from "../images/icons/editor/number-list.svg";
import { ReactComponent as TextBold } from "../images/icons/editor/text-bold.svg";
import { ReactComponent as TextItalic } from "../images/icons/editor/text-italic.svg";
import { ReactComponent as TextUnderline } from "../images/icons/editor/text-underline.svg";
import { ReactComponent as LinkIcon } from "../images/icons/editor/link.svg";

import { Tooltip } from "react-tooltip";

const levels: Level[] = [1, 2, 3, 4, 5, 6];

const Tiptap = (props: { editor: Editor | undefined }) => {
  const toggleLink = () => {
    const previousUrl = props.editor?.getAttributes("link").href;

    // Unset existing link
    if (previousUrl) {
      props.editor?.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    const url = window.prompt("URL", previousUrl);
    // cancelled
    if (url === null) {
      return;
    }

    // update link
    props.editor
      ?.chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();
  };

  return (
    <>
      {props.editor && (
        <>
          {props.editor.isEditable && (
            <div className="agenda-editor-controls-bar">
              <div className="control-group">
                <button
                onClick={() => props.editor?.chain().focus().undo().run()}
                data-tooltip-id="undoHelp"
                data-tooltip-place="top"
                data-tooltip-html="Undo"
              >
                <UndoIcon />
                <span className="visually-hidden">Undo</span>
                <Tooltip
                  className="eventTooltip"
                  id="undoHelp"
                />
              </button>
              <button
                onClick={() => props.editor?.chain().focus().redo().run()}
                data-tooltip-id="redoHelp"
                data-tooltip-place="top"
                data-tooltip-html="Redo"
              >
                <RedoIcon />
                <span className="visually-hidden">Redo</span>
                <Tooltip
                  className="eventTooltip"
                  id="redoHelp"
                />
              </button>
              </div>
              <div className="control-group">
              <select
                data-tooltip-id="paraHelp"
                data-tooltip-place="top"
                data-tooltip-html="Set text style"
                aria-describedby="paraHelp"
                onChange={(e) =>
                  props.editor
                    ?.chain()
                    .focus()
                    .toggleHeading({
                      level:
                        e.target.value === "0"
                          ? props.editor.getAttributes("heading").level
                          : levels[Number(e.target.value) - 1],
                    })
                    .run()
                }
              >
                <option value="0">Paragraph</option>
                <option value="1">Heading 1</option>
                <option value="2">Heading 2</option>
                <option value="3">Heading 3</option>
                <option value="4">Heading 4</option>
                <option value="5">Heading 5</option>
                <option value="6">Heading 6</option>
              </select>
              <Tooltip
                  className="eventTooltip"
                  id="paraHelp"
                />
              <button
                data-tooltip-id="boldHelp"
                data-tooltip-place="top"
                data-tooltip-html="Bold"
                onClick={() => props.editor?.chain().focus().toggleBold().run()}
              >
                <TextBold />
                <span className="visually-hidden">Bold</span>
                <Tooltip
                  className="eventTooltip"
                  id="boldHelp"
                />
              </button>
              <button
                data-tooltip-id="italicHelp"
                data-tooltip-place="top"
                data-tooltip-html="Italic"
                onClick={() =>
                  props.editor?.chain().focus().toggleItalic().run()
                }
              >
                <TextItalic />
                <span className="visually-hidden">Italic</span>
                <Tooltip
                  className="eventTooltip"
                  id="italicHelp"
                />
              </button>
              <button
                data-tooltip-id="underlineHelp"
                data-tooltip-place="top"
                data-tooltip-html="Underline"
                onClick={() =>
                  props.editor?.chain().focus().toggleUnderline().run()
                }
              >
                <TextUnderline />
                <span className="visually-hidden">Underline</span>
                <Tooltip
                  className="eventTooltip"
                  id="underlineHelp"
                />
              </button>
              </div>
              <div className="control-group">
                <button
                  data-tooltip-id="alignLeftHelp"
                  data-tooltip-place="top"
                  data-tooltip-html="Align left"
                  onClick={() =>
                    props.editor?.chain().focus().setTextAlign("left").run()
                  }
                >
                  <AlignLeft />
                  <span className="visually-hidden">Align Left</span>
                  <Tooltip
                    className="eventTooltip"
                    id="alignLeftHelp"
                  />
                </button>
                <button
                  data-tooltip-id="alignCenterHelp"
                  data-tooltip-place="top"
                  data-tooltip-html="Align center"
                  onClick={() =>
                    props.editor?.chain().focus().setTextAlign("center").run()
                  }
                >
                  <AlignCentre />
                  <span className="visually-hidden">Align Centre</span>
                  <Tooltip
                    className="eventTooltip"
                    id="alignCenterHelp"
                  />
                </button>
                <button
                  data-tooltip-id="alignRightHelp"
                  data-tooltip-place="top"
                  data-tooltip-html="Align right"
                  onClick={() =>
                    props.editor?.chain().focus().setTextAlign("right").run()
                  }
                >
                  <AlignRight />
                  <span className="visually-hidden">Align Right</span>
                  <Tooltip
                    className="eventTooltip"
                    id="alignRightHelp"
                  />
                </button>
              </div>
              <div className="control-group">
              <button
                data-tooltip-id="listBulletHelp"
                data-tooltip-place="top"
                data-tooltip-html="Bulleted list"
                onClick={() =>
                  props.editor?.chain().focus().toggleBulletList().run()
                }
              >
                <BulletList />
                <span className="visually-hidden">Bulleted List</span>
                <Tooltip
                  className="eventTooltip"
                  id="listBulletHelp"
                />
              </button>
              <button
                data-tooltip-id="listNumHelp"
                data-tooltip-place="top"
                data-tooltip-html="Numbered list"
                onClick={() =>
                  props.editor?.chain().focus().toggleOrderedList().run()
                }
              >
                <NumberList />
                <span className="visually-hidden">Numbered List</span>
                <Tooltip
                  className="eventTooltip"
                  id="listNumHelp"
                />
              </button>
              </div>
              <div className="control-group">
              <button 
                data-tooltip-id="linkHelp"
                data-tooltip-place="top"
                data-tooltip-html="Add a link"
                onClick={toggleLink}>
                <LinkIcon />
                <span className="visually-hidden">Add Link</span>
                <Tooltip
                  className="eventTooltip"
                  id="linkHelp"
                />
              </button>
              </div>
            </div>
          )}
          <EditorContent
            className="agenda-editor-content"
            editor={props.editor}
          />
        </>
      )}
    </>
  );
};

export default Tiptap;
