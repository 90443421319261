import React from 'react';

const IconSendMessage = ({
    fill = '#141414'
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M7.0769 19L2 30L30 16L2 2L8 17H23.5278L6.2052 25.6613L9.27966 19H7.0769ZM9.354 15L5.81067 6.14136L23.5278 15H9.354ZM25.4971 15.9846L25.5278 16L25.4971 16.0154V15.9846Z" fill={fill}/>
        </svg>
    )
};

export default IconSendMessage;