import React, { useEffect, useRef, useState } from "react";
import { FeedbackMessage } from "../App";
import { Button } from "react-bootstrap";

const Feedback = (props: { message: FeedbackMessage }) => {
  const feedbackTimeoutRef = useRef<number | null>(null); // useRef to keep track of timeout
  const [feedbackMessage, setFeedbackMessage] = useState<string>(
    props.message.message
  );
  const [buttonText, setButtonText] = useState<string>(
    props.message.button || "OK"
  );
  const [type, setType] = useState<string>(props.message.type || "");

  const reset = () => {
    setButtonText("OK");
    setType("");
    setFeedbackMessage("");
  };

  useEffect(() => {
    // Clear timeout when component unmounts
    return () => {
      if (feedbackTimeoutRef.current) {
        clearTimeout(feedbackTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setFeedbackMessage(props.message.message); // Set feedback message
    setButtonText(props.message.button || "OK");
    setType(props.message.type || "");

    // Set timeout to clear feedback message after 5 seconds
    if (feedbackTimeoutRef.current) {
      clearTimeout(feedbackTimeoutRef.current); // Clear any existing timeout
    }
    feedbackTimeoutRef.current = window.setTimeout(() => {
      reset();
    }, 5000);
  }, [props.message]);

  return (
    <div className="feedback-container">
      {feedbackMessage && (
        <p className={`feedback-message ${type}`}>
          {feedbackMessage}
          <Button onClick={reset}>{buttonText}</Button>
        </p>
      )}
    </div>
  );
};

export default Feedback;
