import React from "react";

import { EventDate } from "./EventStatus";

import "./Events.css";
import "./attendeeForm.css";

import { Editor, EditorContent } from "@tiptap/react";
import { format } from "date-fns";

import { ReactComponent as AttendanceRequired } from "../img/attendees-dark.svg";
import { ReactComponent as AttendanceTravel } from "../img/walking-black.svg";

const EventAttendeeAgenda = (props: {
  eventDates: EventDate[];
  editors: Map<number, Editor>;
}) => {
  return (
    <div className="attendee-form-agenda">
      <div className="attendee-form-agenda-content">
        {props.eventDates
          ?.filter((d) => d.type !== "Travel")
          .map((eventDate) => (
            <div className="agenda-date" key={eventDate.id}>
              <div className="agenda-date-value">
                <h3>{format(eventDate.date, "EEEE")}</h3>{" "}
                <h4>{format(eventDate.date, "dd MMMM yyyy")}</h4>{" "}
                <aside className="agenda-date-type">
                  {eventDate.type === "Travel" ? (
                    <AttendanceTravel />
                  ) : (
                    <AttendanceRequired />
                  )}
                  {eventDate.type === "Travel"
                    ? "Non-event Date"
                    : "Event Date"}
                </aside>
              </div>
              <aside className="list-bubble" role="presentation">
                <div className="date-dot" role="presentation"></div>
              </aside>
              <div className="agenda-editor">
                {props.editors.get(eventDate.id) &&
                props.editors.get(eventDate.id)?.state.doc.textContent
                  .length ? (
                  <EditorContent
                    className="agenda-editor-content"
                    editor={props.editors.get(eventDate.id) || null}
                  />
                ) : (
                  <p>No agenda was set for this day.</p>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EventAttendeeAgenda;
