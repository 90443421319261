import "@aws-amplify/ui-react/styles.css";

import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@aws-amplify/ui-react";
import { useAuth0 } from "@auth0/auth0-react";

import ImpalaHeader from "../../components/ImpalaHeader/ImpalaHeader";
import RedirectingComponent from "../../components/RedirectingComponent";

import OfferDetails from "../Offers/OfferDetails";
import Offers from "../Offers/Offers";
import Services from "./Services";
import Invites from "./Invites";
import Reporting from "../Reporting/Reporting";

import { ImpalaTheme } from "../../theme/index";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";
import Event from "../Events/Event";
import NoPermission from "./NoPermission";

import { ReactComponent as Spinner } from "../../img/spinner.svg";
import ExternalBooking from "../ExternalBooking/ExternalBooking";
import AccountDetails from "../../components/AccountDetails";
import useCompanyDetails from "../../lib/useCompanyDetails";

import { LogoWordmark } from "../../components/ImpalaLogo/ImpalaLogo";

const Dashboard = () => {
  // Accessibility enhancement: https://reactcommunity.org/react-modal/accessibility/
  Modal.setAppElement("#root");

  let userAuth = useAuth0();
  const [authenticatedAxios, tokenSet] = useAuthenticatedAxios(true);
  const [isAdmin, setIsAdmin] = useState(null);
  const [activeServices, setActiveServices] = useState(null);
  const [isSelfServe, setIsSelfServe] = useState();
  const [hasCustomer, setHasCustomer] = useState();
  const [companyDetails, setCompanyDetails] = useState({
    name: "",
    logo: "",
  });
  const [refresh, setRefresh] = useState(false);

  useCompanyDetails(setCompanyDetails, refresh);

  const onCompanyUpdate = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tokenSet && hasCustomer) {
        try {
          await authenticatedAxios.get("/api/users/is-company-admin");
          setIsAdmin(true);
        } catch (e) {
          setIsAdmin(false);
        }
        try {
          const response = await authenticatedAxios.get(
            "/api/users/active-services"
          );
          setActiveServices(response?.data?.services);
          setIsSelfServe(
            response?.data?.services.events_service &&
              !response?.data?.services.hotel_offers_service
          );
        } catch {
          console.error("Error retrieving active services");
        }
      }
    };

    fetchData();
  }, [authenticatedAxios, tokenSet, hasCustomer]);

  useEffect(() => {
    const fetchData = async () => {
      if (tokenSet) {
        try {
          const response = await authenticatedAxios.get("/api/users/customer");
          setHasCustomer(
            Boolean(response.data.customer && response.data.customer.name)
          );
        } catch (e) {
          console.error("Error checking valid customer");
        }
      }
    };

    fetchData();
  }, [authenticatedAxios, tokenSet, refresh]);

  const NoPermissionComponent = () => (
    <NoPermission
      user={userAuth.user}
      headline="This is the administration dashboard for your company"
      logout={userAuth.logout}
    />
  );

  return (
    <ThemeProvider theme={ImpalaTheme}>
      {(hasCustomer === undefined || !tokenSet) && (
        <div className="dashboard-authenticating-state">
          <div className="wrapper">
            <h1>Retrieving Your Data</h1>
            <Spinner />
          </div>
        </div>
      )}
      {hasCustomer === false && (
        <div className="dashboard-authenticating-state account-creation-info">
          <div>
            <LogoWordmark variant="primaryDark" />
          </div>
          <div className="wrapper">
            <h1>Great! You’re signed up</h1>
            <p>There’s just one more step before you can create your events.</p>
            <AccountDetails
              companyDetails={companyDetails}
              onCompanyUpdate={onCompanyUpdate}
            />
          </div>
        </div>
      )}
      {userAuth.isAuthenticated &&
        tokenSet &&
        isAdmin &&
        isSelfServe === false && (
          <>
            <ImpalaHeader userAuth={userAuth} activeServices={activeServices} />
            <Routes>
              <Route path="/offers/:offerId" Component={OfferDetails} />
              <Route path="/reporting" Component={Reporting} />
              <Route path="/events/:eventId" Component={Event} />
              <Route path="/external/:eventId" Component={ExternalBooking} />
              <Route path="/offers" Component={Offers} />
              <Route path="/setup" Component={Offers} />
              <Route path="/invites" Component={Invites} />
              <Route
                path="/services"
                element={<Services activeServices={activeServices} />}
              />
              <Route
                path="*"
                element={<RedirectingComponent isSelfServe={isSelfServe} />}
              />
            </Routes>
          </>
        )}
      {isSelfServe !== undefined &&
        userAuth.isAuthenticated &&
        tokenSet &&
        (!isAdmin || isSelfServe) && (
          <>
            <Routes>
              <Route
                path="/offers/:offerId"
                Component={NoPermissionComponent}
              />
              <Route path="/reporting" Component={NoPermissionComponent} />
              <Route path="/events/:eventId" Component={Event} />
              <Route path="/external/:eventId" Component={ExternalBooking} />
              <Route path="/offers" Component={NoPermissionComponent} />
              <Route path="/invites" Component={NoPermissionComponent} />
              <Route path="/services" Component={NoPermissionComponent} />
              <Route path="/setup" Component={NoPermissionComponent} />
              <Route
                path="*"
                element={<RedirectingComponent isSelfServe={isSelfServe} />}
              />
            </Routes>
          </>
        )}
      {!userAuth.isAuthenticated && (
        <Routes>
          <Route
            path="*"
            element={<RedirectingComponent isSelfServe={isSelfServe} />}
          />
        </Routes>
      )}
    </ThemeProvider>
  );
};

export default Dashboard;
