import { useEffect } from "react";

import useAuthenticatedAxios from "./useAuthenticatedAxios";

export enum TMC {
  TRAVELPERK = "TravelPerk",
  NAVAN = "Navan",
}

export type Customer = {
  id: number;
  name: string;
  tmc: string | null;
};

const useCustomers = (
  setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>,
  setSelectedCustomerId: React.Dispatch<
    React.SetStateAction<Customer | undefined>
  >,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  const [axios, tokenSet] = useAuthenticatedAxios(true);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get("/api/users/customers");
        const customers: Customer[] = [];
        for (const customer of response.data) {
          customers.push({
            name: customer.name,
            id: customer.id,
            tmc: customer.tmc,
          });
        }

        // Sort them alphabetically
        customers.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );

        setCustomers(customers);
        if (customers.length > 0) setSelectedCustomerId(customers[0]);
        else setLoading(false);
      } catch (error) {
        console.error("Error fetching customers:", error);
        setLoading(false);
      }
    };

    fetchCustomers();
  }, [tokenSet, setCustomers, setLoading, setSelectedCustomerId]);
};

export default useCustomers;
