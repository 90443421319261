import React, { useState, useEffect } from "react";
import { ReactComponent as StatsEmpty } from "../img/stats-empty.svg";
import { format } from "date-fns";
import { CSVLink } from "react-csv";

import EventCosts from "./EventCosts";
import EventEmpty from "./EventEmpty";
import {
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
} from "@aws-amplify/ui-react";

import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";
import getSymbolFromCurrency from "currency-symbol-map";

import "./Events.css";

const CSVHeaders = [
  { label: "Date", key: "date" },
  { label: "Type", key: "type" },
  { label: "Attending", key: "attending" },
  { label: "Rooms requested", key: "hotel" },
  { label: "Rooms booked", key: "booked" },
  { label: "Average Rate", key: "rate" },
];

function countAttendeesAndHotelRequests(data) {
  // Initialize dictionary to store count of attendees and hotel requests per date
  let dateAttendeesAndHotelRequests = {};

  // Initialize each date's counts in the dictionary
  data.dates.forEach((event_date) => {
    dateAttendeesAndHotelRequests[event_date.id] = {
      date: event_date.date,
      type: event_date.type,
      attendeesCount: 0,
      hotelRequestsCount: 0,
    };
  });

  // Count attendees and hotel requests per date
  data.attendees.forEach((attendee) => {
    attendee.dates.forEach((date) => {
      if (date.attending) {
        dateAttendeesAndHotelRequests[date.date].attendeesCount++;
      }
      if (date.hotel_required && attendee.book_room) {
        dateAttendeesAndHotelRequests[date.date].hotelRequestsCount +=
          attendee.must_share_room ? 0.5 : 1;
      }
    });
  });

  // Round up any half-rooms
  data.dates.forEach((event_date) => {
    dateAttendeesAndHotelRequests[event_date.id].hotelRequestsCount = Math.ceil(
      dateAttendeesAndHotelRequests[event_date.id].hotelRequestsCount
    );
  });

  // Output the result
  return Object.values(dateAttendeesAndHotelRequests);
}

const EventStats = ({ event }) => {
  const [stats, setStats] = useState(null);
  const [rates, setContractedRates] = useState([]);
  const [authedAxios, tokenSet] = useAuthenticatedAxios(true);
  const [exportStats, setExportStats] = useState([]);

  useEffect(() => {
    if (!event.realEvent) return;
    if (!tokenSet) return;
    const fetchData = async () => {
      try {
        const response = await authedAxios.get(
          `/api/users/events/1634${event?.details?.id}/contracted-rates`
        );
        setContractedRates(response.data);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [tokenSet]);

  useEffect(() => {
    if (event.realEvent) {
      setStats(countAttendeesAndHotelRequests(event));
    }
  }, [event]);

  useEffect(() => {
    if (stats)
      setExportStats(
        stats
          .sort((a, b) =>
            new Date(a.date.toString()) > new Date(b.date.toString()) ? 1 : -1
          )
          .map((statSet) => {
            const rate = rates.find(
              (x) => x.date === format(statSet.date, "yyyy-MM-dd")
            );
            return {
              date: format(statSet.date, "eee, dd MMM yyyy"),
              type: statSet.type,
              attending: statSet.attendeesCount,
              hotel: statSet.hotelRequestsCount,
              booked: rates?.length > 0 ? totalContracted(rate) : 0,
              rate: rates?.length > 0 ? getCurrency(rate) + adr(rate) : "",
            };
          })
      );
  }, [stats, rates]);

  const roomNights = () => {
    if (!stats) return 0;
    const total = stats.reduce((a, b) => a + b.hotelRequestsCount, 0);
    return event?.details?.share_room_option ? Math.ceil(total / 2) : total;
  };

  const totalContracted = (rate) => {
    if (!rate) return "-";
    return rate.rates.reduce((a, b) => a + b.number_of_rooms, 0);
  };

  const totalCancellable = (rate) => {
    if (!rate) return "-";
    return rate.rates.reduce((a, b) => a + b.cancellable, 0);
  };

  const adr = (rate) => {
    if (!rate) return "-";
    return rate.rates.reduce((a, b) => a + b.cost, 0) / rate.rates.length;
  };

  const getCurrency = (rate) => {
    if (!rate) return "";
    try {
      return getSymbolFromCurrency(rate.rates[0].currency);
    } catch {
      return "";
    }
  };

  return (
    <div>
      <header className="header-wrapper">
        <h2>Stats & Costs</h2>
      </header>
      {!event.realEvent && (
        <div>
          <div className="event-not-accepted-wrapper">
            <StatsEmpty />
            <EventEmpty />
          </div>
        </div>
      )}
      {event.realEvent && (
        <div className="event-costs-wrapper">
          <EventCosts event={event} stats={stats} />
        </div>
      )}
      {/* {event.realEvent && stats && (
                <div className="eventAttendeeSummaryBar">
                    <div className="summaryBarItem">{`Total Hotel Nights: ${roomNights()}`}</div>
                </div>
            )} */}
      {event.realEvent && stats && (
        <>
          <Table className="attendee-list event-stats">
            <TableHead>
              <TableRow>
                <TableCell as="th">Date</TableCell>
                <TableCell as="th">Type</TableCell>
                <TableCell as="th">Attending</TableCell>
                <TableCell as="th">Rooms requested</TableCell>
                {rates?.length > 0 && (
                  <>
                    <TableCell as="th">Rooms booked</TableCell>
                    <TableCell as="th">Average rate</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {stats
                .sort((a, b) =>
                  new Date(a.date.toString()) > new Date(b.date.toString())
                    ? 1
                    : -1
                )
                .map((statSet, key) => {
                  const rate = rates.find(
                    (x) => x.date === format(statSet.date, "yyyy-MM-dd")
                  );
                  return (
                    <TableRow key={key}>
                      <TableCell>
                        {format(statSet.date, "eee, dd MMM yyyy")}
                      </TableCell>
                      <TableCell>{statSet.type}</TableCell>
                      <TableCell>{statSet.attendeesCount}</TableCell>
                      <TableCell>{statSet.hotelRequestsCount}</TableCell>
                      {rates?.length > 0 && (
                        <>
                          <TableCell>{totalContracted(rate)}</TableCell>
                          <TableCell>
                            {getCurrency(rate)}
                            {adr(rate)}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <p></p>
          <div className="summaryBarItem export-btn">
            <CSVLink
              headers={CSVHeaders}
              data={exportStats}
              filename="event_stats.csv"
              target="_blank"
              className="btn btn-secondary"
            >
              <span>Export Table</span>
            </CSVLink>
          </div>
        </>
      )}
      {event.realEvent && (
        <p className="text-body-small basis-explanation">
          {event?.attendees.filter((a) => a.must_share_room).length > 0 && (
            <>
              All room night totals and costs are based on <b>two</b> attendees
              sharing a room where mandated by their groups.
            </>
          )}
          {event?.attendees.filter((a) => a.must_share_room).length === 0 && (
            <>
              All room night totals and costs is based on <b>one</b> attendee
              per room.
            </>
          )}
        </p>
      )}
    </div>
  );
};

export default EventStats;
