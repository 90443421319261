import React from "react";
import { Tooltip } from "react-tooltip";

import {
  Badge,
  Button,
  Heading,
  Link,
  Text,
  View,
} from "@aws-amplify/ui-react";

import { SETUP_HEADER } from "../utils/Strings";
import SetupOfferTable from "./SetupOfferTable";
import { HotelDeal } from "../lib/useHotelDeals";
import { Customer } from "../lib/useCustomers";

function NavanSetup(props: {
  hotelDeals: HotelDeal[];
  customer: Customer;
  requestSetup: () => void;
}) {
  function getCompanyEmail(): string {
    if (!props.customer.name) return "contracting@impala.travel";
    return (
      "contracing+" +
      props.customer.name?.replaceAll(/[\W]+/g, "") +
      "@impala.travel"
    );
  }

  return (
    <View>
      <Heading className="SetupHeader" level={4}>
        {SETUP_HEADER}
      </Heading>
      {props.hotelDeals.filter(
        (cd) =>
          cd.signed_by_corporate && !cd.setup_requested && !cd.setup_complete
      ).length === 0 && (
        <Badge variation="warning">
          You have currently no signed offers that require setting up.
        </Badge>
      )}
      <View className="SetupInstructions">
        <View className="SetupPanel">
          <View className="SetupText">
            <Heading level={5}>Using Navan</Heading>
            <Text>
              In order to activate your rates we need to message Navan on your
              behalf. Follow the steps below to setup your signed rates.
            </Text>
            <Tooltip id="clipboard">Click to copy to Clipboard</Tooltip>
            <ol>
              <li>
                Add&nbsp;
                <Link
                  data-tooltip-id="clipboard"
                  onClick={() => {
                    navigator.clipboard.writeText(getCompanyEmail());
                  }}
                >
                  {getCompanyEmail()}
                </Link>
                &nbsp;as an Admin user to your Navan account.
              </li>
              <li>
                Once you've done that, select 'Confirm' and we'll do the rest.
              </li>
            </ol>
            <Link>
              <Button onClick={props.requestSetup}>Confirm</Button>
            </Link>
          </View>
        </View>
        <SetupOfferTable hotelDeals={props.hotelDeals} />
      </View>
    </View>
  );
}

export default NavanSetup;
