import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Event, EventAttendee } from "./EventStatus";
import { ReactComponent as InfoIcon } from "../img/information-circle.svg";
import { Button, Form } from "react-bootstrap";
import axios from "axios";

import "./Events.css";
import CreatableSelect from "react-select/creatable";
import { MultiValue } from "react-select";
import NewGroup from "./NewGroup";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "640px",
  },
};

type Option = { label: string; value: string };

function AttendeeGroupsModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: Event;
  eventGroups: Option[];
  setEventGroups: React.Dispatch<React.SetStateAction<Option[]>>;
  eventAttendee: EventAttendee | undefined;
  onUpdate: Function;
}) {
  const [isExempt, setIsExempt] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [mustShareRoom, setMustShareRoom] = useState(false);
  const [organiserManaged, setOrganiserManaged] = useState(false);
  const [attendeeGroups, setAttendeeGroups] = useState<Option[]>([]);

  // Accessibility enhancement: https://reactcommunity.org/react-modal/accessibility/
  Modal.setAppElement("#root");

  useEffect(() => {
    setAttendeeGroups(
      props.eventAttendee?.categories?.map((c) => {
        return { label: c, value: c };
      }) || []
    );
  }, [props.eventAttendee]);

  function closeModal() {
    setAttendeeGroups(
      props.eventAttendee?.categories?.map((c) => {
        return { label: c, value: c };
      }) || []
    );
    props.setIsOpen(false);
  }

  const handleCreate = async (inputValue: string) => {
    try {
      const body = {
        groupName: inputValue,
        isTeam: isTeam,
        isExempt: isExempt,
        mustShareRoom: mustShareRoom,
        isSilent: organiserManaged,
      };
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/group`,
        body
      );
      props.setEventGroups([
        ...(structuredClone(props.eventGroups) || []),
        { value: inputValue, label: inputValue },
      ]);
      handleNewValue([
        ...(attendeeGroups || []),
        { value: inputValue, label: inputValue },
      ]);
    } catch (err) {
      alert("Could not add new attendee group, please try again later");
      console.error("Error adding group:", err);
    } finally {
      setIsExempt(false);
      setIsTeam(false);
      setOrganiserManaged(false);
      setMustShareRoom(false);
    }
  };

  const handleNewValue = (newValue: MultiValue<Option> | Option[]) => {
    const newOptions: Option[] = structuredClone(newValue);
    setAttendeeGroups(newOptions);
  };

  const handleSave = async () => {
    try {
      await axios.patch(
        `/api/users/events/1634${props.event.details.id}/attendees/${props.eventAttendee?.id}/groups`,
        {
          groups: attendeeGroups?.map((g) => g.value) || null,
        }
      );
      props.onUpdate();
      props.setIsOpen(false);
    } catch (error) {
      console.error("Error submitting groups:", error);
      alert("We could not submit your changes");
    }
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit attendance dates"
    >
      <div className="event-management-modal">
        <h4>Edit groups</h4>
        {props.eventAttendee && (
          <div>
            <p>
              You're{" "}
              {props.eventAttendee.categories &&
              props.eventAttendee.categories.length > 0
                ? "editing"
                : "adding"}{" "}
              groups for{" "}
              <b>{props.eventAttendee.name || props.eventAttendee.email}</b>.
              You can assign people to one or multiple groups.
            </p>
            {props.eventAttendee.has_replied &&
              props.eventAttendee.categories &&
              props.eventAttendee.categories.length > 0 && (
                <p>
                  Please note that this attendee has already replied and may
                  have selected hotel stays that clash with restrictions if you
                  change their group membership.
                </p>
              )}

            <Form.Label for="attendeeGroups">Groups</Form.Label>
            <CreatableSelect
              isMulti
              isClearable
              isDisabled={["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )}
              options={props.eventGroups || []}
              value={attendeeGroups || []}
              id="attendeeGroups"
              name="attendeeGroups"
              onChange={(newValue) => handleNewValue(newValue)}
              onCreateOption={handleCreate}
              placeholder="No groups selected"
              formatCreateLabel={(inputValue: string) => {
                return (
                  <NewGroup
                    inputValue={inputValue}
                    isTeam={isTeam}
                    setIsTeam={setIsTeam}
                    isExempt={isExempt}
                    setIsExempt={setIsExempt}
                    mustShareRoom={mustShareRoom}
                    setMustShareRoom={setMustShareRoom}
                    organiserManaged={organiserManaged}
                    setOrganiserManaged={setOrganiserManaged}
                  />
                );
              }}
            />

            <span className="info-line">
              <InfoIcon />
              <span>
                Attendees will <strong>not</strong> be notified of changes you
                make here.
              </span>
            </span>
          </div>
        )}
        <footer className="modalButtonFooter">
          <Button className="btn-secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={["Ongoing", "Completed", "Cancelled"].includes(
              props.event.details.status
            )}
            onClick={handleSave}
          >
            Save
          </Button>
        </footer>
      </div>
    </Modal>
  );
}

export default AttendeeGroupsModal;
