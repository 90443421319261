import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PublicTransportIcon } from '../img/public-transport.svg';
import { ReactComponent as WalkingIcon } from '../img/walking.svg';
import { ReactComponent as CarIcon } from '../img/car.svg';
import { ReactComponent as HelpIcon } from '../img/help-circle.svg';
import { ReactComponent as ArrowRightIcon } from '../img/arrow-right.svg';

import { Button } from 'react-bootstrap';

import { Tooltip } from "react-tooltip";

const DealList = ({ deals, companyName }) => {
    const navigate = useNavigate();

    const handleHotelDealClick = (hotelDealId) => {
        // Navigating to Google
        navigate(`/srv/hotel-program-hotel/${hotelDealId}`);
    };

    return (
        <div className="deal-list">
            {deals.sort((a, b) => a.location_name < b.location_name ? -1 : 1 ).map(deal => (
                <div className="deal-view" key={deal.location_name}>
                    <h2>{deal.location_name}</h2>
                    {deal.hotel_deals.map((hotelDeal) => (
                        <div className="hotel-deal-wrapper" key={hotelDeal.id}>
                            <div className="deal-left-side">
                                <h4>{hotelDeal.hotel_name}</h4>
                                <div className="entice-list">
                                    <label>Book this hotel for</label>
                                    <div className="reasons list">
                                        {hotelDeal.unique_benefits.map((benefit, index) => (
                                            <li key={index}>{benefit}</li>
                                        ))}
                                    </div>
                                </div>
                                <div className="entice-list">
                                    <label>Distance from your office:</label>
                                    <div className="reasons">
                                        {hotelDeal.walking_description && (
                                            <p><WalkingIcon /> {hotelDeal.walking_description}</p>
                                        )}
                                        {hotelDeal.car_description && (
                                            <p><CarIcon /> {hotelDeal.car_description}</p>
                                        )}
                                        {hotelDeal.public_transport_description && (
                                            <p><PublicTransportIcon /> {hotelDeal.public_transport_description}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="tag-wrapper">
                                    <div className="airport-transfer-tag">
                                        <Tooltip className="eventTooltip" id="airportTransfer" />
                                        <CarIcon /> One click airport transfer <HelpIcon data-tooltip-id="airportTransfer" data-tooltip-place="top" data-tooltip-html="<h3>Transfer<h3><p>Bookings through the frequent traveller program can include a transfer with our partners WelcomePickups. You'll receive an email around 10 minutes after booking with a link to book.</p>"/>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="deal-right-side">
                                <div className="savings-area">
                                    <div className="save-tag">
                                        Save 25%
                                    </div>
                                    <p>On average this is how much the company saves booking here.</p>
                                </div>
                                <Button variant="primary"  onClick={() => handleHotelDealClick(hotelDeal.id)}>
                                    View & Book <ArrowRightIcon />
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
};

export default DealList;