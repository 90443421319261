import React, { useState, useEffect } from "react";
import axios from "axios";
import HotelProgramMeta from "../../components/HotelProgramMeta";

import { Link } from "react-router-dom";

import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import currencyToSymbolMap from "currency-symbol-map";

import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as PublicTransportIcon } from "../../img/public-transport.svg";
import { ReactComponent as WalkingIcon } from "../../img/walking.svg";
import { ReactComponent as TagIcon } from "../../img/tag.svg";
import { ReactComponent as CarIcon } from "../../img/car.svg";
import { ReactComponent as GiftIcon } from "../../img/gift.svg";
import { ReactComponent as ArrowRightIcon } from "../../img/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "../../img/arrow-left.svg";

import GettingThereMap from "../../components/GettingThereMap";

import HotelProgramSidebar from "../../components/HotelProgramSidebar";
import NoPermission from "../Navigation/NoPermission";

import "../../components/HotelProgram.css";
import { usePostHog } from "posthog-js/react";

const HotelProgramHotel = ({
  hasDirectBookingServicePermission,
  hasEventServicePermission,
  userAuth,
  user,
}) => {
  const [data, setData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const { companyName, hotelDealId } = useParams();

  const posthog = usePostHog();
  posthog.capture("Service Visit", {
    service: "Frequent Traveller",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/hotel-program/hotel-deal/${hotelDealId}`
        );
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [hotelDealId]);

  const heroImage = () => {
    if (!data?.images || !data?.images.length) return null;
    return data.images.find((x) => x.is_hero_image);
  };

  const handleBooking = () => {
    const checkIn = startDate
      ? encodeURIComponent(new Date(startDate).toLocaleDateString("en-GB"))
      : false;
    const checkOut = endDate
      ? encodeURIComponent(new Date(endDate).toLocaleDateString("en-GB"))
      : false;

    if (checkIn && checkOut) {
      navigate(
        `/srv/hotel-program-hotel/${hotelDealId}/book?checkIn=${checkIn}&checkOut=${checkOut}`
      );
    }
  };

  const bestStaticRate = () => {
    if (!data?.offers || !data?.offers.length) return null;

    const staticOffers = data.offers.filter(
      (offer) => offer.static_rate !== null
    );

    if (!staticOffers.length) return null;

    // Find the offer with the minimum static rate
    const bestOffer = staticOffers.reduce(
      (minOffer, offer) =>
        offer.static_rate < minOffer.static_rate ? offer : minOffer,
      staticOffers[0]
    );

    // Convert currency code to symbol
    const currencySymbol =
      currencyToSymbolMap(bestOffer.static_currency) ||
      bestOffer.static_currency;

    return {
      rate: bestOffer.static_rate,
      currencySymbol,
    };
  };

  // ... existing render code
  const bestRate = bestStaticRate();

  const bestDynamicRate = () => {
    if (!data?.offers || !data?.offers.length) return null;

    const dynamicOffers = data.offers.filter(
      (offer) => offer.dynamic_percentage !== null
    );

    if (!dynamicOffers.length) return null;

    // Find the maximum dynamic percentage
    return Math.max(...dynamicOffers.map((offer) => offer.dynamic_percentage));
  };

  if (userAuth.isLoading) return <div />;

  if (!hasDirectBookingServicePermission && !userAuth.isLoading) {
    return (
      <NoPermission
        user={user}
        headline={`You don't have permission to use the direct booking tool`}
        logout={userAuth.logout}
      />
    );
  }

  return (
    <div className="hotel-program-display">
      <div className="page-split">
        <div className="dark-column reduced-on-mobile">
          <HotelProgramSidebar data={customerData} />
        </div>
        <div className="light-column">
          <HotelProgramMeta
            setData={setCustomerData}
            setIsLoading={setIsLoading}
            setError={setError}
            showEventLink={hasEventServicePermission}
            data={customerData}
          />
          {data && (
            <>
              <div className="hotel-program-hotel-display">
                <div className="hotel-program-back-link">
                  <Link to="/srv/hotel-program">
                    <ArrowLeftIcon /> Back to all hotels
                  </Link>
                </div>
                <h1>{data.name}</h1>
                <p>{data.location}</p>
                {heroImage() && (
                  <div
                    className="hero-image-container"
                    style={{
                      backgroundImage: `url(${heroImage().url})`,
                    }}
                  ></div>
                )}
                <div className="booking-container">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date()}
                    placeholderText="Select check-in date"
                    dateFormat="eee, dd MMM Y"
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select check-out date"
                    dateFormat="eee, dd MMM Y"
                  />
                  <Button
                    className="book-now-button"
                    onClick={handleBooking}
                    variant="primary"
                  >
                    Book Now <ArrowRightIcon />
                  </Button>
                </div>

                <h4 className="about-this-hotel">About this hotel</h4>
                <div className="about-hotel-wrapper">
                  {data?.hotelDealDisplayDirect?.unique_benefits?.length >
                    0 && (
                    <div className="unique-benefits-display">
                      <h5>Unique benefits when booking here</h5>
                      <div className="benefits-list">
                        {data.hotelDealDisplayDirect.unique_benefits.map(
                          (benefit, i) => (
                            <p key={i}>
                              <GiftIcon />
                              {benefit}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  {data?.lat && data?.lng && customerData && (
                    <div className="getting-there-wrapper">
                      <h5>Getting there</h5>
                      <div className="descriptions-list">
                        {data.hotelDealDisplayDirect.walking_description && (
                          <p>
                            <WalkingIcon />
                            {data.hotelDealDisplayDirect.walking_description}
                          </p>
                        )}
                        {data.hotelDealDisplayDirect.driving_description && (
                          <p>
                            <CarIcon />
                            {data.hotelDealDisplayDirect.driving_description}
                          </p>
                        )}
                        {data.hotelDealDisplayDirect
                          .public_transport_description && (
                          <p>
                            <PublicTransportIcon />
                            {
                              data.hotelDealDisplayDirect
                                .public_transport_description
                            }
                          </p>
                        )}
                      </div>
                      <div className="map-wrapper">
                        <GettingThereMap
                          hotelLat={data.lat}
                          hotelLng={data.lng}
                          dealLat={data.deal_lat}
                          dealLng={data.deal_lng}
                          hotelColor={customerData.colors.brandPrimaryColor}
                          dealColor={customerData.colors.brandPrimaryColor}
                          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyD7tGntA-5CYR_b6Te3PrQgKWsp7VkPwRw"
                          loadingElement={<div style={{ height: "100%" }} />}
                          containerElement={<div style={{ height: "100%" }} />}
                          mapElement={<div style={{ height: "100%" }} />}
                        />
                      </div>
                    </div>
                  )}
                  {data?.hotelDealDisplayDirect?.amenities_to_list?.length >
                    0 && (
                    <div className="unique-benefits-display">
                      <h5>Hotel features</h5>
                      <div className="benefits-list">
                        {data.hotelDealDisplayDirect.amenities_to_list.map(
                          (amenity, i) => (
                            <p key={i}>
                              <TagIcon />
                              {amenity}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  {data?.images && (
                    <div className="image-gallery">
                      <h5>Gallery</h5>
                      <div className="images-wrapper">
                        {data.images.map((image) => {
                          return (
                            <div
                              style={{ backgroundImage: `url(${image.url})` }}
                              className="image"
                              onClick={() => {
                                window.open(image.url, "_blank");
                              }}
                            ></div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HotelProgramHotel;
