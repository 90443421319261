import React from "react";

import { Event } from "./EventStatus";

import { ReactComponent as EditIcon } from "../images/icons/edit.svg";
import { format } from "date-fns";

const EventDetails = (props: { event: Event | undefined; user: string }) => {
  let firstDateString = "";
  if (props.event?.dates && props.event.dates.length > 0)
    firstDateString = format(
      new Date(props.event?.dates[0].date || 0),
      "dd MMM yyyy"
    );
  let lastDateString = "";
  if (props.event?.dates && props.event.dates.length > 0)
    lastDateString = format(
      new Date(props.event?.dates.slice(-1)[0].date || 0),
      "dd MMM yyyy"
    );

  return (
    <div className="steps-column">
      {/* Massive hack to make GYG happy */}

      {props.event?.details.id === 68 ? (
        <div className="logo-line">
          <img className="mainlogo" src="/logo.png" alt="Impala Logo" />
          <img
            src="https://corporate-dashboard-img.s3.eu-central-1.amazonaws.com/customers/5/gyg-long.min-hires.min.png"
            alt="Logo"
            className="company-logo"
          />
        </div>
      ) : (
        <div>
          {props.event?.customer && <img src={props.event.customer.logo_url} />}
        </div>
      )}
      {props.event && props.event.details && (
        <div className="attendee-form-details">
          {/*Organisers*/}

          {/*Attendees*/}
          {props.user !== "organiser" && (
            <h1 className="event-invite-title">
              You're invited to{" "}
              {{
                INTERVIEW: "your interview",
                ONBOARDING_EVENT: "your onboarding",
                TEAM_EVENT: props.event.details.name || "a team event",
              }[props.event.details.type] || "an event"}
            </h1>
          )}
          {/*Onboarding & Interview Attendees*/}
          {props.user !== "organiser" &&
            props.event.details.type !== "TEAM_EVENT" && (
              <div className="colleague-explainer">
                {"You've been invited by "}
                <b>
                  {props.event.details.creator_name ||
                    props.event.details.creator_email}
                </b>{" "}
                {{
                  INTERVIEW: "to your interview.",
                  ONBOARDING_EVENT: "to your onboarding.",
                }[props.event.details.type] || "to an event."}
              </div>
            )}
          {/*Event Attendees*/}
          {props.event.details.id !== 68 &&
            props.user !== "organiser" &&
            props.event.details.type === "TEAM_EVENT" && (
              <div
                className="colleague-explainer"
                dangerouslySetInnerHTML={{
                  __html: props.event.details.invite_text,
                }}
              ></div>
            )}
          {/* Massive Hack to make GYG happy */}
          {props.event.details.id === 68 &&
            props.user !== "organiser" &&
            props.event.details.type === "TEAM_EVENT" && (
              <div className="colleague-explainer">
                <p>
                  <b>Welcome to Summit Week 2024!</b>
                </p>
                <p>
                  Great news you're all set for Summit Week 2024! Your
                  accommodation details are included below!
                </p>
                <p>
                  So you feel fully prepared for your trip to Berlin, please
                  find all the information on your arrival and stay on the{" "}
                  <a
                    href="https://sites.google.com/getyourguide.com/intranet/workplace/summit-week-2024/international-travel"
                    target="_blank"
                  >
                    International Travel page
                  </a>
                  .
                </p>
                <p>
                  For information on the office, from maps to access card
                  information, please see the{" "}
                  <a
                    href="https://sites.google.com/getyourguide.com/intranet/workplace/summit-week-2024/navigating-ampere"
                    target="_blank"
                  >
                    Navigating Ampere page
                  </a>
                  .
                </p>
                <p>
                  A quick read through both of these pages will set you up well
                  before, during and after your trip! Safe travels!
                </p>
              </div>
            )}
          <div className={`steps-wrapper summary-mode`}>
            {props.user === "organiser" &&
              props.event.details.type === "TEAM_EVENT" && (
                <div className="step">
                  <div className="right-side">
                    <p className="step-title">How many people are coming?</p>
                    {props.event.details.number_option === "exact" && (
                      <p>{props.event.details.exact_people}</p>
                    )}
                    {props.event.details.number_option !== "exact" && (
                      <p>
                        {props.event.details.min_people} -{" "}
                        {props.event.details.max_people}
                      </p>
                    )}
                  </div>
                </div>
              )}
            <div className="step">
              <div className="right-side">
                <p className="step-title">{`When's it happening?`}</p>
                {firstDateString && lastDateString ? (
                  <>
                    {firstDateString} - {lastDateString}
                  </>
                ) : (
                  <>TBD</>
                )}
              </div>
            </div>
            <div className="step">
              <div className="right-side">
                <p className="step-title">{`Where is it?`}</p>
                {props.event.details.place_option === "specific" && (
                  <p>{props.event.details.specific_place}</p>
                )}
                {props.event.details.place_option !== "specific" && (
                  <p>{props.event.details.find_destination}</p>
                )}
              </div>
            </div>
            {props.user === "organiser" &&
              props.event.details.type === "TEAM_EVENT" && (
                <div className="step">
                  <div className="right-side">
                    <p className="step-title">{`What's your budget?`}</p>
                    {props.event.details.budget_option === "specific" && (
                      <p>
                        {props.event.details.budget_currency}{" "}
                        {props.event.details.budget_amount}{" "}
                        {props.event.details.budget_type}
                      </p>
                    )}
                    {props.event.details.budget_option !== "specific" && (
                      <p>{`The average for an event of this type.`}</p>
                    )}
                  </div>
                </div>
              )}
            {props.user === "organiser" &&
              props.event.details.type === "TEAM_EVENT" && (
                <div className="step">
                  <div className="right-side">
                    <p className="step-title">Any extras?</p>
                    <p>
                      {!props.event.details.additional_requirements_option &&
                        !props.event.details.venue_option &&
                        !props.event.details.fandb_option &&
                        !props.event.details.share_rooms_option &&
                        `None`}
                    </p>
                    {props.event.details.venue_option ? (
                      <p>Venue Required</p>
                    ) : (
                      ""
                    )}
                    {props.event.details.fandb_option ? (
                      <p>F&B Required</p>
                    ) : (
                      ""
                    )}
                    {props.event.details.share_rooms_option ? (
                      <p>Attendees should share rooms</p>
                    ) : (
                      ""
                    )}
                    {props.event.details.additional_requirements_option ? (
                      <p>
                        Additional Requirements:{" "}
                        {props.event.details.additional_requirements}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
          </div>
          {props.user === "organiser" &&
            ["Received", "Planning"].includes(props.event.details.status) && (
              <div className="change-request">
                <a
                  href={`mailto:events@impala.travel?subject=Change to Event ID #1634${props.event.details.id}`}
                >
                  <EditIcon />
                  Request Change
                </a>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default EventDetails;
