import React from "react";
import { Form } from "react-bootstrap";

const NewGroup = (props: {
  inputValue: string;
  isTeam: boolean;
  setIsTeam: React.Dispatch<React.SetStateAction<boolean>>;
  isExempt: boolean;
  setIsExempt: React.Dispatch<React.SetStateAction<boolean>>;
  organiserManaged: boolean;
  setOrganiserManaged: React.Dispatch<React.SetStateAction<boolean>>;
  mustShareRoom: boolean;
  setMustShareRoom: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="newGroup">
      <div>
        Create new group called "<b>{props.inputValue}</b>"
      </div>
      <div className="checkbox-group">
        <Form.Check
          id="isTeam"
          name="isTeam"
          type="checkbox"
          checked={props.isTeam}
          onChange={(e) => props.setIsTeam(e.target.checked)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <b>This is a team: Aim to place members in the same hotel</b>
      </div>
      <div className="checkbox-group">
        <Form.Check
          id="isExempt"
          name="isExempt"
          type="checkbox"
          checked={props.isExempt}
          onChange={(e) => props.setIsExempt(e.target.checked)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <b>This group does not count against hotel stay limits</b>
      </div>
      <div className="checkbox-group">
        <Form.Check
          id="mustShareRoom"
          name="mustShareRoom"
          type="checkbox"
          checked={props.mustShareRoom}
          onChange={(e) => props.setMustShareRoom(e.target.checked)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <b>Attendees in this group must share rooms</b>
      </div>
      <div className="checkbox-group">
        <Form.Check
          id="organiserManaged"
          name="organiserManaged"
          type="checkbox"
          checked={props.organiserManaged}
          onChange={(e) => props.setOrganiserManaged(e.target.checked)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <b>Attendees in this group are managed by the organiser</b>
      </div>
    </div>
  );
};

export default NewGroup;
