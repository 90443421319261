import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const EventsTable = ({ events }) => {
  const navigate = useNavigate();

  return (
    <section className="events-list-table">
      <table>
        <thead>
          <tr>
            <th className="hide-on-mobile">Stage</th>
            <th>Event name</th>
            <th>Location</th>
            <th className="hide-on-mobile">Planner</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) => (
            <tr key={event.id}>
              <td className="hide-on-mobile">
                <div className={`tag tag-${event.stage}`}>{event.stage}</div>
              </td>
              <td>{event.name || "Team Event"}</td>
              <td>
                <span className="truncate-content">{event.location}</span>
              </td>
              <td className="hide-on-mobile">
                {event.organisers && event.organisers.length > 0
                  ? `${event.organisers[0].name || event.organisers[0].email}${
                      event.organisers.length > 1
                        ? ` +${event.organisers.length - 1} more`
                        : ""
                    }`
                  : ""}
              </td>
              <td className="view-cell">
                {event.hasAccess ? (
                  <>
                    <Button
                      disabled={["Ongoing", "Completed"].includes(event.stage)}
                      className="event-edit-planners-button event-table-button"
                      onClick={() =>
                        navigate(`/srv/event/1634${event.id}/planners`)
                      }
                    >
                      Manage
                    </Button>
                    <Button
                      className="event-view-button event-table-button"
                      onClick={() => navigate(`/events/1634${event.id}`)}
                    >
                      View
                    </Button>
                  </>
                ) : (
                  <Button
                    disabled={["Ongoing", "Completed", "Cancelled"].includes(
                      event.stage
                    )}
                    className="event-view-button "
                    onClick={() =>
                      navigate(`/srv/event/1634${event.id}/access`)
                    }
                  >
                    {event.requestedAccess
                      ? "Access requested"
                      : "Request access"}
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default EventsTable;
