import React, { useState } from "react";
import {
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
} from "@aws-amplify/ui-react";

import { ReactComponent as GroupsIcon } from "../img/services-events.svg";
import { ReactComponent as WarningIcon } from "../img/warning-triangle.svg";

import { Button, Form } from "react-bootstrap";

import { Event } from "./EventStatus";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";

const EventAttendeeGroups = (props: { event: Event; onUpdate: Function }) => {
  const [axios] = useAuthenticatedAxios(true);
  const [isCreateNewGroup, setIsCreateNewGroup] = useState(false);
  const [isEditGroup, setIsEditGroup] = useState(false);
  const [editTarget, setEditTarget] = useState("");
  const [oldGroupName, setOldGroupName] = useState("");
  const [newGroupName, setNewGroupName] = useState("");
  const [isExempt, setIsExempt] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [organiserManaged, setOrganiserManaged] = useState(false);
  const [mustShareRoom, setMustShareRoom] = useState(false);

  const handleCreateGroup = async () => {
    setIsCreating(true);
    try {
      const body = {
        groupName: newGroupName,
        isExempt: isExempt,
        isTeam: isTeam,
        isSilent: organiserManaged,
        mustShareRoom: mustShareRoom,
      };
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/group`,
        body
      );
      props.onUpdate();
      setNewGroupName("");
      setIsExempt(false);
      setIsTeam(false);
      setOrganiserManaged(false);
      setMustShareRoom(false);
      setIsCreateNewGroup(false);
    } catch (err) {
      alert("Could not add new attendee group, please try again later");
      console.error("Error adding group:", err);
    } finally {
      setIsCreating(false);
    }
  };

  const handleUpdateGroup = async () => {
    setIsCreating(true);
    try {
      const body = {
        groupName: newGroupName,
        isExempt: isExempt,
        isTeam: isTeam,
        isSilent: organiserManaged,
        mustShareRoom: mustShareRoom,
      };
      await axios.put(
        `/api/users/events/1634${props.event.details.id}/group/${editTarget}`,
        body
      );
      props.onUpdate();
      setNewGroupName("");
      setIsExempt(false);
      setIsTeam(false);
      setOrganiserManaged(false);
      setMustShareRoom(false);
      setIsEditGroup(false);
    } catch (err) {
      alert("Could not update attendee group, please try again later");
      console.error("Error updating group:", err);
    } finally {
      setIsCreating(false);
    }
  };

  const handleDeleteGroup = async (groupId: string) => {
    setIsCreating(true);
    try {
      await axios.delete(
        `/api/users/events/1634${props.event.details.id}/group/${groupId}`
      );
      props.onUpdate();
    } catch (err) {
      alert("Could not delete attendee group, please try again later");
      console.error("Error deleting group:", err);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <>
      {isCreateNewGroup || isEditGroup ? (
        <div className="add-new-group-container">
          <GroupsIcon className="hero-image" />
          <h3>
            {isCreateNewGroup
              ? "Create a new attendee group"
              : `Edit attendee group "${oldGroupName}"`}
          </h3>
          <section className="attendee-group-form">
            <Form.Label for="newGroupName">Group name</Form.Label>
            <Form.Control
              type="text"
              id="newGroupName"
              value={newGroupName}
              onChange={(e: any) => setNewGroupName(e.target.value)}
            />
            {props.event.groups &&
              props.event.groups
                .map((g) => g.name.toLowerCase())
                .includes(newGroupName.toLowerCase()) &&
              newGroupName.toLowerCase() !== oldGroupName.toLowerCase() && (
                <div className="warning-error">
                  <WarningIcon />
                  This group name already exists
                </div>
              )}
            <div className="ignore-stay-limits">
              <div className="checkbox-group">
                <Form.Check
                  id="isTeam"
                  name="isTeam"
                  type="checkbox"
                  checked={isTeam}
                  onChange={(e) => setIsTeam(e.target.checked)}
                />
                <Form.Label for="isTeam">
                  <b>This is a team</b>
                  <p>
                    When creating a rooming list, we aim to co-locate team
                    members in the same hotel where possible.
                  </p>
                </Form.Label>
              </div>
              <div className="checkbox-group">
                <Form.Check
                  id="isExempt"
                  name="isExempt"
                  type="checkbox"
                  checked={isExempt}
                  onChange={(e) => setIsExempt(e.target.checked)}
                />
                <Form.Label for="isExempt">
                  <b>This group does not count against hotel stay limits</b>
                  <p>
                    If selected then anyone in this group won't count against
                    any maximum stay limits.
                  </p>
                </Form.Label>
              </div>
              <div className="checkbox-group">
                <Form.Check
                  id="mustShareRoom"
                  name="mustShareRoom"
                  type="checkbox"
                  checked={mustShareRoom}
                  onChange={(e) => setMustShareRoom(e.target.checked)}
                />
                <Form.Label for="mustShareRoom">
                  <b>Attendees must share rooms</b>
                  <p>
                    If selected then anyone in this group will be asked to share
                    rooms to keep accomodation cost low.
                  </p>
                </Form.Label>
              </div>
              <div className="checkbox-group">
                <Form.Check
                  id="organiserManaged"
                  name="organiserManaged"
                  type="checkbox"
                  checked={organiserManaged}
                  onChange={(e) => setOrganiserManaged(e.target.checked)}
                />
                <Form.Label for="organiserManaged">
                  <b>Managed by organiser</b>
                  <p>
                    If selected then anyone in this group will not receive any
                    emails from Impala. All attendance and hotel dates must be
                    managed by the event organiser on their behalf.
                  </p>
                </Form.Label>
              </div>
            </div>
          </section>
          <div className="add-group-buttons">
            <Button
              className="btn-secondary"
              onClick={() => {
                setNewGroupName("");
                setIsExempt(false);
                setIsTeam(false);
                setIsCreateNewGroup(false);
                setIsEditGroup(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={isCreateNewGroup ? handleCreateGroup : handleUpdateGroup}
              disabled={
                isCreating ||
                !newGroupName ||
                (props.event.groups &&
                  props.event.groups
                    .map((g) => g.name.toLowerCase())
                    .includes(newGroupName.toLowerCase()) &&
                  newGroupName.toLowerCase() !== oldGroupName.toLowerCase())
              }
            >
              {isCreateNewGroup ? "Create Group" : "Save Changes"}
            </Button>
          </div>
        </div>
      ) : (
        <>
          {!props.event.groups || props.event.groups.length === 0 ? (
            <div className="add-new-group-container">
              <GroupsIcon className="hero-image" />
              <h3>You've not created any attendee groups yet</h3>
              <p>
                Take control of who can book hotel rooms and when. Simplify your
                attendee experience with one source of truth.
              </p>
              <Button
                disabled={["Ongoing", "Completed", "Cancelled"].includes(
                  props.event.details.status
                )}
                className="invite-or-add btn-secondary"
                onClick={() => setIsCreateNewGroup(true)}
              >
                Create an attendee group
              </Button>
            </div>
          ) : (
            <div>
              <div className="control-header">
                Control attendance, room sharing, and hotel assignment using
                attendee groups.{" "}
                <Button
                  disabled={["Ongoing", "Completed", "Cancelled"].includes(
                    props.event.details.status
                  )}
                  className="invite-or-add"
                  onClick={() => setIsCreateNewGroup(true)}
                >
                  Create a new group
                </Button>
              </div>
              <div>
                <Table
                  className="attendee-list"
                  caption=""
                  highlightOnHover={false}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell as="th">Group name</TableCell>
                      <TableCell as="th">Attendees in this group</TableCell>
                      <TableCell as="th">Is a team?</TableCell>
                      <TableCell as="th">
                        Exempt from hotel stay limits?
                      </TableCell>
                      <TableCell as="th">Must share rooms?</TableCell>
                      <TableCell as="th">Managed by organiser?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.event.groups.map((group) => (
                      <TableRow key={group.id}>
                        <TableCell>{group.name}</TableCell>
                        <TableCell>
                          {
                            props.event.attendees.filter(
                              (attendee) =>
                                attendee.categories &&
                                attendee.categories.includes(group.name)
                            ).length
                          }
                        </TableCell>
                        <TableCell>{group.is_team ? "Yes" : "No"}</TableCell>
                        <TableCell>
                          {group.exempt_from_room_night_limit ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          {group.must_share_room ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>{group.is_silent ? "Yes" : "No"}</TableCell>
                        <TableCell>
                          <Button
                            disabled={[
                              "Ongoing",
                              "Completed",
                              "Cancelled",
                            ].includes(props.event.details.status)}
                            className="btn-secondary edit-groups"
                            onClick={() => {
                              setEditTarget(group.id);
                              setOldGroupName(group.name);
                              setNewGroupName(group.name);
                              setIsExempt(group.exempt_from_room_night_limit);
                              setIsTeam(group.is_team);
                              setMustShareRoom(group.must_share_room);
                              setOrganiserManaged(group.is_silent);
                              setIsEditGroup(true);
                            }}
                          >
                            Edit
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            disabled={[
                              "Ongoing",
                              "Completed",
                              "Cancelled",
                            ].includes(props.event.details.status)}
                            className="btn-secondary danger-button"
                            onClick={() => handleDeleteGroup(group.id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EventAttendeeGroups;
