import './BannerAlert.css';

import React from 'react';

import { ReactComponent as IconHelp } from '../../images/icons/help-circle.svg';
import { ReactComponent as IconNeutral } from '../../images/icons/information-circle.svg';
import { ReactComponent as IconPositive } from '../../images/icons/tick-circle.svg';
import { ReactComponent as IconNegative } from '../../images/icons/warning-triangle.svg';

  export interface BannerProps {
    className?: 'string';
    children?: React.ReactNode;
    variant?: 'BannerPositive' | 'BannerNegative' | 'BannerNeutral' | 'BannerHelp';
}

  const BannerAlert = ({
    children = null,
    variant = 'BannerNeutral'
  }: BannerProps): JSX.Element => {
    if (variant === 'BannerPositive') {
      return (
        <div className={`BannerAlert ${variant}`}>
            <IconPositive />
            {children}
        </div>
      );
    }
    if (variant === 'BannerNegative') {
      return (
        <div className={`BannerAlert ${variant}`}>
            <IconNegative />
            {children}
        </div>      
        );
    }

    if (variant === 'BannerHelp') {
        return (
          <div className={`BannerAlert ${variant}`}>
              <IconHelp />
              {children}
          </div>      
          );
      }
  
    return (
        <div className={`BannerAlert ${variant}`}>
            <IconNeutral />
            {children}
        </div>    
    );
  };

export default BannerAlert;