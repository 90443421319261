import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import useAuthenticatedAxios from '../../lib/useAuthenticatedAxios';
import { ReactComponent as HotelIcon } from '../../img/hotel.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import HotelPackages from './HotelPackages';


const HotelsAudit = () => {
  const [cities, setCities] = useState([]);
  const [cachedData, setCachedData] = useState({});
  const [loading, setLoading] = useState(true);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [meanLoadingStates, setMeanLoadingStates] = useState({});
  const [savingsLoadingStates, setSavingsLoadingStates] = useState({});
  const [contractSavingsLoadingStates, setContractSavingsLoadingStates] = useState({});
  const [auditBoundaries, setAuditBoundaries] = useState([]);
  const [boundariesFetched, setBoundariesFetched] = useState(false);
  const [authAxios, tokenSet] = useAuthenticatedAxios(true);
  const [discountError, setDiscountError] = useState(false);
  const [meanError, setMeanError] = useState({});
  const [savingsError, setSavingsError] = useState({});
  const [contractSavingsError, setContractSavingsError] = useState({});
  const [discountFetched, setDiscountFetched] = useState(false);
  const [cachedDataFetched, setCachedDataFetched] = useState(false);
  const [cityDataFetched, setCityDataFetched] = useState(false);

  const [totalImmediateSavings, setTotalImmediateSavings] = useState(0);
  const [totalFutureSavings, setTotalFutureSavings] = useState(0);
  const [totalSavingsAvailable, setTotalSavingsAvailable] = useState(0);
  const [totalNewContracts, setTotalNewContracts] = useState(0);

  const [prevTotalImmediateSavings, setPrevTotalImmediateSavings] = useState(0);
  const [prevTotalFutureSavings, setPrevTotalFutureSavings] = useState(0);
  const [prevTotalSavingsAvailable, setPrevTotalSavingsAvailable] = useState(0);
  const [prevTotalNewContracts, setPrevTotalNewContracts] = useState(0);

  const [hotelPackages, setHotelPackages] = useState([]);

  const navigate = useNavigate(); // Initialize useNavigate hook

  const DISCOUNT_KEY = 'discounted_booking_percentages';
  const MEAN_DISCOUNT_KEY = 'mean_discount';
  const MAX_SAVINGS_KEY = 'max_savings';
  const CONTRACT_GAP_KEY = 'total_new_contracts';
  const CONTRACT_SAVINGS_KEY = 'potential_contract_savings';

  useEffect(() => {
    const recalculateTotals = () => {
      const itotalImmediateSavings = cities.reduce((total, city) => total + (city[MAX_SAVINGS_KEY] || 0), 0);
      const itotalFutureSavings = cities.reduce((total, city) => total + (city[CONTRACT_SAVINGS_KEY] || 0), 0);
      const itotalSavingsAvailable = itotalImmediateSavings + itotalFutureSavings;
      const itotalNewContracts = cities.reduce((total, city) => total + (city[CONTRACT_GAP_KEY] || 0), 0);
    
      setPrevTotalImmediateSavings(totalImmediateSavings);
      setPrevTotalFutureSavings(totalFutureSavings);
      setPrevTotalSavingsAvailable(totalSavingsAvailable);
      setPrevTotalNewContracts(totalNewContracts);

      setTotalImmediateSavings(itotalImmediateSavings);
      setTotalFutureSavings(itotalFutureSavings);
      setTotalSavingsAvailable(itotalSavingsAvailable);
      setTotalNewContracts(itotalNewContracts);
    };

    recalculateTotals();
  }, [cities]);


  // Fetch cities
  useEffect(() => {
    if (!tokenSet) return;

    const fetchHotelCachedData = async () => {
      try {
        const response = await authAxios.get('/api/audit/hotel-audit-cached-load');
        return response.data.cached_data;
      } catch (error) {
        console.error('Error fetching hotel cached data:', error);
      }
    };

    const initializeData = async () => {
      try {
        const cityResponse = await authAxios.get('/api/audit/key-cities');
        if (cityResponse.data) {
          setCities(cityResponse.data.map(city => ({
            ...city,
            id: city.city,
          })));

          const cachedData = await fetchHotelCachedData();
          setCachedData(cachedData);
          setCachedDataFetched(true);

          setHotelPackages(cachedData['/hotel-contract-packages']?.general?.packages || []);
        }
      } catch (error) {
        console.error('Error fetching key cities:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, [authAxios, tokenSet]);

  useEffect(() => {
    if (!cities.length || !cachedDataFetched || !discountFetched || cityDataFetched || !tokenSet) return;


    const fetchCityData = async (city) => {
      setMeanLoadingStates(prev => ({ ...prev, [city]: true }));
      setSavingsLoadingStates(prev => ({ ...prev, [city]: true }));
      setContractSavingsLoadingStates(prev => ({ ...prev, [city]: true }));
    
      const endpoints = [
        { key: MEAN_DISCOUNT_KEY, url: '/city-mean-analysis', stateSetter: setMeanError, loadingSetter: setMeanLoadingStates },
        { key: MAX_SAVINGS_KEY, url: '/savings-available-new-contracts', stateSetter: setSavingsError, loadingSetter: setSavingsLoadingStates },
        { key: CONTRACT_GAP_KEY, url: '/savings-available-new-contracts',  stateSetter: setSavingsError, loadingSetter: setSavingsLoadingStates },
        { key: CONTRACT_SAVINGS_KEY, url: '/contract-savings', stateSetter: setContractSavingsError, loadingSetter: setContractSavingsLoadingStates }
      ];
    
      for (const endpoint of endpoints) {
        const data = cachedData[endpoint.url]?.[city];
        if (data) {
          setCities(prevCities => {
            return prevCities.map(c => c.city === city ? { ...c, [endpoint.key]: data[endpoint.key] } : c);
          });
          endpoint.loadingSetter(prev => ({ ...prev, [city]: false }));
          continue;
        }
    
        await authAxios.get('/api/audit' + endpoint.url + '/' + city)
          .then(response => {
            const data = response.data[endpoint.key];
            setCities(prevCities =>
              prevCities.map(c => c.city === city ? { ...c, [endpoint.key]: data } : c)
            );
          })
          .catch(error => {
            console.error(`Error fetching ${endpoint.key} for ${city}:`, error);
            endpoint.stateSetter(prev => ({ ...prev, [city]: true }));
          })
          .finally(() => endpoint.loadingSetter(prev => ({ ...prev, [city]: false })));
      }
    
      return true;
    };

    cities.map(city => {
      return fetchCityData(city.city);
    });
    setCityDataFetched(true);

  }, [tokenSet, authAxios, cachedData, cities, cachedDataFetched, cityDataFetched, discountFetched]);

  useEffect(() => {
    if (!cities.length || !tokenSet || discountFetched) return;

    const fetchDiscountedBookingPercentages = async () => {
      try {
        setDiscountLoading(true);
        const response = await authAxios.post('/api/audit/city-discount-analysis', { cities: cities.map(city => city.city) });
        const { discounted_booking_percentages } = response.data;

        const updatedCities = cities.map(city => {
          const discountEntry = discounted_booking_percentages.find(entry => entry.city_name === city.city);
          return {
            ...city,
            [DISCOUNT_KEY]: discountEntry ? discountEntry.percentage : null,
          };
        });

        setCities(updatedCities);
        setDiscountFetched(true);
      } catch (error) {
        console.error('Error fetching discounted booking percentages:', error);
        setDiscountError(true);
      } finally {
        setDiscountLoading(false);
      }
    };

    fetchDiscountedBookingPercentages();
  }, [cities, tokenSet, authAxios, discountFetched])

  // Fetch audit boundaries
  useEffect(() => {
    if (tokenSet) {
      authAxios.get('/api/audit/audit-boundaries')
        .then(response => {
          if (response.data) {
            setAuditBoundaries(response.data.audit_boundaries);
            setBoundariesFetched(true);
          }
        })
        .catch(error => {
          console.error('Error fetching audit boundaries:', error);
        });
    }
  }, [authAxios, tokenSet]);

  const getClassForCity = (city, key, value) => {
    const boundary = auditBoundaries.find(
      boundary => boundary.city === city.city && boundary.key === key
    ) || auditBoundaries.find(
      boundary => boundary.city === 'all' && boundary.key === key
    );

    if (!boundary) {
      return '';
    }

    const numericValue = parseFloat(value);

    if (boundary.direction === 'higher') {
      if (numericValue >= parseFloat(boundary.good)) return 'good';
      if (numericValue >= parseFloat(boundary.average)) return 'average';
      return 'bad';
    } else if (boundary.direction === 'lower') {
      if (numericValue <= parseFloat(boundary.good)) return 'good';
      if (numericValue <= parseFloat(boundary.average)) return 'average';
      return 'bad';
    }

    return '';
  };

  const renderDiscountedBookingsCell = (row) => {
    if (discountLoading) {
      return <div className="display-cell neutral"></div>;
    }
    if (discountError) {
      return <div className="display-cell neutral">Err</div>;
    }
    if (row[DISCOUNT_KEY] == null) {
      return <div className="display-cell empty">-</div>;
    }

    const cellClass = getClassForCity(row, DISCOUNT_KEY, row[DISCOUNT_KEY]);
    return <div className={`display-cell ${cellClass}`}>{`${(row[DISCOUNT_KEY] * 100).toFixed(2)}%`}</div>;
  };

  const renderMeanDiscountCell = (row) => {
    if (meanLoadingStates[row.city]) {
      return <div className="display-cell neutral">Loading...</div>;
    }
    if (meanError[row.city]) {
      return <div className="display-cell neutral">Err</div>;
    }
    if (row[MEAN_DISCOUNT_KEY] == null) {
      return <div className="display-cell empty">-</div>;
    }

    const cellClass = getClassForCity(row, MEAN_DISCOUNT_KEY, row[MEAN_DISCOUNT_KEY]);
    return <div className={`display-cell ${cellClass}`}>{`${(row[MEAN_DISCOUNT_KEY] * 100).toFixed(2)}%`}</div>;
  };

  const renderMaxSavingsCell = (row) => {
    if (savingsLoadingStates[row.city]) {
      return <div className="display-cell neutral">Loading...</div>;
    }
    if (savingsError[row.city]) {
      return <div className="display-cell neutral">Err</div>;
    }

    if (row[MAX_SAVINGS_KEY] == null) {
      return <div className="display-cell empty">-</div>;
    }

    return (
      <div className="display-cell">
        {row[MAX_SAVINGS_KEY] !== null && row[MAX_SAVINGS_KEY] >= 1000000 ? 
          `$${(row[MAX_SAVINGS_KEY] / 1000000).toFixed(1)}M` : 
          row[MAX_SAVINGS_KEY] !== null && row[MAX_SAVINGS_KEY] >= 1000 ? 
          `$${(row[MAX_SAVINGS_KEY] / 1000).toFixed(1)}K` : 
          `$${row[MAX_SAVINGS_KEY].toFixed(2)}`}
      </div>
    );
  };

  const renderContractGapCell = (row) => {
    if (savingsLoadingStates[row.city]) {
      return <div className="display-cell neutral">Loading...</div>;
    }
    if (savingsError[row.city]) {
      return <div className="display-cell neutral">Err</div>;
    }
    if (row[CONTRACT_GAP_KEY] == null) {
      return <div className="display-cell empty">-</div>;
    }

    return <div className="display-cell">{row[CONTRACT_GAP_KEY]}</div>;
  };

  const renderContractSavingsCell = (row) => {
    if (contractSavingsLoadingStates[row.city]) {
      return <div className="display-cell neutral">Loading...</div>;
    }
    if (contractSavingsError[row.city]) {
      return <div className="display-cell neutral">Err</div>;
    }
    if (row[CONTRACT_SAVINGS_KEY] == null) {
      return <div className="display-cell empty">-</div>;
    }

    return (
      <div className="display-cell">
        {row[CONTRACT_SAVINGS_KEY] !== null && row[CONTRACT_SAVINGS_KEY] >= 1000000 ? 
          `$${(row[CONTRACT_SAVINGS_KEY] / 1000000).toFixed(1)}M` : 
          row[CONTRACT_SAVINGS_KEY] !== null && row[CONTRACT_SAVINGS_KEY] >= 1000 ? 
          `$${(row[CONTRACT_SAVINGS_KEY] / 1000).toFixed(1)}K` : 
          `$${row[CONTRACT_SAVINGS_KEY].toFixed(2)}`}
      </div>
    );
  };

  const columns = [
    {
      name: 'City Name',
      selector: row => row.city,
      sortable: true,
    },
    {
      name: 'Total Nights',
      selector: row => row.total_nights,
      sortable: true,
    },
    {
      name: 'Discounted Bookings',
      cell: renderDiscountedBookingsCell,
      sortable: true,
      sortFunction: (a, b) => {
        return parseFloat(a[DISCOUNT_KEY]) - parseFloat(b[DISCOUNT_KEY]);
      }
    },
    // {
    //   name: 'Mean Discount',
    //   cell: renderMeanDiscountCell,
    //   sortable: true,
    //   sortFunction: (a, b) => {
    //     return parseFloat(a[MEAN_DISCOUNT_KEY]) - parseFloat(b[MEAN_DISCOUNT_KEY]);
    //   }
    // },
    {
      name: 'Immediate Savings',
      cell: renderMaxSavingsCell,
      sortable: true,
      sortFunction: (a, b) => {
        return parseFloat(a[MAX_SAVINGS_KEY]) - parseFloat(b[MAX_SAVINGS_KEY]);
      }
    },
    {
      name: 'Contract Gap',
      cell: renderContractGapCell,
      sortable: true,
      sortFunction: (a, b) => {
        return parseFloat(a[CONTRACT_GAP_KEY]) - parseFloat(b[CONTRACT_GAP_KEY]);
      }
    },
    // {
    //   name: 'Contract Savings',
    //   cell: renderContractSavingsCell,
    //   sortable: true,
    //   sortFunction: (a, b) => {
    //     return parseFloat(a[CONTRACT_SAVINGS_KEY]) - parseFloat(b[CONTRACT_SAVINGS_KEY]);
    //   }
    // },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="view-button-wrapper">
            <div className="view-button">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
        )
      }
    }
  ];

  // Add row click handler
  const handleRowClicked = (row) => {
    navigate(`/srv/reporting/city-audit/${row.city}`);
  };

  return (
    <div>
      <div className="audit-wrapper hotel-audit-wrapper">
        <h1><HotelIcon /> Hotels</h1>
        <div className="top-bubbles">
          {/* <div className="bubble black">
            <p className="title">Total Savings Available</p>
            <p className="value">
              <CountUp
                start={prevTotalSavingsAvailable}
                end={totalSavingsAvailable}
                duration={2}
                separator=","
                decimals={2}
                prefix="$"
              />
            </p>
          </div> */}
          <div className="bubble black">
            <p className="title">Total Savings</p>
            <p className="value">
              <CountUp
                start={prevTotalImmediateSavings}
                end={totalImmediateSavings}
                duration={2}
                separator=","
                decimals={2}
                prefix="$"
              />
            </p>
          </div>
          {/* <div className="bubble black">
            <p className="title">Total Future</p>
            <p className="value">
              <CountUp
                start={prevTotalFutureSavings}
                end={totalFutureSavings}
                duration={2}
                separator=","
                decimals={2}
                prefix="$"
              />
            </p>
          </div> */}
          <div className="bubble black">
            <p className="title">New Contracts</p>
            <p className="value">
              <CountUp
                start={prevTotalNewContracts}
                end={totalNewContracts}
                duration={2}
              />
            </p>
          </div>
        </div>
        {loading ? (
          <div className="spinner-container">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <DataTable
              columns={columns}
              data={cities}
              progressPending={loading || discountLoading}
              highlightOnHover
              pagination={cities.length > 10}
              onRowClicked={handleRowClicked}
            />
          </>
        )}
      </div>
      <div className="package-section">
        {hotelPackages.length > 0 && <HotelPackages packages={hotelPackages} />}
      </div>
    </div>
  );
};

export default HotelsAudit;