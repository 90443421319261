export const MODAL_HEADING_1: string = "Mark contract as signed?";
export const MODAL_BUTTON_1: string = "No, don't change";
export const MODAL_BUTTON_2: string = "Mark as Signed";
export const MODAL_HEADING_2: string =
  "Do you want to mark this contract as signed?";
export const MODAL_LABEL: string = "Confirm Contract Signature";
export const MODAL_BODY_TEXT: string =
  "This won't remove it from the list, and you'll still be able to review hotel information.";
export const OFFERS_HEADING: string = "Hotel Offers";
export const REPORTING_HEADING: string = "Reporting";
export const OFFERS_TAB: string = "Offers";
export const SETUP_TAB: string = "Setup";
export const OFFERS_OVERVIEW_HEADING: string = "Your offers";
export const OFFERS_OVERVIEW_TABLE_COLUMN_NAME: string = "Hotel name";
export const OFFERS_OVERVIEW_TABLE_COLUMN_LOCATION: string = "Location";
export const OFFERS_OVERVIEW_TABLE_COLUMN_MIN_RN: string = "Min. room nights";
export const OFFERS_OVERVIEW_TABLE_COLUMN_RATE: string = "Rate";
export const OFFERS_OVERVIEW_TABLE_OFFER_TYPE: string = "Type";
export const OFFERS_OVERVIEW_OFFER_COUNT: string =
  "You have {0} offer(s) that need signed";
export const SIGN_BUTTON: string = "Sign";
export const SIGNED: string = "Signed";
export const REJECTED: string = "Rejected";
export const EXPIRED: string = "Expired";
export const LIVE: string = "Live";
export const SETUPREQUESTED: string = "Setting Up...";
export const BACK_TO_OFFER_OVERVIEW: string = "Back to the Hotel Offer page";
export const OFFER_DETAILS: string = "Offer details";
export const BOOKING_LINK: string = "View on Booking.com";
export const HOTEL_LINK: string = "Hotel Website";
export const NEGOTIATED_RATES_HEADING: string = "Negotiated Rates";
export const SOMETHING_NOT_RIGHT_LINK: string =
  "Something not right? Let us know";
export const RESTRICTIONS: string = "Restrictions";
export const ROOM_TYPE: string = "Room Type";
export const CANCELLATION: string = "Cancellation";
export const MEALS: string = "Meals";
export const OFFER: string = "Offer";
export const SIGN_CONTRACT_BUTTON: string = "Sign contract";
export const SETUP_RATES_BUTTON: string = "View setup instructions";
export const SETUP_WEBSITE: string = "Website";
export const SETUP_RATE_CODE: string = "Discount Code";
export const SETUP_BOOKING_INSTRUCTIONS: string = "Booking Instructions";
export const DIRECT_SETUP_HEADER: string = "How to book your rates";
export const SETUP_HEADER: string = "Set up your rates";
export const CANCELLATION_TOOLTIP: string =
  'Applicable cancellation policy.<br />"Any" means that the rate applies regardless which available cancellation policy is booked.';
export const MEALS_TOOLTIP: string =
  'Included meals that apply for this rate.<br />"Any" means that the rate applies regardless which meal plan is booked.';
export const OFFER_TOOLTIP: string =
  "Offered price for a particular room and rate.<br />This can be an absolute price, or a percentage off the public rate.";
export const RESTRICTIONS_TOOLTIP: string =
  "If the rate has restrictions (e.g. seasonal availability), it will be mentioned here.<br />Move your cursor over the row to see restriction details.";
export const OFFER_TYPE_TOOLTIP: string =
  "Rate types can be either Corporate Negiotated Rates (CNRs)<br/>or Group Rates. CNRs will require being set up with your TMC</br>(if applicable) after the contract has been signed.";
export const DATABAR_ALLBOOKINGS: string =
  "This is the total number of hotel bookings<br/> you've made since you signed up for Impala Offers";
export const DATABAR_TOTALSPEND: string =
  "This is the total amount you spent on hotel<br/>bookings since you signed up for Impala Offers";
export const DATABAR_TOTALSAVING: string =
  "This is the total amount you saved through<br/>Impala Offers, compared to public prices";
export const DATABAR_NONOFFER: string =
  "This is the percentage of bookings<br/>that were not part of your Impala Offer";
export const DATABAR_MISSEDSAVING: string =
  "This is the extra amount you could have saved if<br/>all bookings had been made via an Impala Offer";

export function format(input: string, args: string[]) {
  return input.replace(/{([0-9]+)}/g, function (match, index) {
    return typeof args[index] == "undefined" ? match : args[index];
  });
}
