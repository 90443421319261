import "./ConfirmSignatureModal.css";

import React, { useState } from "react";
import Modal from "react-modal";

import { Button, Heading, Text, ThemeProvider } from "@aws-amplify/ui-react";
import { ProgressBar } from "react-bootstrap";

import { ImpalaTheme } from "../../theme/index";
import { MODAL_HEADING_2, MODAL_LABEL } from "../../utils/Strings";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0",
    transform: "translate(-50%, -50%)",
    maxWidth: "550px",
  },
};

function ContractUploadModal(props: {
  contractUrl: string;
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hotelName: string;
  customerDealId: number;
}) {
  const [axios] = useAuthenticatedAxios(false);
  const [contractUploadProgress, setContractUploadProgress] = useState(0);

  function closeModal() {
    props.setIsOpen(false);
  }

  const handleContractFileUpload = async (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("contract", file);

    try {
      setContractUploadProgress(0);
      const response = await axios.post(
        `/api/users/hotelDeals/${props.customerDealId}/offline-contract`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1)
            );
            setContractUploadProgress(progress);
          },
        }
      );

      if (response.status !== 200) {
        throw new Error();
      }
      axios.put(`/api/users/hotelDeals/${props.customerDealId}/status`, {
        signedByCorporate: true,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error Uploading or Parsing File");
    } finally {
      setContractUploadProgress(0);
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={MODAL_LABEL}
    >
      <ThemeProvider theme={ImpalaTheme}>
        <Heading className="ModalHeader">Upload your signed contract</Heading>
        <div className="ModalBody contract-sign-modal">
          <p>Your offer contract from <b>{props.hotelName}</b> is ready to be signed.</p>
          <div className="contract-sign-step">
            Download and review your contract.
            <a
              className="btn-secondary" 
              href={props.contractUrl}>
                Download your document
            </a>
          </div>
          <div className="contract-sign-step">
            Sign the document and upload the signed version here.
             <label
              htmlFor="file-upload"
              className="btn-secondary file-upload-label"
            >
              Upload signed contract
            </label>
          </div>
        </div>
        <input
          id="file-upload"
          type="file"
          accept="application/pdf"
          onChange={handleContractFileUpload}
          style={{ display: "none" }}
        />
        {contractUploadProgress > 0 && (
          <ProgressBar
            now={contractUploadProgress}
            label={`${contractUploadProgress}%`}
          />
        )}
        <div className="ModalButtonFooter">
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </ThemeProvider>
    </Modal>
  );
}

export default ContractUploadModal;
