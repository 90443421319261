import React, { useState, useEffect } from "react";
import { ReactComponent as Elements } from "../img/elements.svg";
import { ReactComponent as Badge } from "../img/badge.svg";
import { ReactComponent as AngledArrow } from "../img/angled-arrow-ccw.svg";
import { ReactComponent as HotelGreen } from "../images/icons/hotel-green.svg";
import { ReactComponent as FilterIcon } from "../img/filter.svg";
import { ReactComponent as ActiveFilterIcon } from "../img/filter-remove.svg";
import { ReactComponent as CheckmarkCircleRed } from "../images/icons/checkmark-circle-filled.svg";
import { ReactComponent as TimerRed } from "../images/icons/timer.svg";
import { Tooltip } from "react-tooltip";

import {
  Event,
  EventAttendee,
  EventAttendeeDate,
  EventHotel,
} from "./EventStatus";
import {
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Button,
} from "@aws-amplify/ui-react";
import { Form } from "react-bootstrap";

import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";
import BannerAlert from "./BannerAlert/BannerAlert";
import RoomingListChangeRequestModal from "./RoomingListChangeRequestModal";
import EventAttendeeRLNotes from "./EventAttendeeRLNotes";
import { format } from "date-fns";
import EditRoomingList from "./EditRoomingList";
import RoomingListFilterModal from "./RoomingListFilterModal";

type Option = { label: string; value: string };

type Stay = {
  attendeeId: string;
  setup: string[];
  hotel: string[];
  checkIn: string[];
  checkOut: string[];
  reference: string[];
};

const EventAttendeeRoomingList = (props: {
  event: Event;
  onUpdate: Function;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
  setSelectedAttendees: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [axios] = useAuthenticatedAxios(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [requestSent, setRequestSent] = useState(false);
  const [showChangeRequestModal, setShowChangeRequestModal] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [attendees, setAttendees] = useState<EventAttendee[]>([]);
  const [stays, setStays] = useState<Stay[]>([]);
  const [nameFilter, setNameFilter] = useState<string | undefined>();
  const [filterIndex, setFilterIndex] = useState(0);
  const [showAttendeeFilterModal, setShowAttendeeFilterModal] = useState(false);
  const [eventGroups, setEventGroups] = useState<Option[]>([]);
  const [filterGroups, setFilterGroups] = useState<Option[]>([]);
  const [eventHotels, setEventHotels] = useState<Option[]>([]);
  const [filterHotels, setFilterHotels] = useState<Option[]>([]);
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);
  const [showNotInvited, setShowNotInvited] = useState(true);
  const [showInvited, setShowInvited] = useState(true);

  const [displayedAttendees, setDisplayedAttendees] = useState<EventAttendee[]>(
    []
  );

  useEffect(() => {
    const options: Option[] = props.event.details.attendee_categories
      ?.filter((c) =>
        props.event.groups
          .filter((g) => g.is_team)
          .map((g) => g.name)
          .includes(c)
      )
      .map((g) => {
        return {
          label: g,
          value: g,
        };
      });
    setEventGroups(options);
  }, [props.event.details.attendee_categories, props.event.groups]);

  useEffect(() => {
    const options: Option[] = props.event.hotels.map((h: EventHotel) => {
      return {
        label: h.name,
        value: h.id.toString(),
      };
    });
    setEventHotels(options);
  }, [props.event.hotels]);

  useEffect(() => {
    setDisplayedAttendees(
      attendees
        // Name filter
        .filter((attendee) => {
          return (
            !nameFilter ||
            attendee.email.toLowerCase().includes(nameFilter.toLowerCase()) ||
            attendee.name?.toLowerCase().includes(nameFilter.toLowerCase())
          );
        })
        // Invite status filter
        .filter((attendee) => {
          if (!showInvited && attendee.room_confirmation_sent) return false;
          if (!showNotInvited && !attendee.room_confirmation_sent) return false;
          return true;
        })
        // Group Filter
        .filter((attendee) => {
          if (filterGroups.length === 0) return true;
          return (
            attendee.categories.filter((category) =>
              filterGroups.map((g) => g.label).includes(category)
            ).length > 0
          );
        })
        // Hotel Filter
        .filter((attendee) => {
          if (filterHotels.length === 0) return true;
          return (
            attendee.dates.filter((date) =>
              filterHotels
                .map((h) => Number(h.value))
                .includes(date.assigned_hotel)
            ).length > 0
          );
        })
        .sort((a, b) => (a?.email < b?.email ? -1 : 1))
    );
  }, [
    attendees,
    filterGroups,
    filterHotels,
    nameFilter,
    props.event.attendees,
    props.event.dates,
    showInvited,
    showNotInvited,
  ]);

  useEffect(() => {
    const list: Stay[] = [];
    //const seen: Set<string> = new Set();
    props.event.attendees
      .filter(
        (attendee) =>
          attendee.book_room &&
          attendee.dates.filter((d) => d.hotel_required).length > 0
      )
      .forEach((attendee) => {
        //if (seen.has(attendee.id)) return;
        let hotels: string[] = [];
        let lastDate: Date = new Date();
        let checkIns: Date[] = [];
        let checkOuts: Date[] = [];
        let setups: string[] = [];
        let references: string[] = [];
        attendee.dates
          .filter((date) => date.hotel_required && date.assigned_hotel)
          .sort(
            (a, b) =>
              (props.event.dates.find((d) => d.id === a.date)?.date.getTime() ||
                0) -
              (props.event.dates.find((d) => d.id === b.date)?.date.getTime() ||
                0)
          )
          .forEach((date) => {
            const currentHotel = props.event.hotels.find(
              (h) => h.id === date.assigned_hotel
            )?.name;
            const currentDate = props.event.dates.find(
              (d) => d.id === date.date
            )?.date;
            if (!currentHotel || !currentDate) return false;
            if (
              hotels.length === 0 ||
              !hotels.slice(-1).includes(currentHotel) ||
              currentDate.getDate() > lastDate.getDate() + 1
            ) {
              references.push("");
              hotels.push(currentHotel);
              setups.push(date.room_setup);
              if (checkIns.length > 0) {
                let checkOut = new Date(lastDate);
                checkOut.setDate(checkOut.getDate() + 1);
                checkOuts.push(checkOut);
              }
              checkIns.push(currentDate);
            }
            lastDate = currentDate;
            if (date.reference) {
              references.pop();
              references.push(date.reference);
            }
          });
        if (checkIns.length > checkOuts.length) {
          let checkOut = new Date(lastDate);
          checkOut.setDate(checkOut.getDate() + 1);
          checkOuts.push(checkOut);
        }

        const room = {
          attendeeId: attendee.id,
          setup: setups,
          hotel: hotels,
          checkIn: checkIns.map((d) => format(d, "dd MMM")),
          checkOut: checkOuts.map((d) => format(d, "dd MMM")),
          reference: references,
        };

        list.push(room);
        //seen.add(attendee.id);
      });
    setStays(list);
  }, [props.event.attendees, props.event.dates, props.event.hotels]);

  const handleConfirmList = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/rooming-list`
      );
      props.onUpdate();
    } catch (err) {
      alert("Could not confirm rooming list, please try again later");
      console.error("Error adding group:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const newAttendees = props.event.attendees
      .filter((a) => a.book_room && a.dates.find((d) => d.hotel_required))
      .sort((a: EventAttendee, b: EventAttendee) =>
        a.assigned_hotel < b.assigned_hotel ? -1 : 1
      );
    newAttendees.forEach((attendee) =>
      attendee.dates.forEach((date: EventAttendeeDate) => {
        date.assigned_hotel = attendee.assigned_hotel;
        date.sharing_room_with = attendee.sharing_room_with;
        date.room_setup = attendee.room_setup;
      })
    );
    setAttendees(newAttendees);
  }, [props.event.attendees]);

  const handleSelectAll = (checked: boolean) => {
    let newSelectedAttendees = structuredClone(selectedAttendees);
    if (checked) {
      newSelectedAttendees.push(
        ...displayedAttendees
          .filter(
            (a) =>
              !a.is_silent &&
              a.dates.find((d) => d.assigned_hotel && d.hotel_required)
          )
          .map((a) => a.id)
          .filter((a) => !selectedAttendees.includes(a))
      );
    } else {
      newSelectedAttendees = newSelectedAttendees.filter(
        (id: string) => !displayedAttendees.map((a) => a.id).includes(id)
      );
    }
    setSelectedAttendees(newSelectedAttendees);
  };

  const handleSelectAttendee = (checked: boolean, attendee: string) => {
    const newSelectedAttendees = structuredClone(selectedAttendees);
    if (checked && !newSelectedAttendees.includes(attendee))
      newSelectedAttendees.push(attendee);
    if (!checked && newSelectedAttendees.includes(attendee))
      newSelectedAttendees.splice(newSelectedAttendees.indexOf(attendee), 1);
    setSelectedAttendees(newSelectedAttendees);
  };

  const handleSubmitNote = async () => {
    try {
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/notes?type=RoomingList`,
        {
          content: newNote,
        }
      );
      setNewNote("");
      setRequestSent(true);
    } catch (e) {
      console.error(e);
      alert("Could not submit preferences. Please try again later.");
    }
  };

  return (
    <>
      {isEdit ? (
        <EditRoomingList
          event={props.event}
          onUpdate={props.onUpdate}
          setIsEdit={setIsEdit}
        ></EditRoomingList>
      ) : (
        <>
          <RoomingListFilterModal
            modalIsOpen={showAttendeeFilterModal}
            setIsOpen={setShowAttendeeFilterModal}
            initialIndex={filterIndex}
            setShowInvited={setShowInvited}
            setShowNotInvited={setShowNotInvited}
            showInvited={showInvited}
            showNotInvited={showNotInvited}
            nameFilter={nameFilter}
            setNameFilter={setNameFilter}
            eventGroups={eventGroups}
            filterGroups={filterGroups}
            setFilterGroups={setFilterGroups}
            eventHotels={eventHotels}
            filterHotels={filterHotels}
            setFilterHotels={setFilterHotels}
          ></RoomingListFilterModal>
          <RoomingListChangeRequestModal
            event={props.event}
            setIsOpen={setShowChangeRequestModal}
            setRequestSent={setRequestSent}
            modalIsOpen={showChangeRequestModal}
          ></RoomingListChangeRequestModal>
          {!props.event.details.rooming_list_sent &&
          props.event.details.is_procured ? (
            <div className="event-not-accepted-wrapper">
              <Elements />
              <h2>We'll create your rooming list after the reply deadline</h2>
              <p>
                Hotels need a rooming list before check-in to plan for guest
                requirements and room types. We’ll create this automatically for
                you, and you’ll have final approval about who goes where.
              </p>
              <p>
                If you have any preferences for how attendees should be placed
                you can let us know below.
              </p>
              <Form.Control
                disabled={["Ongoing", "Completed", "Cancelled"].includes(
                  props.event.details.status
                )}
                as="textarea"
                rows={4}
                id="newNote"
                className="rooming-list-pre-preferences"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
              />
              {requestSent && (
                <BannerAlert variant="BannerPositive">
                  Thanks, we have received your request!
                </BannerAlert>
              )}
              <Button
                disabled={["Ongoing", "Completed", "Cancelled"].includes(
                  props.event.details.status
                )}
                variation="primary"
                onClick={handleSubmitNote}
              >
                Submit Preferences
              </Button>
            </div>
          ) : showNotes ? (
            <EventAttendeeRLNotes
              event={props.event}
              setShowNotes={setShowNotes}
            ></EventAttendeeRLNotes>
          ) : (
            <div>
              <div className="eventAttendeeSummaryBar event-attendee-invite">
                {props.event.details.is_procured && (
                  <h4 className="rooming-list-title">Rooming List</h4>
                )}
                {props.event.details.is_procured && (
                  <div className="summaryBarItem">
                    <a href="#" onClick={() => setShowChangeRequestModal(true)}>
                      Request a change
                    </a>
                  </div>
                )}
                {props.event.details.is_procured && (
                  <div className="summaryBarItem">
                    <a href="#" onClick={() => setShowNotes(true)}>
                      View requests
                    </a>
                  </div>
                )}
                {!props.event.details.is_procured && (
                  <>
                    <div>
                      <Button
                        disabled={
                          selectedAttendees.length === 0 ||
                          ["Ongoing", "Completed", "Cancelled"].includes(
                            props.event.details.status
                          )
                        }
                        onClick={() => {
                          props.setSelectedAttendees(selectedAttendees);
                          props.setSubPage("Send Notification");
                        }}
                      >
                        Notify
                        {selectedAttendees.length > 0
                          ? ` ${selectedAttendees.length}`
                          : ""}{" "}
                        selected
                      </Button>
                    </div>
                    <div className="summaryBarItem">
                      <h6>Showing</h6> {displayedAttendees.length} of{" "}
                      {attendees.length} attendees{" "}
                      <h5>who requested a hotel room</h5>
                    </div>

                    <div className="summaryBarItem export-btn">
                      <Button
                        disabled={
                          isLoading ||
                          ["Ongoing", "Completed", "Cancelled"].includes(
                            props.event.details.status
                          )
                        }
                        onClick={() => setIsEdit(true)}
                        variation="primary"
                      >
                        Edit Rooming List
                      </Button>
                    </div>
                  </>
                )}
                {props.event.details.is_procured && (
                  <div className="summaryBarItem export-btn">
                    {!props.event.details.rooming_list_approved ? (
                      <Button
                        disabled={
                          isLoading ||
                          ["Ongoing", "Completed", "Cancelled"].includes(
                            props.event.details.status
                          )
                        }
                        onClick={handleConfirmList}
                        variation="primary"
                      >
                        Approve Rooming List
                      </Button>
                    ) : props.event.hotels
                        .filter(
                          (h) =>
                            stays.map((s) => s.hotel.includes(h.name)).length >
                            0
                        )
                        .filter(
                          (h) =>
                            h.viewed_rooming_list === true ||
                            h.viewed_rooming_list === false
                        ).length === 0 ? (
                      <div className="rooming-status-information">
                        <CheckmarkCircleRed />
                        Approved
                      </div>
                    ) : props.event.hotels
                        .filter(
                          (h) =>
                            stays.map((s) => s.hotel.includes(h.name)).length >
                            0
                        )
                        .filter((h) => h.confirmed_rooming_list === false)
                        .length > 0 ? (
                      <div className="rooming-status-information">
                        <TimerRed />
                        Pending Hotel Confirmation
                      </div>
                    ) : (
                      <div className="rooming-status-information">
                        <HotelGreen />
                        Confirmed by Hotel(s)
                      </div>
                    )}
                  </div>
                )}
              </div>
              {requestSent && (
                <BannerAlert variant="BannerPositive">
                  <div>
                    <div>Change request sent</div>
                    <div>
                      <a href="#" onClick={() => setShowNotes(true)}>
                        View your change requests
                      </a>
                    </div>
                  </div>
                </BannerAlert>
              )}{" "}
              <Table
                className="attendee-list rooming-list"
                caption=""
                highlightOnHover={false}
              >
                <TableHead>
                  <TableRow>
                    {!props.event.details.is_procured && (
                      <TableCell as="th">
                        <Form.Check
                          id="selectAllAttendees"
                          name="selectAllAttendees"
                          checked={
                            displayedAttendees
                              .filter((a) => !a.is_silent)
                              .filter((a) =>
                                a.dates.find(
                                  (d) => d.assigned_hotel && d.hotel_required
                                )
                              )
                              .filter((a) => !selectedAttendees.includes(a.id))
                              .length === 0
                          }
                          onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                      </TableCell>
                    )}
                    <TableCell as="th">
                      Name
                      {nameFilter ? (
                        <button
                          className="attendee-table-filter clear-filter"
                          aria-label="Clear filter - attendee name"
                          onClick={() => {
                            setNameFilter(undefined);
                          }}
                        >
                          <div
                            data-tooltip-id="filterNameClear"
                            data-tooltip-place="top"
                            data-tooltip-html="<span>Clear filter - Name</span>"
                          >
                            <ActiveFilterIcon />
                            <Tooltip
                              className="eventTooltip"
                              id="filterNameClear"
                            />
                          </div>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setFilterIndex(0);
                            setShowAttendeeFilterModal(true);
                          }}
                          className="attendee-table-filter"
                          aria-label="Filter by attendee name"
                        >
                          <div
                            data-tooltip-id="filterAttendeeNameTooltip"
                            data-tooltip-place="top"
                            data-tooltip-html="<span>Filter by attendee name</span>"
                          >
                            <FilterIcon />
                            <span className="visually-hidden">
                              Filter by attendee name
                            </span>
                            <Tooltip
                              className="eventTooltip"
                              id="filterAttendeeNameTooltip"
                            />
                          </div>
                        </button>
                      )}
                    </TableCell>
                    {!props.event.details.is_procured && (
                      <TableCell as="th">
                        Notified?
                        {!showInvited || !showNotInvited ? (
                          <button
                            className="attendee-table-filter clear-filter"
                            aria-label="Clear filter - Notification status"
                            onClick={() => {
                              setShowInvited(true);
                              setShowNotInvited(true);
                            }}
                          >
                            <div
                              data-tooltip-id="filterInvitedClear"
                              data-tooltip-place="top"
                              data-tooltip-html="<span>Clear filter - Notification status</span>"
                            >
                              <ActiveFilterIcon />
                              <Tooltip
                                className="eventTooltip"
                                id="filterInvitedClear"
                              />
                            </div>
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setFilterIndex(1);
                              setShowAttendeeFilterModal(true);
                            }}
                            className="attendee-table-filter"
                            aria-label="Filter by notification status"
                          >
                            <div
                              data-tooltip-id="filterAttendeeInviteTooltip"
                              data-tooltip-place="top"
                              data-tooltip-html="<span>Filter by notification status</span>"
                            >
                              <FilterIcon />
                              <span className="visually-hidden">
                                Filter by notification status
                              </span>
                              <Tooltip
                                className="eventTooltip"
                                id="filterAttendeeInviteTooltip"
                              />
                            </div>
                          </button>
                        )}
                      </TableCell>
                    )}

                    <TableCell as="th">
                      Team
                      {filterGroups && filterGroups.length > 0 ? (
                        <button
                          className="attendee-table-filter clear-filter"
                          aria-label="Clear filter - Team"
                          onClick={() => {
                            setFilterGroups([]);
                          }}
                        >
                          <div
                            data-tooltip-id="filterAttendeesClear"
                            data-tooltip-place="top"
                            data-tooltip-html="<span>Clear filter - Team</span>"
                          >
                            <ActiveFilterIcon />
                            <Tooltip
                              className="eventTooltip"
                              id="filterAttendeesClear"
                            />
                          </div>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setFilterIndex(2);
                            setShowAttendeeFilterModal(true);
                          }}
                          className="attendee-table-filter"
                          aria-label="Filter by team"
                        >
                          <div
                            data-tooltip-id="filterAttendeeGroupTooltip"
                            data-tooltip-place="top"
                            data-tooltip-html="<span>Filter by team</span>"
                          >
                            <FilterIcon />
                            <span className="visually-hidden">
                              Filter by team
                            </span>
                            <Tooltip
                              className="eventTooltip"
                              id="filterAttendeeGroupTooltip"
                            />
                          </div>
                        </button>
                      )}
                    </TableCell>
                    <TableCell as="th">
                      Hotel
                      {filterHotels && filterHotels.length > 0 ? (
                        <button
                          className="attendee-table-filter clear-filter"
                          aria-label="Clear filter - Hotel"
                          onClick={() => {
                            setFilterHotels([]);
                          }}
                        >
                          <div
                            data-tooltip-id="filterAttendeesClear"
                            data-tooltip-place="top"
                            data-tooltip-html="<span>Clear filter - Hotel</span>"
                          >
                            <ActiveFilterIcon />
                            <Tooltip
                              className="eventTooltip"
                              id="filterAttendeesClear"
                            />
                          </div>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setFilterIndex(3);
                            setShowAttendeeFilterModal(true);
                          }}
                          className="attendee-table-filter"
                          aria-label="Filter by hotel"
                        >
                          <div
                            data-tooltip-id="filterAttendeeHotelTooltip"
                            data-tooltip-place="top"
                            data-tooltip-html="<span>Filter by hotel</span>"
                          >
                            <FilterIcon />
                            <span className="visually-hidden">
                              Filter by hotel
                            </span>
                            <Tooltip
                              className="eventTooltip"
                              id="filterAttendeeHotelTooltip"
                            />
                          </div>
                        </button>
                      )}
                    </TableCell>
                    <TableCell as="th">Dates</TableCell>
                    <TableCell as="th">Room Type</TableCell>
                    <TableCell as="th">Confirmation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedAttendees.map((attendee) => (
                    <TableRow key={attendee.id}>
                      {!props.event.details.is_procured && (
                        <TableCell
                          data-tooltip-id={
                            attendee.is_silent ||
                            !attendee.dates.find(
                              (d) => d.assigned_hotel && d.hotel_required
                            )
                              ? "ManByOrgSelect"
                              : ""
                          }
                          data-tooltip-place="top"
                          data-tooltip-html={
                            attendee.is_silent
                              ? "<span>This attendee is in a group that's managed by the event organiser, and does not receive emails. If you want to send a hotel confirmation email, first change their group membership in the attendee view.</span>"
                              : "<span>This attendee does not have any hotel assigned. Please assign a hotel before sending a notification.</span>"
                          }
                        >
                          {(attendee.is_silent ||
                            !attendee.dates.find(
                              (d) => d.assigned_hotel && d.hotel_required
                            )) && (
                            <Tooltip
                              className="eventTooltip"
                              id="ManByOrgSelect"
                            />
                          )}
                          <Form.Check
                            id="selectAttendee"
                            name="selectAttendee"
                            disabled={
                              attendee.is_silent ||
                              !attendee.dates.find(
                                (d) => d.assigned_hotel && d.hotel_required
                              )
                            }
                            checked={selectedAttendees.includes(attendee.id)}
                            onChange={(e) =>
                              handleSelectAttendee(
                                e.target.checked,
                                attendee.id
                              )
                            }
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <div className="attendee-name-email">
                          <span
                            className=" major"
                            data-tooltip-id="attendee-tooltip"
                            data-tooltip-place="top"
                            data-tooltip-html={attendee.email}
                          >
                            {attendee.name || attendee.email}
                            {attendee.plus_one && (
                              <>
                                {" "}
                                <Badge />
                              </>
                            )}
                          </span>
                        </div>
                        {attendee.sharing_room_with && (
                          <div
                            className="attendee-name-email"
                            data-tooltip-id="attendee-tooltip"
                            data-tooltip-place="top"
                            data-tooltip-html={
                              props.event.attendees.find(
                                (a) => a.id === attendee.sharing_room_with
                              )?.email
                            }
                          >
                            <Tooltip id="attendee-tooltip" />
                            <span className="major rooming-list-sharing">
                              <AngledArrow />
                              <span>
                                {props.event.attendees.find(
                                  (a) => a.id === attendee.sharing_room_with
                                )?.name ||
                                  props.event.attendees.find(
                                    (a) => a.id === attendee.sharing_room_with
                                  )?.email}
                              </span>
                            </span>
                          </div>
                        )}
                      </TableCell>
                      {!props.event.details.is_procured && (
                        <TableCell>
                          {attendee.is_silent
                            ? "Managed by organiser"
                            : attendee.room_confirmation_sent
                            ? "Yes"
                            : "No"}
                        </TableCell>
                      )}
                      <TableCell>
                        <div>
                          {attendee?.categories
                            .filter((c) =>
                              props.event.groups
                                .filter((g) => g.is_team)
                                .map((g) => g.name)
                                .includes(c)
                            )
                            .join(", ")}
                        </div>
                      </TableCell>
                      <TableCell>
                        {stays?.find((s) => s.attendeeId === attendee.id)?.hotel
                          ?.length
                          ? stays
                              .find((s) => s.attendeeId === attendee.id)
                              ?.hotel.map<React.ReactNode>((t) => t)
                              .reduce((prev, curr) => [prev, <br />, curr])
                          : ""}
                      </TableCell>
                      <TableCell>
                        {stays?.find((s) => s.attendeeId === attendee.id)
                          ?.checkIn?.length
                          ? stays
                              ?.find((s) => s.attendeeId === attendee.id)
                              ?.checkIn.map<React.ReactNode>((t, i) => (
                                <>
                                  {t} -{" "}
                                  {
                                    stays?.find(
                                      (s) => s.attendeeId === attendee.id
                                    )?.checkOut[i]
                                  }
                                </>
                              ))
                              .reduce((prev, curr) => [prev, <br />, curr])
                          : ""}
                      </TableCell>
                      <TableCell>
                        {stays?.find((s) => s.attendeeId === attendee.id)?.setup
                          ?.length
                          ? stays
                              ?.find((s) => s.attendeeId === attendee.id)
                              ?.setup.map<React.ReactNode>((t) => t)
                              .reduce((prev, curr) => [prev, <br />, curr])
                          : ""}
                      </TableCell>
                      <TableCell>
                        {stays?.find((s) => s.attendeeId === attendee.id)
                          ?.reference?.length
                          ? stays
                              ?.find((s) => s.attendeeId === attendee.id)
                              ?.reference.map<React.ReactNode>((t) => t)
                              .reduce((prev, curr) => [prev, <br />, curr])
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EventAttendeeRoomingList;
