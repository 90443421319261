import React, { useEffect, useState, useContext } from "react";

import { FeedbackContext } from "../App";

import CreatableSelect from "react-select/creatable";

import { Event } from "./EventStatus";

import { Button, Form } from "react-bootstrap";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";
import { MultiValue } from "react-select";
import NewGroup from "./NewGroup";
import ConfirmModal from "./ConfirmModal";
import PricingListModal from "./PricingListModal";
import { useAuth0 } from "@auth0/auth0-react";

import { ReactComponent as LinkIllustration } from "../img/link-illustration.svg";

type Option = { label: string; value: string };

const AddEventAttendees = (props: { event: Event; onUpdate: Function }) => {
  let userAuth = useAuth0();

  const { feedbackMessage, setFeedbackMessage } = useContext(FeedbackContext);

  const [axios] = useAuthenticatedAxios(true);
  const [attendeeEmails, setAttendeeEmails] = useState("");
  const [invitingAttendees, setInvitingAttendees] = useState(false);

  const [isExempt, setIsExempt] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [mustShareRoom, setMustShareRoom] = useState(false);
  const [organiserManaged, setOrganiserManaged] = useState(false);
  const [eventGroups, setEventGroups] = useState<Option[]>([]);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [pricingListModalOpen, setPricingListModalOpen] = useState(false);

  const [attendeeGroups, setAttendeeGroups] = useState<Option[]>([]);

  useEffect(() => {
    const options: Option[] = props.event.details.attendee_categories?.map(
      (g) => {
        return {
          label: g,
          value: g,
        };
      }
    );
    setEventGroups(options);
  }, [props.event.details.attendee_categories]);

  useEffect(() => {
    setAttendeeEmails("");
    setInvitingAttendees(false);
  }, [props.event.attendees]);

  const handleEmailChange = async (e: any) => {
    setAttendeeEmails(e.target.value);
  };

  const handleCreate = async (inputValue: string) => {
    setInvitingAttendees(true);
    try {
      const body = {
        groupName: inputValue,
        isTeam: isTeam,
        isExempt: isExempt,
        mustShareRoom: mustShareRoom,
        isSilent: organiserManaged,
      };
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/group`,
        body
      );
      setEventGroups([
        ...(structuredClone(eventGroups) || []),
        { value: inputValue, label: inputValue },
      ]);
      handleNewValue([
        ...(attendeeGroups || []),
        { value: inputValue, label: inputValue },
      ]);
    } catch (err) {
      setFeedbackMessage({
        message: `Could not add new attendee group, please try again later.`,
        refresh: !Boolean(feedbackMessage?.refresh),
        type: "error",
      });
      console.error("Error adding group:", err);
    } finally {
      setInvitingAttendees(false);
      setIsExempt(false);
      setIsTeam(false);
      setOrganiserManaged(false);
      setMustShareRoom(false);
    }
  };

  const handleNewInviteId = async () => {
    setInvitingAttendees(true);
    try {
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/regenerate-invite-id`
      );
      props.onUpdate();
    } catch (err) {
      setFeedbackMessage({
        message: `Could not re-create self-signup link, please try again later.`,
        refresh: !Boolean(feedbackMessage?.refresh),
        type: "error",
      });
      console.error("Error re-generating invite id:", err);
    } finally {
      setInvitingAttendees(false);
    }
  };

  const handleNewValue = (newValue: MultiValue<Option> | Option[]) => {
    const newOptions: Option[] = structuredClone(newValue);
    setAttendeeGroups(newOptions);
  };

  const handleAddAttendees = async (e: any) => {
    setInvitingAttendees(true);
    const emails: string[] = attendeeEmails
      .split(/[\s,]+/)
      .map((email: string) => email.trim())
      .filter((email) => email);

    const allEmailsValid = emails
      .map((email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))
      .every(Boolean);

    if (emails.length > 0 && !allEmailsValid)
      setFeedbackMessage({
        message: `It looks like some of the emails don't have the right format. Please double check.`,
        refresh: !Boolean(feedbackMessage?.refresh),
        type: "error",
      });
    else {
      try {
        await axios.post(
          `/api/users/events/1634${props.event.details.id}/attendees`,
          {
            emails: emails,
            groups: attendeeGroups?.map((g) => g.value),
            suppressInvite: true,
          }
        );
        setInvitingAttendees(false);
        setAttendeeEmails("");
        setAttendeeGroups([]);
        props.onUpdate();
        console.log("Attendees added");
        setFeedbackMessage({
          message: `Successfully added ${emails.length} attendee${
            emails.length > 1 ? "s" : ""
          } to your event.`,
          refresh: !Boolean(feedbackMessage?.refresh),
        });
      } catch (error: any) {
        console.error("Error adding event attendees:", error);
        if (error?.response?.data?.error === "Too many attendees") {
          if (error.response.data.subscribed)
            setFeedbackMessage({
              message: `You can add a maximum of ${error?.response?.data?.limit} attendees to your event. Please get in touch if this clashes with your requirements.`,
              refresh: !Boolean(feedbackMessage?.refresh),
              type: "error",
            });
          else
            setFeedbackMessage({
              message: `Events with more than ${error?.response?.data?.limit} attendees are a Pro Account feature. Consider upgrading your subscription to unlock Impala's tool for events of all sizes.`,
              refresh: !Boolean(feedbackMessage?.refresh),
              type: "error",
            });
        } else
          setFeedbackMessage({
            message: `Could not add new attendees, please try again later.`,
            refresh: !Boolean(feedbackMessage?.refresh),
            type: "error",
          });
        setInvitingAttendees(false);
      }
    }
    setInvitingAttendees(false);
  };

  return (
    <div>
      {userAuth.user && (
        <PricingListModal
          setIsOpen={setPricingListModalOpen}
          modalIsOpen={pricingListModalOpen}
          user={userAuth.user}
        ></PricingListModal>
      )}
      <ConfirmModal
        modalIsOpen={confirmModalOpen}
        setIsOpen={setConfirmModalOpen}
        content="Are you sure you want to re-create the invite link? This will invalidate the existing link, and attendees will no longer be able to self-signup with it."
        onConfirm={handleNewInviteId}
        isDisabled={["Ongoing", "Completed", "Cancelled"].includes(
          props.event.details.status
        )}
      ></ConfirmModal>
      <div className="invite-attendees-wrapper">
        <h4>Option 1: Add by email address</h4>
        <section className="attendeeInviteType">
          <div>
            <Form.Label for="inviteEmail">Enter email addresses</Form.Label>
            <Form.Control
              disabled={["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )}
              as="textarea"
              rows={5}
              id="inviteEmail"
              value={attendeeEmails}
              onChange={handleEmailChange}
            />
            <p className="invite-explanation">
              To add multiple email addresses separate them with commas or put
              them in separate lines.
              {props.event.subscription?.subscribed ||
              props.event.details.is_procured
                ? ""
                : " Please note that you will need a Pro Account subscription to add more than 10 attendees."}
              <br />
              Adding people will not send any emails. You'll be able to send
              invitations to your attendees separately once you've added them
            </p>
            <Form.Label for="attendeeGroups">
              Add to groups <small>(Optional)</small>
            </Form.Label>
            <CreatableSelect
              isMulti
              isClearable
              isLoading={invitingAttendees}
              options={eventGroups || []}
              isDisabled={
                invitingAttendees ||
                ["Ongoing", "Completed", "Cancelled"].includes(
                  props.event.details.status
                )
              }
              value={attendeeGroups || []}
              id="attendeeGroups"
              name="attendeeGroups"
              onChange={(newValue) => handleNewValue(newValue)}
              onCreateOption={handleCreate}
              placeholder="No groups selected"
              formatCreateLabel={(inputValue: string) => {
                return (
                  <NewGroup
                    inputValue={inputValue}
                    isTeam={isTeam}
                    setIsTeam={setIsTeam}
                    isExempt={isExempt}
                    setIsExempt={setIsExempt}
                    mustShareRoom={mustShareRoom}
                    setMustShareRoom={setMustShareRoom}
                    organiserManaged={organiserManaged}
                    setOrganiserManaged={setOrganiserManaged}
                  />
                );
              }}
            />
            <Button
              onClick={handleAddAttendees}
              disabled={
                invitingAttendees ||
                ["Ongoing", "Completed", "Cancelled"].includes(
                  props.event.details.status
                )
              }
            >
              {!invitingAttendees ? "Add" : "Adding..."}
            </Button>
          </div>
        </section>
        <div>
          <h4>Option 2: Share link</h4>
          <section className="attendeeInviteType">
            {props.event.subscription?.subscribed ||
            props.event.details.is_procured ? (
              <div>
                <p className="invite-explanation">
                  This is your unique event link to share with attendees who
                  wish to self-signup. Tap or click to copy. Link was leaked?{" "}
                  <a href={"#"} onClick={() => setConfirmModalOpen(true)}>
                    Create a new one.
                  </a>
                </p>
                <a
                  href={`https://dashboard.impala.travel/events/invite/${props.event.details.invite_id}`}
                  className="inviteLink"
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(
                      `https://dashboard.impala.travel/events/invite/${props.event.details.invite_id}`
                    );

                    const target = e.target as HTMLElement; // Type assertion
                    target.classList.add("link-copied"); // Add the new class
                    setTimeout(() => {
                      target.classList.remove("link-copied"); // Remove the new class after 10 seconds
                    }, 3000);
                  }}
                >
                  https://dashboard.impala.travel/events/invite/
                  {props.event.details.invite_id}
                </a>
              </div>
            ) : (
              <div className="upgrade-link-pro">
                <LinkIllustration />
                <h3>Upgrade to a Pro Account to invite by link</h3>
                <p>
                  Easily share an invite link, and let your attendees sign-up
                  themselves. Upgrade your account to access this feature.
                </p>
                <Button
                  variant="secondary"
                  onClick={() => setPricingListModalOpen(true)}
                >
                  Upgrade my account
                </Button>
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddEventAttendees;
