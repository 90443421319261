import "@aws-amplify/ui-react/styles.css";

import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@aws-amplify/ui-react";
import { useAuth0 } from "@auth0/auth0-react";

import { ImpalaTheme } from "../../theme/index";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

import EventList from "../Events/EventList";

import HotelProgramLanding from "../FrequentTraveller/HotelProgramLanding";
import HotelProgramHotel from "../FrequentTraveller/HotelProgramHotel";
import HotelProgramBook from "../FrequentTraveller/HotelProgramBook";
import AboutOurProgram from "../FrequentTraveller/AboutOurProgram";

import BookOut from "../FrequentTraveller/BookOut";
import ExternalBookingList from "../ExternalBooking/ExternalBookingList";
import EventPlanners from "../Events/EventPlanners";
import EventAccess from "../Events/EventAccess";

import Reporting from "../Reporting/Reporting";
import ReportingHotelDeal from "../Reporting/ReportingHotelDeal";
import ReportingCityAudit from "../Reporting/ReportingCityAudit";

const AuthenticatedServices = () => {
  // Accessibility enhancement: https://reactcommunity.org/react-modal/accessibility/
  Modal.setAppElement("#root");

  let userAuth = useAuth0();
  const [isEventEditor, setIsEventEditor] = useState(false);
  const [isExternalBookingEditor, setIsExternalBookingEditor] = useState(false);
  const [isDirectBooker, setIsDirectBooker] = useState(false);
  const [authenticatedAxios, tokenSet] = useAuthenticatedAxios(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeServices, setActiveServices] = useState(null);

  useEffect(() => {
    if (!userAuth.isAuthenticated && !userAuth.isLoading) {
      userAuth.loginWithRedirect({
        appState: { path: window.location.pathname },
        authorizationParams: {
          redirect_uri: window.location.origin + "/callback",
        },
      });
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (tokenSet) {
        try {
          const roles = await authenticatedAxios.get("/api/users/roles");
          const isAdmin = roles.data.includes("Company Admin");
          setIsEventEditor(isAdmin || roles.data.includes("Event Editor"));
          setIsExternalBookingEditor(
            isAdmin || roles.data.includes("External Booking Editor")
          );
          setIsDirectBooker(
            isAdmin || roles.data.includes("Direct Booking User")
          );
        } catch (e) {
          console.log(e);
          setIsEventEditor(false);
          setIsDirectBooker(false);
          setIsExternalBookingEditor(false);
        }
        try {
          const response = await authenticatedAxios.get(
            "/api/users/active-services"
          );
          setActiveServices(response?.data?.services);
        } catch {
          console.error("Error retrieving active services");
        }
      }
    };

    const fetchAll = async () => {
      setIsLoaded(false);
      await fetchData();
      setIsLoaded(true);
    };

    fetchAll();
  }, [authenticatedAxios, tokenSet]);

  if (!isLoaded) return <div />;

  return (
    <ThemeProvider theme={ImpalaTheme}>
      <>
        <Routes>
          <Route
            path="/reporting"
            element={
              <Reporting
                isAuthenticated={userAuth.isAuthenticated}
                user={userAuth.user}
                userAuth={userAuth}
                activeServices={activeServices}
              />
            }
          />
          <Route
            path="/reporting/hotel-deal/:hotelDealId"
            element={
              <ReportingHotelDeal
                isAuthenticated={userAuth.isAuthenticated}
                user={userAuth.user}
                userAuth={userAuth}
                activeServices={activeServices}
              />
            }
          />
          <Route
            path="/reporting/city-audit/:cityName"
            element={
              <ReportingCityAudit
                isAuthenticated={userAuth.isAuthenticated}
                user={userAuth.user}
                userAuth={userAuth}
                activeServices={activeServices}
              />
            }
          />
          <Route
            path="/event/list"
            element={
              <EventList
                isAuthenticated={userAuth.isAuthenticated}
                hasEventServicePermission={isEventEditor}
                user={userAuth.user}
                userAuth={userAuth}
                activeServices={activeServices}
              />
            }
          />
          <Route
            path="/event/:eventId/planners"
            element={
              <EventPlanners
                userAuth={userAuth}
                activeServices={activeServices}
                user={userAuth.user}
              />
            }
          />
          <Route
            path="/event/:eventId/access"
            element={
              <EventAccess
                userAuth={userAuth}
                activeServices={activeServices}
                user={userAuth.user}
              />
            }
          />
          <Route
            path="/external/list"
            element={
              <ExternalBookingList
                isAuthenticated={userAuth.isAuthenticated}
                hasExternalBookingServicePermission={isExternalBookingEditor}
                user={userAuth.user}
                userAuth={userAuth}
                activeServices={activeServices}
              />
            }
          />
          <Route
            path="/hotel-program"
            element={
              <HotelProgramLanding
                hasDirectBookingServicePermission={isDirectBooker}
                hasEventServicePermission={isEventEditor}
                user={userAuth.user}
                userAuth={userAuth}
              />
            }
          />
          <Route
            path="/hotel-program-hotel/:hotelDealId"
            element={
              <HotelProgramHotel
                hasDirectBookingServicePermission={isDirectBooker}
                hasEventServicePermission={isEventEditor}
                user={userAuth.user}
                userAuth={userAuth}
              />
            }
          />
          <Route
            path="/hotel-program-hotel/:hotelDealId/book"
            element={
              <HotelProgramBook
                hasDirectBookingServicePermission={isDirectBooker}
                hasEventServicePermission={isEventEditor}
                user={userAuth.user}
                userAuth={userAuth}
              />
            }
          />
          <Route
            path="/book-out-hotel/:hotelDealId"
            element={
              <BookOut
                hasDirectBookingServicePermission={isDirectBooker}
                hasEventServicePermission={isEventEditor}
                user={userAuth.user}
                userAuth={userAuth}
              />
            }
          />
          <Route path="/about-our-program" element={<AboutOurProgram />} />
        </Routes>
      </>
    </ThemeProvider>
  );
};

export default AuthenticatedServices;
