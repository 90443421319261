import React from "react";
import Modal from "react-modal";
import { Button } from "react-bootstrap";
import { Event } from "../components/EventStatus";
import {
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
} from "@aws-amplify/ui-react";

import "./Events.css";
import { formatCurrency, percentageOffPublic } from "../utils/utils";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "1000px",
  },
};

function PersonalDaysModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: Event | undefined;
}) {
  Modal.setAppElement("#root");

  function closeModal() {
    props.setIsOpen(false);
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"Stay extra nights"}
    >
      <div className="personal-days-modal">
        <h4>Book extra nights</h4>
        <div className="personal-days-modal-body">
          <p>
            We have negotiated preferential rates with some of the event hotels.
            You can access these if you would like to stay extra nights before
            and/or after {props.event?.details.name || "the event"}, where they
            are not available on your response form. Note that these will need
            to be booked and paid for by yourself, and that these rates are on a
            first-come, first-serve basis.{" "}
            {props.event?.details.personal_day_note === undefined
              ? `Note also that there is no guarantee
            that you will be staying at the same hotel during the event,
            although you can add this as a preference on your response form.`
              : props.event?.details.personal_day_note}
          </p>
          <p></p>
          <Table
            className="OfferDetailsOfferTable"
            caption=""
            highlightOnHover={false}
          >
            <TableHead>
              <TableRow>
                <TableCell as="th">Hotel</TableCell>
                <TableCell as="th">Room</TableCell>
                <TableCell as="th">Cancellation</TableCell>
                <TableCell as="th">Meal plan</TableCell>
                <TableCell as="th">Rate offer</TableCell>
                <TableCell as="th">How to book</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.event?.offers.map((offer) => (
                <TableRow>
                  <TableCell>
                    {offer.website_url ? (
                      <a href={offer.website_url} target="_blank">
                        {offer.hotel_name}
                      </a>
                    ) : (
                      offer.hotel_name
                    )}
                  </TableCell>
                  <TableCell>
                    {offer.room_type ? offer.room_type : "Any"}
                  </TableCell>
                  <TableCell>
                    {offer.flexibility ? offer.flexibility : "Any"}
                  </TableCell>
                  <TableCell>
                    {offer.meal_plan ? offer.meal_plan : "Any"}{" "}
                  </TableCell>
                  <TableCell>
                    {offer.offer_type === "Static"
                      ? formatCurrency(offer.static_rate, offer.static_currency)
                      : percentageOffPublic(offer.dynamic_percentage)}
                  </TableCell>
                  <TableCell>{offer.personal_day_instructions}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <footer className="modalButtonFooter">
        <Button className="btn-primary" onClick={closeModal}>
          Ok
        </Button>
      </footer>
    </Modal>
  );
}

export default PersonalDaysModal;
