import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';

const AuditOptionsModal = ({ show, onHide }) => {
  const [loading, setLoading] = useState(false);
  const [statusCounts, setStatusCounts] = useState(null);
  const intervalRef = useRef(null); // useRef to store the interval ID

  const clearPolling = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const checkAuditStatus = async () => {
    try {
      const response = await axios.get('/api/audit/audit-cache-status');
      setStatusCounts(response.data.statusCounts);

      if (!response.data.statusCounts.waiting) {
        setLoading(false);
        clearPolling();
        onHide(); // Close the modal
      }
    } catch (error) {
      console.error('Error checking audit status:', error);
    }
  };

  const rerunAudit = async () => {
    try {
      setLoading(true);
      await axios.post('/api/audit/create-cache-runs');

      // Start polling every 200ms
      intervalRef.current = setInterval(checkAuditStatus, 200);
    } catch (error) {
      console.error('Error rerunning audit:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Cleanup on unmount or onHide
    return () => clearPolling();
  }, []);

  return (
    <Modal show={show} onHide={() => {clearPolling(); onHide();}}>
      <Modal.Header closeButton>
        <Modal.Title>Audit Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="rerun-audit-button">
          <Button
            variant="primary"
            onClick={rerunAudit}
            disabled={loading}
          >
            {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Re-run Audit'}
          </Button>
        </div>
        {statusCounts && (
          <div className="status-counts">
            <p><b>Status of Cities & Hotels:</b></p>
            <p>Waiting: {statusCounts.waiting || 0}</p>
            <p>Processing: {statusCounts.processing || 0}</p>
            <p>Done: {statusCounts.done || 0}</p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AuditOptionsModal;