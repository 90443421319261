import React from "react";
import { TabItem, Tabs } from "@aws-amplify/ui-react";
import { CompanyDetails } from "../lib/useCompanyDetails";
import Billing from "./Billing";
import AccountDetails from "./AccountDetails";
import { ReactComponent as BackIcon } from "../img/arrow-left.svg";
import EventsInvites from "./EventsInvites";

const Account = (props: {
  companyDetails: CompanyDetails;
  setIsAccount: React.Dispatch<React.SetStateAction<boolean>>;
  onCompanyUpdate: Function;
}) => {
  return (
    <div className="event-my-account-outer">
      <div className="event-my-account">
        <div className="event-my-account-inner">
          <header className="event-section-header">
            <a className="back-link" onClick={() => props.setIsAccount(false)}>
              <BackIcon />
              <span>Back to all events</span>
            </a>
            <h1>My Account</h1>
          </header>
          <div className="events-tabs">
            <Tabs className="header">
              <TabItem title="Account Details">
                <AccountDetails
                  companyDetails={props.companyDetails}
                  onCompanyUpdate={props.onCompanyUpdate}
                />
              </TabItem>
              <TabItem title="Manage Users">
                <EventsInvites />
              </TabItem>
              <TabItem title="Plan & Billing">
                <Billing companyDetails={props.companyDetails} />
              </TabItem>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
