import "./ImpalaHeader.css";

import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";

import { Button, Image } from "@aws-amplify/ui-react";

import { LogoWordmark } from "../ImpalaLogo/ImpalaLogo";
import { ReactComponent as IconHelp } from "../../images/icons/help-circle.svg";
import { ReactComponent as IconBriefcase } from "../../img/briefcase.svg";
import { ReactComponent as IconCheckOut } from "../../img/check-out.svg";
import { ReactComponent as IconIdentity } from "../../img/identity-black.svg";
import { ReactComponent as EventHeaderIcon } from "../../img/event-header.svg";

import NavItem from "../NavItem/NavItem";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";

import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";
import { CompanyDetails } from "../../lib/useCompanyDetails";

type NavigationItem = {
  title: string;
  path: string;
  variant: string;
  icon: JSX.Element;
};

type SpanItem = {
  type: "span";
  content: string;
};

type NavigationItemType = NavigationItem | SpanItem;

function isNavigationItem(item: NavigationItemType): item is NavigationItem {
  return (item as NavigationItem).path !== undefined;
}

const ImpalaEventsHeader = (props: {
  userAuth: Auth0ContextInterface<User>;
  activeServices: any;
  companyDetails: CompanyDetails;
  setIsAccount: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNewEvent: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEventEditor, setIsEventEditor] = useState(false);
  const [authenticatedAxios, tokenSet] = useAuthenticatedAxios(true);

  const isSelfServe =
    props.activeServices?.events_service &&
    !props.activeServices?.hotel_offers_service;

  useEffect(() => {
    const fetchData = async () => {
      if (tokenSet) {
        try {
          const roles = await authenticatedAxios.get("/api/users/roles");
          setIsAdmin(roles.data.includes("Company Admin"));
          setIsEventEditor(
            roles.data.includes("Company Admin") ||
              roles.data.includes("Event Editor")
          );
        } catch (e) {
          console.log(e);
          setIsAdmin(false);
          setIsEventEditor(false);
        }
      }
    };

    fetchData();
  }, [authenticatedAxios, tokenSet]);

  const [activeNavItem, setActiveNavItem] = useState(0);

  const handleNavItemChange = (index: number) => {
    setActiveNavItem(index);
  };

  const navItems: NavigationItemType[] =
    isAdmin && !isSelfServe
      ? [
          {
            title: "Your Services",
            path: "/services",
            variant: "icon",
            icon: <IconBriefcase />,
          },
        ]
      : [];

  if (isEventEditor && props.activeServices?.events_service) {
    if (isAdmin && !isSelfServe) {
      navItems.push({
        title: "Team Events",
        path: "/srv/event/list",
        variant: "icon",
        icon: <EventHeaderIcon />,
      });
    }
  }

  useEffect(() => {
    navItems.forEach((navItem, index) => {
      if (
        isNavigationItem(navItem) &&
        window.location.pathname.includes(navItem.path)
      ) {
        setActiveNavItem(index);
      }
    });
  }, [navItems]);

  return (
    <header className="ImpalaHeader">
      <Link to="/">
        <LogoWordmark variant="primaryDark" />
      </Link>
      {props.companyDetails?.logo_url && (
        <Image
          maxHeight={40}
          maxWidth={200}
          src={props.companyDetails.logo_url}
          alt={props.companyDetails.name}
        ></Image>
      )}

      <nav className="PrimaryNav">
        <ul>
          {navItems.map((item, index) =>
            isNavigationItem(item) ? (
              <NavLink className="HeaderNav" to={item.path} key={index}>
                <NavItem
                  key={index}
                  title={item.title}
                  path={item.path}
                  variant={item.variant}
                  icon={item.icon}
                  active={activeNavItem === index}
                  onClick={() => handleNavItemChange(index)}
                />
              </NavLink>
            ) : (
              <span key={index}>{item.content}</span>
            )
          )}
        </ul>
      </nav>

      <div className="HeaderSideNav">
        <a href="https://impala.travel/help/help-support/" target="_blank" className="navLink withMargin">
          <IconHelp />
          <span>Help & Support</span>
        </a>
        {isAdmin && isSelfServe && props.setIsAccount && (
          <a
            href="#"
            onClick={() => {
              props.setIsAccount(true);
              props.setIsNewEvent(false);
            }}
            className="navLink withMargin"
          >
            <IconIdentity />
            <span>My Account</span>
          </a>
        )}

        {props.userAuth.isAuthenticated && (
          <div>
            <a
              href="#"
              className="navLink"
              onClick={(e) => {
                e.preventDefault();
                props.userAuth.logout({
                  logoutParams: {
                    returnTo: window.location.origin + `/callback`,
                  },
                });
              }}
            >
              <IconCheckOut />
              <span>Log Out</span>
            </a>
          </div>
        )}
        {!props.userAuth.isAuthenticated && (
          <div>
            <Button onClick={() => props.userAuth.loginWithPopup()}>
              Sign In
            </Button>
          </div>
        )}
      </div>
    </header>
  );
};

export default ImpalaEventsHeader;
