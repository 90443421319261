import { CancellationPolicy, Mealplan, OfferType } from "../API";

export function formatCurrency(
  amount: number | null | undefined,
  currency: string | null | undefined,
  includeMinorUnit: boolean = true
): string {
  if (!amount) amount = 0;
  if (!currency) return "";
  if (!includeMinorUnit) amount = Math.round(amount);
  let formatter = null;
  if (includeMinorUnit)
    formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currency,
    });
  else
    formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  return formatter.format(amount);
}

export function formatPercentage(amount: number | null | undefined): string {
  if (!amount) amount = 0;
  return (Math.round(amount * 10) / 10).toString() + "%";
}

export function percentageOffPublic(amount: number | null | undefined): string {
  return formatPercentage(amount) + " off public rate";
}

export function formatMeals(mealplan: Mealplan | null | undefined): string {
  if (!mealplan) return "Any";
  const meals: string[] = [];
  if (mealplan?.breakfast) meals.push("Breakfast");
  if (mealplan?.lunch) meals.push("Lunch");
  if (mealplan?.dinner) meals.push("Dinner");
  return meals.join(", ") || "None";
}

export function formatCancellationPolicy(
  cancellationPolicy: CancellationPolicy | null | undefined
): string {
  if (!cancellationPolicy) return "Any";
  if (cancellationPolicy.nonRefundable) return "Non-refundable";
  if (cancellationPolicy.cancellationDeadline) {
    return (
      "Free cancellation " +
      cancellationPolicy.cancellationDeadline.toString() +
      " days before check-in"
    );
  }
  return "Free Cancellation";
}

/*
Format the style enum values for display. Example: "MODERN_TRENDY" becomes "Modern, Trendy"
*/
export function formatEnum(
  text: string | null | undefined,
  separator: string = " "
): string {
  if (!text) return "";
  return text
    .split("_")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(separator);
}

/*
Format the style enum values for display. Example: "MODERN_TRENDY" becomes "Modern, Trendy"
*/
export function formatOfferType(input: OfferType | null | undefined): string {
  if (input === OfferType.GROUPRATE) return "Group Rate";
  return "CNR";
}

export function formatDate(
  input: Date | string | undefined,
  weekday: boolean = false
): string {
  if (!input) return "";
  if (typeof input === "string") {
    input = new Date(input);
  }
  return input.toLocaleDateString("en-gb", {
    weekday: weekday ? "short" : undefined,
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}
