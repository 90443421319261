import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Button } from "react-bootstrap";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import { ReactComponent as Spinner } from "../img/spinner.svg";

import { useAuth0 } from "@auth0/auth0-react";
import PricingListModal from "./PricingListModal";
import { Tooltip } from "react-tooltip";
import useCompanyDetails from "../lib/useCompanyDetails";

const EventsInvites = () => {
  const location = useLocation();
  const serviceQuery = new URLSearchParams(location.search).get("role");

  const [authenticatedAxios, tokenSet] = useAuthenticatedAxios(true);
  const [emailAddress, setEmailAddress] = useState("");
  const [name, setName] = useState("");
  const [selectedServices, setSelectedServices] = useState(
    serviceQuery ? [decodeURIComponent(serviceQuery)] : []
  );
  const [companyUsers, setCompanyUsers] = useState([]);

  const [isInvitingUser, setIsInvitingUser] = useState(false);
  const [isRevokingUser, setIsRevokingUser] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const userAuth = useAuth0();
  const [companyDetails, setCompanyDetails] = useState();
  const [refresh, setRefresh] = useState(false);
  const [PricingListModalOpen, setPricingListModalOpen] = useState(false);

  useCompanyDetails(setCompanyDetails, refresh);

  const inviteUser = async () => {
    if (!emailAddress) {
      return alert("Please ensure all fields are filled in");
    }

    try {
      setIsInvitingUser(true);
      await authenticatedAxios.post("/api/users/invite-users", {
        userEmail: emailAddress,
        name: name,
        roles: ["Company Admin"],
      });
      setSelectedServices([]);
      setEmailAddress("");
      setName("");
    } catch (e) {
      console.error("Error inviting user", e);
      alert("Error inviting user, please try again later");
    } finally {
      setIsInvitingUser(false);
    }
  };

  const revokeUserRoles = async (userId) => {
    const c = window.confirm(
      "Are you sure you wish to remove access to all services for this user?"
    );
    if (!c) return;

    try {
      setIsRevokingUser(true);
      await authenticatedAxios.delete("/api/users/remove-user-roles", {
        data: { userId },
      });
    } catch (e) {
      console.error("Error revoking user roles");
    } finally {
      setIsRevokingUser(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingUsers(true);
        const response = await authenticatedAxios.get(
          "/api/users/company-users"
        );
        setCompanyUsers(response?.data?.users);
      } catch {
        console.error("Error retrieving users");
      } finally {
        setIsLoadingUsers(false);
      }
    };
    fetchData();
  }, [tokenSet, isInvitingUser, isRevokingUser, authenticatedAxios]);

  const rolesDict = {
    "Company Admin": "Company Admin",
    "Event Editor": "Event Planner",
  };

  const services = [];

  const handleServiceCheckChange = (serviceName, isChecked) => {
    if (isChecked) {
      setSelectedServices((prevServices) => [...prevServices, serviceName]);
    } else {
      setSelectedServices((prevServices) =>
        prevServices.filter((service) => service !== serviceName)
      );
    }
  };

  return (
    <div className="invites-wrapper event-account-section">
      {userAuth.user && (
        <PricingListModal
          setIsOpen={setPricingListModalOpen}
          modalIsOpen={PricingListModalOpen}
          user={userAuth.user}
        ></PricingListModal>
      )}
      <h2>Manage Users</h2>
      <div className="invites-inner-wrapper">
        <div className="add-invites-area">
          <section className="invite-user-form">
            <div className="form-line">
              <h2>Invite New User</h2>
              <div className="invite-row">
                <div className="fields">
                  <label>Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label>Email Address</label>
                  <input
                    type="text"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>
                {companyUsers.filter(
                  (user) => !user.email.endsWith("impala.travel")
                ).length > 4 && !companyDetails?.subscribed ? (
                  <>
                    <Tooltip
                      className="eventTooltip"
                      id="upgradeButton"
                    ></Tooltip>
                    <Button
                      disabled={isInvitingUser}
                      data-tooltip-id="upgradeButton"
                      data-tooltip-place="top"
                      data-tooltip-html={`<span>${companyDetails.name} have reached the maximum number of users. Upgrade to a Pro account for unlimited users.</span>`}
                      variant="primary"
                      onClick={() => setPricingListModalOpen(true)}
                    >
                      Upgrade to Invite More Users
                    </Button>
                  </>
                ) : !isInvitingUser ? (
                  <Button
                    variant="primary"
                    disabled={!name || !emailAddress}
                    onClick={() => inviteUser()}
                  >
                    Send Invite
                  </Button>
                ) : (
                  <Button variant="primary" className="with-spinner">
                    <Spinner />
                  </Button>
                )}
              </div>

              <div className="set services-set">
                <div className="services-wrapper">
                  {services.map((service, index) =>
                    service.enabled ? (
                      <div key={index}>
                        <input
                          type="checkbox"
                          checked={selectedServices.includes(service.role)}
                          id={index}
                          onChange={(e) =>
                            handleServiceCheckChange(
                              service.role,
                              e.target.checked
                            )
                          }
                        />
                        <label for={index}>{service.name}</label>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </div>
            <div className="existing-users">
              <h2>Existing Users</h2>
              <table>
                <thead>
                  <tr>
                    <th>Email address</th>
                    <th>Has accepted invite?</th>
                    <th>Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {(isLoadingUsers || isRevokingUser) && (
                    <tr>
                      <td className="loading-table" colSpan="4">
                        <Spinner />
                      </td>
                    </tr>
                  )}
                  {!isLoadingUsers &&
                    !isRevokingUser &&
                    companyUsers
                      .filter((user) => !user.email.endsWith("impala.travel"))
                      .map((user, index) => (
                        <tr key={index}>
                          <td>{user.email}</td>
                          <td>{user.has_ever_logged_in ? "Yes" : "No"}</td>
                          <td>
                            {user.roles
                              .map((x) => rolesDict[x] || x)
                              .join(", ")}
                          </td>
                          <td className="button-cell">
                            {user.email !== userAuth?.user?.email &&
                              user.roles.length > 0 && (
                                <Button
                                  variant="secondary"
                                  onClick={() => revokeUserRoles(user.id)}
                                  className="secondary-danger"
                                  size="sm"
                                >
                                  Revoke Access
                                </Button>
                              )}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EventsInvites;
