import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import EventDetails from "../../components/EventDetails";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Event, EventDate } from "../../components/EventStatus";
import { Loader } from "@aws-amplify/ui-react";
import { Button, Form, Image } from "react-bootstrap";
import { LogoWordmark } from "../../components/ImpalaLogo/ImpalaLogo";
import { ReactComponent as InviteAccepted } from "../../img/invite-accepted.svg";
import Error from "../../components/Error";

import { CompanyDetails } from "../../lib/useCompanyDetails";

import "../../components/Events.css";
import { utcToZonedTime } from "date-fns-tz";

const EventInvite = () => {
  const [loadingEventData, setLoadingEventData] = useState(true);
  const { eventInviteId } = useParams();

  const [event, setEvent] = useState<Event>();
  const [email, setEmail] = useState("");
  const [inviteSuccess, setInviteSuccess] = useState(false);

  useEffect(() => {
    setEmail("");
    setInviteSuccess(false);
  }, []);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        setLoadingEventData(true);
        const response = await axios.get(
          `/api/event-external/invites/${eventInviteId}`
        );
        setEvent({
          details: response.data.details,
          realEvent: true,
          attendees: [],
          dates: response.data.dates
            .sort((a: EventDate, b: EventDate) =>
              a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            )
            .map((d: EventDate) => {
              return {
                ...d,
                date: utcToZonedTime(
                  new Date(d.date.toString() + "T00:00:00Z"),
                  "Etc/UTC"
                ),
              };
            }),
          owner: undefined,
          calendlyInvite: undefined,
          offers: [],
          groups: [],
          hotels: [],
          marketing: [],
          subscription: undefined,
          customer: response.data.customer,
        });
        setLoadingEventData(false);
      } catch (error) {
        console.error("Error fetching event details:", error);
        setLoadingEventData(false);
      }
    };

    fetchEventDetails();
  }, [eventInviteId]);

  const handleEmailChange = async (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    setLoadingEventData(true);

    const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

    if (email.length > 0 && !isEmailValid) {
      alert(
        "It looks like the email doesn't have the right format. Please double check."
      );
    } else {
      try {
        await axios.post(
          `/api/event-external/invites/${eventInviteId}/attendee`,
          { email: email }
        );
        setLoadingEventData(false);
        setInviteSuccess(true);
      } catch (error) {
        alert(
          "Unable to create invitation, you may have already received an invite. Please check your inbox"
        );
        console.error("Error adding event attendees:", error);
        setLoadingEventData(false);
        setInviteSuccess(false);
      }
    }
  };

  return (
    <Container fluid className="pre-event-main">
      <div className="pre-event-details">
        <Col className="pre-event-description">
          <div>
            <EventDetails event={event} user="invitee" />
            <div className="buildYourOwn">
              <p>Team events, powered by</p>
              <LogoWordmark variant="primaryDark" />
            </div>
          </div>
        </Col>
        <Col className="pre-invite-form">
          <div>
            {inviteSuccess ? (
              <div className="preferencesSuccess">
                <InviteAccepted />
                <h3>We've sent an invite link to your email address</h3>
                <h5>Open it to finish entering your preferences</h5>
              </div>
            ) : event && event.details ? (
              <div className="">
                <div className="splash-form-group">
                  <section className="formQuestionSection">
                    <article className="formQuestionInputs">
                      <h4>
                        Let your event organiser know if you can attend. Enter
                        your email below and we'll send a link for your unique
                        attendee space.
                      </h4>
                      <Form.Label for="formEmail">
                        Your email address
                      </Form.Label>
                      <p>
                        We’ll send confirmation to this address. You can also
                        use it for changing preferences later.
                      </p>
                      <Form.Control
                        disabled={[
                          "Ongoing",
                          "Completed",
                          "Cancelled",
                        ].includes(event.details.status)}
                        id="formEmail"
                        type="text"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Enter your email address"
                      />
                    </article>
                  </section>
                  <div className="form-confirm-wrapper">
                    <Button
                      disabled={["Ongoing", "Completed", "Cancelled"].includes(
                        event.details.status
                      )}
                      className="formConfirm"
                      onClick={handleSubmit}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : loadingEventData ? (
              <Loader variation="linear" />
            ) : (
              <div className="overall-summary">
                <Error />
              </div>
            )}
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default EventInvite;
