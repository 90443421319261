import React, { useState } from "react";
import { TabItem, Tabs } from "@aws-amplify/ui-react";

import { Event, EventHotel } from "./EventStatus";

import { Button } from "react-bootstrap";

import EventAttendeeRoomingList from "./EventAttendeeRoomingList";
import EventHotels from "./EventHotels";
import EditHotel from "./EditHotel";
import HotelDeleteModal from "./HotelDeleteModal";

import { ReactComponent as AddHotelIllustration } from "../img/hotel-add.svg";
import NotifyEventAttendees from "./NotifyEventAttendees";

const tabs = ["", "Rooming List"];

const ManageHotels = (props: {
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  openWarningModal: Function;
  subPage: string;
  event: Event;
  onUpdate: Function;
}) => {
  const [selectedHotel, setSelectedHotel] = useState<EventHotel | undefined>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);

  return (
    <>
      {props.subPage === "Send Notification" ? (
        <NotifyEventAttendees
          openWarningModal={props.openWarningModal}
          event={props.event}
          onUpdate={props.onUpdate}
          selectedAttendees={selectedAttendees}
          setSubPage={props.setSubPage}
        />
      ) : (
        <>
          <HotelDeleteModal
            modalIsOpen={deleteModalOpen}
            setIsOpen={setDeleteModalOpen}
            event={props.event}
            hotel={selectedHotel}
            onUpdate={props.onUpdate}
          ></HotelDeleteModal>

          {["Add Hotel", "Edit Hotel"].includes(props.subPage) ? (
            <div>
              <EditHotel
                event={props.event}
                subPage={props.subPage}
                onUpdate={props.onUpdate}
                selectedHotel={selectedHotel}
                setSubPage={props.setSubPage}
              ></EditHotel>
            </div>
          ) : (
            <>
              <div className="header-wrapper">
                <h2>Hotels</h2>
              </div>{" "}
              {props.event.hotels.length === 0 ? (
                <div>
                  {props.event.details.is_procured ? (
                    <div className="hotels-empty">
                      <AddHotelIllustration />
                      <h4>There aren't any hotels to display</h4>
                      <p>
                        Once we've contracted hotels for your event they'll
                        appear here.
                      </p>
                    </div>
                  ) : (
                    <div className="hotels-empty">
                      <AddHotelIllustration />
                      <h4>You've not added any hotels to your event.</h4>{" "}
                      <p>Add hotels that you've contracted here. </p>
                      <p>
                        Once you've added hotels and your attendees have added
                        their preferences you'll be able to create rooming
                        lists.
                      </p>
                      <Button
                        onClick={() => {
                          props.setSubPage("Add Hotel");
                        }}
                      >
                        Add a Hotel
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="events-tabs">
                  <Tabs
                    className="header"
                    currentIndex={tabs.indexOf(props.subPage)}
                    onChange={(i) => props.setSubPage(tabs[Number(i)])}
                  >
                    <TabItem title="Hotels">
                      <EventHotels
                        event={props.event}
                        onUpdate={props.onUpdate}
                        openWarningModal={props.openWarningModal}
                        setSelectedHotel={setSelectedHotel}
                        setDeleteModalOpen={setDeleteModalOpen}
                        setSubPage={props.setSubPage}
                      />
                    </TabItem>
                    <TabItem title="Rooming List">
                      <EventAttendeeRoomingList
                        setSubPage={props.setSubPage}
                        setSelectedAttendees={setSelectedAttendees}
                        event={props.event}
                        onUpdate={props.onUpdate}
                      />
                    </TabItem>
                  </Tabs>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ManageHotels;
