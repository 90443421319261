import React from "react";

import BenefitsList from "../components/BenefitsList";
import { Image } from 'react-bootstrap';

import { ReactComponent as HeadsetIcon } from '../img/headset.svg';
import { ReactComponent as LightningIcon } from '../img/lightning.svg';
import { ReactComponent as GiftIcon } from '../img/gift.svg';

import '../components/HotelProgram.css';

const HotelProgramSidebar = ({
  data
}) => {
  return (
    <div className="dark-column-content">
        <div className="logo-line">
        <Image className="mainlogo" src="/logo.png" alt="Impala Logo" />
        {data?.logo && (
            <img src={data?.logo} alt="Logo" className="company-logo" />
        )}
        </div>
        <h1 className="booking-cta">Book your hotel</h1>
        <BenefitsList mode="left-bar" />
        <div className="why-book-direct">
        <h4>Why book direct?</h4>
        <div className="reasons">
            <div className="reason">
            <LightningIcon />
            <div className="reason-right">
                <label>Instant booking confirmation</label>
                <p>No waiting for your travel software to let you know if they’ve booked your room.</p>
            </div>
            </div>
            <div className="reason">
            <HeadsetIcon />
            <div className="reason-right">
                <label>Better customer service</label>
                <p>You book with the hotel so they can support you directly.</p>
            </div>
            </div>
            <div className="reason">
            <GiftIcon />
            <div className="reason-right">
                <label>Personalised rewards</label>
                <p>Start earning personal travel rewards like upgrades and lounge access, starting after 6 direct bookings.</p>
            </div>
            </div>
        </div>
        </div>
    </div>
              
  );
};

export default HotelProgramSidebar;
