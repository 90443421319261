import React from "react";
import { ReactComponent as ImageGift } from "../img/gift.svg";
import { useAuth0 } from "@auth0/auth0-react";


const BenefitsList = ({ mode, deals, hideHeadline }) => {
    const { isAuthenticated, user } = useAuth0();    
    const showName = isAuthenticated && user.name;

    return (
        <div className={`benefits-list ${mode}`}>
            <div className="benefits-list-inner-wrapper">
                {!hideHeadline && (
                    <div className="headline">
                        <ImageGift />
                        <h4>Get these great benefits by booking direct</h4>
                    </div>
                )}
                <div className="sidebar">
                    <div className="boast-line">
                        {!showName && (
                            <><ImageGift /> Starting from just 6 hotel bookings.</>
                        )}
                        {showName && (
                            <>{`${user.name}, you're on your way to better travel.`}</>
                        )}
                    </div>
                    <div className="offers-line">
                        <div className="offer">
                            <div className="inner-offer">
                                <div className="bookings-required">6</div>
                                <p>Airport lounge access</p> 
                            </div>
                        </div>
                        <div className="offer">
                            <div className="inner-offer">
                                <div className="bookings-required">25</div>
                                <p>Business class upgrade (short-haul)</p>
                            </div>
                        </div>
                        <div className="offer">
                            <div className="inner-offer">
                                <div className="bookings-required">100</div>
                                <p>Business class upgrade (long-haul)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deals && deals.length > 0 && (
                <div className="deals-list">
                    <h4>+ Unique benefits booking here</h4>
                    <ul className="deals-container">
                        {deals.map(deal => (
                            <li>{deal}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default BenefitsList;