import React from "react";
import { Button } from "react-bootstrap";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

import { useNavigate } from "react-router-dom";

import { ReactComponent as ServiceEventsIcon } from "../../img/services-events.svg";
import { ReactComponent as ServiceDirectIcon } from "../../img/services-direct.svg";
import { ReactComponent as ServiceOffersIcon } from "../../img/services-offers.svg";
import { ReactComponent as ServiceComplexIcon } from "../../img/services-complex.svg";
import { ReactComponent as ServiceNegotiationIcon } from "../../img/services-negotiation.svg";

import { ReactComponent as ArrowRightIcon } from "../../img/arrow-right.svg";
import { ReactComponent as BriefcaseIcon } from "../../img/briefcase.svg";

const Services = ({ activeServices }) => {
  const [authenticatedAxios] = useAuthenticatedAxios(true);

  const navigate = useNavigate();

  const activateService = async (service) => {
    try {
      await authenticatedAxios.post("/api/users/activate-service", {
        service,
      });
      alert(
        "Activation request sent. Your account manager will be in touch shortly."
      );
    } catch {
      alert(
        "There was an error requesting service activation, please contact your account manager."
      );
    }
  };

  const services = [
    {
      name: "Team events",
      enabled: activeServices?.events_service,
      description:
        "Easier planning for offsites, co-locations and get togethers",
      icon: <ServiceEventsIcon />,
      actions: [
        {
          title: "Invite organiser",
          action: () => navigate("/invites?role=Event+Editor"),
        },
        {
          title: "Plan event",
          action: () => navigate("/srv/event/list?page=new_event"),
        },
      ],
    },
    {
      name: "Frequent traveller program",
      role: "Direct Booking User",
      enabled: activeServices?.direct_booking_service,
      description:
        "Enjoy better prices and traveller rewards with direct booking for frequent travellers.",
      icon: <ServiceDirectIcon />,
      actions: [
        {
          title: "Invite frequent traveller",
          action: () => navigate("/invites?role=Direct+Booking+User"),
        },
        {
          title: "Book your hotel",
          action: () => navigate("/srv/hotel-program"),
        },
      ],
    },
    {
      name: "Hotel offers",
      enabled: activeServices?.hotel_offers_service,
      description:
        "Impala negotiates dramatic direct discounts for your top destinations.",
      icon: <ServiceOffersIcon />,
      actions: [
        { title: "View current offers", action: () => navigate("/offers") },
      ],
    },
    {
      name: "Onboarding & Interview bookings",
      role: "External Booking Editor",
      enabled: activeServices?.complex_bookings_service,
      description:
        "Easily plan travel for inductions, onboarding, & interviews.",
      icon: <ServiceComplexIcon />,
      actions: [
        {
          title: "Invite organiser",
          action: () => navigate("/invites?role=External+Booking+Editor"),
        },
        {
          title: "Start a new booking",
          action: () => navigate("/srv/external/create"),
        },
      ],
    },
    {
      name: "TMC negotiation",
      enabled: activeServices?.tmc_negotiation_service,
      description:
        "Do you worry that you're overpaying for your current online booking tool? Let us negotiate pricing down for you.",
      icon: <ServiceNegotiationIcon />,
      actions: [{ title: "Request TMC Negotiation", action: () => {} }],
    },
  ];

  return (
    <div className="services-outer-wrapper">
      <div className="services-wrapper">
        <div className="savings-opportunity-area"></div>
        <div className="services-status-area">
          <div className="top-bar">
            <h2>Your Services</h2>
            <Button variant="secondary" onClick={() => navigate("/invites")}>
              MANAGE USERS
            </Button>
          </div>
          <div className="service-list">
            {services.map((service, i) => (
              <div
                className={`service ${
                  service.enabled ? "enabled" : "disabled"
                }`}
                key={i}
              >
                <div className="top-area">
                  {service.icon}
                  <div className="right-area">
                    <h3>{service.name}</h3>
                    <p>{service.description}</p>
                  </div>
                </div>
                {service.enabled && (
                  <div className="actions-area">
                    {service.actions.map((action, i) => (
                      <div className="action" key={i} onClick={action.action}>
                        <span>{action.title}</span>
                        <ArrowRightIcon />
                      </div>
                    ))}
                  </div>
                )}
                {!service.enabled && (
                  <div className="disabled-actions-area">
                    <span className="tag">Not Enabled</span>
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={() => activateService(service.name)}
                    >
                      <BriefcaseIcon /> Add this free service
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
