import React, { useState } from "react";
import Modal from "react-modal";
import { Event, EventAttendee } from "./EventStatus";
import { ReactComponent as UserRemoveIcon } from "../img/user-remove.svg";
import { Button, Form } from "react-bootstrap";
import axios from "axios";

import "./Events.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "640px",
  },
};

function EventPlannerConfirmationModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirm: Function;
}) {
  function closeModal() {
    props.setIsOpen(false);
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Remove yourself from this event?"
    >
      <div className="event-management-modal">
        <h4>Remove yourself from this event?</h4>
        <div className="event-attendee-delete-modal-title">
          <p>
            If you remove yourself from this event you'll need an admin or
            another planner to add you back in.
          </p>
        </div>
      </div>
      <footer className="modalButtonFooter">
        <Button className="btn-secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          className="btn-secondary danger-button"
          onClick={() => {
            props.handleConfirm(true);
            props.setIsOpen(false);
          }}
        >
          Yes, Remove me
        </Button>
      </footer>
    </Modal>
  );
}

export default EventPlannerConfirmationModal;
