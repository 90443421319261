import React, { useState, useEffect, useContext } from "react";
import Tiptap from "./TipTap";
import { format } from "date-fns";
import { Event } from "./EventStatus";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";
import "./Events.css";
import { Button } from "react-bootstrap";
import { Editor, JSONContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { ReactComponent as AttendanceOptional } from "../img/holiday2.svg";
import { ReactComponent as AttendanceRequired } from "../img/attendees.svg";
import { FeedbackContext } from "../App";

const extensions = [
  StarterKit,
  Underline,
  Link.configure({
    protocols: ["mailto"],
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Placeholder.configure({
    placeholder: "What's happening on this day...?",
  }),
];

const EventAgenda = (props: { event: Event; onUpdate: Function }) => {
  const [axios] = useAuthenticatedAxios(true);
  const [editors, setEditors] = useState<Map<number, Editor>>(new Map());
  const [initialContents, setInitialContents] = useState<
    Map<number, JSONContent>
  >(new Map());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { feedbackMessage, setFeedbackMessage } = useContext(FeedbackContext);

  useEffect(() => {
    const newEditorsMap = new Map<number, Editor>();
    const newInitialContentsMap = new Map<number, JSONContent>();

    props.event.dates
      .filter((d) => d.type !== "Travel")
      .forEach((d) => {
        const editor = new Editor({
          extensions: extensions,
          content: d.agenda || "",
          editable: !["Ongoing", "Completed", "Cancelled"].includes(
            props.event.details.status
          ),
        });
        newEditorsMap.set(d.id, editor);
        newInitialContentsMap.set(d.id, editor.getJSON());

        // Add an update handler to detect changes
        editor.on("update", () => {
          detectChanges(newEditorsMap, newInitialContentsMap);
        });
      });

    setEditors(newEditorsMap);
    setInitialContents(newInitialContentsMap);
  }, [props.event]);

  const detectChanges = (
    editorsMap: Map<number, Editor>,
    initialContentsMap: Map<number, JSONContent>
  ) => {
    let changesDetected = false;
    editorsMap.forEach((editor, key) => {
      const initialContent = initialContentsMap.get(key);
      const currentContent = editor.getJSON();
      if (JSON.stringify(initialContent) !== JSON.stringify(currentContent)) {
        changesDetected = true;
      }
    });
    setHasChanges(changesDetected);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const agendas = Array.from(editors, ([key, value]) => ({
        date_id: key,
        content: value.getJSON(),
      }));
      console.log(agendas);
      const body = {
        agendas,
      };
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/agenda/`,
        body
      );
      props.onUpdate();
      setFeedbackMessage({
        message: "Changes saved successfully!",
        refresh: !Boolean(feedbackMessage?.refresh),
      });
    } catch (err) {
      setFeedbackMessage({
        message: "Could not submit agenda, please try again later",
        type: "error",
        refresh: !Boolean(feedbackMessage?.refresh),
      });
      console.error("Error updating agenda:", err);
    } finally {
      setIsSubmitting(false);
      setHasChanges(false); // Reset changes detection
    }
  };

  return (
    <div className="manageDates">
      <div>
        <div className="agenda-header">
          <div>
            <p>
              Let your attendees know what’s happening on the different days of
              your event.
            </p>
            <p>This information will appear on the attendee pages.</p>
          </div>
          <Button
            disabled={
              isSubmitting ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              ) ||
              !hasChanges
            }
            onClick={handleSubmit}
          >
            {isSubmitting ? "Saving..." : "Save changes"}
          </Button>
        </div>
        <section className="agenda-container">
          <div className="agenda-date-rows">
            {props.event.dates
              .filter((d) => d.type !== "Travel")
              .sort((a, b) =>
                new Date(a.date.toString()) > new Date(b.date.toString())
                  ? 1
                  : -1
              )
              .map((date, dateIndex) => (
                <div className="agenda-date" key={date.id}>
                  <div className="agenda-date-value">
                    <h3>{format(date.date, "eee, dd MMM yyyy")}</h3>{" "}
                    <div className="agenda-day-type">
                      {date.type === "Required" ? (
                        <AttendanceRequired />
                      ) : (
                        <AttendanceOptional />
                      )}
                      Event date
                    </div>
                  </div>
                  <div className="agenda-editor">
                    <Tiptap editor={editors.get(date.id)} />
                  </div>
                </div>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default EventAgenda;
