import React, { useState } from "react";
import { ReactComponent as AttendeesEmpty } from "../img/attendees-empty.svg";

import { Event, EventAttendee } from "./EventStatus";
import EventEmpty from "./EventEmpty";
import { TabItem, Tabs } from "@aws-amplify/ui-react";

import "./Events.css";

import EventAttendeeList from "./EventAttendeeList";
import AddEventAttendees from "./AddEventAttendees";
import ManageAttendee from "./ManageAttendee";
import AttendeeDeleteModal from "./AttendeeDeleteModal";
import EventAttendeeGroups from "./EventAttendeeGroups";
import InviteEventAttendees from "./InviteEventAttendees";
import EventAttendeeSettings from "./EventAttendeeSettings";

const tabs = ["", "Add People", "Groups", "Settings"];

const EventAttendees = (props: {
  openWarningModal: Function;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
  subPage: string;
  event: Event;
  onUpdate: Function;
}) => {
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);
  const [selectedAttendee, setSelectedAttendee] = useState<EventAttendee>();
  const [showAttendeeDeleteModal, setShowAttendeeDeleteModal] = useState(false);

  return (
    <>
      <AttendeeDeleteModal
        modalIsOpen={showAttendeeDeleteModal}
        setIsOpen={setShowAttendeeDeleteModal}
        event={props.event}
        onUpdate={props.onUpdate}
        eventAttendee={selectedAttendee}
        setSubPage={props.setSubPage}
      ></AttendeeDeleteModal>
      {props.subPage === "Manage Attendee" && selectedAttendee ? (
        <ManageAttendee
          event={props.event}
          onUpdate={props.onUpdate}
          selectedAttendee={selectedAttendee}
          setSubPage={props.setSubPage}
        />
      ) : props.subPage === "Create New Invite" ? (
        <InviteEventAttendees
          openWarningModal={props.openWarningModal}
          event={props.event}
          onUpdate={props.onUpdate}
          selectedAttendees={selectedAttendees}
          setSubPage={props.setSubPage}
        />
      ) : (
        <div>
          <div>
            <div className="header-wrapper">
              <h2>Attendees</h2>
            </div>
          </div>

          {props.event.realEvent ? (
            <Tabs
              className="attendeeTabs"
              currentIndex={tabs.indexOf(props.subPage)}
              onChange={(i) => props.setSubPage(tabs[Number(i)])}
            >
              <TabItem title="Attendees">
                <EventAttendeeList
                  event={props.event}
                  onUpdate={props.onUpdate}
                  setSubPage={props.setSubPage}
                  setSelectedAttendee={setSelectedAttendee}
                  setSelectedAttendees={setSelectedAttendees}
                />
              </TabItem>
              <TabItem title="Add People">
                <AddEventAttendees
                  event={props.event}
                  onUpdate={props.onUpdate}
                />
              </TabItem>
              <TabItem title="Groups">
                <EventAttendeeGroups
                  event={props.event}
                  onUpdate={props.onUpdate}
                />
              </TabItem>
              <TabItem title="Settings">
                <EventAttendeeSettings
                  event={props.event}
                  onUpdate={props.onUpdate}
                />
              </TabItem>
            </Tabs>
          ) : (
            <div>
              <div className="event-not-accepted-wrapper">
                <AttendeesEmpty />
                <EventEmpty />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EventAttendees;
