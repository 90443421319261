import React, { useEffect, useState } from "react";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";
import getSymbolFromCurrency from "currency-symbol-map";
import { format } from "date-fns";

import "./Events.css";

const EventCosts = ({ event, stats }) => {
  const [authedAxios, tokenSet] = useAuthenticatedAxios(true);
  const [rates, setContractedRates] = useState([]);

  const [costData, setCostData] = useState(null);

  useEffect(() => {
    if (!tokenSet) return;
    const fetchData = async () => {
      const response = await authedAxios.get(
        `/api/users/events/1634${event?.details?.id}/costs?currency=GBP`
      );
      setCostData(response.data);
    };

    fetchData();
  }, [tokenSet]);

  useEffect(() => {
    if (!event.realEvent) return;
    if (!tokenSet) return;
    const fetchData = async () => {
      try {
        const response = await authedAxios.get(
          `/api/users/events/1634${event?.details?.id}/contracted-rates`
        );
        setContractedRates(response.data);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [tokenSet]);

  const currencySymbol = getSymbolFromCurrency(event?.details.currency);
  const renderEstimate = () =>
    `${currencySymbol}${new Intl.NumberFormat("en-GB").format(
      event?.details.hotel_estimate_min
    )} - ${currencySymbol}${new Intl.NumberFormat("en-GB").format(
      event?.details.hotel_estimate_max
    )}`;
  const renderVenueEstimate = () =>
    `${currencySymbol}${new Intl.NumberFormat("en-GB").format(
      event?.details.venue_estimate
    )}`;
  const renderOtherEstimate = () =>
    `${currencySymbol}${new Intl.NumberFormat("en-GB").format(
      event?.details.other_estimate
    )}`;

  const totalEstimate = (minOrMax) => {
    if (!event || !event.details) return "N/A";
    const { details } = event;
    const hotelEstimate =
      (details.number_option === "rough"
        ? (details.min_people + details.max_people) / 2
        : details.exact_people) * (details[`hotel_estimate_${minOrMax}`] || 0);
    const hotelEstimateRequiredDates =
      hotelEstimate *
      event.dates.filter((x) => x.type === "Required" && x.hotel_required)
        .length;
    const hotelEstimateOptionalDates =
      hotelEstimate *
      (event.dates.filter((x) => x.type === "Optional" && x.hotel_required)
        .length *
        0.5);
    const hotelEstimateTravelDates =
      hotelEstimate *
      (event.dates.filter((x) => x.type === "Travel").length * 0.2);
    const hotelEstimateAllDates =
      hotelEstimateOptionalDates +
      hotelEstimateRequiredDates +
      hotelEstimateTravelDates;

    return (
      (details.venue_estimate || 0) +
      (details.other_estimate || 0) +
      hotelEstimateAllDates
    );
  };

  const renderTotalEstimateLine = () =>
    event?.details.number_option === "rough" ? (
      <p className="estimate-explainer">
        {`Based on the mid-point of # attendees you initially suggested, the total cost estimate is `}
        <b>{`${currencySymbol}${new Intl.NumberFormat("en-GB").format(
          totalEstimate("min")
        )} - ${currencySymbol}${new Intl.NumberFormat("en-GB").format(
          totalEstimate("max")
        )}`}</b>
      </p>
    ) : (
      <p className="estimate-explainer">
        {`Based on the number of attendees you initially suggested, the total cost estimate is `}
        <b>{`${currencySymbol}${new Intl.NumberFormat("en-GB").format(
          totalEstimate("min")
        )} - ${currencySymbol}${new Intl.NumberFormat("en-GB").format(
          totalEstimate("max")
        )}`}</b>
      </p>
    );

  const costAndOccupancyData = () => {
    if (!stats || !rates?.length) return [];

    try {
      const getAllContracts = (date) =>
        rates.find((x) => x.date === format(date.date, "yyyy-MM-dd"));
      const getHotelRequests = (date) =>
        stats.find((x) => x.date === date.date).hotelRequestsCount;

      const dateCosts = [];

      event.dates.forEach((date) => {
        const contracts = getAllContracts(date); // Might not have contract
        let roomsRequired = getHotelRequests(date);
        let totalCost = 0;

        // Let's fill the uncancellable rooms
        (contracts ? contracts.rates : [])
          .sort((a, b) => (a.date > b.date ? 1 : -1))
          .map((contract) => {
            // We have to pay for the uncancellable rooms
            const uncancellable =
              contract.number_of_rooms - contract.cancellable;
            totalCost += uncancellable * contract.cost;

            // Subtract all the uncancellable rooms
            roomsRequired -= uncancellable;
            return contract;
          })
          .forEach((contract) => {
            // Now let's fill the cancellable
            if (roomsRequired > 0) {
              const numberFromHere =
                roomsRequired > contract.cancellable
                  ? contract.cancellable
                  : roomsRequired;
              totalCost += numberFromHere * contract.cost;

              // Subtract amount we took
              roomsRequired -= numberFromHere;
            }
          });

        dateCosts.push({
          date: date.date,
          totalCost,
          roomsRequired,
        });
      });

      return dateCosts;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const hotelCostsCurrent = costAndOccupancyData();
  const anyUncancellableRemain = !!hotelCostsCurrent.find(
    (x) => x.roomsRequired < 0
  );
  const anyUncontracted = !!hotelCostsCurrent.find((x) => x.roomsRequired > 0);

  const countUncancellable = Math.abs(
    hotelCostsCurrent.reduce(
      (p, c) => (c.roomsRequired < 0 ? p + c.roomsRequired : p),
      0
    )
  );
  const countUncontracted = hotelCostsCurrent.reduce(
    (p, c) => (c.roomsRequired > 0 ? p + c.roomsRequired : p),
    0
  );

  return (
    <div className="costs-outer-wrapper">
      <h3>Initial Cost Estimates</h3>
      <div className="estimates">
        {event?.details?.hotel_estimate_min &&
          event?.details?.hotel_estimate_max &&
          renderTotalEstimateLine()}
        <div className="costs-wrapper">
          {event?.details?.hotel_estimate_min > 0 &&
            event?.details?.hotel_estimate_max > 0 && (
              <div className="entry">
                <h4>Hotel</h4>
                <p className="price">{renderEstimate()}</p>
                <p className="explanation">per-room, per-night</p>
              </div>
            )}
          {event?.details?.venue_estimate > 0 && (
            <div className="entry">
              <h4>Venue</h4>
              <p className="price">{renderVenueEstimate()}</p>
              <p className="explanation">all-in</p>
            </div>
          )}
          {event?.details?.other_estimate > 0 && (
            <div className="entry">
              <h4>Other</h4>
              <p className="price">{renderOtherEstimate()}</p>
              <p className="explanation">all-in</p>
            </div>
          )}
        </div>
        {event?.details?.hotel_estimate_min &&
          event?.details?.hotel_estimate_max && (
            <p className="estimate-explainer">
              <b>Please note: </b> The total estimate suggests a 50% uptake of
              Optional nights and a 20% uptake of Travel nights. Estimates are
              based on our industry knowledge and experience and may differ from
              the final bill.
            </p>
          )}
      </div>
      <h3>Current Costs</h3>
      <div className="current">
        <div className="costs-wrapper">
          <div className="entry">
            <h4>Hotel</h4>
            <p className="price">
              {hotelCostsCurrent.length === 0
                ? "N/A"
                : `${currencySymbol}${new Intl.NumberFormat("en-GB").format(
                    hotelCostsCurrent.reduce((p, c) => p + c.totalCost, 0)
                  )}`}
            </p>
            <p className="explanation">
              {hotelCostsCurrent.length === 0 &&
                `No rooms currently contracted. `}
              {anyUncancellableRemain &&
                `Some dates have uncancellable nights without enough attendees (${countUncancellable} nights in total). `}
              {anyUncontracted &&
                `Some nights require additional capacity (${countUncontracted} room-nights in total).`}
            </p>
          </div>
          {costData?.Venue?.total && (
            <div className="entry">
              <h4>Venue</h4>
              <p className="price">
                {getSymbolFromCurrency(costData.Venue.currency)}
                {new Intl.NumberFormat("en-GB").format(
                  costData.Venue.total.toFixed(2)
                )}
              </p>
            </div>
          )}
          {costData?.Other?.total && (
            <div className="entry">
              <h4>Other</h4>
              <p className="price">
                {getSymbolFromCurrency(costData.Other.currency)}
                {new Intl.NumberFormat("en-GB").format(
                  costData.Other.total.toFixed(2)
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCosts;
