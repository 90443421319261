import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import {
  Badge,
  Button,
  Card,
  Heading,
  Image,
  Loader,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  useTheme,
  View,
} from "@aws-amplify/ui-react";

import ConfirmSignatureModal from "../../components/ConfirmSignatureModal/ConfirmSignatureModal";
import { ReactComponent as IconCertificate } from "../../images/icons/certificate.svg";
import { ReactComponent as IconChevronRight } from "../../images/icons/chevron-right.svg";
import { ReactComponent as IconHelp } from "../../images/icons/help-circle.svg";
import { ReactComponent as IconBack } from "../../images/icons/long-arrow-left.svg";
import {
  BACK_TO_OFFER_OVERVIEW,
  BOOKING_LINK,
  CANCELLATION,
  CANCELLATION_TOOLTIP,
  HOTEL_LINK,
  MEALS,
  MEALS_TOOLTIP,
  NEGOTIATED_RATES_HEADING,
  OFFER,
  OFFER_DETAILS,
  OFFER_TOOLTIP,
  RESTRICTIONS,
  RESTRICTIONS_TOOLTIP,
  ROOM_TYPE,
  SETUP_RATES_BUTTON,
  SIGN_CONTRACT_BUTTON,
  SIGNED,
  SOMETHING_NOT_RIGHT_LINK,
} from "../../utils/Strings";
import { formatCurrency, percentageOffPublic } from "../../utils/utils";
import useCustomerDeals, { HotelDeal, Offer } from "../../lib/useHotelDeals";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";
import ContractUploadModal from "../../components/ConfirmSignatureModal/ContractUploadModal";

type OfferWithImageURL = Offer & {
  imageURL: string;
};

function OfferDetails() {
  const [axios] = useAuthenticatedAxios(false);

  const [contractUploadModalIsOpen, setContractUploadModalIsOpen] =
    useState<boolean>(false);

  const [offlineContractURL, setOfflineContractURL] = useState<string>("");
  const [loadingHotelDeal, setLoadingHotelDeal] = useState<boolean>(true);
  const [hotelDeals, setHotelDeals] = useState<HotelDeal[]>([]);
  const [selectedHotelDealId, setSelectedHotelDealId] = useState<number>(
    Number(window.location.pathname.replace("/offers/", ""))
  );
  const [signatureModalIsOpen, setSignatureModalIsOpen] =
    useState<boolean>(false);

  async function openSignatureModal() {
    if (hotelDeals[0].is_offline_contract) {
      const contractUrl = await axios.get(
        `/api/users/hotelDeals/${hotelDeals[0].id}/offline-contract`
      );
      setOfflineContractURL(contractUrl.data.url);
      setContractUploadModalIsOpen(true);
    } else {
      setSignatureModalIsOpen(true);
    }
  }

  useCustomerDeals(
    signatureModalIsOpen || contractUploadModalIsOpen,
    null,
    selectedHotelDealId,
    setHotelDeals,
    setLoadingHotelDeal,
    false
  );

  const navigate = useNavigate();
  function goToSetup() {
    navigate("/setup");
  }

  const { tokens } = useTheme();

  return (
    <View className="MainContent">
      {!loadingHotelDeal ? (
        <View className="PageContent">
          {hotelDeals[0]?.hotel && (
            <>
              <ConfirmSignatureModal
                modalIsOpen={signatureModalIsOpen}
                setIsOpen={setSignatureModalIsOpen}
                customerDealId={hotelDeals[0].id}
                hotelName={hotelDeals[0].hotel.name}
              ></ConfirmSignatureModal>
              <ContractUploadModal
                contractUrl={offlineContractURL}
                modalIsOpen={contractUploadModalIsOpen}
                setIsOpen={setContractUploadModalIsOpen}
                customerDealId={hotelDeals[0].id}
                hotelName={hotelDeals[0].hotel.name}
              ></ContractUploadModal>
            </>
          )}
          <NavLink className="backLink" to="/offers">
            <IconBack />
            {BACK_TO_OFFER_OVERVIEW}
          </NavLink>
          <Heading className="PageTitle" level={1}>
            {OFFER_DETAILS}
            <IconCertificate />
          </Heading>
          <div className="OfferDetailsPageContent">
            {hotelDeals[0].hotel.image_url && (
              <Image
                src={hotelDeals[0].hotel.image_url}
                alt={hotelDeals[0].hotel.name}
              />
            )}
            <div className="OfferDetailsTextContent">
              <div className="OfferDetailsHotelDetails">
                <Heading level={3}>{hotelDeals[0]?.hotel.name}</Heading>
                <Text className="HotelAddress">
                  {hotelDeals[0]?.hotel.address
                    ? hotelDeals[0]?.hotel.address
                    : ""}
                </Text>
                {hotelDeals[0]?.hotel.stars && (
                  <Rating
                    gap="0"
                    value={hotelDeals[0].hotel.stars}
                    maxValue={hotelDeals[0].hotel.stars}
                    fillColor={tokens.colors.yellow[60]}
                  ></Rating>
                )}
                <Text className="HotelDescription">
                  {hotelDeals[0]?.hotel.description}
                </Text>
                <div className="HotelLinks">
                  <a
                    className="ListLink"
                    href={
                      "https://www.booking.com/hotel/" +
                      hotelDeals[0]?.hotel.b_com_uri
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {BOOKING_LINK}
                    <IconChevronRight />
                  </a>
                  {hotelDeals[0]?.hotel.website_url && (
                    <a
                      className="ListLink"
                      href={hotelDeals[0]?.hotel.website_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {HOTEL_LINK}
                      <IconChevronRight />
                    </a>
                  )}
                </div>
              </div>

              <Card className="OfferDetailsOfferList">
                <div className="OfferDetailsHeader">
                  <Heading level={4}>{NEGOTIATED_RATES_HEADING}</Heading>
                  <a href="mailto:support@impala.travel">
                    <IconHelp />
                    {SOMETHING_NOT_RIGHT_LINK}
                  </a>
                  {hotelDeals[0]?.signed_by_corporate && (
                    <Badge variation="info">{SIGNED}</Badge>
                  )}
                </div>
                <Table
                  className="OfferDetailsOfferTable"
                  caption=""
                  highlightOnHover={false}
                >
                  <TableHead>
                    <Tooltip id="cancellation" />
                    <Tooltip id="meals" />
                    <Tooltip id="offer" />
                    <Tooltip id="restrictions" />
                    <TableRow>
                      <TableCell as="th">{ROOM_TYPE}</TableCell>
                      <TableCell
                        as="th"
                        data-tooltip-id="cancellation"
                        data-tooltip-place="top"
                        data-tooltip-html={CANCELLATION_TOOLTIP}
                      >
                        {CANCELLATION}
                      </TableCell>
                      <TableCell
                        as="th"
                        data-tooltip-id="meals"
                        data-tooltip-place="top"
                        data-tooltip-html={MEALS_TOOLTIP}
                      >
                        {MEALS}
                      </TableCell>
                      <TableCell
                        as="th"
                        data-tooltip-id="offer"
                        data-tooltip-place="top"
                        data-tooltip-html={OFFER_TOOLTIP}
                      >
                        {OFFER}
                      </TableCell>
                      <TableCell
                        as="th"
                        data-tooltip-id="restrictions"
                        data-tooltip-place="top"
                        data-tooltip-html={RESTRICTIONS_TOOLTIP}
                      >
                        {RESTRICTIONS}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hotelDeals[0]?.offers.map((pricingDetail) => (
                      <TableRow key={pricingDetail?.id}>
                        <TableCell>
                          {pricingDetail?.room_type
                            ? pricingDetail?.room_type
                            : "Any"}
                        </TableCell>
                        <TableCell>
                          {pricingDetail?.flexibility
                            ? pricingDetail?.flexibility
                            : "Any"}
                        </TableCell>
                        <TableCell>
                          {pricingDetail?.meal_plan
                            ? pricingDetail?.meal_plan
                            : "Any"}{" "}
                        </TableCell>
                        <TableCell>
                          {pricingDetail?.offer_type === "Static"
                            ? formatCurrency(
                                pricingDetail.static_rate,
                                pricingDetail.static_currency
                              )
                            : percentageOffPublic(
                                pricingDetail?.dynamic_percentage
                              )}
                        </TableCell>
                        <TableCell>
                          {/* <Tooltip id={pricingDetail?.id.toString()}>
                            <ul>
                              {pricingDetail?.restrictions?.map(
                                (restriction) => (
                                  <li>{restriction}</li>
                                )
                              )}
                            </ul>
                          </Tooltip>
                          {pricingDetail?.restrictions?.length ? (
                            <Text
                              data-tooltip-id={pricingDetail.id}
                              data-tooltip-place="left"
                            >
                              {RESTRICTIONS}
                            </Text>
                          ) : (
                            "N/A"
                          )} */}
                          N/A
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {!hotelDeals[0]?.signed_by_corporate ? (
                  hotelDeals[0]?.is_offline_contract ? (
                    <Button isFullWidth={true} onClick={openSignatureModal}>
                      {SIGN_CONTRACT_BUTTON}
                    </Button>
                  ) : (
                    <a
                      className="SignButton"
                      href={hotelDeals[0]?.contract_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button isFullWidth={true} onClick={openSignatureModal}>
                        {SIGN_CONTRACT_BUTTON}
                      </Button>
                    </a>
                  )
                ) : (
                  <Button
                    className="SetupButton"
                    isFullWidth={true}
                    onClick={goToSetup}
                  >
                    {SETUP_RATES_BUTTON}
                  </Button>
                )}
              </Card>
            </div>
          </div>
        </View>
      ) : (
        <Loader variation="linear"></Loader>
      )}
    </View>
  );
}

export default OfferDetails;
