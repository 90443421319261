import "./ConfirmSignatureModal.css";

import React from "react";
import Modal from "react-modal";

import { Button, Heading, Text, ThemeProvider } from "@aws-amplify/ui-react";

import { ImpalaTheme } from "../../theme/index";
import {
  MODAL_BODY_TEXT,
  MODAL_BUTTON_1,
  MODAL_BUTTON_2,
  MODAL_HEADING_1,
  MODAL_HEADING_2,
  MODAL_LABEL,
} from "../../utils/Strings";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0",
    transform: "translate(-50%, -50%)",
  },
};

function ConfirmSignatureModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hotelName: string;
  customerDealId: number;
}) {
  const [axios] = useAuthenticatedAxios(false);

  function closeModal() {
    props.setIsOpen(false);
  }

  /* 
  This function updates the status field of an offer to ACCEPTED
  */
  async function acceptOffer() {
    try {
      axios.put(`/api/users/hotelDeals/${props.customerDealId}/status`, {
        signedByCorporate: true,
      });
    } catch (error) {
      console.log(error);
    }

    closeModal();
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={MODAL_LABEL}
    >
      <ThemeProvider theme={ImpalaTheme}>
        <Heading className="ModalHeader">{MODAL_HEADING_1}</Heading>
        <div className="ModalBody">
          <div className="HotelInfo">
            <Text>{props.hotelName}</Text>
          </div>
          <Heading>{MODAL_HEADING_2}</Heading>
          <Text>{MODAL_BODY_TEXT}</Text>
        </div>
        <div className="ModalButtonFooter">
          <Button onClick={closeModal}>{MODAL_BUTTON_1}</Button>
          <Button variation="primary" onClick={acceptOffer}>
            {MODAL_BUTTON_2}
          </Button>
        </div>
      </ThemeProvider>
    </Modal>
  );
}

export default ConfirmSignatureModal;
