import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";

import { ReactComponent as BackIcon } from "../img/arrow-left.svg";
import { ReactComponent as CaretLeft } from "../img/caret-left.svg";
import { ReactComponent as CaretRight } from "../img/caret-right.svg";
import { ReactComponent as CaretLeftGrey } from "../img/caret-left-grey.svg";
import { ReactComponent as CaretRightGrey } from "../img/caret-right-grey.svg";
import { format } from "date-fns";

import { Button, Form } from "react-bootstrap";

import { Event } from "./EventStatus";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";
import { MultiValue } from "react-select";

import NewGroup from "./NewGroup";

type Option = { label: string; value: string };

const EventDateRestrictions = (props: {
  event: Event;
  onUpdate: Function;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
  initialDate: number;
}) => {
  const [axios] = useAuthenticatedAxios(true);

  const [currentDate, setCurrentDate] = useState(props.initialDate);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExempt, setIsExempt] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [mustShareRoom, setMustShareRoom] = useState(false);
  const [organiserManaged, setOrganiserManaged] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [maxAttendees, setMaxAttendees] = useState<
    number | string | undefined
  >();
  const [allowList, setAllowList] = useState<Option[]>([]);
  const [allowListOptions, setAllowListOptions] = useState<Option[]>([]);
  const [isHotelAllowed, setIsHotelAllowed] = useState<boolean>(
    props.event.dates[currentDate].hotel_required
  );

  useEffect(() => {
    setHasChanged(false);
    setIsExempt(false);
    setIsTeam(false);
  }, [currentDate]);

  useEffect(() => {
    setMaxAttendees(
      props.event.dates[currentDate].max_people ||
        props.event.dates[currentDate].max_people === 0
        ? props.event.dates[currentDate].max_people
        : ""
    );
    setIsHotelAllowed(props.event.dates[currentDate].hotel_required);
  }, [currentDate, props.event.dates]);

  useEffect(() => {
    const options: Option[] = props.event.dates[currentDate].allow_list?.map(
      (g) => {
        return {
          label: g,
          value: g,
        };
      }
    );
    setAllowList(options);
  }, [currentDate]);

  useEffect(() => {
    const options: Option[] = props.event.details.attendee_categories?.map(
      (g) => {
        return {
          label: g,
          value: g,
        };
      }
    );
    setAllowListOptions(options);
  }, [props.event.details.attendee_categories]);

  const handleToggleHotelRequired = (e: any) => {
    setIsHotelAllowed(e.target.checked);
    setHasChanged(true);
  };

  const handleMaxAttendeesChange = (e: React.ChangeEvent<any>) => {
    if (Number.isNaN(parseInt(e.target.value)) || parseInt(e.target.value) < 0)
      setMaxAttendees("");
    else setMaxAttendees(parseInt(e.target.value));
    setHasChanged(true);
  };

  const handleCreate = async (inputValue: string) => {
    setIsSubmitting(true);
    try {
      const body = {
        groupName: inputValue,
        isTeam: isTeam,
        isExempt: isExempt,
        mustShareRoom: mustShareRoom,
        isSilent: organiserManaged,
      };
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/group`,
        body
      );
      setAllowListOptions([
        ...(structuredClone(allowListOptions) || []),
        { value: inputValue, label: inputValue },
      ]);
      props.onUpdate();
      handleNewValue([
        ...(allowList || []),
        { value: inputValue, label: inputValue },
      ]);
    } catch (err) {
      alert("Could not add new attendee group, please try again later");
      console.error("Error adding group:", err);
    } finally {
      setIsSubmitting(false);
      setIsExempt(false);
      setIsTeam(false);
      setOrganiserManaged(false);
      setMustShareRoom(false);
    }
  };

  const handleNewValue = (newValue: MultiValue<Option> | Option[]) => {
    const newOptions: Option[] = structuredClone(newValue);
    setAllowList(newOptions);
    setHasChanged(true);
  };

  const handleSaveChanges = async () => {
    setIsSubmitting(true);
    try {
      const body = {
        hotelRequired: isHotelAllowed,
        maxPeople: maxAttendees,
        allowList:
          allowList?.length > 0 ? allowList?.map((o) => o.value) : null,
      };
      await axios.put(
        `/api/users/events/1634${props.event.details.id}/dates/${props.event.dates[currentDate].id}/restrictions`,
        body
      );
      props.onUpdate();
      setHasChanged(false);
    } catch (err) {
      alert("Could not save date restrictions, please try again later");
      console.error("Error saving date restrictions", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="manageDates">
      <header className="pageHeader topLevel">
        <Button className="backHeaderLink" onClick={() => props.setSubPage("")}>
          <BackIcon />
          <span className="visually-hidden">Back</span>
        </Button>
        <h2>Set Restrictions</h2>
      </header>
      <section className="restrict-date-selection">
        <h3>
          {currentDate > 0 ? (
            <CaretLeft
              aria-label="Button, previous date"
              onClick={() => setCurrentDate(currentDate - 1)}
            />
          ) : (
            <CaretLeftGrey aria-label="Cannot go back. No earlier dates added" />
          )}{" "}
          {format(props.event.dates[currentDate].date, "eee, dd MMM yyyy")}{" "}
          {currentDate < props.event.dates.length - 1 ? (
            <CaretRight
              aria-label="Button, next date"
              onClick={() => setCurrentDate(currentDate + 1)}
            />
          ) : (
            <CaretRightGrey aria-label="Cannot go forward. No earlier dates added" />
          )}
        </h3>
        <Button
          disabled={
            !hasChanged ||
            isSubmitting ||
            ["Ongoing", "Completed", "Cancelled"].includes(
              props.event.details.status
            )
          }
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
      </section>
      <section className="allow-hotel-stays-flag">
        <Form.Check
          disabled={["Ongoing", "Completed", "Cancelled"].includes(
            props.event.details.status
          )}
          id="hotelRequired"
          name="hotelRequired"
          type="checkbox"
          className="toggleCheckbox"
          checked={isHotelAllowed}
          onChange={handleToggleHotelRequired}
        />
        <div className="hotel-stay-disable-text">
          <h3>Allow hotel stays for this night</h3>
          <p>
            Deselecting this will prevent your attendees booking hotel stays for
            this night. Only change this if you do not need accomodation for
            this night.
          </p>
        </div>
      </section>
      <section className="restriction-settings">
        <div className="restriction-section">
          <h4>Allow list</h4>
          <p>
            Only people in these groups can book hotel stays for this night.
            <br />
            You can add people to a group when you invite them.
          </p>
          <CreatableSelect
            isMulti
            isClearable
            isLoading={isSubmitting}
            options={allowListOptions || []}
            isDisabled={
              isSubmitting ||
              !isHotelAllowed ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
            value={allowList || []}
            id="allowList"
            name="allowList"
            onChange={(newValue) => handleNewValue(newValue)}
            onCreateOption={handleCreate}
            placeholder="No restrictions"
            formatCreateLabel={(inputValue: string) => {
              return (
                <NewGroup
                  inputValue={inputValue}
                  isTeam={isTeam}
                  setIsTeam={setIsTeam}
                  isExempt={isExempt}
                  setIsExempt={setIsExempt}
                  mustShareRoom={mustShareRoom}
                  setMustShareRoom={setMustShareRoom}
                  organiserManaged={organiserManaged}
                  setOrganiserManaged={setOrganiserManaged}
                />
              );
            }}
          />
        </div>
        <div className="restriction-section">
          <h4>Maximum attendees</h4>
          <p>
            Restrict the number of attendees that can select a hotel stay for
            this night.
          </p>
          <Form.Control
            id="maxAttendees"
            name="maxAttendees"
            className="short-field"
            placeholder="No maximum"
            value={maxAttendees}
            onChange={(e) => handleMaxAttendeesChange(e)}
            disabled={
              isSubmitting ||
              !isHotelAllowed ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
          ></Form.Control>
          <small>
            This restriction will not apply for groups you've set to be exempt
            from such limits.{" "}
            <a
              href="#"
              onClick={() => {
                props.setPage("Attendees");
                props.setSubPage("Groups");
              }}
            >
              View & edit your groups
            </a>
            .
          </small>
        </div>
      </section>
    </div>
  );
};

export default EventDateRestrictions;
