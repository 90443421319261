import React, { useEffect, useState } from "react";

import {
  Event,
  EventAttendee,
  EventAttendeeDate,
  EventHotel,
} from "./EventStatus";

import "./Events.css";
import { Button } from "react-bootstrap";
import BannerAlert from "./BannerAlert/BannerAlert";

import { ReactComponent as IconEdit } from "../img/edit.svg";
import { ReactComponent as IconRoom } from "../img/room.svg";
import { ReactComponent as IconSent } from "../img/send.svg";
import { ReactComponent as IconSeen } from "../img/show.svg";
import { ReactComponent as IconAccepted } from "../img/thumbs-up-icon.svg";
import { ReactComponent as IconTrash } from "../img/trashcan.svg";

import IconSendMessage from "./Icons/iconSendMessage";

import ConfirmModal from "./ConfirmModal";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";
import { Tooltip } from "react-tooltip";

const EventHotels = (props: {
  event: Event;
  onUpdate: Function;
  openWarningModal: Function;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
  setSelectedHotel: React.Dispatch<
    React.SetStateAction<EventHotel | undefined>
  >;
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [axios] = useAuthenticatedAxios(true);

  const [combinedAttendees, setCombinedAttendees] = useState<EventAttendee[]>(
    []
  );
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState<EventHotel | undefined>();

  useEffect(() => {
    const ca = props.event.attendees
      .filter((a) => a.book_room)
      .filter((a) => !a.sharing_room_with || a.id > a.sharing_room_with)
      .map((a) => {
        return {
          ...a,
          dates: [
            ...a.dates,
            ...(props.event.attendees
              .find((sp) => sp.id === a.sharing_room_with)
              ?.dates.filter(
                (spd) =>
                  spd.hotel_required &&
                  !a.dates
                    .filter((ad) => ad.hotel_required)
                    .map((ad) => ad.date)
                    .includes(spd.date)
              ) || []),
          ],
        };
      });
    setCombinedAttendees(ca);
  }, [props.event.attendees]);

  const assignedRoomNights = (hotel: EventHotel) => {
    return combinedAttendees
      .map(
        (a) =>
          a.dates.filter(
            (d: EventAttendeeDate) =>
              d.hotel_required && d.assigned_hotel === hotel.id
          ).length
      )
      .reduce((i, acc) => acc + i, 0);
  };

  const sendRoomingList = async () => {
    try {
      await axios.post(
        `/api/users/events/1634${props.event.details.id}/send-hotel-confirmation-emails`,
        { hotels: [selectedHotel?.id] }
      );
      props.onUpdate();
    } catch (err) {
      props.openWarningModal(
        "Something went wrong",
        `Could not send rooming list to hotel, please try again later.`
      );
      console.error("Error sending rooming list email:", err);
    }
  };

  return (
    <section className="event-hotel-list">
      <ConfirmModal
        modalIsOpen={confirmModalOpen}
        setIsOpen={setConfirmModalOpen}
        content={`Please confirm you want to send the rooming list to ${selectedHotel?.name}. This will trigger an email to ${selectedHotel?.booking_email} with a link to their Rooming List Page, asking them to add all attendees to their internal systems. Please ensure your rooming list is finalized and you have been in touch with the hotel about the booking before triggering this email.`}
        onConfirm={sendRoomingList}
        isDisabled={false}
      ></ConfirmModal>
      {!props.event.details.is_procured && (
        <Button
          onClick={() => {
            props.setSubPage("Add Hotel");
            props.setSelectedHotel(undefined);
          }}
        >
          Add Hotel
        </Button>
      )}

      <section className="hotel-information">
        {props.event.hotels.map((hotel) => (
          <div className="hotel-list-item">
            <div className="banner-warnings">
              {!props.event.details.is_procured &&
                assignedRoomNights(hotel) <
                  (hotel.contracted_rooms || 0) -
                    (hotel.cancellable_rooms || 0) && (
                  <BannerAlert variant={"BannerNegative"}>
                    <div className="banner-content">
                      <span>
                        There are currently more non-refundable than assigned
                        room nights for this hotel.
                      </span>
                      <span>
                        To avoid paying for unused rooms,{" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            props.setSubPage("Rooming List");
                          }}
                        >
                          add more attendees
                        </a>{" "}
                        to this hotel.
                      </span>
                    </div>
                  </BannerAlert>
                )}
              {!props.event.details.is_procured &&
                assignedRoomNights(hotel) > (hotel.contracted_rooms || 0) && (
                  <BannerAlert variant={"BannerNegative"}>
                    <div className="banner-content">
                      <span>
                        There are currently more assigned than contracted room
                        nights for this hotel.
                      </span>
                      <span>
                        Either{" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            props.setSubPage("Rooming List");
                          }}
                        >
                          reduce the number of assigned attendees
                        </a>{" "}
                        or contract more room nights.
                      </span>
                    </div>
                  </BannerAlert>
                )}
            </div>
            <div className="hotel-list-actions">
              <div className="safe-actions">
                {!props.event.details.is_procured && (
                  <a
                    className="hotel-list-action-button"
                    onClick={() => {
                      props.setSubPage("Edit Hotel");
                      props.setSelectedHotel(hotel);
                    }}
                  >
                    <IconEdit />
                    Edit Hotel
                  </a>
                )}
                {hotel.rooming_list_id && (
                  <a
                    className="hotel-list-action-button"
                    target="_blank"
                    href={`/events/1634${props.event.details.id}/hotel?pageId=${hotel.rooming_list_id}`}
                  >
                    <IconRoom />
                    Preview Hotel Rooming List Page
                  </a>
                )}
                {!props.event.details.is_procured && hotel.booking_email && (
                  <a
                    className="hotel-list-action-button"
                    onClick={() => {
                      setSelectedHotel(hotel);
                      setConfirmModalOpen(true);
                    }}
                  >
                    <IconSendMessage />
                    Send Rooming List to Hotel
                  </a>
                )}
              </div>
              <div className="dangerous-actions">
                {!props.event.details.is_procured && (
                  <a
                    className="hotel-list-action-button"
                    onClick={() => {
                      props.setSelectedHotel(hotel);
                      props.setDeleteModalOpen(true);
                    }}
                  >
                    <IconTrash />
                    Delete Hotel
                  </a>
                )}
              </div>
            </div>
            <div className="hotel-item-content">
              <img src={hotel.image_url} role="presentation" />
              <div className="hotel-item-text">
                {(hotel.confirmed_rooming_list ||
                  hotel.viewed_rooming_list ||
                  hotel.sent_rooming_list) && (
                  <Tooltip id={`hotel-rl-status-${hotel.id}`}></Tooltip>
                )}
                <div className="hotel-item-title">
                  <h2>{hotel.name}</h2>
                    <div
                      data-tooltip-id={`hotel-rl-status-${hotel.id}`}
                      data-tooltip-place="bottom"
                      data-tooltip-html={
                        hotel.confirmed_rooming_list
                          ? `${hotel.name} has approved its rooming list`
                          : hotel.viewed_rooming_list
                          ? `${hotel.name} has seen its rooming list`
                          : hotel.sent_rooming_list
                          ? `A rooming list was sent to ${hotel.name}`
                          : ""
                      } 
                      className="rooming-list-status">
                      {hotel.confirmed_rooming_list ? (
                      <span className="confirmed-list">
                        <IconAccepted /> Rooming list accepted
                      </span>
                    ) : hotel.viewed_rooming_list ? (
                      <span className="seen-list">
                        <IconSeen /> Rooming list seen
                      </span>
                    ) : hotel.sent_rooming_list ? (
                      <span>
                        <IconSent /> Rooming list sent
                      </span>
                    ) : (
                      ""
                    )}
                    </div>
                </div>
                <p>
                  {hotel.address || ""}
                  <br />
                  {hotel.maps_url && <a href={hotel.maps_url}>Show on a map</a>}
                </p>
                <div className="hotel-information-meta">
                  <div className="hotel-information-meta-block">
                    <h5>Hotel details</h5>

                    <div>
                      <b>Check-in time:</b> {hotel.check_in_time || ""}
                    </div>

                    <div>
                      <b>Check-out time:</b> {hotel.check_out_time || ""}
                    </div>

                    {hotel.booking_reference &&
                      !props.event.details.is_procured && (
                        <div>
                          <b>Group Booking ID:</b> {hotel.booking_reference}
                        </div>
                      )}
                  </div>
                  <div className="hotel-information-meta-block">
                    <h5>Contract details</h5>
                    {props.event.details.is_procured &&
                      hotel.min_rate &&
                      hotel.currency && (
                        <div>
                          <b>Negotiated rate:</b> {hotel.min_rate}
                          {Number(hotel.max_rate) > Number(hotel.min_rate)
                            ? `-${hotel.max_rate}`
                            : ""}{" "}
                          {hotel.currency}
                        </div>
                      )}
                    <div>
                      <b>Contracted room-nights:</b>{" "}
                      {hotel.contracted_rooms || 0}
                    </div>
                    <div>
                      <b>Non-refundable room-nights:</b>{" "}
                      {(hotel.contracted_rooms || 0) -
                        (hotel.cancellable_rooms || 0)}
                    </div>
                    <div>
                      <b>Assigned room-nights:</b> {assignedRoomNights(hotel)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default EventHotels;
