import React, { useState } from "react";
import { Event, EventHotel } from "./EventStatus";
import { ReactComponent as BackIcon } from "../img/arrow-left.svg";

import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import "./Events.css";
import { Button, Form, ProgressBar } from "react-bootstrap";
import { Image } from "@aws-amplify/ui-react";

const EditHotel = (props: {
  event: Event;
  onUpdate: Function;
  selectedHotel: EventHotel | undefined;
  subPage: string;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [axios] = useAuthenticatedAxios(true);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [hotelId, setHotelId] = useState(props.selectedHotel?.id);
  const [available, setAvailable] = useState(
    props.selectedHotel?.contracted_rooms
  );
  const [cancellable, setCancellable] = useState(
    props.selectedHotel?.cancellable_rooms
  );
  const [bookingReference, setBookingReference] = useState(
    props.selectedHotel?.booking_reference
  );
  const [imageUrl, setImageUrl] = useState(props.selectedHotel?.image_url);
  const [name, setName] = useState(props.selectedHotel?.name);
  const [address, setAddress] = useState(props.selectedHotel?.address);
  const [checkInTime, setCheckInTime] = useState(
    props.selectedHotel?.check_in_time
  );
  const [checkOutTime, setCheckOutTime] = useState(
    props.selectedHotel?.check_out_time
  );
  const [mapsUrl, setMapsUrl] = useState(props.selectedHotel?.maps_url);
  const [bookingEmail, setBookingEmail] = useState(
    props.selectedHotel?.booking_email
  );

  const handleImageUpload = async (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      let id = hotelId;
      setIsLoading(true);
      setUploadProgress(0);
      // For new hotels, create it first before we can upload the picture
      if (!id) {
        const hotelResponse = await axios.post(
          `/api/users/events/1634${props.event.details.id}/event-hotel`,
          { name, address, checkInTime, checkOutTime, mapsUrl, bookingEmail }
        );
        id = hotelResponse.data.hotelId;
        setHotelId(id);
      }
      const uploadResponse = await axios.post(
        `/api/users/events/1634${props.event.details.id}/event-hotel/${id}/image`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1)
            );
            setUploadProgress(progress);
          },
        }
      );
      setImageUrl(uploadResponse.data.url);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error Uploading or Parsing File");
    } finally {
      setUploadProgress(0);
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      let id = hotelId;
      setIsLoading(true);
      setUploadProgress(0);
      if (!id) {
        // For new hotels, create it first
        const hotelResponse = await axios.post(
          `/api/users/events/1634${props.event.details.id}/event-hotel`,
          { name, address, checkInTime, checkOutTime, mapsUrl, bookingEmail }
        );
        id = hotelResponse.data.hotelId;
        setHotelId(id);
      } else {
        // If already exists, update it
        await axios.put(
          `/api/users/events/1634${props.event.details.id}/event-hotel/${id}`,
          { name, address, checkInTime, checkOutTime, mapsUrl, bookingEmail }
        );
      }
      // Now link it to the event
      await axios.put(
        `/api/users/events/1634${props.event.details.id}/event-hotel/${id}/offers`,
        { available, cancellable, bookingReference }
      );

      props.onUpdate();
      props.setSubPage("");
    } catch (error) {
      console.error("Error saving details:", error);
      alert("Error Saving Details");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <header className="pageHeader topLevel">
        <Button className="backHeaderLink" onClick={() => props.setSubPage("")}>
          <BackIcon />
          <span className="visually-hidden">Back</span>
        </Button>
        <h2>
          {props.subPage}
          {props.selectedHotel?.name ? ` "${props.selectedHotel.name}"` : ""}
        </h2>
      </header>
      <div className="add-hotel-section-content">
        <div className="add-hotel-form">
          <Form.Label htmlFor="hotelName" className="full-width">
            Hotel Name
            <Form.Control
              disabled={isLoading}
              type="text"
              id="hotelName"
              placeholder="Please enter a hotel name"
              className="accountDetailsFormControl"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Label>
          <Form.Label htmlFor="address" className="full-width">
            Full hotel address
            <p>This is shown to your attendees when they're assigned to the hotel</p>
            <Form.Control
              disabled={isLoading}
              type="text"
              id="address"
              placeholder="Hotel Address"
              className="accountDetailsFormControl"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></Form.Control>
          </Form.Label>
          <Form.Label htmlFor="checkInTime">
            Check-in time
            <Form.Control
              disabled={isLoading}
              type="text"
              id="checkInTime"
              placeholder="e.g. 15:00"
              className="accountDetailsFormControl"
              value={checkInTime}
              onChange={(e) => setCheckInTime(e.target.value)}
            ></Form.Control>
          </Form.Label>
          <Form.Label htmlFor="checkOutTime">
            Check-out time
            <Form.Control
              disabled={isLoading}
              type="text"
              id="checkOutTime"
              placeholder="e.g. 11:00"
              className="accountDetailsFormControl"
              value={checkOutTime}
              onChange={(e) => setCheckOutTime(e.target.value)}
            ></Form.Control>
          </Form.Label>
          <Form.Label htmlFor="mapsUrl" className="full-width">
            Google Maps URL
            <p>This is shown to your attendees when they're assigned to the hotel</p>
            <Form.Control
              disabled={isLoading}
              type="text"
              id="mapsUrl"
              placeholder="e.g. Google Maps URL for this Hotel"
              className="accountDetailsFormControl"
              value={mapsUrl}
              onChange={(e) => setMapsUrl(e.target.value)}
            ></Form.Control>
          </Form.Label>
          <Form.Label htmlFor="bookingEmail" className="full-width">
            Hotel contact email
            <Form.Control
              disabled={isLoading}
              type="text"
              id="bookingEmail"
              className="accountDetailsFormControl"
              value={bookingEmail}
              onChange={(e) => setBookingEmail(e.target.value)}
            ></Form.Control>
          </Form.Label>
          <Form.Label htmlFor="bookingReference">
            Group Booking ID
            <Form.Control
              disabled={isLoading}
              type="text"
              id="bookingReference"
              className="accountDetailsFormControl"
              value={bookingReference}
              onChange={(e) => setBookingReference(e.target.value)}
            ></Form.Control>
          </Form.Label>
          <Form.Label htmlFor="available">
            Number of room nights contracted with the hotel
            <Form.Control
              disabled={isLoading}
              type="number"
              min={0}
              id="available"
              className="accountDetailsFormControl"
              value={available}
              onChange={(e) => {
                setAvailable(Number(e.target.value));
                setCancellable(
                  Number(e.target.value) < (cancellable || 0)
                    ? Number(e.target.value)
                    : cancellable
                );
              }}
            ></Form.Control>
          </Form.Label>
          <Form.Label htmlFor="cancellable">
            Number of room nights that can be cancelled
            <Form.Control
              disabled={isLoading}
              type="number"
              id="cancellable"
              className="accountDetailsFormControl"
              value={cancellable}
              max={available}
              min={0}
              onChange={(e) =>
                setCancellable(
                  Number(e.target.value) > (available || 0)
                    ? available
                    : Number(e.target.value)
                )
              }
            ></Form.Control>
          </Form.Label>
        </div>
        <div className="hotel-image-upload">
          <Form.Label className="full-width">
            Hotel Picture
            <div className="logo-holder">
              <Image
                alt={imageUrl ? `Picture of ${name || "hotel"}` : ""}
                src={imageUrl}
              />
            </div>
          </Form.Label>
          <label
            htmlFor="file-upload"
            className="btn-secondary file-upload-label"
          >
            {imageUrl ? (
              <div>Update Hotel Picture</div>
            ) : (
              <div>Add Hotel Picture</div>
            )}
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
          <small>
            PNG and JPG accepted. Your attendees will see this picture on their
            forms if they are assigned to this hotel.
          </small>
          {uploadProgress > 0 && (
            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
          )}
        </div>
      </div>
      <Button disabled={isLoading || !name} onClick={handleSave}>
        Save
      </Button>

      <div></div>
    </div>
  );
};

export default EditHotel;
