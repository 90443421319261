import React from "react";
import { ReactComponent as CheckOutIcon } from "../img/check-out.svg";
import { ReactComponent as HelpCircleIcon } from "../img/help-circle.svg";

import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const ExternalBookingsHeader = () => {
  let userAuth = useAuth0();

  if (!userAuth.isAuthenticated) return <></>;

  return (
    <div className="events-header-wrapper">
      <div className="left-side">
        <Link to="/srv/external/list">View all your bookings</Link>
      </div>
      <div className="right-side">
        <Link to="mailto:support@impala.travel">
          <HelpCircleIcon />{" "}
          <span className="hide-on-mobile">Help & Support</span>
        </Link>
        <div
          className="link-like"
          onClick={() =>
            userAuth.logout({
              logoutParams: {
                returnTo:
                  window.location.origin +
                  `/callback?externalServiceLogout=true`,
              },
            })
          }
        >
          <CheckOutIcon /> <span className="hide-on-mobile">Log Out</span>
        </div>
      </div>
    </div>
  );
};

export default ExternalBookingsHeader;
