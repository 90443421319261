import React, { useEffect, useState } from "react";
import { ReactComponent as CaretDown } from "../img/caret-down.svg";
import { ReactComponent as AttendeesEmpty } from "../img/attendees-empty.svg";
import { ReactComponent as CheckMark } from "../img/checkmark-circle-filled.svg";
import { ReactComponent as RedFlag } from "../img/flag.svg";
import { ReactComponent as HelpIcon } from "../img/help-circle.svg";
import { ReactComponent as MenuOverFlowVertical } from "../img/menu-overflow-vertical.svg";
import { ReactComponent as FilterIcon } from "../img/filter.svg";
import { ReactComponent as ActiveFilterIcon } from "../img/filter-remove.svg";
import { ReactComponent as EditIcon } from "../images/icons/edit.svg";
import { ReactComponent as WarningIcon } from "../img/warning-triangle.svg";

import { CSVLink } from "react-csv";

import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";

import { format } from "date-fns";

import { Tooltip } from "react-tooltip";

import { Event, EventAttendee } from "./EventStatus";
import {
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
} from "@aws-amplify/ui-react";

import { Button } from "react-bootstrap";
import AttendeeDatesModal from "./AttendeeDatesModal";
import AttendeePreferencesModal from "./AttendeePreferencesModal";

import "./Events.css";
import AttendeeGroupsModal from "./AttendeeGroupsModal";
import AttendeeFilterModal from "./AttendeeFilterModal";
import { Form } from "react-bootstrap";

type Option = { label: string; value: string };
type AttendeeAction = { id: string; active: boolean };
type AttendeeNames = { id: string; name: string };

type CSVHeader = { label: string; key: string };

const EventAttendeeList = (props: {
  event: Event;
  onUpdate: Function;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
  setSelectedAttendees: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedAttendee: React.Dispatch<
    React.SetStateAction<EventAttendee | undefined>
  >;
}) => {
  const [axios] = useAuthenticatedAxios(true);
  const [nameFilter, setNameFilter] = useState<string | undefined>();
  const [filterIndex, setFilterIndex] = useState(0);
  const [showAttendeeFilterModal, setShowAttendeeFilterModal] = useState(false);
  const [showAttendeeDatesModal, setShowAttendeeDatesModal] = useState(false);
  const [showAttendeePreferencesModal, setShowAttendeePreferencesModal] =
    useState(false);
  const [showAttendeeGroupsModal, setShowAttendeeGroupsModal] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState<EventAttendee>();
  const [exportAttendees, setExportAttendees] = useState<any[]>([]);
  const [CSVHeaders, setCSVHeaders] = useState<CSVHeader[]>([]);
  const [eventGroups, setEventGroups] = useState<Option[]>([]);
  const [filterGroups, setFilterGroups] = useState<Option[]>([]);
  const [filterGroupType, setFilterGroupsType] = useState<string | undefined>();
  const [filterGroupOperator, setFilterGroupsOperator] = useState<
    string | undefined
  >();

  const [showNotInvited, setShowNotInvited] = useState(true);
  const [showInvited, setShowInvited] = useState(true);

  const [showNoReply, setShowNoReply] = useState(true);
  const [showNotAttending, setShowNotAttending] = useState(true);
  const [showAttending, setShowAttending] = useState(true);
  const [showPartialAttending, setShowPartialAttending] = useState(true);

  const [nameEdited, setNameEdited] = useState<AttendeeAction[]>([]);
  const [attendeeNames, setAttendeeNames] = useState<AttendeeNames[]>(
    props.event.attendees.map((a) => {
      return { id: a.id, name: a.name || "" };
    })
  );

  const [displayedAttendees, setDisplayedAttendees] = useState<EventAttendee[]>(
    []
  );
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);

  useEffect(() => {
    setDisplayedAttendees(
      props.event.attendees
        // Name filter
        .filter((attendee) => {
          return (
            !nameFilter ||
            attendee.email.toLowerCase().includes(nameFilter.toLowerCase()) ||
            attendee.name?.toLowerCase().includes(nameFilter.toLowerCase())
          );
        })
        // Invite status filter
        .filter((attendee) => {
          if (!showInvited && attendee.invite_sent) return false;
          if (!showNotInvited && !attendee.invite_sent) return false;
          return true;
        })
        // Date filter
        .filter((attendee) => {
          if (
            !showNoReply &&
            !attendee.has_replied &&
            attendee.dates.filter((ad) => ad.attending).length === 0
          )
            return false;
          if (
            !showNotAttending &&
            attendee.has_replied &&
            attendee.dates.filter((ad) => ad.attending).length === 0
          )
            return false;
          if (
            !showAttending &&
            props.event.dates.filter(
              (d) =>
                d.type === "Required" &&
                !attendee.dates.find((date) => date.date === d.id)?.attending
            ).length === 0
          )
            return false;
          if (
            !showPartialAttending &&
            props.event.dates.filter(
              (d) =>
                d.type === "Required" &&
                !attendee.dates.find((date) => date.date === d.id)?.attending
            ).length > 0 &&
            attendee.dates.filter((ad) => ad.attending).length > 0
          )
            return false;
          return true;
        })
        // Group Filter
        .filter((attendee) => {
          if (filterGroups.length === 0) return true;
          if (filterGroupType === "allow" && filterGroupOperator === "and")
            return (
              attendee.categories.filter((category) =>
                filterGroups.map((g) => g.label).includes(category)
              ).length === filterGroups.length
            );
          if (filterGroupType === "allow" && filterGroupOperator === "or")
            return (
              attendee.categories.filter((category) =>
                filterGroups.map((g) => g.label).includes(category)
              ).length > 0
            );
          if (filterGroupType === "disallow" && filterGroupOperator === "or")
            return (
              attendee.categories.filter((category) =>
                filterGroups.map((g) => g.label).includes(category)
              ).length === 0
            );
          if (filterGroupType === "disallow" && filterGroupOperator === "and")
            return (
              attendee.categories.filter((category) =>
                filterGroups.map((g) => g.label).includes(category)
              ).length < filterGroups.length
            );
          return true;
        })
        .sort((a, b) => (a?.email < b?.email ? -1 : 1))
    );
  }, [
    filterGroupOperator,
    filterGroupType,
    filterGroups,
    nameFilter,
    props.event.attendees,
    props.event.dates,
    showAttending,
    showInvited,
    showNoReply,
    showNotAttending,
    showNotInvited,
    showPartialAttending,
  ]);

  useEffect(() => {
    const options: Option[] = props.event.details.attendee_categories?.map(
      (g) => {
        return {
          label: g,
          value: g,
        };
      }
    );
    setEventGroups(options);
  }, [props.event.details.attendee_categories]);

  useEffect(() => {
    setNameEdited(
      props.event.attendees.map((a) => {
        return { id: a.id, active: false };
      })
    );
    setAttendeeNames(
      props.event.attendees.map((a) => {
        return { id: a.id, name: a.name || "" };
      })
    );
  }, [props.event.attendees]);

  const prepareAttendeesForExport = () => {
    let headers: CSVHeader[] = [
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Groups", key: "groups" },
      { label: "Has replied?", key: "has_replied" },
      { label: "Room preferences", key: "room_preferences" },
      { label: "Must share room", key: "must_share_room" },
      { label: "Sharing preferences", key: "sharing_preferences" },
      { label: "Book this attendee?", key: "book_room" },
      { label: "Bringing +1?", key: "plus_one" },
      { label: "Preferred Room Setup", key: "prefers_twin" },
      { label: "Assigned Hotel", key: "assigned_hotel" },
      { label: "Sharing Room With", key: "sharing_room_with" },
      { label: "Room Setup", key: "room_setup" },
    ];

    for (const date of props.event.dates) {
      headers.push({
        label: `Attending ${format(date.date, "eee, do MMM")}`,
        key: `attending_${date.id}`,
      });
    }
    for (const date of props.event.dates) {
      headers.push({
        label: `Hotel ${format(date.date, "eee, do MMM")}`,
        key: `hotel_${date.id}`,
      });
    }

    setCSVHeaders(headers);

    const ea = props.event.attendees.map((attendee) => {
      const attendeeValues: any = {
        name: attendee.name || "",
        email: attendee.email,
        groups: attendee.categories ? attendee.categories.join("; ") : "",
        has_replied: attendee.has_replied || false,
        room_preferences: attendee.room_preferences || "",
        must_share_room: attendee.must_share_room || false,
        sharing_preferences: attendee.sharing_preferences || "",
        book_room: attendee.book_room || false,
        plus_one: attendee.plus_one || false,
        prefers_twin:
          attendee.prefers_twin === true
            ? "Twin"
            : attendee.prefers_twin === false
            ? "Double"
            : "",
        assigned_hotel:
          props.event.hotels.find((h) => h.id === attendee.assigned_hotel)
            ?.name || "",
        sharing_room_with:
          props.event.attendees.find((a) => a.id === attendee.sharing_room_with)
            ?.name ||
          props.event.attendees.find((a) => a.id === attendee.sharing_room_with)
            ?.email ||
          "",
        room_setup: attendee.room_setup || "",
      };
      for (const date of attendee.dates) {
        attendeeValues[`attending_${date.date}`] = date.attending;
      }
      for (const date of attendee.dates) {
        attendeeValues[`hotel_${date.date}`] = date.hotel_required;
      }
      return attendeeValues;
    });
    setExportAttendees(ea);
  };

  useEffect(() => {
    prepareAttendeesForExport();
  }, [props.event.attendees]);

  const handleStartEdit = (attendee: EventAttendee) => {
    const newNameEdited = structuredClone(nameEdited);
    let editedName = newNameEdited.find(
      (nne: AttendeeAction) => nne.id === attendee.id
    );
    if (editedName) editedName.active = true;
    setNameEdited(newNameEdited);
  };

  const handleNameChange = (attendee: EventAttendee, name: string) => {
    const newAttendeeNames = structuredClone(attendeeNames);
    let editedName = newAttendeeNames.find(
      (nan: AttendeeNames) => nan.id === attendee.id
    );
    if (editedName) editedName.name = name;
    setAttendeeNames(newAttendeeNames);
  };

  const handleFinishEdit = async (attendee: EventAttendee) => {
    const newNameEdited = structuredClone(nameEdited);
    let editedName = newNameEdited.find(
      (nne: AttendeeAction) => nne.id === attendee.id
    );
    if (editedName) editedName.active = false;
    setNameEdited(newNameEdited);

    const newName = attendeeNames.find((an) => an.id === attendee.id)?.name;

    if ((newName || "").trim() !== (attendee.name || "").trim()) {
      try {
        await axios.post(
          `/api/users/events/1634${props.event.details.id}/attendees/${attendee.id}/name`,
          { name: newName?.trim() }
        );
      } catch (err) {
        console.log(err);
      } finally {
        props.onUpdate();
      }
    }
  };

  const handleSelectAll = (checked: boolean) => {
    let newSelectedAttendees = structuredClone(selectedAttendees);
    if (checked) {
      newSelectedAttendees.push(
        ...displayedAttendees
          .filter((a) => !a.is_silent)
          .map((a) => a.id)
          .filter((a) => !selectedAttendees.includes(a))
      );
    } else {
      newSelectedAttendees = newSelectedAttendees.filter(
        (id: string) => !displayedAttendees.map((a) => a.id).includes(id)
      );
    }
    setSelectedAttendees(newSelectedAttendees);
  };

  const handleSelectAttendee = (checked: boolean, attendee: string) => {
    const newSelectedAttendees = structuredClone(selectedAttendees);
    if (checked && !newSelectedAttendees.includes(attendee))
      newSelectedAttendees.push(attendee);
    if (!checked && newSelectedAttendees.includes(attendee))
      newSelectedAttendees.splice(newSelectedAttendees.indexOf(attendee), 1);
    setSelectedAttendees(newSelectedAttendees);
  };

  const handleEditAttendeeDates = async (attendee: EventAttendee) => {
    setSelectedAttendee(attendee);
    setShowAttendeeDatesModal(true);
  };

  const handleEditAttendeeGroups = async (attendee: EventAttendee) => {
    setSelectedAttendee(attendee);
    setShowAttendeeGroupsModal(true);
  };

  const handleViewAttendeePreferences = async (attendee: EventAttendee) => {
    setSelectedAttendee(attendee);
    setShowAttendeePreferencesModal(true);
  };

  return (
    <div>
      <AttendeeFilterModal
        modalIsOpen={showAttendeeFilterModal}
        setIsOpen={setShowAttendeeFilterModal}
        initialIndex={filterIndex}
        setShowAttending={setShowAttending}
        setShowNoReply={setShowNoReply}
        setShowNotAttending={setShowNotAttending}
        setShowPartialAttending={setShowPartialAttending}
        setShowInvited={setShowInvited}
        setShowNotInvited={setShowNotInvited}
        showInvited={showInvited}
        showNotInvited={showNotInvited}
        showAttending={showAttending}
        showPartialAttending={showPartialAttending}
        showNotAttending={showNotAttending}
        showNoReply={showNoReply}
        nameFilter={nameFilter}
        setNameFilter={setNameFilter}
        eventGroups={eventGroups}
        filterGroups={filterGroups}
        setFilterGroups={setFilterGroups}
        filterGroupsType={filterGroupType}
        filterGroupsOperator={filterGroupOperator}
        setFilterGroupsType={setFilterGroupsType}
        setFilterGroupsOperator={setFilterGroupsOperator}
      ></AttendeeFilterModal>
      <AttendeeGroupsModal
        modalIsOpen={showAttendeeGroupsModal}
        setIsOpen={setShowAttendeeGroupsModal}
        event={props.event}
        eventGroups={eventGroups}
        setEventGroups={setEventGroups}
        onUpdate={props.onUpdate}
        eventAttendee={selectedAttendee}
      ></AttendeeGroupsModal>
      <AttendeeDatesModal
        modalIsOpen={showAttendeeDatesModal}
        setIsOpen={setShowAttendeeDatesModal}
        event={props.event}
        onUpdate={props.onUpdate}
        eventAttendee={selectedAttendee}
      ></AttendeeDatesModal>
      <AttendeePreferencesModal
        modalIsOpen={showAttendeePreferencesModal}
        setIsOpen={setShowAttendeePreferencesModal}
        event={props.event}
        eventAttendee={selectedAttendee}
      ></AttendeePreferencesModal>
      {props.event.attendees.length > 0 ? (
        <div>
          <div className="eventAttendeeSummaryBar event-attendee-invite">
            <div>
              <Button
                disabled={
                  selectedAttendees.length === 0 ||
                  ["Ongoing", "Completed", "Cancelled"].includes(
                    props.event.details.status
                  )
                }
                onClick={() => {
                  props.setSelectedAttendees(selectedAttendees);
                  props.setSubPage("Create New Invite");
                }}
              >
                Invite
                {selectedAttendees.length > 0
                  ? ` ${selectedAttendees.length}`
                  : ""}{" "}
                selected
              </Button>
            </div>
            <div className="summaryBarItem">
              <h6>Showing</h6> {displayedAttendees.length} of{" "}
              {props.event.attendees.length} attendees
            </div>
            {props.event.attendees.filter(
              (a) =>
                !a.name &&
                a.book_room &&
                a.dates.find((ad) => ad.hotel_required)
            ).length > 0 && (
              <div
                className="summaryBarItem"
                data-tooltip-id="missingName"
                data-tooltip-place="top"
                data-tooltip-html="<span>Attendees with selected hotel stays and no saved name. Please add a name or they can't be added to hotel rooming lists.</span>"
              >
                <Tooltip className="eventTooltip" id="missingName" />
                <WarningIcon />
                {
                  props.event.attendees.filter(
                    (a) =>
                      !a.name &&
                      a.book_room &&
                      a.dates.find((ad) => ad.hotel_required)
                  ).length
                }{" "}
                requiring names
              </div>
            )}
            <div className="summaryBarItem export-btn">
              <CSVLink
                headers={CSVHeaders}
                data={exportAttendees}
                filename="attendees.csv"
                target="_blank"
                className="btn btn-secondary"
              >
                <span>Export Attendee List</span>
              </CSVLink>
            </div>
          </div>
          <Table className="attendee-list" caption="" highlightOnHover={false}>
            <TableHead>
              <TableRow>
                <TableCell as="th">
                  <Form.Check
                    id="selectAllAttendees"
                    name="selectAllAttendees"
                    checked={
                      displayedAttendees
                        .filter((a) => !a.is_silent)
                        .filter((a) => !selectedAttendees.includes(a.id))
                        .length === 0
                    }
                    onChange={(e) => handleSelectAll(e.target.checked)}
                  />
                </TableCell>
                <TableCell as="th">
                  Name
                  {nameFilter ? (
                    <button
                      className="attendee-table-filter clear-filter"
                      aria-label="Clear filter - attendee name"
                      onClick={() => {
                        setNameFilter(undefined);
                      }}
                    >
                      <div
                        data-tooltip-id="filterNameClear"
                        data-tooltip-place="top"
                        data-tooltip-html="<span>Clear filter - Name</span>"
                      >
                        <ActiveFilterIcon />
                        <Tooltip
                          className="eventTooltip"
                          id="filterNameClear"
                        />
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setFilterIndex(0);
                        setShowAttendeeFilterModal(true);
                      }}
                      className="attendee-table-filter"
                      aria-label="Filter by attendee name"
                    >
                      <div
                        data-tooltip-id="filterAttendeeNameTooltip"
                        data-tooltip-place="top"
                        data-tooltip-html="<span>Filter by attendee name</span>"
                      >
                        <FilterIcon />
                        <span className="visually-hidden">
                          Filter by attendee name
                        </span>
                        <Tooltip
                          className="eventTooltip"
                          id="filterAttendeeNameTooltip"
                        />
                      </div>
                    </button>
                  )}
                </TableCell>
                <TableCell as="th">
                  Invite sent{" "}
                  {!showInvited || !showNotInvited ? (
                    <button
                      className="attendee-table-filter clear-filter"
                      aria-label="Clear filter - invite status"
                      onClick={() => {
                        setShowInvited(true);
                        setShowNotInvited(true);
                      }}
                    >
                      <div
                        data-tooltip-id="filterInvitedClear"
                        data-tooltip-place="top"
                        data-tooltip-html="<span>Clear filter - Invite sent</span>"
                      >
                        <ActiveFilterIcon />
                        <Tooltip
                          className="eventTooltip"
                          id="filterInvitedClear"
                        />
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setFilterIndex(1);
                        setShowAttendeeFilterModal(true);
                      }}
                      className="attendee-table-filter"
                      aria-label="Filter by invite status"
                    >
                      <div
                        data-tooltip-id="filterAttendeeInviteTooltip"
                        data-tooltip-place="top"
                        data-tooltip-html="<span>Filter by invite status</span>"
                      >
                        <FilterIcon />
                        <span className="visually-hidden">
                          Filter by invite status
                        </span>
                        <Tooltip
                          className="eventTooltip"
                          id="filterAttendeeInviteTooltip"
                        />
                      </div>
                    </button>
                  )}
                </TableCell>
                <TableCell as="th">
                  Dates Attending
                  {!showAttending ||
                  !showNoReply ||
                  !showPartialAttending ||
                  !showNotAttending ? (
                    <button
                      className="attendee-table-filter clear-filter"
                      aria-label="Clear filter - attendee dates"
                      onClick={() => {
                        setShowAttending(true);
                        setShowNotAttending(true);
                        setShowPartialAttending(true);
                        setShowNoReply(true);
                      }}
                    >
                      <div
                        data-tooltip-id="filterDatesClear"
                        data-tooltip-place="top"
                        data-tooltip-html="<span>Clear filter - Dates</span>"
                      >
                        <ActiveFilterIcon />
                        <Tooltip
                          className="eventTooltip"
                          id="filterDatesClear"
                        />
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setFilterIndex(2);
                        setShowAttendeeFilterModal(true);
                      }}
                      className="attendee-table-filter"
                      aria-label="Filter by dates attending"
                    >
                      <div
                        data-tooltip-id="filterAttendeeDatesTooltip"
                        data-tooltip-place="top"
                        data-tooltip-html="<span>Filter by dates attending</span>"
                      >
                        <FilterIcon />
                        <span className="visually-hidden">
                          Filter by dates attending
                        </span>
                        <Tooltip
                          className="eventTooltip"
                          id="filterAttendeeDatesTooltip"
                        />
                      </div>
                    </button>
                  )}
                </TableCell>
                <TableCell as="th">
                  Groups
                  {filterGroups && filterGroups.length > 0 ? (
                    <button
                      className="attendee-table-filter clear-filter"
                      aria-label="Clear filter - attendee group"
                      onClick={() => {
                        setFilterGroups([]);
                        setFilterGroupsOperator(undefined);
                        setFilterGroupsType(undefined);
                      }}
                    >
                      <div
                        data-tooltip-id="filterAttendeesClear"
                        data-tooltip-place="top"
                        data-tooltip-html="<span>Clear filter - Groups</span>"
                      >
                        <ActiveFilterIcon />
                        <Tooltip
                          className="eventTooltip"
                          id="filterAttendeesClear"
                        />
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setFilterIndex(3);
                        setShowAttendeeFilterModal(true);
                      }}
                      className="attendee-table-filter"
                      aria-label="Filter by attendee group"
                    >
                      <div
                        data-tooltip-id="filterAttendeeGroupTooltip"
                        data-tooltip-place="top"
                        data-tooltip-html="<span>Filter by attendee group</span>"
                      >
                        <FilterIcon />
                        <span className="visually-hidden">
                          Filter by attendee group
                        </span>
                        <Tooltip
                          className="eventTooltip"
                          id="filterAttendeeGroupTooltip"
                        />
                      </div>
                    </button>
                  )}
                </TableCell>
                {props.event.details.type === "TEAM_EVENT" && (
                  <TableCell as="th">Preferences added</TableCell>
                )}
                <TableCell
                  as="th"
                  hidden={["Ongoing", "Completed", "Cancelled"].includes(
                    props.event.details.status
                  )}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedAttendees.map((attendee) => (
                <TableRow key={attendee.id}>
                  <TableCell
                    data-tooltip-id={attendee.is_silent ? "ManByOrgSelect" : ""}
                    data-tooltip-place="top"
                    data-tooltip-html="<span>This attendee is in a group that's managed by the event organiser, and does not receive emails. If you want to send an invite, first change their group membership.</span>"
                  >
                    {attendee.is_silent && (
                      <Tooltip className="eventTooltip" id="ManByOrgSelect" />
                    )}
                    <Form.Check
                      id="selectAttendee"
                      name="selectAttendee"
                      disabled={attendee.is_silent}
                      checked={selectedAttendees.includes(attendee.id)}
                      onChange={(e) =>
                        handleSelectAttendee(e.target.checked, attendee.id)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <div className="attendee-name-email">
                      {!["Ongoing", "Completed", "Cancelled"].includes(
                        props.event.details.status
                      ) &&
                      nameEdited.find((ne) => ne.id === attendee.id)?.active ? (
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleFinishEdit(attendee);
                          }}
                        >
                          <input
                            type="text"
                            id="nameEdit"
                            className="inline-input"
                            autoFocus
                            value={
                              attendeeNames.find((ne) => ne.id === attendee.id)
                                ?.name || ""
                            }
                            onChange={(e) => {
                              handleNameChange(attendee, e.target.value);
                            }}
                            onBlur={() => handleFinishEdit(attendee)}
                          />
                        </Form>
                      ) : (
                        <span
                          className="major-edit"
                          onClick={() => handleStartEdit(attendee)}
                        >
                          {!attendeeNames.find((ne) => ne.id === attendee.id)
                            ?.name &&
                            attendee.book_room &&
                            attendee.dates.find((d) => d.hotel_required) && (
                              <span className="data-missing">
                                <WarningIcon />
                                Name required
                              </span>
                            )}
                          {
                            attendeeNames.find((ne) => ne.id === attendee.id)
                              ?.name
                          }
                          {!["Ongoing", "Completed", "Cancelled"].includes(
                            props.event.details.status
                          ) && <EditIcon className="attendee-name-edit" />}
                        </span>
                      )}
                      <span className="minor">({attendee.email})</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {attendee.is_silent
                      ? "Managed by organiser"
                      : attendee.invite_sent
                      ? "Yes"
                      : "No"}
                  </TableCell>
                  <TableCell
                    onClick={(e) => handleEditAttendeeDates(attendee)}
                    className="dates-attending-cell interactive-cell"
                  >
                    <div className="reply-wrapper">
                      {attendee.has_replied ||
                      attendee.dates.filter((ad) => ad.attending).length > 0 ? (
                        attendee.dates.filter((ad) => ad.attending).length ===
                        0 ? (
                          <>Not attending</>
                        ) : props.event.dates.filter(
                            (d) =>
                              d.type === "Required" &&
                              !attendee.dates.find((date) => date.date === d.id)
                                ?.attending
                          ).length === 0 ? (
                          <CheckMark />
                        ) : (
                          <RedFlag />
                        )
                      ) : (
                        <>
                          <HelpIcon /> <b>No reply&nbsp;</b>{" "}
                        </>
                      )}
                      {props.event.dates
                        .filter(
                          (d) =>
                            attendee.dates.find((date) => date.date === d.id)
                              ?.attending
                        )
                        .map((d) => format(d.date, "eee"))
                        .join(", ")}
                      <CaretDown />
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={(e) => handleEditAttendeeGroups(attendee)}
                    className="dates-attending-cell interactive-cell"
                  >
                    <div className="reply-wrapper">
                      {attendee.categories && attendee.categories.length > 0 ? (
                        <>
                          {attendee.categories.length} group
                          {attendee.categories.length > 1 ? "s" : ""} set
                        </>
                      ) : (
                        <>No groups</>
                      )}
                      <CaretDown />
                    </div>
                  </TableCell>
                  {props.event.details.type === "TEAM_EVENT" && (
                    <TableCell
                      onClick={(e) =>
                        attendee.room_preferences ||
                        attendee.plus_one ||
                        attendee.sharing_preferences
                          ? handleViewAttendeePreferences(attendee)
                          : null
                      }
                    >
                      {attendee.room_preferences ||
                      attendee.plus_one ||
                      attendee.sharing_preferences ? (
                        <span className="clickable">Yes</span>
                      ) : (
                        "No"
                      )}
                    </TableCell>
                  )}
                  <TableCell
                    className="manage-attendee-button"
                    hidden={["Ongoing", "Completed", "Cancelled"].includes(
                      props.event.details.status
                    )}
                    onClick={() => {
                      props.setSelectedAttendee(attendee);
                      props.setSubPage("Manage Attendee");
                    }}
                  >
                    Manage <MenuOverFlowVertical />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <>
          <div className="event-not-accepted-wrapper">
            <AttendeesEmpty />
            <h3>You've not invited anyone to your event.</h3>
            <p>
              Is it really a party if nobody comes? Start adding your attendees
              so we can collect their attendance and accomodation preferences.
            </p>

            <Button
              className="invite-or-add"
              variant="secondary"
              onClick={() => props.setSubPage("Add People")}
            >
              Add attendees
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EventAttendeeList;
