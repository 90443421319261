// useHotels.js
import { useEffect } from "react";
import useAuthenticatedAxios from "./useAuthenticatedAxios";
import { Customer } from "./useCustomers";

export type Hotel = {
  name: string;
  address: string;
  description: string;
  stars: number;
  b_com_uri: string;
  website_url: string;
  contact_name: string;
  contact_email: string;
  image_url: string;
};

export type Offer = {
  id: number;
  offer_type: string;
  meal_plan: string;
  room_type: string;
  flexibility: string;
  payment: string;
  static_rate: number;
  static_currency: string;
  dynamic_percentage: number;
  season_start_date: Date;
  season_end_date: Date;
};

export enum DealType {
  CNR = "CNR",
  OFFSITE = "Offsite",
  RETREAT = "Retreat",
  GROUPRATE = "GroupRate",
}

export type HotelDeal = {
  id: number;
  signed_by_corporate: boolean;
  setup_requested: boolean;
  setup_complete: boolean;
  contract_url: string;
  minimum_room_nights: number;
  booking_instructions: string;
  type: DealType;
  hotel: Hotel;
  offers: Offer[];
  is_offline_contract: boolean;
};

const useCustomerDeals = (
  signatureModalIsOpen: boolean,
  customer: Customer | null | undefined,
  hotelDealId: number | null,
  setHotelDeals: React.Dispatch<React.SetStateAction<HotelDeal[]>>,
  setLoadingHotels: React.Dispatch<React.SetStateAction<boolean>>,
  filterSetupDeals: boolean
): void => {
  const [axios] = useAuthenticatedAxios(false);

  useEffect(() => {
    const fetchHotels = async () => {
      if (customer || hotelDealId) {
        try {
          setHotelDeals([]);
          setLoadingHotels(true);

          let dealResponse = null;
          if (customer)
            dealResponse = await axios.get(
              `/api/users/customers/${customer.id}/hotelDeals`
            );
          else
            dealResponse = await axios.get(
              `/api/users/hotelDeals/${hotelDealId}`
            );

          if (filterSetupDeals)
            dealResponse.data = dealResponse.data.filter(
              (d: HotelDeal) => d.signed_by_corporate && d.type === DealType.CNR
            );

          setHotelDeals(dealResponse.data);
          setLoadingHotels(false);
        } catch (error) {
          console.error("Error fetching hotels:", error);
          setLoadingHotels(false);
        }
      }
    };
    fetchHotels();
  }, [
    axios,
    customer,
    filterSetupDeals,
    hotelDealId,
    setHotelDeals,
    setLoadingHotels,
    signatureModalIsOpen,
  ]);
};

export default useCustomerDeals;
