import React from "react";
import Modal from "react-modal";

import "./Events.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "640px",
  },
};

function PricingListModal({modalIsOpen, setIsOpen, user}) {
  Modal.setAppElement("#root");

  const closeModal = () => {setIsOpen(false)}

  return (
    <Modal
      isOpen={modalIsOpen && user}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"Pricing List"}
    >
      <stripe-pricing-table
          pricing-table-id="prctbl_1PYkfTKUlcumV1K8LpQLPqyD"
          publishable-key="pk_live_51PSfXQKUlcumV1K8CLfVCUspCW8TZa9RamkOaXMg89ExQsRJSRsHm4HLdqgRcWDWX0gSayiAR0PMyuFvNEpZeRFn00O3tCzqXW"
          client-reference-id={user.sub.replace("auth0|", "")}
          customer-email={user.email}
        ></stripe-pricing-table>
    </Modal>
  );
}

export default PricingListModal;
