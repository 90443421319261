import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Event, EventAttendee, EventAttendeeDate } from "./EventStatus";
import { ReactComponent as InfoIcon } from "../img/information-circle.svg";
import { Button, Form } from "react-bootstrap";
import { format } from "date-fns";
import axios from "axios";

import "./Events.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.75)",
    zIndex: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0 0 0 2px #141414",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "640px",
  },
};

function AttendeeDatesModal(props: {
  modalIsOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: Event;
  eventAttendee: EventAttendee | undefined;
  onUpdate: Function;
}) {
  // Accessibility enhancement: https://reactcommunity.org/react-modal/accessibility/
  Modal.setAppElement("#root");

  const [attendeeDates, setAttendeeDates] = useState<EventAttendeeDate[]>([
    ...(props.eventAttendee?.dates || []),
  ]);
  const [isPastCancellationDeadline, setIsPastCancellationDeadline] =
    useState(false);

  useEffect(() => {
    setAttendeeDates([...(props.eventAttendee?.dates || [])]);
    const now = new Date();
    const cancellationDeadline = new Date(
      props.event.details.cancellation_deadline
    );
    console.log(props.event.details.cancellation_deadline);
    setIsPastCancellationDeadline(
      props.event.details.cancellation_deadline !== null &&
        cancellationDeadline.getTime() - now.getTime() < 0
    );
  }, [props.eventAttendee]);

  function closeModal() {
    props.setIsOpen(false);
  }

  const toggleAttendConfirmation = async (e: any, dateId: number) => {
    let cd = [...attendeeDates];
    let changeDate = cd.find((ad) => ad.date === dateId);
    if (changeDate) changeDate.attending = e.target.checked;

    // May not have been created yet if user is just invited
    if (!changeDate) {
      cd = [
        ...cd,
        {
          date: dateId,
          attending: e.target.checked,
          hotel_required: false,
          assigned_hotel: 0,
          sharing_room_with: "",
          room_setup: "",
          reference: "",
        }, // hotel_required set to false as it would be true if it was checked
      ];
    }
    setAttendeeDates(cd);
  };

  const toggleHotelRequiredConfirmation = async (e: any, dateId: number) => {
    let cd = [...attendeeDates];
    let changeDate = cd.find((ad) => ad.date === dateId);
    if (changeDate) changeDate.hotel_required = e.target.checked;

    // May not have been created yet if user is just invited
    if (!changeDate) {
      cd = [
        ...cd,
        {
          date: dateId,
          hotel_required: e.target.checked,
          attending: false,
          assigned_hotel: 0,
          sharing_room_with: "",
          room_setup: "",
          reference: "",
        }, // attending set to false as it would be true if it was checked
      ];
    }

    setAttendeeDates(cd);
  };

  const handleSave = async () => {
    try {
      await axios.patch(
        `/api/event-external/events/1634${props.event.details.id}/attendees/${props.eventAttendee?.id}?organiser=true`,
        {
          attendeeDates: attendeeDates,
        }
      );
      props.setIsOpen(false);
      props.onUpdate();
    } catch (error) {
      console.error("Error submitting preferences:", error);
      alert("We could not submit your changes");
    }
  };

  const getSanitaryCheckedValue = (
    attendeeDates: Array<EventAttendeeDate | undefined>,
    dateId: any,
    type: string
  ) => {
    const value = attendeeDates.find((ad) => ad?.date === dateId);

    if (typeof value === "undefined") return false;
    let valueToInspect;

    if (type === "hotel_required") {
      valueToInspect = value?.hotel_required;
    } else {
      valueToInspect = value?.attending;
    }

    return typeof valueToInspect === "undefined" ? false : valueToInspect;
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit attendance dates"
    >
      <div className="event-management-modal">
        <h4>Edit attendance dates</h4>
        {props.eventAttendee && (
          <div>
            <p>
              You're {props.eventAttendee.has_replied ? "editing" : "adding"}{" "}
              dates for <b>{props.eventAttendee.email}</b>
            </p>
            {isPastCancellationDeadline && (
              <>
                <div className="information warning">
                  <p>
                    Hotel bookings and room allocations have now been finalised
                    for this event. You can still adjust attendance, but rooms
                    cannot be added or removed any longer.
                  </p>
                </div>
                <p></p>
              </>
            )}
            <div className="editAttendeeTable">
              <table>
                <thead>
                  <tr>
                    <th>Attending?</th>
                    <th>Hotel required?</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {props.event.dates
                    .sort((a, b) =>
                      new Date(a.date.toString()) > new Date(b.date.toString())
                        ? 1
                        : -1
                    )
                    .map((eventDate) => (
                      <tr key={eventDate.id}>
                        <td>
                          {eventDate.type !== "Travel" && (
                            <Form.Check
                              disabled={[
                                "Ongoing",
                                "Completed",
                                "Cancelled",
                              ].includes(props.event.details.status)}
                              id="attending"
                              name="attending"
                              type="checkbox"
                              checked={getSanitaryCheckedValue(
                                attendeeDates,
                                eventDate.id,
                                "attending"
                              )}
                              onChange={(e) => {
                                toggleAttendConfirmation(e, eventDate.id);
                              }}
                            ></Form.Check>
                          )}
                        </td>
                        <td>
                          {eventDate.hotel_required && (
                            <Form.Check
                              disabled={
                                isPastCancellationDeadline ||
                                ["Ongoing", "Completed", "Cancelled"].includes(
                                  props.event.details.status
                                )
                              }
                              id="hotelRequired"
                              name="hotelRequired"
                              type="checkbox"
                              checked={getSanitaryCheckedValue(
                                attendeeDates,
                                eventDate.id,
                                "hotel_required"
                              )}
                              onChange={(e) => {
                                toggleHotelRequiredConfirmation(
                                  e,
                                  eventDate.id
                                );
                              }}
                            ></Form.Check>
                          )}
                        </td>
                        <td>
                          <b>{format(eventDate.date, "eeee")}</b>{" "}
                          {format(eventDate.date, "dd MMM yyyy")}
                        </td>
                        <td>
                          {eventDate.type === "Travel"
                            ? "Non-event Date"
                            : "Event Date"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <span className="info-line">
              <InfoIcon />
              <span>
                Attendees will <strong>not</strong> be notified of changes you
                make here.
              </span>
            </span>
          </div>
        )}
        <footer className="modalButtonFooter">
          <Button className="btn-secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={["Ongoing", "Completed", "Cancelled"].includes(
              props.event.details.status
            )}
            onClick={handleSave}
          >
            Save
          </Button>
        </footer>
      </div>
    </Modal>
  );
}

export default AttendeeDatesModal;
