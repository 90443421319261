import React, { useState } from "react";

import BenefitsList from "../../components/BenefitsList";
import DealLocationSelector from "../../components/DealLocationSelector";
import DealList from "../../components/DealList";
import HotelProgramMeta from "../../components/HotelProgramMeta";
import HotelProgramSidebar from "../../components/HotelProgramSidebar";

import NoPermission from "../Navigation/NoPermission";
import { Image } from "react-bootstrap";

import { ReactComponent as HeadsetIcon } from "../../img/headset.svg";
import { ReactComponent as LightningIcon } from "../../img/lightning.svg";
import { ReactComponent as GiftIcon } from "../../img/gift.svg";

import "../../components/HotelProgram.css";
import { usePostHog } from "posthog-js/react";

const HotelProgramLanding = ({
  hasDirectBookingServicePermission,
  hasEventServicePermission,
  userAuth,
  user,
}) => {
  const [data, setData] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const posthog = usePostHog();
  posthog.capture("Service Visit", {
    service: "Frequent Traveller",
  });

  if (!hasDirectBookingServicePermission && !userAuth.isLoading) {
    return (
      <NoPermission
        user={user}
        headline={`You don't have permission to use the direct booking tool`}
        logout={userAuth.logout}
      />
    );
  }

  return (
    <div className="hotel-program-display">
      {!isLoading && !error && (
        <>
          <div className="page-split">
            <div className="dark-column">
              <HotelProgramSidebar data={data} />
            </div>
            <div className="light-column">
              <HotelProgramMeta
                setData={setData}
                setIsLoading={setIsLoading}
                setError={setError}
                data={data}
                showEventLink={hasEventServicePermission}
              />
              {data && (
                <>
                  <DealLocationSelector
                    deals={data.deals}
                    selectedDeal={selectedDeal}
                    setSelectedDeal={setSelectedDeal}
                  />
                  <DealList
                    deals={selectedDeal ? [selectedDeal] : data.deals}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HotelProgramLanding;
