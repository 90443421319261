import React, { useState, useEffect } from "react";
import { ReactComponent as Badge } from "../img/badge.svg";
import { ReactComponent as AngledArrow } from "../img/angled-arrow-ccw.svg";
import { Tooltip } from "react-tooltip";

import {
  Event,
  EventAttendee,
  EventAttendeeDate,
  EventHotel,
} from "./EventStatus";
import {
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Button,
} from "@aws-amplify/ui-react";
import { Form } from "react-bootstrap";

import "./Events.css";
import useAuthenticatedAxios from "../lib/useAuthenticatedAxios";
import AttendeePreferencesModal from "./AttendeePreferencesModal";
import BannerAlert from "./BannerAlert/BannerAlert";

const EditRoomingList = (props: {
  event: Event;
  onUpdate: Function;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [axios] = useAuthenticatedAxios(true);

  const [submitting, setSubmitting] = useState(false);
  const [attendees, setAttendees] = useState<EventAttendee[]>([]);
  const [combinedAttendees, setCombinedAttendees] = useState<EventAttendee[]>(
    []
  );
  const [showAttendeePreferencesModal, setShowAttendeePreferencesModal] =
    useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState<EventAttendee>();

  useEffect(() => {
    const newAttendees = props.event.attendees
      .filter((a) => a.book_room && a.dates.find((d) => d.hotel_required))
      .sort((a: EventAttendee, b: EventAttendee) =>
        a.assigned_hotel < b.assigned_hotel ? -1 : 1
      );
    newAttendees.forEach((attendee) =>
      attendee.dates.forEach((date: EventAttendeeDate) => {
        date.assigned_hotel = attendee.assigned_hotel;
        date.sharing_room_with = attendee.sharing_room_with;
        date.room_setup = attendee.room_setup;
      })
    );
    setAttendees(newAttendees);
  }, [props.event.attendees]);

  useEffect(() => {
    const ca = attendees
      .filter((a) => a.book_room)
      .filter((a) => !a.sharing_room_with || a.id > a.sharing_room_with)
      .map((a) => {
        return {
          ...a,
          dates: [
            ...a.dates,
            ...(props.event.attendees
              .find((sp) => sp.id === a.sharing_room_with)
              ?.dates.filter(
                (spd) =>
                  spd.hotel_required &&
                  !a.dates
                    .filter((ad) => ad.hotel_required)
                    .map((ad) => ad.date)
                    .includes(spd.date)
              ) || []),
          ],
        };
      });
    setCombinedAttendees(ca);
  }, [attendees]);

  const assignedRoomNights = (hotel: EventHotel) => {
    return combinedAttendees
      .map(
        (a) =>
          a.dates.filter(
            (d: EventAttendeeDate) =>
              d.hotel_required && d.assigned_hotel === hotel.id
          ).length
      )
      .reduce((i, acc) => acc + i, 0);
  };

  const handleChangeHotel = (attendeeId: string, hotelId: number) => {
    const newAttendees = structuredClone(attendees);
    const attendee = newAttendees.find(
      (a: EventAttendee) => a.id === attendeeId
    );
    if (attendee) {
      attendee.assigned_hotel = hotelId;
      attendee.dates.forEach((date: EventAttendeeDate) => {
        date.assigned_hotel = hotelId;
      });
      const sharingPartner = newAttendees.find(
        (a: EventAttendee) => a.id === attendee.sharing_room_with
      );
      if (sharingPartner) {
        sharingPartner.assigned_hotel = hotelId;
        sharingPartner.dates.forEach((date: EventAttendeeDate) => {
          date.assigned_hotel = hotelId;
        });
      }
    }
    setAttendees(newAttendees);
  };

  const handleChangeRoomSetup = (attendeeId: string, setup: string) => {
    const newAttendees = structuredClone(attendees);
    const attendee = newAttendees.find(
      (a: EventAttendee) => a.id === attendeeId
    );
    if (attendee) {
      attendee.room_setup = setup;
      attendee.dates.forEach((date: EventAttendeeDate) => {
        date.room_setup = setup;
      });
      const sharingPartner = newAttendees.find(
        (a: EventAttendee) => a.id === attendee.sharing_room_with
      );
      if (sharingPartner) {
        sharingPartner.room_setup = setup;
        sharingPartner.dates.forEach((date: EventAttendeeDate) => {
          date.room_setup = setup;
        });
      }
    }
    setAttendees(newAttendees);
  };

  const handleViewAttendeePreferences = async (attendee: EventAttendee) => {
    setSelectedAttendee(attendee);
    setShowAttendeePreferencesModal(true);
  };

  const handleChangeSharingPartner = (attendeeId: string, partner: string) => {
    const newAttendees = structuredClone(attendees);
    const attendee = newAttendees.find(
      (a: EventAttendee) => a.id === attendeeId
    );
    if (attendee) {
      // Remove the link from the existing sharing partner
      const oldSharingPartner = newAttendees.find(
        (a: EventAttendee) => a.id === attendee.sharing_room_with
      );
      if (oldSharingPartner) {
        oldSharingPartner.sharing_room_with = "";
        oldSharingPartner.dates.forEach((date: EventAttendeeDate) => {
          date.sharing_room_with = "";
        });
      }

      // Add link to the attendee themselves
      attendee.sharing_room_with = partner;
      attendee.dates.forEach((date: EventAttendeeDate) => {
        date.sharing_room_with = partner;
      });

      // Find new sharing partner
      const sharingPartner = newAttendees.find(
        (a: EventAttendee) => a.id === attendee.sharing_room_with
      );
      if (sharingPartner) {
        // Remove the link from their old sharing partner
        const partneroldSharingPartner = newAttendees.find(
          (a: EventAttendee) => a.id === sharingPartner.sharing_room_with
        );
        if (partneroldSharingPartner) {
          partneroldSharingPartner.sharing_room_with = "";
          partneroldSharingPartner.dates.forEach((date: EventAttendeeDate) => {
            date.sharing_room_with = "";
          });
        }

        // Now also add the link from the new sharing partner to the attendee, and update hotel and room type
        sharingPartner.sharing_room_with = attendee.id;
        sharingPartner.assigned_hotel = attendee.assigned_hotel;
        sharingPartner.room_setup = attendee.room_setup;

        sharingPartner.dates.forEach((date: EventAttendeeDate) => {
          date.sharing_room_with = attendee.id;
          date.assigned_hotel = attendee.assigned_hotel;
          date.room_setup = attendee.room_setup;
        });
      }
    }
    setAttendees(newAttendees);
  };

  const handleClear = () => {
    const newAttendees = structuredClone(attendees);
    newAttendees.forEach((attendee: EventAttendee) => {
      attendee.assigned_hotel = 0;
      attendee.room_setup = attendee.prefers_twin ? "Twin" : "Double";
      attendee.sharing_room_with = "";
      attendee.dates.forEach((date: EventAttendeeDate) => {
        date.assigned_hotel = 0;
        date.room_setup = attendee.prefers_twin ? "Twin" : "Double";
        date.sharing_room_with = "";
      });
    });
    setAttendees(newAttendees);
  };

  const handleSave = async () => {
    try {
      setSubmitting(true);
      await axios.put(
        `/api/users/events/1634${props.event.details.id}/rooming-list`,
        {
          roomingList: attendees.map((a) => {
            return {
              attendee: a.id,
              hotel: a.assigned_hotel,
              sharing: a.sharing_room_with,
              setup: a.room_setup,
            };
          }),
          attendees: attendees.map((a) => {
            return {
              id: a.id,
              hotel_dates: a.dates.map((d) => {
                return {
                  date_id: d.date,
                  assigned_hotel: d.assigned_hotel,
                  room_setup: d.room_setup,
                  sharing_room_with: d.sharing_room_with,
                };
              }),
            };
          }),
        }
      );
      props.onUpdate();
      props.setIsEdit(false);
    } catch (error) {
      console.error("Error saving rooming list:", error);
      alert("Error Saving Rooming List");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSuggest = async () => {
    try {
      setSubmitting(true);
      const suggestionResponse = await axios.put(
        `/api/users/events/1634${props.event.details.id}/suggest-rooming-list`,
        {}
      );
      const rl = suggestionResponse.data.roomingList;
      const newAttendees = structuredClone(attendees);
      newAttendees.forEach((attendee: EventAttendee) => {
        const attendeeRl = rl[attendee.id];
        attendee.assigned_hotel = attendeeRl.hotel;
        attendee.room_setup = attendeeRl.setup;
        attendee.sharing_room_with = attendeeRl.sharing;
        attendee.dates.forEach((date: EventAttendeeDate) => {
          date.assigned_hotel = attendeeRl.hotel;
          date.room_setup = attendeeRl.setup;
          date.sharing_room_with = attendeeRl.sharing;
        });
      });
      setAttendees(newAttendees);
    } catch (error) {
      console.error("Error suggesting rooming list:", error);
      alert("We could not suggest a rooming list");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <AttendeePreferencesModal
        modalIsOpen={showAttendeePreferencesModal}
        setIsOpen={setShowAttendeePreferencesModal}
        event={props.event}
        eventAttendee={selectedAttendee}
      ></AttendeePreferencesModal>
      <div className="organiser-rooming-list">
        <div className="rooming-list-header">
          <h4 className="rooming-list-title">Edit Rooming List</h4>
          <Button
            className="btn-secondary"
            disabled={
              submitting ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
            onClick={() => props.setIsEdit(false)}
          >
            Discard changes
          </Button>
          <Button
            className="btn-primary"
            disabled={
              submitting ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
            onClick={handleSave}
            variation="primary"
          >
            Save changes
          </Button>
        </div>
        <div className="eventAttendeeSummaryBar">
          <div className="summaryBarItem">
            <h6>Allocated</h6>{" "}
            {attendees.filter((a) => a.assigned_hotel).length} of{" "}
            {attendees.length} attendees
          </div>
          <Button
            className="btn-secondary danger-button"
            disabled={
              submitting ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
            onClick={handleClear}
            variation="primary"
          >
            Clear Rooming List
          </Button>

          <Button
            className="btn-secondary"
            disabled={
              submitting ||
              ["Ongoing", "Completed", "Cancelled"].includes(
                props.event.details.status
              )
            }
            onClick={handleSuggest}
            variation="primary"
          >
            Suggest Rooming List
          </Button>
        </div>
        {props.event.hotels
          .filter((hotel) => assignedRoomNights(hotel) > hotel.contracted_rooms)
          .map((hotel) => (
            <BannerAlert
              key={`overassigned-${hotel.id}`}
              variant="BannerNegative"
            >
              You have assigned{" "}
              {assignedRoomNights(hotel) - hotel.contracted_rooms} more room
              nights to {hotel.name} than you have contracted.
            </BannerAlert>
          ))}
        {props.event.hotels
          .filter(
            (hotel) =>
              assignedRoomNights(hotel) <
              hotel.contracted_rooms - hotel.cancellable_rooms
          )
          .map((hotel) => (
            <BannerAlert
              key={`underassigned-${hotel.id}`}
              variant="BannerNegative"
            >
              There are{" "}
              {hotel.contracted_rooms -
                hotel.cancellable_rooms -
                assignedRoomNights(hotel)}{" "}
              unassigned non-refundable room nights at {hotel.name}.
            </BannerAlert>
          ))}
        <Table
          className="attendee-list rooming-list"
          caption=""
          highlightOnHover={false}
        >
          <TableHead>
            <TableRow>
              <TableCell as="th">Name</TableCell>
              <TableCell as="th">Preferences</TableCell>
              <TableCell as="th">Team(s)</TableCell>
              <TableCell as="th">Hotel(s)</TableCell>
              <TableCell as="th">Room Type(s)</TableCell>
              <TableCell as="th">Nights</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendees.map((attendee) => (
              <TableRow key={attendee.id}>
                <TableCell>
                  <div className="attendee-name-email">
                    <span
                      className=" major"
                      data-tooltip-id="attendee-tooltip"
                      data-tooltip-place="top"
                      data-tooltip-html={attendee.email}
                    >
                      <Tooltip id="attendee-tooltip" />
                      {attendee.name || attendee.email}
                      {attendee.plus_one && (
                        <>
                          {" "}
                          <Badge />
                        </>
                      )}
                    </span>
                  </div>
                  {attendee?.categories.find((c: string) =>
                    props.event.groups.find(
                      (g) => g.name === c && g.must_share_room
                    )
                  ) && (
                    <div className="attendee-name-email">
                      <span className="major rooming-list-sharing">
                        <AngledArrow />
                        <span>
                          <Form.Select
                            disabled={
                              submitting ||
                              ["Ongoing", "Completed", "Cancelled"].includes(
                                props.event.details.status
                              )
                            }
                            id="toggle-check"
                            className="selectSharingPartner"
                            value={attendee.sharing_room_with}
                            onChange={(e) => {
                              handleChangeSharingPartner(
                                attendee.id,
                                e.target.value
                              );
                            }}
                          >
                            <option value=""></option>
                            {attendees
                              .filter((a) =>
                                a.categories.find((c: string) =>
                                  props.event.groups.find(
                                    (g) => g.name === c && g.must_share_room
                                  )
                                )
                              )
                              .filter((a) => a.id !== attendee.id)
                              .map((a) => (
                                <option key={a.id} value={a.id}>
                                  {a.name || a.email}
                                </option>
                              ))}
                          </Form.Select>
                        </span>
                      </span>
                    </div>
                  )}
                </TableCell>
                <TableCell
                  onClick={(e) =>
                    attendee.room_preferences ||
                    attendee.plus_one ||
                    attendee.sharing_preferences
                      ? handleViewAttendeePreferences(attendee)
                      : null
                  }
                >
                  {attendee.room_preferences ||
                  attendee.plus_one ||
                  attendee.sharing_preferences ? (
                    <span className="clickable">Show</span>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell>
                  <div>
                    {attendee?.categories
                      .filter((c) =>
                        props.event.groups
                          .filter((g) => g.is_team)
                          .map((g) => g.name)
                          .includes(c)
                      )
                      .join(", ")}
                  </div>
                </TableCell>
                <TableCell>
                  <Form.Select
                    disabled={
                      submitting ||
                      ["Ongoing", "Completed", "Cancelled"].includes(
                        props.event.details.status
                      )
                    }
                    id="toggle-check"
                    className="selectHotel"
                    value={attendee.assigned_hotel}
                    onChange={(e) => {
                      handleChangeHotel(attendee.id, Number(e.target.value));
                    }}
                  >
                    <option value=""></option>
                    {props.event.hotels.map((h) => (
                      <option key={h.id} value={h.id}>
                        {h.name}
                      </option>
                    ))}
                  </Form.Select>
                </TableCell>
                <TableCell>
                  <Form.Select
                    disabled={
                      submitting ||
                      ["Ongoing", "Completed", "Cancelled"].includes(
                        props.event.details.status
                      )
                    }
                    id="toggle-check"
                    className="selectHotel"
                    value={
                      attendee.room_setup ||
                      (attendee.prefers_twin
                        ? "Twin"
                        : attendee.sharing_room_with
                        ? "Twin"
                        : "Double")
                    }
                    onChange={(e) => {
                      handleChangeRoomSetup(attendee.id, e.target.value);
                    }}
                  >
                    <option value="Double">Double</option>
                    <option value="Twin">Twin</option>
                  </Form.Select>
                </TableCell>
                <TableCell>
                  {attendee.dates.filter((d) => d.hotel_required).length}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default EditRoomingList;
