import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { utcToZonedTime } from "date-fns-tz";

import { TabItem, Tabs } from "@aws-amplify/ui-react";
import { ReactComponent as ImageHome } from "../../img/home.svg";

import { useAuth0 } from "@auth0/auth0-react";
import useAuthenticatedAxios from "../../lib/useAuthenticatedAxios";

import { Loader } from "@aws-amplify/ui-react";
import EventDetails from "../../components/EventDetails";
import EventStatus from "../../components/EventStatus";
import ManageDates from "../../components/ManageDates";
import EventAttendees from "../../components/EventAttendees";
import Error from "../../components/Error";
import EventPolicy from '../../components/EventPolicy';
import ExternalBookingsHeader from "../../components/ExternalBookingsHeader";


const ExternalBooking = () => {
  let userAuth = useAuth0();
  const [axios, tokenSet] = useAuthenticatedAxios(true);

  const { eventId } = useParams();
  const [loadingEventData, setLoadingEventData] = useState(true);
  const [event, setEvent] = useState(null);
  const [refetchEvent, setRefetchEvent] = useState(false);
  const [index, setIndex] = useState(0);


  useEffect(() => {
    if (!userAuth.isAuthenticated && !userAuth.isLoading) {
      userAuth.loginWithRedirect({
        appState: { path: window.location.pathname },
        authorizationParams: {
          redirect_uri: window.location.origin + "/callback",
        },
      });
    } else if(tokenSet) {
      const fetchEventDetails = async () => {
        try {
          setLoadingEventData(true);
          const response = await axios.get(`/api/users/events/${eventId}`);
          setEvent({
            realEvent: response.data.event ? true : false,
            details: response.data.prospective_event,
            dates: response.data.event_dates.sort((a, b) =>
            a.date > b.date ? 1 : b.date > a.date ? -1 : 0).map((d) => {return {...d, date: utcToZonedTime(
              new Date(
                d.date.toString() +
                  "T00:00:00Z"
              ),
              "Etc/UTC"
            )}}),
            attendees: response.data.event_attendees,
            owner: {name: response.data.event?.owner_name, email: response.data.event?.owner_email},
            calendlyInvite: undefined,
          });
          setLoadingEventData(false);
        } catch (error) {
          console.error("Error fetching event details:", error);
          setLoadingEventData(false);
        }
      };

      fetchEventDetails();
    }
  }, [axios, eventId, tokenSet, userAuth, refetchEvent]);


  return (
    <Container fluid>
      <Row className="full-height-row eventTracker">
        <Col className="dark-column limit-width">
          <div className="dark-column-content">
          {(userAuth.isAuthenticated &&
            <EventDetails event={event} user="organiser"/>)}
          </div>
        </Col>
        <Col className="light-column event-view-wrapper">
          <ExternalBookingsHeader />
          {(userAuth.isAuthenticated &&
            <div className="light-column-content">
              {event?.details ? (
                <div>
                
                <div className="events-tabs">
                  <Tabs className="header" currentIndex={index} onChange={(i) => setIndex(i)}>
                    <TabItem title={<ImageHome />} >
                      <EventStatus event={event} setIndex={setIndex} />
                    </TabItem>
                    <TabItem title="Manage Dates">
                      <ManageDates event={event} onUpdate={() => setRefetchEvent(!refetchEvent)} />
                    </TabItem>
                    <TabItem title="Attendees" >
                      <EventAttendees event={event} setEvent={setEvent} onUpdate={() => setRefetchEvent(!refetchEvent)} />
                    </TabItem>
                    <TabItem title="Travel policy">
                      <EventPolicy event={event} />
                    </TabItem>
                  </Tabs>
                </div>
                </div>
              ) : (loadingEventData || !tokenSet) ? (
                <Loader variation="linear" />
              ) : (
                <div className="overall-summary">
                  <Error />
                </div>
              )}
            </div>
            )}
        </Col>
      </Row>
    </Container>
  );
};

export default ExternalBooking;
