import "./NavItem.css";

import React from "react";

interface NavItemProps {
  title: string;
  path: string;
  active: boolean;
  onClick?: React.MouseEventHandler;
  variant?: string;
  icon?: React.ReactNode;
}

const NavItem = ({
  title,
  path,
  active,
  onClick,
  variant = "default",
  icon,
}: NavItemProps) => {
  return (
    <li className="NavItem">
      <span
        className={`NavItemLink ${active ? "Active" : ""} ${
          variant === "icon" ? "icon" : ""
        }`}
        onClick={onClick}
      >
        {variant === "icon" && icon}
        <span className="hide-on-mobile">{title}</span>
      </span>
    </li>
  );
};

export default NavItem;
